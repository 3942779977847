import { useState, useEffect} from 'react';
import  Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import MetaData from '../../layouts/MetaData';
import defaultImg from '../../assets/Images/doctorIcon.png';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getMessages, submitMessage, getHrLists } from '../../actions/adminActions';
import Loader from '../../layouts/Loader';
import { Modal } from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import assistantImg from '../../assets/Images/assistant.png';
import { 
    getCCMPatients,  
    getPatients
} from '../../actions/assistantActions';


const AssistantMessages = () => {


const { user } = useSelector(state => state.auth);

 let assistantId = user?._id;

 const dispatch = useDispatch();
 const alert = useAlert();
 const history = useHistory();

 const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    
 const {error, message} = useSelector(state => state.common);
 const {loading, messages} = useSelector(state => state.messages);
 const { patients} = useSelector(state => state.admin);
 const { hrs } = useSelector(state => state.hrslist);
 
 const [currentPage, setCurrentPage] = useState(1);
 const [resPerPage, setResPerPage] = useState(300);
 
 const [hrId, setHrId] = useState('');
 const [patientId, setPatientId] = useState('');
 const [question, setQuestion] = useState('');
 const [patientType, setPatientType] = useState('RPM');
 
 let drIds = localStorage.getItem('myArray');
 

 const handleSubmit = () => {    
    dispatch(submitMessage(assistantId, hrId, patientId, question));
    handleClose();
 }

    useEffect(() => {
        if(message){
            alert.success(message);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }
    
        if(error){
            alert.error(error)

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }
        
        dispatch(getMessages({getFor:"admin"}));
        dispatch(getHrLists());


        if(patientType === 'RPM'){
            dispatch(getPatients(resPerPage, currentPage, drIds))
        } else {
            dispatch(getCCMPatients(resPerPage, currentPage, drIds));
        }
    
      },[dispatch, alert, error, message, patientType])
      

  return (
    <>
    <MetaData title="Messages"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

            <div className="row-display">
                <div className="col-md-6">   
                    <h5 style={{color: '#00A8A8', marginTop: '10px'}}>
                        <i className="bx bx-user"></i> <strong>&nbsp;Messages </strong>
                    </h5>
                </div>
                
                <div className="row-display">
                    <button className="edit-pill" onClick={handleShow}>
                        <i className='bx bx-message-rounded'></i> Send Message
                    </button>
                    &nbsp;&nbsp;

                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                        <i className='bx bx-arrow-back' ></i>
                    </button>
                </div>                          
            </div>
            <hr />

            {loading ? <Loader /> : <>
            {messages && messages?.length > 0 ? <>
            {messages.map((message, index) => (
                <div key={index} className="ml-5">
                <div className="row-display-secondary">
                 <div className="mt-3 mr-3">
                    <img src={assistantImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                </div>   
                <div className="mt-4">
                <small>Asked : {moment(message?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}
                    <b className="ml-3">Asked By : <span style={{color:'dodgerblue'}}>( Assistant ) &nbsp;{message?.assigned_assistant_id?.lastname}  {message?.assigned_assistant_id?.firstname} </span></b> 
                    <b className="ml-3">
                        To: <span style={{color:'dodgerblue'}}>( {message?.assigned_hr_id?.role} ) &nbsp; {message?.assigned_hr_id?.firstname} {message?.assigned_hr_id?.lastname} </span>
                    </b>
                    <b className="ml-3">
                        Related To Patient: <span style={{color: 'dodgerblue'}}> {message?.assigned_patient_id?.lastname} {message?.assigned_patient_id?.firstname} </span>
                    </b> 
                </small>
                
                <p>{message?.question} ?</p>
                </div>
                </div>
                
                {message?.answers?.length > 0 ? <>
                    {message?.answers?.filter(item => item?.approvalStatus === true).map((answers, index) => (
                    <div key={index} className="mt-3 ml-4">
                    <div className="row-display-secondary">
                        <div className="mr-3">
                            <img src={defaultImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                        </div>
                        <div>  
                            
                            <b>Replied By : {answers?.repliedBy}</b><br/>
                                                
                            <p className="mt-1 mr-3">{answers?.answer} <br/> 
                                <div className="row-display-secondary">
                                    
                                    {answers?.approvalStatus === true ? 
                                    <div className="row">
                                    <p className="mt-1 ml-3" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                        <small style={{color: 'green'}} className="ml-4 mt-1">+1 minute added</small> 
                                    </div> : 
                                    <p className="mt-1" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                    }
                                </div>
                            </p>
                        </div>
                    </div>
                    </div>
                ))}
                
                </> : <div className="text-center">
                        <p style={{color: 'red'}}>No reply yet</p>
                    </div>
                }
                <hr className="blue-hr" />
                </div>
            ))}
            
            </> : <></>}
        
            </>}

            </div>
        </div>

        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header>
                <Modal.Title>Send Message</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row-display">
                    <div>
                        <label className="form-label">Select Nurse <span style={{color: 'red', fontWeight: 'bold'}}>*</span> </label>
                        <select className="form-control" value={hrId} onChange={e => setHrId(e.target.value)}>
                            <option value="default">Select Nurse</option>
                            {hrs && hrs.map((hr, index) => (
                                <option value={hr?._id} key={index}>{hr?.firstname} {hr?.lastname}</option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="form-label">Patient Type <span style={{color: 'red', fontWeight: 'bold'}}>*</span> </label>
                        <select className="form-control" value={patientType} onChange={e => setPatientType(e.target.value)}>
                        <option value="default">Patient Type</option>
                            <option value="RPM">RPM</option>
                            <option value="CCM">CCM</option>
                        </select>
                    </div>

                    <div>
                        <label className="form-label">Select Patient <span style={{color: 'red', fontWeight: 'bold'}}>*</span> </label>
                        <select className="form-control" value={patientId} onChange={e => setPatientId(e.target.value)}>
                        <option value="default">Select Patient</option>
                        {patients && patients.map((patient, index) => (
                            <option value={patient?._id} key={index}>{patient?.firstname} {patient?.lastname} {moment(patient?.DOB).format("MM/DD/YYYY")}
                            </option>
                        ))}

                        </select>
                    </div>
                </div>    
                <br/>    
                <label className="form-label"> Message <span style={{color: 'red', fontWeight: 'bold'}}>*</span> </label>
                <textarea id="message" name="message" value={question} onChange={e => setQuestion(e.target.value)} 
                rows="4" cols="50" className='form-control' />        

            </Modal.Body>
            
            <Modal.Footer>
                <button className="edit-pill" onClick={handleClose} style={{backgroundColor: 'red', color: 'white'}}>
                    Close
                </button>
             
                <button className="edit-pill" onClick={handleSubmit}>
                    Send Message
                </button>
            </Modal.Footer>
        </Modal>            
        </section>
    </>
  )
}

export default AssistantMessages;