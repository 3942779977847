// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes bottom-to-top {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes top-top-bottom {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }

}


.bottom-to-top-animation {
  animation: bottom-to-top 0.2s ease-out;
}


`, "",{"version":3,"sources":["webpack://./src/Modals/base-modal/alterbox.css"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,2BAA2B;IAC/B;;AAEJ;;;AAGA;EACE,sCAAsC;AACxC","sourcesContent":["@keyframes bottom-to-top {\n  0% {\n    transform: translateY(100%);\n  }\n  100% {\n    transform: translateY(0);\n  }\n}\n\n@keyframes top-top-bottom {\n    0% {\n        transform: translateY(0);\n    }\n    100% {\n        transform: translateY(100%);\n    }\n\n}\n\n\n.bottom-to-top-animation {\n  animation: bottom-to-top 0.2s ease-out;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
