import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { getAvgBPsList,
    getAvgBPsListDoctor,
    getDoctors,
    getOrganizations,
 } from '../../actions/adminActions';
import { useSelector, useDispatch } from 'react-redux'
import {useAlert} from 'react-alert';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
import Loader from '../../layouts/Loader';



const AvgBPsAdmin = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);

    const [doctorId, setDoctorId] = useState('');
    const [orgId, setOrgId] = useState("");
    const [pagination, setPagination] = useState(25);

    const {loading, error, averageBP, count} = useSelector(state => state.avgBPs);
    const { doctors } = useSelector(state => state.doctor);
    const { organizations } = useSelector(state => state.orgs);

    let color;
    let sysBPCategory;
    let compliantStatus; // variable to store compliant status of patient




    useEffect(() => {
        if(error){
            alert.error(error);
        }
        
        if(doctorId){
            dispatch(getAvgBPsListDoctor(limit, pagination, doctorId));
        } else {
            dispatch(getAvgBPsList(limit, pagination));
        }

        dispatch(getOrganizations());
        dispatch(getDoctors(50, 1));

    }, [limit, pagination, doctorId]);       


    const refreshHandler = () => {
        dispatch(getAvgBPsList(limit, pagination));
        setDoctorId("");
        setOrgId("");
        setLimit(10);
    } 


    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = ""
                color = '000'
            } 
        }

        function calculateCompliancyStatus(date) {  
            var m = moment(date);
            var today = moment().startOf('day');
            var days = moment.duration(today - m).asDays();
    
            switch(true){
                case (days >= 0 && days < 3):
                    compliantStatus = 'compliant'
                    break;
    
                case (days >= 3 && days <= 7):
                    compliantStatus = "mostLikelyCompliant"
                    break;
    
                case (days >= 8 && days <= 20):
                    compliantStatus = "notLikelyCompliant"
                    break;
    
                case (days >= 8 && days <= 31):
                    compliantStatus = "nonCompliant"
                    break;
                
                default:
                    compliantStatus = 'nonCompliant'
                    break;
            }
        }

        const updateAvgReadingsPagination = () => {
            setPagination(pagination + 25);
            dispatch(getAvgBPsList(limit, pagination));
        }
 
  

  return (
    <>
        <MetaData title="Avg B.Ps List"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br />
        {loading ? <Loader /> : (
        
        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">              
                        <div className="row-display">
                            <h5 className='title'>
                                <i className='bx bxs-bar-chart-alt-2'></i> 
                                <strong>&nbsp;Average Blood Pressure Of Patients </strong>
                            </h5>
                            
                            <div>
                                <Link to="/adminDashboard" className="btn btn-secondary"><i className='bx bx-arrow-back' ></i></Link> 
                                &nbsp;&nbsp;
                                <button className="edit-pill" onClick={refreshHandler}>
                                    <i className='bx bx-refresh'></i>
                                </button> 
                            </div>                           
                        </div>
                    <hr />
                    <div className="row-display">
                        <p><b>Note: </b> By default, the following represents the average blood pressure from the last 10 readings.</p>
                        <div className="row-display">
                            <select 
                                className="form-control mt-2" 
                                value={limit} 
                                onChange={e => setLimit(e.target.value)}>
                                <option value="10">last 10 Readings</option>
                                <option value="20">last 20 Readings</option>
                                <option value="30">last 30 Readings</option>
                                <option value="40">last 40 Readings</option>
                                <option value="50">last 50 Readings</option>
                            </select>

                             {/* Sort by Doctor */}
                             <div className="row-display colored-row">                       
                                    <select className='form-control'
                                            value={orgId}
                                            onChange={e => setOrgId(e.target.value)}
                                            style={{width: '200px'}}
                                            >
                                            <option key="151546">Sort By Org.</option>
                                                {organizations && organizations.map((org) =>  
                                                    <option key={org?._id} value={org?._id}>{org?.name}</option>
                                                )}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;

                                    {orgId && <>
                                        <select className='form-control'
                                            value={doctorId}  
                                            onChange={e => setDoctorId(e.target.value)}
                                            style={{width: '200px'}}
                                            >
                                                <option value="undefined" key="15151">Select Doctor</option>   
                                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) =>  
                                                    <option key={doctor?._id} value={doctor?._id}>Dr. {doctor?.lastname}, {doctor?.firstname}</option>
                                                )}
                                        </select>
                                        </> }                
                                    </div>
                        </div>
                    </div>

                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>Name & EHR-ID</th>
                                <th>D.O.B</th>
                                <th>Account Status</th>
                                <th>Devices Assigned</th>
                                <th>Program Enrolled</th>
                                <th>Provider Name</th>
                                <th>Avg SBP / DBP</th>
                                <th>Last Reading</th>
                            </tr>
                        </thead>
                        <tbody>
                        {averageBP && averageBP.filter(item => item?.avgSystolic !== null && item?.avgDiastolic !== null).map((pts, index) => (

                            

                            <tr key={index}>
                                <td>
                                    <Link to={{ pathname: "/patientProfile", state: {patientid: pts?._id}}} className="link">
                                    {pts?.lastname + " , "+ pts?.firstname } </Link>
                                    <p><b>{pts?.emrId}</b></p>
                                </td>    
                                <td> <b>{moment(pts?.DOB).format("MM-DD-YYYY")}</b> <p>
                                <Badge bg="info text-white" style={{
                                        fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '3px',
                                        letterSpacing: '2px',
                                    }} key={index}>{pts?.gender}</Badge>
                                    </p>
                                </td>

                                <td>
                                {pts?.block === true ? <b style={{color: 'red'}}>Blocked</b> : 
                                        <b style={{color: 'green'}}>Active</b>}
                                </td> 

                                <td className="text-center">
                                <p>{pts?.assigned_devices.map((device, index) => (
                                    <Badge bg="info text-white" style={{
                                        fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '5px',
                                        letterSpacing: '2px',
                                        margin: '2px'
                                    }} key={index}>{device?.deviceObjectId?.deviceType}</Badge>
                                ))}</p> 
                                </td>

                                <td><b>{pts?.patientType === 'Both' ? 'RPM & CCM' : pts?.patientType}</b></td>

                                <td><b> {'Dr. ' +pts?.assigned_doctor_id?.lastname +', '+ pts?.assigned_doctor_id?.firstname}</b></td>
                                {calcSysBpCategory(pts?.avgSystolic)}
                                <td>
                                    <b>
                                        {pts?.avgSystolic >= 139 || pts?.avgSystolic <= 110 ? 
                                        <span style={{color: 'red', fontWeight: 'bold', fontSize: '16px'}}>{pts?.avgSystolic}</span> : 
                                        <span>{pts?.avgSystolic}</span>
                                        }
                                        &nbsp;{'/'}&nbsp;                                        
                                        {
                                        pts?.avgDiastolic >= 90 || pts?.avgDiastolic <= 70 ? 
                                            <span style={{color: 'red', fontWeight: 'bold', fontSize: '16px'}}>{pts?.avgDiastolic}</span> : 
                                            <span>{pts?.avgDiastolic}</span>
                                        } 
                                        &nbsp;{'mmHG'}
                                        <p style={{color: color}}>{sysBPCategory}</p>
                                    </b>
                                </td>

                                {calculateCompliancyStatus(moment(pts?.lastReading))}

                                <td><b>{pts?.lastReading ? moment(pts?.lastReading).format("MM/DD/YYYY") : '--'}</b>
                                <p>{pts?.lastReading ? 
                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                    Non Compliant</p> : null : '--'}</p>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <br/>
                        {averageBP?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateAvgReadingsPagination()}>Show more</button>
                            </div>
                        </> }
                </div>
            </div>
            )}
        </section>
    </>
  )
}

export default AvgBPsAdmin