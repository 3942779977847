import { useEffect } from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Button } from 'react-bootstrap';
import RPMDeviceBasicInformation from '../../components/AdminDashboard/RPMDeviceBasicInformation';
import { getDeviceDetails } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import Loader from '../../layouts/Loader';
import { Link, useHistory } from 'react-router-dom';


const DevicesDetails = (props) => {

    let deviceId = props?.location?.state?.id;
    const history = useHistory();

    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, deviceDetails } = useSelector(state => state.deviceDetails);


    useEffect(() => {
        if (error) {
            return alert.error(error);
        }

        dispatch(getDeviceDetails(deviceId));
    }, [dispatch, alert, error]);


    return (
        <>
            <MetaData title="Device Details" />
            <Sidebar />

            <section className="home-section">
                {/* TopBar */}
                <TopBar />
                
                <br/>
                {loading ? <Loader /> : <>
                    <div className="shadow-lg p-3 mb-2 mr-4 ml-4 background-white">
                        <div className="home-content">
                            <div className="row-display">
                                <h5 className="pt-2 title">Device Details </h5>

                                <div className="row-display">
                                <Button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}><i className='bx bx-arrow-back' ></i></Button> &nbsp;
                                <Link to="adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home' ></i></Link> &nbsp;
                            </div>
                        </div>


                            <hr />

                            <RPMDeviceBasicInformation deviceData={deviceDetails} />
                        </div>
                    </div>
                </>}

            </section>
        </>
    )
}

export default DevicesDetails
