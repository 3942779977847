import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import defaultImg from '../../assets/Images/defaultUser.png';
import folderImg from '../../assets/Images/folder.png';
import { getDoctors, getDoctorPatients, getDoctorStats, doctorProfile, getOrganizations} from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import ExportActivePtsReportToCSV from '../../components/ExportActivePtsReportToCSV';



const ActivePtsReport = () => {

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '52px', // Adjust the height here as needed
          width: '250px',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth:'2px',
          borderColor: '#444',
        }),
      };

    const dispatch = useDispatch();
    const [doctorId, setDoctorId] = useState("");
    const [orgId, setOrgId] = useState("");
    const { organizations } = useSelector(state => state.orgs);

    const alert = useAlert();
    let compliantStatus; // variable to store compliant status of patient

    const { error, doctors } = useSelector(state => state.doctor);
    const { doctor } = useSelector(state => state.doctorProfile);
    const { doctorpatients } = useSelector(state => state.docPatients);
    const [filterBy, setFilterBy] = useState('active');

    const {
        totalPatients, 
        RpmPatients, 
        CcmPatients, 
        bothPatients,
        malepts, 
        femalepts, 
        activepts, 
        inActivepts
    } = useSelector(state => state.doctorStats);

    useEffect(() => {
        if(error){
            alert.error(error);
        }
    
        dispatch(getDoctors(50, 1));
        dispatch(getOrganizations());

        if(doctorId){
            dispatch(doctorProfile(doctorId));
            dispatch(getDoctorStats(doctorId));
            dispatch(getDoctorPatients(doctorId, filterBy));
        }
    
    }, [dispatch, doctorId]);

    
    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name]})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }


    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }

  return (
    <>
        <MetaData title="Active Patients Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <br/>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

                <div className="row-display">
                    <h5 className='title'>
                        <i className="bx bx-user"></i> <strong>&nbsp;Active Patients Report </strong>
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;
                        
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>
                <br />

                <div className="row reportFormFields" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div>
                        <label className="form-label">
                            Select Organization  <span style={{color: 'red'}}> *</span>  
                        </label>
                        <Select
                            options={orgOptions}
                            onChange={getOrgProfile}
                            styles={customStyles}
                        />    
                    </div>&nbsp;&nbsp;&nbsp;
                    
                    <div>
                        <label className='form-label'>Select Doctor</label>
                            <select className="form-control" 
                                value={doctorId} 
                                onChange={e => setDoctorId(e.target.value)}
                                disabled={orgId ? false : true}
                                >
                                <option value="null">Select Doctor</option>
                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                    <option key={doctor?._id} value={doctor?._id}>
                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                    </option>
                                ))}
                            </select>
                        </div>

                    <div className="mt-3">
                        &nbsp;&nbsp;
                        <button className="edit-pill-2 mt-3">Generate Report</button>
                    </div>
                </div>
                <br />

                <div>
                        {doctorpatients && doctor?.firstname && <>
                            <div className="row">
                            <div className="col-md-2">
                                <img src={defaultImg} className="profile-img" alt="profileimg" />
                                
                                <br /><br />
                                <div>
                                {doctorpatients && <>
                                    <ExportActivePtsReportToCSV 
                                    csvData={doctorpatients} 
                                    fileName={`Dr. ${doctor?.lastname +' , '+ doctor?.firstname} ` + '.csv'} 
                                    />
                                </>}
                                    
                                </div>
                            </div>

                            <div className="col-md-3">            
                                    <h4 className="title m-0 p-0">Dr. {doctor?.lastname}, {doctor?.firstname} </h4>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.name}</p>
                                    
                                    {doctor?.assigned_hr_id ? <>
                                        <div className="row">
                                        <p> &nbsp;&nbsp;&nbsp;&nbsp;{doctor?.assigned_hr_id?.lastname}, {doctor?.assigned_hr_id?.firstname}</p>
                                        &nbsp;&nbsp; 
                                    </div>
                                    </> : null}    
                                    

                                    <hr className='m-0 p-0'/>
                                    <br />
                                    <p className="profile-label m-0 p-0">License Number :</p>
                                    <p className="m-0 p-0">{doctor?.licensenumber ? doctor?.licensenumber : 'N/A'}</p>

                                    <br />    
                                    <p className="profile-label m-0 p-0">Email Address : </p>
                                    <Link to="/doctorProfile" 
                                        className="link" 
                                        >{doctor?.email}
                                    </Link>

                                    <br/><br/>
                                    <p className="profile-label m-0 p-0">Member Since :</p>
                                    <p className="m-0 p-0">{moment(doctor?.createdAt).format("MM/DD/YYYY hh:mm z A")}</p>                                    
                            </div>

                                <div className="col-md-3">            
                                    <h5>Organization Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Name :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.name}</p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">City :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.city}</p>

                                    <br/>
                                    <p className="profile-label m-0 p-0">Country :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.country}</p>

                                </div>

                                <div className="col-md-2">            
                                <h5>Contact Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">NPI Number :</p>
                                    <p className="m-0 p-0">{doctor?.npinumber ? 
                                        doctor?.npinumber : 'N/A'}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Primary Phone :</p>
                                    <p className="m-0 p-0">{doctor?.phone1 ? 
                                        <span><i className='bx bx-phone'></i> {doctor?.phone1} </span> : 'N/A'}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Mobile No :</p>
                                    <p className="m-0 p-0">{doctor?.mobileNo ? 
                                        <span style={{color: 'dodgerblue'}}><i className='bx bx-phone'></i> {doctor?.mobileNo} </span> : 'N/A'}
                                    </p>
                                    <br/>
                                </div>

                                <div className="col-md-2">            
                                <h5>Authorization Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Account Status :</p>
                                    <p className="m-0 p-0">{doctor?.block === true ? <b style={{color: 'red'}}>Blocked</b> : 
                                        <b style={{color: 'green'}}>Active</b>}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Verification Status :</p>
                                        <p className="m-0 p-0">{doctor?.verified === false ? <b style={{color: 'red'}}>Not Verified</b> : <b style={{color: 'green'}}>Verified</b>}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Signature :</p>
                                    <p className="m-0 p-0">
                                        {doctor?.signature ? <>
                                            <img src={`https://www.rpmatrix.com/v1/uploadFiles/${doctor?.signature}`} 
                                            width='100%' height="80" alt="img" 
                                        /> </> : 'Not Added Yet' }
                                        </p>
                                    <br/>
                                </div>
                        </div>    

                        <br/>

                        
                        <div className='container-fluid doctorStatsWrapper text-center'>
                                   <div className="docStats">
                                        <h5 className="title">Total Patients</h5>
                                        <h2>{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Only RPM</h5>
                                       <h2>{RpmPatients && RpmPatients < 10 ? '0'+RpmPatients : RpmPatients}</h2>
                                   </div>  

                                   <div className="docStats">
                                       <h5 className="title">Only CCM</h5>
                                       <h2>{CcmPatients && CcmPatients < 10 ? '0'+CcmPatients : CcmPatients}</h2>
                                   </div>
                                   
                                   <div className="docStats">
                                       <h5 className="title">RPM & CCM</h5>
                                       <h2>{bothPatients && bothPatients < 10 ? '0'+bothPatients : bothPatients}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Male</h5>
                                       <h2>{malepts && malepts < 10 ? '0'+malepts : malepts}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Female</h5>
                                       <h2>{femalepts && femalepts < 10 ? '0'+femalepts : femalepts}</h2>
                                   </div>   

                                   <div className="docStats">
                                       <h5 className="title">Active</h5>
                                       <h2 style={{color: 'green'}}>{activepts && activepts < 10 ? '0'+activepts : activepts}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Inactive</h5>
                                       <h2 style={{color: 'red'}}>{inActivepts && inActivepts < 10 ? '0'+inActivepts : inActivepts}</h2>
                                   </div>
                                </div>
                            <hr />
                            <br />     
                        
                        {doctorpatients && doctorpatients?.length > 0 ? (<>
                            <div className="row-display">
                            
                            <h5 className="title">
                                 Assigned Patients
                            </h5>
                               
                            </div>

                                <div>
                                    <>
                                        <table className="styled-table">
                                        <thead align="center">
                                            <tr>
                                            <th>Sr #</th>
                                            <th>EHR - ID</th>
                                            <th>Name</th>
                                            <th>DOB </th>
                                            <th>Acc Status</th>
                                            <th>Clinician</th>
                                            <th>Compliancy Status</th>
                                            <th>Last Reading </th>
                                            <th>Consent Role</th>
                                            <th>Last Call</th>
                                            </tr> 
                                        </thead>
                                        <tbody>
                                        
                                        {doctorpatients && doctorpatients.map((patient, index) => ( 
                                        <tr align="center" key={index}>

                                        <td><b>{index + 1}</b></td>  

                                        <td><b>{patient.emrId || 'N/A'}</b></td>

                                        {patient?.block === false ? <td><Link className="link"
                                                to={{ pathname: "/patientProfile", state: {patientid: patient?._id, deviceid: patient?.deviceassigned?.deviceid}}}>
                                                {patient?.lastname}, {patient?.firstname}
                                                <p>{patient?.phone1}</p>  
                                                </Link>
                                            </td> : <td>
                                            {patient?.lastname}, {patient?.firstname}</td> }

                                            <td><b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b></td> 
                                        
                                                <td style={{color: 'green', fontWeight: 'bold'}}>
                                                {patient?.block === false ? <span style={{color: 'green', fontWeight: 'bold'}}>Active</span> : <span style={{color: 'red', fontWeight: 'bold'}}>Deactivated</span>}
                                                </td>

                                                {patient?.assigned_doctor_id ? <>
                                                    <td><b>Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</b></td>
                                                    </> : <>
                                                    <td><Badge bg="danger text-white" className="not-assigned-tag">Not Assigned</Badge></td>
                                                </>}

                                                {patient?.patientType === 'RPM' || patient?.patientType === 'Both' ? calculateCompliancyStatus(moment(patient?.lastReading)) : null}    
                        
                                                <td>
                                                    {patient?.lastReading ? 
                                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                                    Non Compliant</p> : null : '--'}
                                                </td>

                                                <td><span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                                </td>

                                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>        
                                                
                                                <td><b>{patient?.lastcallrpm && moment(patient?.lastcallrpm).format('MM-DD-YYYY')}</b></td>            
                                        </tr> 
                                        ))}    
                                            
                                        </tbody>
                                    </table>    
                                    </>                      
                            </div>
                        </> ) : <>

                        <div>    
                        <h5 className="pt-2 mt-2">Patient's List </h5> 
                            <hr className="blue-hr"/>
                                           
                                <img src={folderImg} className="no-record-found-img"/>
                                <p className="doctor-specilizations">No Patient Assigned Yet...</p>
                            
                             
                        </div>
                        </> }               

                        </>}
                        
                    </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default ActivePtsReport