import {useState, useEffect} from 'react';
import {Modal, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { getHrLists, HRDeactivate, HRActivate } from '../../actions/adminActions';
import { useDispatch, useSelector} from 'react-redux';
import { useAlert } from 'react-alert';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const HRList = () => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const { loading, error, hrs } = useSelector(state => state.hrslist);
    const {message, error: hrUpdateError } = useSelector(state => state.common);

    const [smShow, setSmShow] = useState(false); //small confirm modal
    const [HRModel, setHRModel] = useState(null);
    const [HRToDelete, setHRToDelete] = useState(null);
    const [sort, setSort] = useState(true);
    const [query, setQuery] = useState(""); // set search query.
    const keys = ["firstname", "lastname", "email", "DOB", "gender"];

    useEffect(() =>{
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(hrUpdateError){
            alert.error(hrUpdateError);

            dispatch({
              type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            alert.success(message);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })

            setSmShow(false);
            dispatch(getHrLists());
        }

        dispatch(getHrLists());

    }, [dispatch, error, message, hrUpdateError]);

    const deActivateHR = () => {
        dispatch(HRDeactivate(HRModel));
    }

    const activateHR = () => {
        dispatch(HRActivate(HRModel));
    }
    

  return <>
        <MetaData title="Nurses"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                <br/>
                {loading ? <Loader /> : ( <>
                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">

                        <div className="row-display">
                            <h5 style={{color: '#00A8A8'}}>
                                <i className='bx bx-plus-medical'></i> <strong>&nbsp;List of Nurses </strong>
                            </h5>

                            <div className="row-display"> 
                                    <Link to="/adminDashboard" className="btn btn-secondary pt-2"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                    &nbsp;&nbsp;
                                    <Link to="/addhr" className="edit-pill">Add New Nurse</Link>
                            </div>
                        </div>
                        <hr />

                        <div className='ml-3'>
                            <div className="row-display">    
                                <input type="text" 
                                    placeholder="Search by name, email and DOB ..." 
                                    className="form-control"
                                    onChange={e => setQuery(e.target.value)}
                                    style={{width: '380px'}}
                                />
                                &nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                        <br />


                        {/* HRs List */}
                        <div className="col-md-12">
                         <>
                         <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Name & Email</th>
                                <th>Gender </th>
                                <th>Verification Status</th>
                                <th>Assigned Dr.</th>
                                <th>Assigned Organizations</th>
                                <th>Account Status</th>
                                <th>Consent Program</th>
                                <th>Action</th> 
                                </tr> 
                            </thead>
                            
                            <tbody>
                            
                            {hrs && hrs.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((hr, index) => (
                               <tr key={index}>

                                <td className='text-center'>
                                {/* <Link className="link" 
                                to={{ pathname: "/hrProfile", state: {hr: hr}}}
                                >  */}
                                    <b>{hr?.lastname +" , "+ hr?.firstname}</b>
                                    {/* </Link> */}
                                    <p>{hr?.email}</p>   
                                </td>

                                <td> {hr?.gender === 'male' ? 
                                        <b>Male</b>
                                        : <b>Female</b>
                                    }
                                </td>
                                
                                <td>{hr?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}</td>        
                                
                                {hr?.assigned_doctor_id ? <td>
                                    <b>Dr. {hr?.assigned_doctor_id?.lastname +" , "+ hr?.assigned_doctor_id?.firstname} 
                                    </b></td> : 
                                <td>--</td>}

                                
                                <td>{
                                    hr?.organizations?.length > 0 && hr?.organizations?.map((orgs, index) => (
                                        <p key={index} className="m-0 p-0">
                                            <b>{orgs?.assigned_org_id?.name}</b>
                                        </p>
                                    ))
                                }
                                </td>
               
                                {hr?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>}

                                <td>
                                    {hr?.consentRole === 'RPM' ? <b>RPM</b> : hr?.consentRole === 'CCM' ? <b>CCM</b> : <b>RPM & CCM</b>}
                                </td>

                                <td>
                                    <div className="row-display">
                                        {/* <Link to={{ pathname: "/hrProfile", state: {hr: hr}}} className="link"><i className="fa fa-user"></i></Link> */}
                                        <Link to={{ pathname: "/updateHR", state:{ hr: hr}}} className="link"><i className="fa fa-edit"></i></Link>
                                        {hr?.block === false ? <Link to="hrlist" style={{color: 'red'}} className="link" onClick={() => {setSmShow(true); setHRModel(hr?._id); setHRToDelete(hr?.lastname)}}><i className="bx bx-block"></i></Link> : 
                                                <Link to="hrlist" className="link" style={{color: 'green'}} onClick={() => {setSmShow(true); setHRModel(hr?._id); setHRToDelete(hr?.lastname)}}><i className="fa fa-user-check"></i></Link> }

                                    </div>
                                </td>
                                
                               </tr> 
                            ))}
                            
                            </tbody>
                        </table>
                        </>
                        </div>

                      </div>
                    </div>
                </> 
                )}
                </section>

                <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>
                    <small style={{color: 'gray'}}>Are you sure you want to activate / de-activate 
                        <span style={{color: '#000'}}> HR. {HRToDelete}'s</span> Account ?
                    </small>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-sm btn-success" onClick={activateHR}>Activate</button>
                    <button className="btn btn-sm btn-danger" onClick={deActivateHR}>Block</button>
                </Modal.Footer>
            </Modal>  
    </>;
};

export default HRList;
