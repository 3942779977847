import { useState, useEffect } from "react";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import {useAlert} from 'react-alert';
import { submitCCMCareplan } from "../../actions/adminActions";


const PatientCCMCareplanManual = ({patient}) => {
  
  const dispatch = useDispatch();
  const alert = useAlert();
  const { user } = useSelector(state => state.auth);
  const role = user?.role;

  
  const [show, setShow] = useState(false);
  const [patientDiseases, setPatientDiseases] = useState([]);
  const [diseases, setDiseases] = useState([]);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (patient && patient.sickness?.length > 0) {
      const diseaseNames = patient.sickness.map((item) => item.name);
      
      // Set the patientDiseases state with the array of names
      setPatientDiseases(diseaseNames);

      // Initialize the diseases state with the disease names and default goals/interventions
      const updatedDiseases = diseaseNames.map(disease => ({
        diseaseName: disease,
        shortTermGoals: [{ goalName: '', addedDate: moment().format('YYYY-MM-DD'), achievedStatus: 'notachieved' }],
        longTermGoals: [{ goalName: '', addedDate: moment().format('YYYY-MM-DD'), achievedStatus: 'notachieved' }],
        interventions: [{ goalName: '', addedDate: moment().format('YYYY-MM-DD'), achievedStatus: 'notachieved' }],
      }));
      setDiseases(updatedDiseases);
    }
  }, [patient]);

  const handleGoalChange = (diseaseIndex, goalType, goalIndex, field, value) => {
    const newDiseases = [...diseases];
    newDiseases[diseaseIndex][goalType][goalIndex][field] = value;
    setDiseases(newDiseases);
  };

  const addGoal = (diseaseIndex, goalType) => {
    const newDiseases = [...diseases];
    newDiseases[diseaseIndex][goalType].push({ goalName: '', addedDate: moment().format('YYYY-MM-DD'), achievedStatus: 'notachieved' });
    setDiseases(newDiseases);
  };

  const removeGoal = (diseaseIndex, goalType, goalIndex) => {
    const newDiseases = [...diseases];
    newDiseases[diseaseIndex][goalType].splice(goalIndex, 1);
    setDiseases(newDiseases);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const validateDiseases = () => {
    for (let disease of diseases) {
      // Check if the disease name is present
      if (!disease.diseaseName) {
        alert.error('Disease name is required');
        return false;
      }
  
      // Validate short-term goals
      for (let s of disease.shortTermGoals) {
        if (!s.goalName || !s.addedDate || !s.achievedStatus) {
          alert.error('All fields in short-term goals must be filled');
          return false;
        }
      }
  
      // Validate long-term goals
      for (let l of disease.longTermGoals) {
        if (!l.goalName || !l.addedDate || !l.achievedStatus) {
          alert.error('All fields in long-term goals must be filled');
          return false;
        }
      }
  
      // Validate interventions
      for (let i of disease.interventions) {
        if (!i.goalName || !i.addedDate || !i.achievedStatus) {
          alert.error('All fields in interventions must be filled');
          return false;
        }
      }
    }
  
    // If all validations passed
    return true;
  };

  
  const submitHandler = () => {
    // if (!validateDiseases()) {
    //   alert.error('Please ensure all fields are filled.');
    //   return;
    // }

    // const formattedDiseases = diseases.map(disease => {
    //   return {
    //     name: disease.diseaseName,
    //     shortTermGoals: disease.shortTermGoals.map(s => ({
    //       goalname: s.goalName,
    //       addedDate: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
    //       achievedStatus: s.achievedStatus
    //     })),
    //     longTermGoals: disease.longTermGoals.map(l => ({
    //       goalname: l.goalName,
    //       addedDate: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
    //       achievedStatus: l.achievedStatus
    //     })),
    //     interventions: disease.interventions.map(i => ({
    //       goalname: i.goalName,
    //       addedDate: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
    //       achievedStatus: i.achievedStatus
    //     }))
    //   };
    // });
  
    const formattedDiseases = diseases
    .map(disease => {
    // Helper function to filter out empty goals/interventions
    const filterGoals = goals =>
      goals
        ? goals.filter(g => g.goalName && g.achievedStatus !== undefined)
          .map(g => ({
            goalname: g.goalName,
            addedDate: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
            achievedStatus: g.achievedStatus
          }))
        : [];

    // Format the disease object only if it has non-empty goals/interventions
    const shortTermGoals = filterGoals(disease.shortTermGoals);
    const longTermGoals = filterGoals(disease.longTermGoals);
    const interventions = filterGoals(disease.interventions);

    return {
      name: disease.diseaseName,
      shortTermGoals,
      longTermGoals,
      interventions
    };
  })
  .filter(disease => 
    // Exclude diseases where all goal arrays are empty
    disease.shortTermGoals.length > 0 ||
    disease.longTermGoals.length > 0 ||
    disease.interventions.length > 0
  );
    
    dispatch(submitCCMCareplan(formattedDiseases, notes, user?._id, patient?._id, role));
  }

  return (
    <div>
         <button 
            className="edit-pill mt-2" 
            onClick={handleShow}>
            Add CCM Careplan
         </button>

         {/* Modal */}
         <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header>
            <h5 className="title">Add CCM Careplan</h5>
            <button className="edit-pill" onClick={handleClose}><i className='bx bx-x'></i></button>
            </Modal.Header>
            <Modal.Body>
            
            <div className="row-display">
                <div>
                    <h5 className="title">{patient?.lastname}, {patient?.firstname}</h5>
                    <p><b>{patient?.emrId}</b></p>
                </div>

                <div>
                    <h5>{moment(patient?.DOB).format('MM/DD/YYYY')}</h5>
                    <p><b>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</b></p>
                </div>
            </div>

            <hr />

            {/* CCM Careplan Form  */}
            <div>
        <b>Enter Goals and Interventions for Diseases.</b>
        <br /><br />
      {diseases.map((disease, diseaseIndex) => (
        <div key={diseaseIndex}>
          <h5 className="title">{diseaseIndex  + 1} - {disease.diseaseName}</h5>
          <div className="row-display" >
            <b> Short Term Goals</b>
            <button className="edit-pill" onClick={() => addGoal(diseaseIndex, 'shortTermGoals')}>
                <i className='bx bx-plus'></i> Add
            </button>
          </div>

          {disease.shortTermGoals.map((goal, goalIndex) => (
            <div key={goalIndex}>
              
            <div className="row-display mt-2">
            <div>
            <label className="form-label"> Goal {goalIndex + 1}: </label>
              <input
                type="text"
                value={goal.goalName}
                className="form-control"
                style={{width: '400px'}}
                placeholder={`Enter short term goal ${goalIndex + 1}`} 
                onChange={(e) => handleGoalChange(diseaseIndex, 'shortTermGoals', goalIndex, 'goalName', e.target.value)}
              />
            </div>

            <div>
              <label className="form-label">Added Date:</label>
              <input
                type="date"
                value={goal.addedDate}
                className="form-control"
                placeholder="Added Date"
                onChange={(e) => handleGoalChange(diseaseIndex, 'shortTermGoals', goalIndex, 'addedDate', e.target.value)}
              />
              </div>
              
              
              <div>
              <label className="form-label">Achieved Status:</label>
              <select
                type="text"
                value={goal.achievedStatus}
                className="form-control"
                disabled
                onChange={(e) => handleGoalChange(diseaseIndex, 'shortTermGoals', goalIndex, 'achievedStatus', e.target.value)}
              >
                <option value="notachieved">Not Achieved</option>
                <option value="achieved">Achieved</option>
              </select>
              </div>

              {disease.shortTermGoals.length > 1 && (
                <button className="edit-pill mt-4" style={{backgroundColor: 'red'}} 
                onClick={() => removeGoal(diseaseIndex, 'shortTermGoals', goalIndex)}>
                    <i className="bx bx-trash"></i>
                </button>
              )}

              </div>
              <br />
            </div>
          ))}

          <div className="row-display">
            <b className="mt-3">Long Term Goals</b>
            <button className="edit-pill mt-4" onClick={() => addGoal(diseaseIndex, 'longTermGoals')}>
                <i className='bx bx-plus'></i> Add
            </button>

          </div>
          {disease.longTermGoals.map((goal, goalIndex) => (
            <div key={goalIndex}>

              <div className="row-display mt-2">
                <div>
                <label className="form-label">Goal Name:</label>
                <input
                    type="text"
                    value={goal.goalName}
                    className="form-control"
                    placeholder={`Enter long term goal ${goalIndex + 1}`}
                    style={{width: '400px'}}
                    onChange={(e) => handleGoalChange(diseaseIndex, 'longTermGoals', goalIndex, 'goalName', e.target.value)}
                />
                </div>

                <div>  
                <label className="form-label">Added Date:</label>
                <input
                    type="date"
                    value={goal.addedDate}
                    className="form-control"
                    onChange={(e) => handleGoalChange(diseaseIndex, 'longTermGoals', goalIndex, 'addedDate', e.target.value)}
                />
                </div>
             <div>
              <label className="form-label">Achieved Status:</label>
              <select
                value={goal.achievedStatus}
                className="form-control"
                onChange={(e) => handleGoalChange(diseaseIndex, 'longTermGoals', goalIndex, 'achievedStatus', e.target.value)}
                disabled
              >
                <option>Not Achieved</option>
                <option>Achieved</option>
              </select>
              </div>

              {disease.longTermGoals.length > 1 && (
                <button className="edit-pill mt-4" style={{backgroundColor: 'red'}} onClick={() => removeGoal(diseaseIndex, 'longTermGoals', goalIndex)}>
                    <i className="bx bx-trash"></i>
                </button>
              )}
              </div>
              <br />
            </div>
          ))}
        
            <div className="row-display">
                <b className="mt-3">Interventions</b>
                <button className="edit-pill mt-4" onClick={() => addGoal(diseaseIndex, 'interventions')}>
                    <i className='bx bx-plus'></i> Add
                </button>
            </div>



            {disease.interventions.map((goal, goalIndex) => (
                <div key={goalIndex}>

                <div className="row-display mt-2">
                <div>
                <label className="form-label">Intervention Name:</label>
                <input
                    type="text"
                    placeholder={`Enter intervention ${goalIndex + 1}`}
                    className="form-control"
                    value={goal.goalName}
                    style={{width: '400px'}}
                    onChange={(e) => handleGoalChange(diseaseIndex, 'interventions', goalIndex, 'goalName', e.target.value)}
                />
                </div>

                <div>
                <label className="form-label">Added Date:</label>
                <input
                    type="date"
                    className="form-control"
                    value={goal.addedDate}
                    onChange={(e) => handleGoalChange(diseaseIndex, 'interventions', goalIndex, 'addedDate', e.target.value)}
                />
                </div>
                
                <div>
                <label className="form-label">Achieved Status:</label>
                <select
                    type="text"
                    className="form-control"
                    value={goal.achievedStatus}
                    disabled
                    onChange={(e) => handleGoalChange(diseaseIndex, 'interventions', goalIndex, 'achievedStatus', e.target.value)}
                >
                    <option value="notachieved">Not Achieved</option>
                    <option value="achieved">Achieved</option>
                </select>
                </div>
                {disease.interventions.length > 1 && (
                    <button className="edit-pill mt-4" style={{backgroundColor: 'red'}} onClick={() => removeGoal(diseaseIndex, 'interventions', goalIndex)}>
                        <i className="bx bx-trash"></i>
                    </button>
                )}
                </div>
              </div>
            ))}
            <br />
            </div>
            ))}

                <br />
                <label className="form-label">Notes (If any)</label>
                <textarea rows={4} cols={4} 
                  value={notes} 
                  onChange={e => setNotes(e.target.value)} 
                  className="form-control" 
                  placeholder="Other Note (If any) ..." />
            
            </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="edit-pill-2" onClick={() => submitHandler()}>Add Careplan</button> {/* Replace with form submission logic */}
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default PatientCCMCareplanManual