import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { getDoctors, getOrganizations } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import { getBillingReportByDoctor, getBillingReportByOrganization } from '../../actions/adminActions';
import moment from 'moment';
import Loader from '../../layouts/Loader';
import ExportBillingReportToCSV from '../../components/ExportBillingReportToCSV'
import { Modal } from 'react-bootstrap';
import AdminRPMMinutesComponent from '../../components/AdminDashboard/AdminRPMMinutesComponent';
import orgIcon from '../../assets/Images/organizations.png';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import {useAlert} from 'react-alert';



const BillingReport = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState("2024");
    const [doctorId, setDoctorId] = useState("");
    const [orgId, setOrgId] = useState("");
    const [patientId, setPatientId] = useState();
    const [show, setShow] = useState(false);
    const [reportBy, setReportBy] = useState('org');

    const { error, doctors } = useSelector(state => state.doctor);
    const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);
    const {organizations } = useSelector(state => state.orgs);

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '52px', // Adjust the height here as needed
          width: '250px',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth:'2px',
          borderColor: '#444',
        }),
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let monthArray = ["01", "02", "03", "04", "05", "06", "07",
    "08", "09", "10", "11", "12"];
    
    useEffect(() => {
        if(error){
            alert.error(error);
        }
    
        dispatch(getDoctors(50, 1));
        dispatch(getOrganizations());
        
        dispatch({
          type: TIME_SUMMARY_REPORT_RESET
        })
    
    }, [dispatch]);

    const doctorOptions = []
    doctors && doctors.filter(item => item?.block === false).map((doctor) => (
        doctorOptions.push({ value: doctor?._id, label: ['Dr. ' + doctor?.lastname +" ,", doctor?.firstname].join(" ")})
    ))

    const getDoctorProfile = (doctor) => {
        setDoctorId(doctor.value);
    }

    // Organizations list
    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name].join(" ")})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }

    const generateBillingReport = () => {
        
        if(reportBy === 'org'){
            if(orgId){
            dispatch(getBillingReportByOrganization(orgId, month, year, reportBy));
            } else {
                alert.error('Please select organization');
            }
        } else if (reportBy === 'doctor'){
            if(doctorId){
                dispatch(getBillingReportByDoctor(doctorId, month, year, reportBy));
            } else {
                alert.error('Please select doctor');
            }
        } else {
            alert.error('Please fill out all fields');
        }
        
    }

    const resetReport = () => {
        dispatch({
            type: TIME_SUMMARY_REPORT_RESET
          })
    }

    const handleViewMinutes = (patientId) => {
        setPatientId(patientId);
        setShow(true);
    }

  return (
    <>
        <MetaData title="RPM Biling Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <br/>
        <div className="shadow-lg p-3 mb-5 mr-2 ml-2 background-white">
            <div className="home-content">

                <div className="row-display">
                    <h5 style={{color: '#00A8A8'}}>
                        <i className="bx bx-edit"></i> <strong>&nbsp;RPM Billing Report </strong>
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;

                        <Link to="/report/billing/ccm" className="link pt-2">CCM Billing Report</Link> &nbsp;

                        
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>
                <hr />


                <div className="row cardWrapper">
                <div className={`cardButton ${reportBy === "org" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("org")}>
                        <img src={orgIcon} alt="" height="60" width="60"/>
                        <p> By Organization </p>
                    </div>


                    <div className={`cardButton ${reportBy === "doctor" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("doctor")}>
                        <img src={doctorIcon} alt="" height="60" width="60"/>
                        <p> By Doctor </p>
                    </div>
                </div>


                <div className="row-display container">
               
                <div className="col-md-3">
                {reportBy === "org"? <>
                    <label className="form-label">Select Organization  <span style={{color: 'red'}}> *</span>  </label>
                    <Select
                        options={orgOptions}
                        onChange={getOrgProfile}
                        styles={customStyles}
                    />
                </> : <>
                <label className="form-label">Select Doctor  <span style={{color: 'red'}}> *</span>  </label>
                    <Select
                        options={doctorOptions}
                        onChange={getDoctorProfile}
                        styles={customStyles}
                    />
                </>}    
                </div>

                <div className="col-md-3">
                <label htmlFor="from" className="form-label">Select Month <span style={{color: 'red'}}> *</span></label>
                        <select 
                            className="form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="year" className="form-label">Select Year <span style={{color: 'red'}}> *</span></label>
                        <select 
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        >
                            <option value="SelectYear">Select Year</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label>&nbsp;</label>
                        <button className="edit-pill-2" onClick={generateBillingReport}>Generate Report</button>
                    </div>
                
                </div>
                <hr />
                {/* Report Result */}

                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
 
                <div className="row-display">
                    <div>
                        <h5 className="title">Billing Report Preview</h5>
                    </div>

                    <div className="row-display">
                        <button className="edit-pill" onClick={resetReport}>
                            <i className='bx bxs-file-pdf'></i> Reset
                        </button> &nbsp;

                        <div>
                            <ExportBillingReportToCSV 
                              csvData={timeSummaryReport} 
                              fileName={'Billing Report '+ moment().tz("America/New_York").format('MM/DD/YYYY')+'.csv'} 
                              month={month}
                              year={year}
                            />
                        </div>

                    </div>
                </div>

                <div className="col-lg-12">
                    <br />
                        <table className="styled-table">
                            <thead align="center">
                                <tr>
                                  {/* <th width="40px">#</th> */}
                                  <th>P. Name & EHR-ID</th>
                                  <th width="100px">Total Readings (old)</th>
                                  <th>Setup Date</th>
                                  <th>FCC-Date</th>
                                  <th>Month Span</th>
                                  <th>Readings</th>
                                  <th width="80px">Total Minutes</th>
                                  <th>Call Minutes</th>
                                  <th>VM Minutes</th>
                                  <th>Review Minutes</th>
                                  <th width="80px">CPT</th>
                                  <th>Month</th>
                                  <th>Last Activity</th>
                                  <th>Doctor</th>
                                  <th width="80px">Action</th>
                                </tr>
                                </thead>
                            <tbody>


                                {timeSummaryReport && timeSummaryReport.sort((a, b) => (a.patientName > b.patientName) ? 1 : -1).map((summaryReport, index) => (
                                    <tr key={index} className={summaryReport?.FCC?._id && moment(summaryReport?.FCC?._id).format('MM') === monthArray[month - 1] && moment(summaryReport?.FCC?._id).format('YYYY') === year ? 'coloredRow' : null}> 
                                     

                                      <td><Link className="link"  
                                        to={{ pathname: "/patientProfile", state: {patientid: summaryReport?.patientId 
                                        }}}>
                                          {summaryReport?.patientName}
                                          <p>{moment(summaryReport?.DOB).format('MM/DD/YYYY')}</p>
                                          </Link>
                                          <p><b>{summaryReport?.emrId || 'N/A'}</b></p></td>

                                    
                                      
                                      {/* Total Readings */}
                                      {summaryReport.totalReadings >= 16 ? <td style={{color: 'green', wordWrap: 'break-word', fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td> 
                                        : 
                                      <td style={{color: 'red', fontWeight: 'bold', wordWrap: 'break-word'}}>{summaryReport?.totalReadings}</td>
                                      }          

                                      <td>
                                        <b>{summaryReport?.setupDate || '---'}</b>
                                      </td>

                                      <td>
                                           {summaryReport?.FCC?._id && summaryReport?.FCC?._id ? <b>{moment(summaryReport?.FCC?._id).format("MM-DD-YYYY")}</b> : <span style={{color:"red"}}>Not Completed Yet</span>}
                                      </td>

                                      <td><b>
                                        {summaryReport?.monthSpanStart !== 0 && summaryReport?.monthSpanEnd !== 0 ? 
                                            moment(summaryReport?.monthSpanStart).format('MM-DD-YYYY') +' to '+
                                            moment(summaryReport?.monthSpanEnd).format('MM-DD-YYYY') 
                                            : '--'}
                                      </b>
                                      </td>

                                    <td>
                                        {summaryReport?.monthDurationReadings >=16 ? 
                                        <b style={{color: 'green'}}>{summaryReport?.monthDurationReadings}</b>
                                        :
                                        <b style={{color: 'red'}}>{summaryReport?.monthDurationReadings}</b>
                                        }
                                    </td>

                                    {/* Total Minutes */}
                                    {summaryReport?.totalMinutes < 20 ? <td style={{color: '#e3404f', fontWeight: 'bold'}}>{summaryReport?.totalMinutes}</td> : 
                                      <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalMinutes}</td>
                                    }

                                    {/* Call Minutes */}
                                    {<td><b>{summaryReport?.callMins} mins</b></td>}

                                    {/* VMs Minutes */}
                                    {<td><b>{summaryReport?.vmMins} mins</b></td>}

                                    {/* Review Minutes */}
                                    {<td><b>{summaryReport?.reviewMins} mins</b></td>}
      
                                      {/* CPT Codes */}
                                     <td>
                                        {summaryReport?.FCC?._id && moment(summaryReport?.FCC?._id).format('MM') === monthArray[month - 1] && moment(summaryReport?.FCC?._id).format('YYYY') === year ? 
                                        <text style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}> 99453</text> : summaryReport.monthDurationReadings >= 16 ? 
                                        <text style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}> 99454</text> : 
                                        null
                                        }
                                        
                                        {summaryReport.totalMinutes >= 20 && summaryReport.totalMinutes < 40 ?  <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457</p> :
                                        summaryReport.totalMinutes >= 40 && summaryReport.totalMinutes < 60 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 </p> :
                                        summaryReport.totalMinutes >= 60 && summaryReport.totalMinutes < 80 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458  </p> :
                                        summaryReport.totalMinutes >= 80 && summaryReport.totalMinutes < 100 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458 <br /> 99458  </p> : 
                                        summaryReport.totalMinutes >= 100 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458 <br /> 99458 <br /> 99458  </p> 
                                        : null}
                                      </td>

                                      <td><b>{monthArray[month - 1]}</b></td>

                                      <td><b>{summaryReport?.lastActivity ? moment(summaryReport?.lastActivity).format("MM/DD/YYYY") : '--'}</b></td> 

                                      <td><b>{summaryReport?.doctorName}</b></td>  
                                      <td>
                                        <button className="edit-pill" 
                                        onClick={() => handleViewMinutes(summaryReport?.patientId)}
                                        ><i className="bx bx-file-find"></i></button>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div> 
                        </> : null }
                        </> }
                    </div>
                </div>


                             {/* Modal to download report */}
             <Modal 
                show={show} 
                onHide={handleClose}
                size='xl'
                animation={true}
                backdrop="static"
                >

                    <Modal.Header>
                        <div className="row-display">
                            <div className="row-display-column">
                                <h5 className='title p-0 m-0'>RPM Minutes Spent</h5>
                                <small>Time duration in month <b>{month}</b> - <b>{year}</b></small>
                            </div>
                        </div>

                        <button className="edit-pill" style={{backgroundColor: '#e1e1e1', color: '#000'}} 
                            onClick={handleClose}>
                            <i className="bx bx-x"></i>
                        </button>
                    </Modal.Header>

                    <Modal.Body style={{zoom: '90%'}}>
                        <AdminRPMMinutesComponent patientId={patientId} />
                    </Modal.Body>
                    
            </Modal>
        </section>
    </>
  )
}

export default BillingReport