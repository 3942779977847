import {useEffect, useRef, useState} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import MetaData from '../../layouts/MetaData';
import TopBar from '../../components/AdminDashboard/TopBar';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import TextField from '../../components/Form/TextField';
import GenderSelectbox from '../../components/Form/GenderSelectbox';
import {addPatient, getDoctors, getHrLists} from '../../actions/adminActions';
import {useDispatch, useSelector} from 'react-redux'
import {useAlert} from 'react-alert';
import {Link, useHistory} from 'react-router-dom';
import Select from 'react-select';
import ConsentRoleSelectBox from '../../components/Form/ConsentRoleSelectBox';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import {Button} from "react-bootstrap";
import DuplicatePatientModal from "../../Modals/DuplicatePatientModal";
import axios from "axios";
import {Prod01} from "../../constants/ActionType";


const AddPatient = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const {message, error} = useSelector(state => state.common);
    const {doctors} = useSelector(state => state.doctor);
    const {hrs} = useSelector(state => state.hrslist);
    const [doctorId, setDoctorId] = useState('')
    const [hrId, setHrId] = useState('');

    useEffect(() => {
        if (error) {
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        dispatch(getDoctors(50, 1));
        dispatch(getHrLists())

        if (message) {
            alert.success(message);
            props?.history.push('/patients');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

    }, [dispatch, error, message]);

    const validate = Yup.object().shape({
        emrId: Yup.string().required("EHR-ID is required"),
        firstname: Yup.string()
            .required('First Name is required')
            .min(2, 'Should be atleast 2 characters')
            .max(20, 'Should be less than 20 characters'),
        lastname: Yup.string()
            .min(2, 'Should be atleast 2 characters')
            .max(20, 'Should be less than 20 characters')
            .required('Last Name is Required'),
        email: Yup.string(),
        DOB: Yup.string().required('DOB is required'),
        phone1: Yup.string(),
        gender: Yup.string().required('Gender is required'),
        zipCode: Yup.string().min(2, 'should be atleast two characters').max(10, 'should not br more than ten characters'),
        address: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        line2: Yup.string(),
        ssn: Yup.string(),
        insurancecompany: Yup.string(),
    });

    const submitHandler = (values) => {
        if (doctorId !== '')
            values = {...values, assigned_doctor_id: doctorId}

        dispatch(addPatient(values));
    }

    const assignDr = (dr) => {
        setDoctorId(dr.value)
    }

    // const assignHr = (hr) => {
    //     setHrId(hr.value)
    // }

    const optionsForDoc = []
    doctors && doctors.filter(item => item?.block === false).map((doc) => (
        optionsForDoc.push({value: doc?._id, label: [doc?.firstname, doc?.lastname].join(" ")})
    ))

    // const optionsForHr = []
    // hrs && hrs.map((hr) => (
    //     optionsForHr.push({value: hr?._id, label: [hr?.firstname, hr?.lastname].join(" ")})
    // ))

    const [isBoxOpen, setIsBoxOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const formRef = useRef();
    const [duplicateData, setDuplicateData] = useState(null);
    const [continueable, setContinueable] = useState(false);


    async function checkForDuplicatePatient() {
        try {
            let form = formRef.current.values;
            // console.log(form)
            let data = {
                emrId: form?.emrId,
                firstname: form?.firstname,
                lastname: form?.lastname,
            }
            if (form?.DOB) {
                data = {...data, DOB: form?.DOB}
            }
            if (form?.phone1) {
                data = {...data, phone1: form?.phone1}
            }
            let res = await axios.post(Prod01 + '/admin/patient/duplicateCheck', data).catch(err => {
                console.log(err)
            })
            if (res.data.length > 0) {
                setDuplicateData(res.data)
                setIsBoxOpen(true)
                let sameEMR = false;
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].emrId === form.emrId) {
                        sameEMR = true;
                        break;
                    }
                }
                if (sameEMR) {
                    setContinueable(false)
                } else {
                    setContinueable(true)
                }
            } else {
                setCurrentStep(2)
            }
        }
        catch (e) {
           alert.error("Please fill out all the fields to proceed to step 2.")
        }
    }

    return <>
        <MetaData title="Add New Patient"/>
        <Sidebar/>

        <section className="home-section">
            {/* TopBar */}
            <TopBar/>
            <DuplicatePatientModal isBoxOpen={isBoxOpen} setIsBoxOpen={setIsBoxOpen} title={`${duplicateData?.length} Duplicate patient(s) found`}
                                   patientData={duplicateData} continuable={continueable} proceedButton={()=> {
                                       setCurrentStep(2)
                                          setIsBoxOpen(false)
            }}/>


            <br/>
            <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                <div className="home-content">
                    <div className="row-display">
                        <h5 style={{color: '#00A8A8'}}>
                            <i className="bx bx-user"></i> <strong>&nbsp;Add New Patient </strong>
                        </h5>
                        <div className="row-display">

                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back'></i>
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard">
                                <button className="btn btn-secondary btn-sm">
                                    <i className='bx bxs-home'></i>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <hr/>

                    <Formik initialValues={{
                        emrId: '',
                        firstname: '',
                        lastname: '',
                        DOB: '',
                        gender: '',
                    }}
                            innerRef={formRef}
                            validationSchema={validate}
                            onSubmit={values => {
                                submitHandler(values)
                            }}
                    >
                        <div>
                            <Form>
                                <div className="row">
                                    {currentStep === 1 && <div className={"p-4"}>
                                        
                                        <h3 className="text-center title"><i className="bx bx-user"></i> Patient Information</h3>
                                        <div className={"row"}>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="EHR-ID"
                                                    type="number"
                                                    name="emrId"
                                                    placeholder="EHR-ID"
                                                    autocomplete={"off"}
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* First Name */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="First Name"
                                                    name="firstname"
                                                    placeholder="First Name"
                                                    autocomplete={"off"}
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* Last Name */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Last Name"
                                                    name="lastname"
                                                    placeholder="Last Name"
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                            </div>

                                            {/* Password */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Password"
                                                    name="password"
                                                    placeholder="Password for App Login"
                                                    type="password"
                                                />
                                            </div>

                                            {/* DOB */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="DOB"
                                                    name="DOB"
                                                    compulsory={true}
                                                    placeholder="MM-DD-YYYY"
                                                />
                                            </div>

                                            {/* Gender */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <GenderSelectbox
                                                    label="Gender"
                                                    name="gender"
                                                    compulsory={true}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Phone 1"
                                                    name="phone1"
                                                    placeholder="Phone Number"
                                                />
                                            </div>
                                        </div>
                                    </div>}


                                    {/* phone1 */}


                                    {/* address */}
                                    {currentStep === 2 && <div className={"p-4"}>
                                        {/* <div className="row-display">
                                            <span>Fields with <span style={{color: 'red'}}>* </span> are compulsory to add.</span>
                                            <span>Atleast two diseases to be add.</span>
                                        </div>
                                        <br/> */}
                                        <h3 className="text-center title"><i className="bx bx-home"></i> Residential & Other Information</h3>

                                        <div className={"row"}>

                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Address"
                                                    name="address"
                                                    placeholder="Address"
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* line2 */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Address Line 2"
                                                    name="line2"
                                                    placeholder="Line 2"
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* City */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="City"
                                                    name="city"
                                                    placeholder="City"
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* State */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="State"
                                                    name="state"
                                                    placeholder="State"
                                                    compulsory={true}
                                                />
                                            </div>


                                            {/* Zip Code */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Zip Code "
                                                    name="zipCode"
                                                    placeholder="Zip Code"
                                                    compulsory={true}
                                                />
                                            </div>

                                            {/* SSN */}
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="SSN"
                                                    name="ssn"
                                                    placeholder="SSN"
                                                    compulsory={true}
                                                />
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <TextField
                                                    label="Insurance Company"
                                                    name="insurancecompany"
                                                    placeholder="Insurance Company"
                                                />

                                            </div>
                                            {/* Insurance Company */}
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <label className="form-label mt-3">Select Doctor</label>
                                                <Select
                                                    options={optionsForDoc}
                                                    onChange={assignDr}
                                                    compulsory={true}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 mt-3">
                                                <ConsentRoleSelectBox
                                                    label="Program Enrolled"
                                                    name="patientType"
                                                    compulsory={true}
                                                />
                                            </div>
                                        </div>
                                    </div>}


                                </div>

                                {/* Buttons */}
                                <br/>
                                <div className="row mr-3" style={{float: 'right'}}>
                                    <button className="edit-pill" style={{backgroundColor: 'red', color: 'white'}}
                                            onClick={() => {
                                                setCurrentStep(1)
                                            }} type="reset">Reset
                                    </button>
                                    <button
                                        disabled={currentStep === 1}
                                        className={`${currentStep === 1 && "bg-secondary"}  edit-pill mx-2  `}
                                        onClick={() => {

                                            setCurrentStep(currentStep - 1)
                                        }}>Back
                                    </button>
                                    {currentStep === 2 &&
                                        <button className="edit-pill" type="submit">Add Patient</button>
                                    }
                                    {currentStep === 1 && <button className="edit-pill"
                                                                  type={"button"}
                                                                  onClick={() => {
                                                                      checkForDuplicatePatient()
                                                                  }}>Next</button>
                                    }
                                </div>

                                <br/><br/><br/>
                            </Form>
                        </div>
                    </Formik>
                </div>
            </div>

        </section>
    </>;
};

export default AddPatient;
