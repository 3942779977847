import { CSVLink } from 'react-csv';
import moment from 'moment';


const CallReportToCSV = ({csvData, fileName, filterBy}) => {
    
  
    let result = csvData && csvData.filter(item => {
        if (filterBy === "null") {
          return item; // Return the item as-is
        } else if (filterBy === "noCall") {
          return item?.totalCall === 0; // Return items where totalCall is 0
        } else if (filterBy === "oneCall") {
            return item?.totalCall === 1;
        }  else if (filterBy === "twoCall") {
            return item?.totalCall === 2;
        } else if(filterBy === "noVMs"){
            return item?.totalVM === 0;
        } else if (filterBy === "noReviews"){
            return item?.totalReview === 0;
        }
        else {
          return null; // Handle any other cases (optional)
        }
      }).map((pts, index) => {
        return {
            'Sr No':index + 1,
            'EHR-ID':pts?.emrId,
            'Patient Name': pts?.patientName,
            'D.O.B':moment(pts?.DOB).format('MM/DD/YYYY'),
            'Account Status': pts?.block === true ? 'Inactive' : 'Active',
            'Total Calls':  pts?.totalCall,
            'last Call':pts?.lastCall,
            'Call Attempts (VM)':  pts?.totalVM,
            'Last Call Attempt': pts?.lastVM,
            'Total Reviews':  pts?.totalReview,
            'Last Review': pts?.lastReview,
            'Month': pts?.Month ,
            'Category': pts?.category,
            'Doctor Name': 'Dr. ' + pts?.doctorName.lastname +' , '+ pts?.doctorName.firstname
        }
    })

    return (
        <button className="edit-pill-2 mt-4">
            <i className='bx bxs-cloud-upload'></i> &nbsp;&nbsp;
            <CSVLink style={{color: '#FFF'}} data={result} filename={fileName}> 
                <span>Download .CSV</span>
            </CSVLink> 
        </button>
      )

}

export default CallReportToCSV;