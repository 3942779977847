import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HRProtectedRoute = ({isHR, component: Component, ...rest}) => {
    
	const { isAuthenticated, user } = useSelector(state => state.auth);
    return (
        <> 
            {user?.role === 'HrMedical' && isHR === true ? (
                <Route {...rest} render={props => {
                    
                    if(isAuthenticated === false && isHR !== true){
                        <Redirect to="/" />
                    }
                    
                    else if(isHR === true && user?.verified === true && isAuthenticated === true){
                        <Redirect to="/Nurse/Dashboard" />
                    }
                    
                    return <Component {...props} />
                }}
                />
            ) :   <Route to="/auth/signin/hr" /> 
        }
    </>
    )
}

export default HRProtectedRoute;