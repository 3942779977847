import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { getAdminNotifications } from '../../actions/adminActions';
import { getAdminProfile } from '../../actions/authActions';
import { useAlert } from 'react-alert';
import SessionTimeout from '../../components/SessionTimeout';
import { logout } from '../../actions/authActions';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';


const moment = require('moment-timezone');

const TopBar = () => {
    
    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const { notifications, error} = useSelector(state => state.adminNoti);
    const { user } = useSelector(state => state.auth);

    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [patientId, setPatientId] = useState('');


    useEffect(() => {
        if(error) {
            alert.error(error);
        }
        dispatch(getAdminNotifications());
        dispatch(getAdminProfile(user?._id));

  }, [dispatch, error, alert]);


  const fetchData = (value) => {
    value?.length >= 3 && axios.post(`${Prod01}/patient/searchAll`, {
            value: value
        })
    .then((result) => {
        value && setResults(result?.data);
    }
    )
  }

  const handleChange = (value) => {
    setInput(value);
    setPatientId(value);
    value?.length >= 3 && fetchData(value)
  }

  const logoutHandler = () => { 
    var confirmation = window.confirm(`Are you sure you want to logout ?`);
        if(confirmation){
            window.location.replace("/");
            dispatch(logout());
        }
        localStorage.removeItem('token');
        localStorage.removeItem('admin');
    }

 const redirectHandler = (patientId) => {
    history.push({
      pathname: `/patientProfile`,
      state: {patientid: patientId} // Pass the state object along with the URL
    });
    setInput('');
 }

    return (
        <>
        <SessionTimeout /> 


        <div className="row-display" id='topbar' style={{backgroundColor: '#006464'}}>
           

            <div className="searchBox" >
                <input 
                    type="text" 
                    placeholder="Search patient . . ." 
                    className="searchInput" 
                    value={input}
                    onChange={e => handleChange(e.target.value)}
                />
                <i className='bx bx-search searchbar-Icon'></i>
            </div>
            
            
            
            <div className='ml-4'>
            <div className="notification-dropdown">
                        <Dropdown className="admin-topbar-dropdown">
                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                <i className='bx bx-bell' style={{color: '#FFF', fontSize: '32px'}}></i>
                                <span className="topbarNotificationBadge">50</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="admin-topbar-notification-dropdown">
                            {notifications && notifications?.data?.slice(0,50).map((noti, index) =>(
                                <Dropdown.Item key={index} className="drop-down-item-active">
                                    {noti?.noti_type === 'bp' ? <>
                                    <Link style={{textDecoration: 'none'}} to={{ pathname: "/patientProfile", state: {patientid: noti?.patientId}}}>
                                    {/* <Alert className="notification-text" variant={noti?.status === "High" ? "danger" : noti?.status === 'Elevated' ? "warning" : "info"}> */}
                                        <small>{noti?.textAny}</small>
                                        <div>
                                            <small style={{fontSize: '12px', color: 'gray', float:'right'}}>
                                                {moment(noti?.createdAt).tz("America/New_York").format("MM/DD/YYYY")}</small>
                                        </div>    
                                    {/* </Alert> */}
                                </Link></> : <>
                                <Link className="link" to="/adminDashboard">
                                        <div className="notification-text">
                                            <p>{noti?.textAny}</p>
                                                <small style={{fontSize: '12px', float:'right', marginTop: '-10px'}}>{moment(noti?.createdAt).tz("America/New_York").format("MM/DD/YYYY")}</small>
                                        </div>    
                                </Link>
                                </>    
                                }
                                
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <i className='bx bx-log-out' style={{fontSize: '28px', color: 'white', marginLeft: '20px', cursor: 'pointer'}} onClick={logoutHandler}></i>
                        </div>
                        {/* Notifications */}
            </div>
        </div>
        
        {input && results && results.length > 0 &&                             
            <div className="searchResults">
                {results.map((result, id) => (
                    <div key={id} className='row-display-secondary searchResult'>
                        
                        {/* <Link style={{textDecoration: 'none', color: '#000'}} 
                            to={{ pathname: "/patientProfile", state: {patientid: result?._id}}}*/} 
                           <span onClick={() => redirectHandler(result?._id)}>
                           <b><i className="bx bx-search"></i> &nbsp;&nbsp;&nbsp;&nbsp;{result?.emrId} &nbsp;&nbsp;&nbsp;&nbsp; {result?.lastname +" "+ result?.firstname } </b> 
                           {result?.assigned_doctor_id && <><span> of Organization </span>  <b>{result?.assigned_doctor_id?.lastname +" "+ result?.assigned_doctor_id?.firstname} </b></>}  
                           </span>

                    </div>
                ))}
            </div>
        }
        </>
    )
}

export default TopBar;
