import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import adminImg from '../../assets/Images/defaultUser.png';
import { Link } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import doctorIcon from '../../assets/Images/doctorIcon.png';


let active = "Dashboard";


const HRSidebar = (props) => {
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { assignedDoctors } = useSelector(state => state.hrStats);


    let nurseDrs = localStorage.getItem('nurseSelectedDrs'); 
    

    const handleActive = (value) => {
        active = value;
    }

    useEffect(() => {
		if(isAuthenticated === false) {
			props?.history?.push("/auth/signin/hr");
		}
	}, [isAuthenticated])


    return (
        <>
            <div className="sidebar">
            <br/>

            <div>
                <div className="row-display-secondary" style={{paddingLeft: '10px'}}>

                    {user && user?.profileImg ? <>
                        <img src={`https://www.rpmatrix.com/v1/uploadFiles/${user?.profileImg}`} 
                        width="70" height="70" className="roundedBorderImg" alt="img" 
                    />  
                    </> : <img src={adminImg} width="50" height="50" className="roundedBorderImg" alt="img" />
                    }

                    <p className='text-white'>    
                    {user && user.firstname +" "+ user?.lastname } <sup>{user?.verified === true ? <i className='bx bxs-badge-check' 
                            style={{fontSize: '18px', color: 'white'}}></i> : null}</sup> 
                            
                            <Link to="/hr" className="link"><small>Edit profile</small></Link>

                            <p style={{fontSize: '18px'}}>Nurse Dashboard</p>
                    </p>
                </div>
            </div>
            

            <ul>
                <li className={`${active === 'Dashboard' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="Dashboard">
                    <Link to="/Nurse/Dashboard" 
                    onClick={() => handleActive("Dashboard")} 
                    className={`${active === 'Dashboard' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-dashboard'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Dashboard</span>
                    </Link>
                </li>

                {nurseDrs && nurseDrs.length > 0 ? <>
                <li className={`${active === 'nursepts' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="nursepts">
                    <Link to="/Nurse/Patients" 
                    onClick={() => handleActive("nursepts")} 
                    className={`${active === 'nursepts' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-user'></i> 
                        &nbsp;&nbsp;&nbsp;<span>RPM Patients</span>
                    </Link>
                </li>
                
                <li className={`${active === 'ccmPts' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="ccmPts">
                    <Link to="/CCM/Nurse/Patients" 
                    onClick={() => handleActive("ccmPts")} 
                    className={`${active === 'ccmPts' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-user'></i> 
                        &nbsp;&nbsp;&nbsp;<span>CCM Patients</span>
                    </Link>
                </li>

                 <li className={`${active === 'ptsMessages' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="ptsMessages">
                    <Link to="/Nurse/Messages" 
                    onClick={() => handleActive("ptsMessages")} 
                    className={`${active === 'ptsMessages' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-chat'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Messages</span>
                    </Link>
                </li>

                <li className={`${active === 'callreport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="callreport">
                    <Link to="/Nurse/Report/Call" 
                    onClick={() => handleActive("callreport")} 
                    className={`${active === 'callreport' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bx-phone-call'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Patients Call Report 
                        &nbsp;&nbsp;<span className='newSpan'>New</span></span>
                    </Link>
                </li>
                
                <li className={`${active === 'criticalReadingsDoctorwise' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="criticalReadingsDoctorwise">
                    <Link to="/Nurse/CriticalReadings" 
                    onClick={() => handleActive("criticalReadingsDoctorwise")} 
                    className={`${active === 'criticalReadingsDoctorwise' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-chat'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Critical Readings Doctorwise</span>
                    </Link>
                </li>

                <li className={`${active === 'trgtMinutesReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="trgtMinutesReport">
                    <Link to="/Nurse/Report/TimeReport" 
                    onClick={() => handleActive("trgtMinutesReport")} 
                    className={`${active === 'trgtMinutesReport' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-watch'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Time Report </span>
                    </Link>
                </li>
                
                <li className={`${active === 'telemetryReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="telemetryReport">
                    <Link to="/Nurse/Telemetry/Report" 
                    onClick={() => handleActive("telemetryReport")} 
                    className={`${active === 'telemetryReport' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bxs-droplet'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Telemetry Data Report</span>
                    </Link>
                </li>
                
                <li className={`${active === 'financialReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="financialReport">
                    <Link to="/Nurse/Report/Financial_Report" 
                    onClick={() => handleActive("financialReport")} 
                    className={`${active === 'financialReport' ? 'activeLink' : 'sidebar-link'}`}>
                        <i className='bx bx-dollar'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Financial Report</span>
                    </Link>
                </li>
                
                <div className="text-white text-center mt-3">
                    <h5>Working Doctor(s)</h5>
                    <img src={doctorIcon} width="85px" height="85px" />
                    {assignedDoctors && assignedDoctors.map((doctor, index) => (
                        <li key={index} className="m-1 p-1">{index + 1 +') '}  Dr. {doctor?.lastname +', '+ doctor?.firstname}</li>
                    ))}
                </div>

                {/* <div className="text-white text-center mt-3">
                    {nurseDrs && organization?.doctors?.length > 0 ? <>
                        <h5>Working Doctor(s)</h5>
                        <img src={doctorIcon} width="85px" height="85px" />
                        
                        {organization?.doctors.map((item, index) => (
                            nurseDrs?.includes(item?.assigned_doctor_id?._id) ? <li key={index} className="m-1 p-1">{index + 1 +') '}  Dr. {item?.assigned_doctor_id?.lastname +', '+ item?.assigned_doctor_id?.firstname}</li> : null
                        ))}    

                        <Link 
                            to="/clinical_staff/settings" 
                            className='edit-pill'
                        >Switch Doctor</Link>
                    </> : null
                    }
                </div> */}
            
            </> : <NotFound title="Select Your Doctor from settings."/> }   
        </ul>

        </div>    
        </>
    )
}

export default HRSidebar;
