import {useEffect, useState} from 'react';
import MetaData from '../../layouts/MetaData';
import  Sidebar from '../../components/Assistant/Sidebar';
import Topbar from '../../components/Assistant/Topbar';
import { useHistory, Link } from 'react-router-dom';
import { getRPMMinutesReportOfAssistant } from '../../actions/assistantActions';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import moment from 'moment';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import Loader from '../../layouts/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'react-bootstrap';


const AssistantMinutesHistory = () => {
  const dispatch = useDispatch();  
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);
  
  const [reportCategory, setReportCategory] = useState('RPM');
  
//   const [startMonth, setStartMonth] = useState(moment().format('MM'));
//   const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));

    const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState("2024")
  let assistantId = user._id;
  
  useEffect(() => {
    // if(error) {
    //     alert.error(error);
    // }

    dispatch({ type: RESET_TIME_REPORT_DATA });
    if(reportCategory === 'RPM'){
        dispatch(getRPMMinutesReportOfAssistant(assistantId, month, year, reportCategory)); 
    } else {    
        dispatch(getRPMMinutesReportOfAssistant(assistantId, startMonth, year, reportCategory, endMonth)); 
    }
     

}, [dispatch, reportCategory, month, year, startMonth, endMonth]);


    const downloadFile = (fileName) => {
        window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
    }

    
  return (
    <>
    <MetaData title="Minutes History" />
        <Sidebar />
        
        <section className="home-section">
            {/* TopBar */}  
            <Topbar />
            <br/>
            <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                <div className="home-content">
                    <div>
                        <div className="row-display">        
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bx-filter'></i> <strong>&nbsp;Minutes History </strong>
                        </h5>

                            <div className="row-display">
                                <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                    <i className='bx bx-arrow-back'></i>
                                </button>

                                &nbsp;&nbsp;
                                <Link to="/clinical_staff/dashboard">
                                    <button className="btn btn-secondary btn-sm">
                                    <i className='bx bxs-home'></i>
                                    </button>
                                </Link>
                            </div>  
                        </div>
                        <hr />

                        <div className="row-display">
                                <h5 style={{color: '#00A8A8 '}}>
                                    <i className='bx bxs-watch'></i> 
                                    <strong>&nbsp;{reportCategory} Minutes Added </strong>
                                    <p><small>For the month of {moment().format('MMMM')} 2024</small></p>
                                </h5>


                                {reportCategory === 'RPM' ? 
                                <div>
                                <label className="form-label" htmlFor="month">Select Month </label>
                                    <select 
                                    name="month" 
                                    className="form-control"
                                    value={month} 
                                    onChange={e => setMonth(e.target.value)}
                                    style={{width: '200px'}}
                                    >
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                    </select>
                                </div> : reportCategory === 'CCM' ? <>
                                    <div>
                                    <label className="form-label" htmlFor="month">Start Month </label>
                                    <select 
                                        name="month" 
                                        className="form-control"
                                        value={startMonth} 
                                        onChange={e => setStartMonth(e.target.value)}
                                        style={{width: '200px'}}
                                        >
                                        <option value="01">January - 26</option>
                                        <option value="02">February - 26</option>
                                        <option value="03">March - 26</option>
                                        <option value="04">April - 26</option>
                                        <option value="05">May - 26</option>
                                        <option value="06">June - 26</option>
                                        <option value="07">July - 26</option>
                                        <option value="08">August - 26</option>
                                        <option value="09">September - 26</option>
                                        <option value="10">October - 26</option>
                                        <option value="11">November - 26</option>
                                        <option value="12">December - 26</option>
                                        </select>
                                    </div>


                                    <div>
                                    <label className="form-label" htmlFor="month">End Month </label>
                                    <select 
                                        name="month" 
                                        className="form-control"
                                        value={endMonth} 
                                        onChange={e => setEndMonth(e.target.value)}
                                        style={{width: '200px'}}
                                        >
                                        <option value="01">January - 25</option>
                                        <option value="02">February - 25</option>
                                        <option value="03">March - 25</option>
                                        <option value="04">April - 25</option>
                                        <option value="05">May - 25</option>
                                        <option value="06">June - 25</option>
                                        <option value="07">July - 25</option>
                                        <option value="08">August - 25</option>
                                        <option value="09">September - 25</option>
                                        <option value="10">October - 25</option>
                                        <option value="11">November - 25</option>
                                        <option value="12">December - 25</option>
                                        </select>
                                    </div>
                                    </> : null }

                                <div style={{float: 'right', width: '190px'}}>
                                    <label className="form-label" htmlFor="year">Select Year </label>
                                        <select 
                                        name="year" 
                                        className="form-control"
                                        value={year} 
                                        onChange={e => setYear(e.target.value)}
                                        style={{width: '200px'}}
                                        >
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                    </select>
                                </div>

                                <div style={{float: 'right', width: '190px'}}>
                                <label className="profile-label">Category</label>
                                    <select 
                                        className="form-control" 
                                        name="category"
                                        value={reportCategory}
                                        onChange={e => setReportCategory(e.target.value)}
                                    >
                                        <option value="RPM">RPM Category</option>
                                        <option value="CCM">CCM Category</option>
                                    </select>
                                </div>
                            </div>

                    {/* First Row Ends Here */}
                    {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>
                                    
                        <br />
                        <div className="row-display">
                            <div className="col-md-12">
                                 
                            <br />

                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div> 
                            </div>
                              </div>        
                            </div>
                            <br />

                                    {targets && targets.map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Name: </label> 
                                                <label className="profile-label ml-2"> {trgt?.assigned_patient_id?.firstname} {trgt?.assigned_patient_id?.lastname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">D.O.B: </label> 
                                                <label className="report-label ">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">S / D: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">E / D: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.startTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E / T: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.endTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                            </label>

                                            <p>
                                                {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                : null 
                                                }
                                            </p>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                            </> : null}
                                            </div>
                                        </div>
                                        </div>
    
                                        <div className="row-display-secondary">
                                            <div className="mt-3 mr-3">
                                                <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                            </div>
                                            <div className="bubble bubble-alt">      
                                                <b className='text-white' 
                                                    style={{letterSpacing: '1px'}}> 
                                                    {trgt?.assigned_assistant_id?.firstname} {trgt?.assigned_assistant_id?.lastname}
                                                </b>
                                                <br/>
                                                                    
                                                <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                </p>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>

                                ))}
                            </> : <div className="text-center" style={{marginTop: '70px'}}>
                                <i className="fas fa-search-minus" style={{fontSize: '60px', color: '#00A8A8'}}></i>
                                <p className="mt-2">No Minutes added in <span style={{ fontWeight: 'bold' }}>
                                    {moment().format('MMMM')}</span> 
                                    &nbsp; in  <span style={{fontWeight: 'bold'}}>{reportCategory}</span> category.</p>
                                </div>}
                            </>}

                        {/* Result of targets ends here */}
                    </div>
                </div>
            </div>
    </section>
    </>
  )
}

export default AssistantMinutesHistory