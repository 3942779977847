import { CSVLink } from 'react-csv';
import moment from 'moment';


const ExportActivePtsReportToCSV = ({csvData, fileName}) => {
    let compliantStatus;

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }
  
    let result = csvData && csvData.filter(item => item?.block === false).map((pts, index) => {
        
        pts?.patientType === 'RPM' || pts?.patientType === 'Both' && calculateCompliancyStatus(moment(pts?.lastReading))    
        
        return {
            'Sr No':index + 1,
            'EHR-ID':pts?.emrId,
            'Patient Name':pts?.lastname +', '+ pts?.firstname,
            'D.O.B':moment(pts?.DOB).format('MM/DD/YYYY'),
            'Acc Status': pts?.block === true ? 'Inactive' : 'Active',
            'Clinician': 'Dr.'+ pts?.assigned_doctor_id?.firstname +', '+ pts?.assigned_doctor_id?.lastname,
            'Compliancy Status':  compliantStatus === 'compliant' ? 
                    'Compliant' : compliantStatus === 'mostLikelyCompliant' ? 
                        'Most Likely Compliant' : compliantStatus === 'notLikelyCompliant' ? 
                            'Not Likely Compliant' : compliantStatus === 'nonCompliant' ? 
                            'Non Compliant' : null,
            'Last Reading': pts?.lastReading ? moment(pts?.lastReading).format("MM/DD/YYYY") : '--',
            'Consent Role' : pts?.patientType === 'Both' ? 'RPM & CCM' : pts?.patientType,
            'Last Call': pts?.lastcallrpm && moment(pts?.lastcallrpm).format('MM-DD-YYYY'),
        }
    })

    return (
        <button className="edit-pill" style={{backgroundColor:'#1F6D43'}}>
            <i className='bx bxs-cloud-upload'></i> &nbsp;&nbsp;
            <CSVLink style={{color: '#FFF'}} className='link' data={result} filename={fileName}> 
                <span>Download .csv</span>
            </CSVLink> 
        </button>
      )

}

export default ExportActivePtsReportToCSV;