import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {getPatientsWithCriticalData} from '../../actions/adminActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal, Badge } from 'react-bootstrap';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import bpImg from '../../assets/Images/blood-pressure.png';
import weightImg from '../../assets/Images/bmi.png';
import glucoseImg from '../../assets/Images/glucometer.png';
import oximeterImg from '../../assets/Images/oximeter.png';



const PatientsWithCriticalReadingsAdmin = () => {
  
  const dispatch = useDispatch();
  const {count, allReadings, respondedReadings, notRespondedReadings, highSystolic, lowDiastolic,  criticalPts, weightReadings, glucoseReadings, spo2Readings} = useSelector(state => state.criticalPatients);
  
  const [filterReadings, setFilterReadings] = useState('all');
  const [pagination, setPagination] = useState(25);  

  const [conclusion, setConclusion] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getPatientsWithCriticalData(pagination, filterReadings));
}, [dispatch, filterReadings, pagination]);

    const refreshCriticalReadings = () => {
        dispatch(getPatientsWithCriticalData(pagination, filterReadings));
    }

    const updateCriticalReadingsPagination = () => {
        setPagination(pagination + 25);
        dispatch(getPatientsWithCriticalData(pagination, filterReadings));
    }

    let sysBPCategory; //variable to store category of BP
    let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }
    
    function handleConclusion(pts) {
        setConclusion([pts])
        setShow(true);
    }

    var minutesDuration;
    const calcDuration = (receiveDate, sentDate) => {
        minutesDuration = moment(receiveDate).diff(moment(sentDate), 'minutes');
    }
  
  return (
    <div className="container-fluid mt-3 mb-5">

        <div className="col-md-12">
            <div className="row">
                <div className="col-md-12">
                  <div className="shadow-lg p-3 recent-readings-card background-white">
                    <div className="home-content">
                            <div className='row-display'>
                                <div className="pr-4 rightBorderLine"> 
                                    <h5 className="title"> Critical Vitals Received</h5> 
                                    <p><b>{criticalPts?.length}</b> results out of <b>{count && count}</b> </p>
                                </div>


                                <div className="row">
                                    <img src={bpImg} width="80px" height="80px" />
                                    <div className="ml-2">
                                        <small><b>Blood Pressure Readings</b></small> <br/>
                                        <h2>{allReadings} </h2>
                                    </div>                                
                                </div>

                                <div className="row">
                                    <img src={weightImg} width="80px" height="80px" />
                                    <div className="ml-2">
                                        <small><b>Weight Scale Readings</b></small> <br/>
                                        <h2>{weightReadings && weightReadings < 10 ? '0'+weightReadings : weightReadings} </h2>
                                    </div>                                
                                </div>

                                <div className="row">
                                    <img src={glucoseImg} width="80px" height="80px" />
                                    <div className="ml-2">
                                        <small><b>Glucose Readings</b></small> <br/>
                                        <h2>{glucoseReadings && glucoseReadings < 10 ? '0'+glucoseReadings : glucoseReadings} </h2>
                                    </div>                                
                                </div>

                                <div className="row">
                                    <img src={oximeterImg} width="80px" height="80px" />
                                    <div className="ml-2">
                                        <small><b>Spo2 Readings</b></small> <br/>
                                        <h2>{spo2Readings && spo2Readings < 10 ? '0'+spo2Readings : spo2Readings} </h2>
                                    </div>                                
                                </div>

                                <div className="row-display m-3">
                                    <Link className="edit-pill" to="/critical/telemetryData">
                                        Doctorwise Readings
                                    </Link>
                                    &nbsp;&nbsp;

                                    <button className="edit-pill" onClick={() => refreshCriticalReadings()}>
                                        <i className="bx bx-refresh"></i>
                                    </button>
                                </div> 
                                <br/>
                                


                                
                            </div>
                        <hr className="blue-hr"/>

                        <div className="row-display">
                            <label className="profile-label mt-2">Search Filters </label>
                            <span className={`smallFilterBtn ${filterReadings === 'all' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('all')}>
                                    All Readings
                            </span> 
                            
                            <span className={`smallFilterBtn ${filterReadings === 'responded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('responded')}>
                                    Responded <span className="notiNumber ml-2" style={{backgroundColor: 'green'}}> {respondedReadings > 10 ? respondedReadings :'0'+ respondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'notResponded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('notResponded')}>
                                    Not Responded <span className="notiNumber ml-2"> {notRespondedReadings > 10 ? notRespondedReadings :'0'+ notRespondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'highSystolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('highSystolic')}>
                                    High Systolic {'>'} 180 <span className="notiNumber ml-2"> {highSystolic > 10 ? highSystolic :'0'+ highSystolic} </span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'lowDiastolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('lowDiastolic')}>
                                    Low Diastolic {'<'} 55 <span className="notiNumber ml-2"> {lowDiastolic > 10 ? lowDiastolic :'0'+ lowDiastolic} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('weight')}>
                                    Weight <span className="notiNumber ml-2"> {weightReadings > 10 ? weightReadings :'0'+ weightReadings}</span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'glucose' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('glucose')}>
                                    Glucose <span className="notiNumber ml-2"> {glucoseReadings > 10 ? glucoseReadings :'0'+ glucoseReadings} </span>  
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('spo2')}>
                                    Spo2 <span className="notiNumber ml-2"> {spo2Readings > 10 ? spo2Readings :'0'+ spo2Readings} </span> 
                            </span>
                        </div>
                        <br/>

                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Vitals</th>
                                    <th width="90px">Device</th>
                                    <th width="90px">Pulse</th>
                                    <th>Status</th>
                                    <th>Doctor</th>
                                    <th width="100px"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {criticalPts && criticalPts.map((pts, index) => (
                                <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                                    <td>
                                        <div className="row-display">
                                        <span className="roundedNameIcon">{pts?.assigned_patient_id?.lastname[0]}{pts?.assigned_patient_id?.firstname[0]}</span>
                                        
                                        <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } </Link>
                                        </div>
                                    </td>

                                    <td>{pts?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p className="p-0 m-0">{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                           
                                           {/* function to calculate time duration */}
                                            {pts?.createdAt && pts?.telemetaryData?.ts && calcDuration(moment(pts?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                                            moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                                            {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }
                                            {/* function to calculate time duration ends */}
                                    </>}
                                    </td>

                                    {calcSysBpCategory(pts?.telemetaryData?.sys)}


                                    {pts?.deviceId?.deviceType === "bp" ? <td> 
                                            <b>{pts?.telemetaryData?.sys} / {pts?.telemetaryData?.dia} mmHG </b><p>
                                                {pts?.telemetaryData?.sys >= 139 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                                pts?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                                pts?.telemetaryData?.dia >= 90 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                                pts?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                                </p>
                                                </td> : pts?.deviceId?.deviceType === "weight" ? <td>
                                                <b>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                                <p>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                            </b>
                                        </td> : pts?.deviceId?.deviceType === "sugar" ? <td>
                                            <b> {pts?.telemetaryData?.data} &nbsp;
                                                {pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                            </b>
                                        </td> : pts?.deviceId?.deviceType === "spO2" ? <td>
                                            <b>{pts?.telemetaryData?.notification_payload?.spo2_pct}
                                            <p>Perfusion: {pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                        </td> : <td>---</td> }

                                    {/* Device type */}
                                    <td><b>{pts?.deviceId?.deviceType === "bp" ? 
                                    <span className='bpDeviceType'>B.P</span> : 
                                    pts?.deviceId?.deviceType === 'weight' ? 
                                    <span className='weightDeviceType'>Weight</span> : 
                                    pts?.deviceId?.deviceType === 'sugar' ? 
                                    <span className="glucoseDeviceType">Glucose</span> :
                                    pts?.deviceId?.deviceType === 'spO2' ? 
                                    <span className="spo2DeviceType">Spo2</span> : null } 
                                    </b>
                                    </td>        

                                     {/* B.P Pulse rate  */}
                                    <td>
                                        <b>{pts?.telemetaryData?.pul ? pts?.telemetaryData?.pul +' bpm' : pts?.telemetaryData?.notification_payload?.pulse_bpm ? pts?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} </b>
                                    </td>
                                    
                                    {/* B.P Category */}
                                    <td> {pts?.telemetaryData?.sys ? <><i className='bx bxs-circle' style={{color: color, fontSize: '24px'}}></i>&nbsp;&nbsp;<b>{sysBPCategory}</b></> : '---'}</td> 
                                    
                                    {/* Doctor Name */}
                                    <td><b>{pts?.assigned_doctor_id ? 'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname : null}</b></td>
                                    
                                    {/* Responded or Not */}
                                    <td>{pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                        <i className="fas fa-times" style={{color: 'red'}}></i>}

                                        &nbsp; &nbsp;
                                        {pts?.notes?.length > 0 && <Link className="link" onClick={() => handleConclusion(pts)}>
                                            <i className='fa fa-eye'></i>
                                            </Link>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                        <br/>
                        {criticalPts?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateCriticalReadingsPagination()}>Show more</button>
                            </div>
                        </> }
                    </div>
              </div>
            </div>
            </div>
        </div>

        <Modal show={show} onHide={handleClose}>
                    <Modal.Header style={{backgroundColor: '#EDEADE'}}><h5 className='title'>Reading Comment</h5></Modal.Header>

                    <Modal.Body style={{backgroundColor: '#EDEADE'}}>
                        {conclusion && <>
                            {conclusion?.map((con, index) => (
                                <div key={index}>
                    
                    <div>      
                    
                    <div className="row-display">
                        <div>
                            <label className="form-label">Patient Name</label> <br />
                            <Link className="link" to={{ pathname: "/patientProfile", state: {patientid: con?.assigned_patient_id?._id}}}>{con?.assigned_patient_id?.lastname +' '+ con?.assigned_patient_id?.firstname} </Link>
                        </div>

                        <div>
                            <label className="form-label">Assigned Doctor</label>
                            <p>{'Dr.'+ con?.assigned_doctor_id?.lastname +' '+ con?.assigned_doctor_id?.firstname}</p>
                        </div>
                    </div>

                    <b>Vitals</b> <br />
                    {con?.deviceId?.deviceType === "bp" ? <td> 
                                            <b>{con?.telemetaryData?.sys} / {con?.telemetaryData?.dia} mmHG </b><p>
                                                {con?.telemetaryData?.sys >= 139 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                                con?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                                con?.telemetaryData?.dia >= 90 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                                con?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                                </p>
                                                </td> : con?.deviceId?.deviceType === "weight" ? <td>
                                                <b>{(con?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                                <p>{(con?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                            </b>
                                        </td> : con?.deviceId?.deviceType === "sugar" ? <td>
                                            <b> {con?.telemetaryData?.data} &nbsp;
                                                {con?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                            </b>
                                        </td> : con?.deviceId?.deviceType === "spO2" ? <td>
                                            <b>{con?.telemetaryData?.notification_payload?.spo2_pct}
                                            <p>Perfusion: {con?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                        </td> : <td>---</td> }


                    <b className="profile-label">Conclusion : </b>
                    <p className="mt-1 mr-3">{con?.notes[0]?.conclusion} </p>

                    <div className="row-display-secondary">                
                            
                        <img src={patientProfileImg} alt="hr/drImg" className='roundedBorderImg' width="50px" height="50px" />
                        
                        <div>
                        <b className="profile-label">{con?.notes[0]?.conclusion_hr_id?.lastname +' , '+ con?.notes[0]?.conclusion_hr_id?.firstname} </b>
                        
                        <p className="mt-1" style={{letterSpacing: '1px'}}>
                        {moment(con?.notes[0]?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                        </div>
                        
                    </div>
                   
                </div>

                    </div>
                ))}
            </>}
            </Modal.Body>
                    
                    <Modal.Footer style={{backgroundColor: '#EDEADE'}}>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleClose}>
                            Close
                        </button>
                    </Modal.Footer>
            </Modal>

        </div>
    )
}

export default PatientsWithCriticalReadingsAdmin