import {useEffect} from 'react'
import  Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useSelector, useDispatch } from 'react-redux';
import folderImg from '../../assets/Images/folder.png';
import { getDoctorPatients } from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import { Badge, ProgressBar } from 'react-bootstrap';
import moment from 'moment';


const StaffPatients = () => {
    let compliantStatus;

    const history = useHistory();
    
    const dispatch = useDispatch();
    
    const { user} = useSelector(state => state.auth);
    const { loading, doctorpatients } = useSelector(state => state.docPatients);
    
    let id = user._id;

    useEffect(() => {
        dispatch(getDoctorPatients(id));
	}, [dispatch])

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }

    return (
        <>
           <MetaData title="RPM Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />

                {loading ? <Loader /> : <>
                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">
                            <div>
                                <div className="row-display">
                                    <h5 className="title">RPM Patients </h5>
                                
                                    <button 
                                        onClick={() => history.goBack()}
                                        className="btn btn-secondary btn-sm">
                                        <i className="bx bx-arrow-back"></i>  
                                    </button>
                                </div>
                                
                                <br />
                                {doctorpatients?.length > 0 ? (<>
                                <div className="col-md-12">
                                    <>
                                    <table className="styled-table">
                                        <thead align="center">
                                            <tr>
                                            <th>Name & EHR-ID</th>
                                            <th>DOB </th>
                                            <th>Device Status</th>
                                            <th>Devices Assigned</th>
                                            <th>Acc Status</th>
                                            <th>Clinician</th>
                                            <th>Last Reading & Compl. Status</th>
                                            <th>Program Enrolled</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {doctorpatients && doctorpatients.map((patient, index) => ( 
                                            <tr align="center" key={index}>
                                            
                                            
                                            <td>
                                                <Link className="link m-0 p-0" to={{ pathname: "/doctor/patient/profile", state: {patientid: patient?._id, deviceid: patient?.deviceassigned?.deviceid}}}>{patient?.firstname} {patient?.lastname}</Link>
                                                <p>{patient?.emrId}</p>
                                            </td>
                                            
                                            <td> <b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b> <p><Badge bg="dark text-white">{patient?.gender}</Badge></p></td> 

                                            <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                                    <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                                    <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                                {patient?.batterySignals?.sig ? <ProgressBar animated variant="success" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                            </td>

                                            <td><b>{'0'+ patient?.assigned_devices?.length}</b>
                                            <p>{patient?.assigned_devices.map((device, index) => (
                                                <Badge pill bg="info text-white" style={{fontWeight: 500, 
                                                    textTransform: 'uppercase', 
                                                    padding: '5px',
                                                    letterSpacing: '3px',
                                                    margin: '2px'
                                                }} key={index}>{device?.deviceObjectId?.deviceType}</Badge>
                                            ))}</p>
                                            </td>

                                            <td style={{color: 'green'}}>
                                                <b>{patient?.block === false ? <span style={{color: 'green'}}>
                                                Activated</span> : <span style={{color: 'red'}}>Deactivated</span>}</b>
                                            </td>

                                            {patient?.assigned_doctor_id ? <>
                                                <td><b>Dr. {patient?.assigned_doctor_id?.lastname}, {patient?.assigned_doctor_id?.firstname}</b></td>
                                                </> : <>
                                                <td>---</td>
                                            </>}
                                            
                                            {calculateCompliancyStatus(moment(patient?.lastReading))}    

                                            <td>
                                            <span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                                <p>{patient?.lastReading ? 
                                                    compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                        Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                            Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                                Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                                Non Compliant</p> : null : '--'}</p>
                                            </td>

                                            <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>
                                            
                                        </tr> 
                                        ))}
                                        
                                        </tbody>
                                        </table>    
                                    </>                      
                            </div>
                        </> ) : <>

                        <div>   
                                <img src={folderImg} className="no-record-found-img"/>
                                <p className="doctor-specilizations">No Patient Assigned Yet...</p>         
                        </div>
                        </> }
                            </div>
                        </div>
                    </div>
                         </> } 
                </section>     
        </>
    )
}

export default StaffPatients
