import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { getDoctors, getOrganizations } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CALL_REPORT_RESET } from '../../constants/adminConstants';
import { generateCallReport } from '../../actions/adminActions';
import moment from 'moment';
import Loader from '../../layouts/Loader';
import { useAlert } from 'react-alert';
import rejectedImg from '../../assets/Images/rejected.png';
import phoneCallImg from '../../assets/Images/phone-call.png';
import phoneImg from '../../assets/Images/phone.png';
import CallReportToCSV from '../../components/CallReportToCSV';


const AdminCallReport = () => {

    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '52px', // Adjust the height here as needed
          width: '250px',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth:'2px',
          borderColor: '#444',
        }),
      };

    const alert = useAlert();
    const dispatch = useDispatch();
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState("2024");
    const [patientType, setPatientType] = useState('RPM');
    const [pagination, setPagination] = useState(5);
    const [doctorId, setDoctorId] = useState("");
    const { error, doctors } = useSelector(state => state.doctor);
    const [filterBy, setFilterBy] = useState("null");
    const { organizations } = useSelector(state => state.orgs);
    const [orgId, setOrgId] = useState("");
    const { loading, count, callReport, noCallPts, oneCallPts, twiceCallPts } = useSelector(state => state.calls);

    useEffect(() => {
        if(error){
            alert.error(error);
        }
    
        dispatch(getDoctors(50, 1));
        dispatch(getOrganizations()); 

    }, [dispatch, pagination, filterBy]);

    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name]})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }

    const submitReport = () => {
        if(!doctorId) {
            alert.error('Please select doctor');
            return
        }

        dispatch(generateCallReport(doctorId, patientType, month, year, pagination));
    }


    const resetReport = () => {
        setFilterBy("null");
        setPagination(50);
        dispatch({
            type: CALL_REPORT_RESET
          })
    }

    // const handlePagination = () => {
    //     setPagination(pagination + 50);
    //     dispatch(generateCallReport(doctorId, patientType, month, year, pagination));
    // }

    return (
        <>
        <MetaData title="Call Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <br/>
        <div className="shadow-lg m-2 background-white rounded">
            <div className="home-content">

                <div className="row-display p-2 mr-2 ml-2">
                    <h5 className='title pt-2'>
                        <i className="bx bx-edit"></i> &nbsp;Call Report
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;
                        
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>

            <div className="row-display mt-3 reportFormFields">
               
            <div>
                        <label className="form-label">
                            Select Organization  <span style={{color: 'red'}}> *</span>  
                        </label>
                        <Select
                            options={orgOptions}
                            onChange={getOrgProfile}
                            styles={customStyles}
                        />    
                    </div>&nbsp;&nbsp;&nbsp;
                    
                    <div>
                        <label className='form-label'>Select Doctor</label>
                            <select className="form-control" 
                                value={doctorId} 
                                onChange={e => setDoctorId(e.target.value)}
                                disabled={orgId ? false : true}
                                >
                                <option value="null">Select Doctor</option>
                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                    <option key={doctor?._id} value={doctor?._id}>
                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                    </option>
                                ))}
                            </select>
                        </div>

               <div>
               <label className='form-label'>Program  <span style={{color: 'red'}}> *</span>  </label>
                   <select className="form-control" 
                        value={patientType} 
                        onChange={e => setPatientType(e.target.value)}
                        style={{width: '200px'}}
                    >
                        <option value="RPM">RPM</option>
                        <option value="CCM">CCM</option>
                   </select>
               </div>

               <div>
               <label className='form-label'>Month  <span style={{color: 'red'}}> *</span>  </label>
                       <select  
                           className="form-control"
                           value={month}
                           onChange={(e) => setMonth(e.target.value)}
                           style={{width: '200px'}}
                       >
                     <option value="01">January</option>
                     <option value="02">February</option>
                     <option value="03">March</option>
                     <option value="04">April</option>
                     <option value="05">May</option>
                     <option value="06">June</option>
                     <option value="07">July</option>
                     <option value="08">August</option>
                     <option value="09">September</option>
                     <option value="10">October</option>
                     <option value="11">November</option>
                     <option value="12">December</option>
                    </select>
                   </div>

                   <div>
                       <label className="form-label">Year  <span style={{color: 'red'}}> *</span>  </label>
                       <select 
                           className="form-control"
                           value={year}
                           onChange={(e) => setYear(e.target.value)}
                           style={{width: '200px'}}
                       >
                           <option value="2022">2022</option>
                           <option value="2023">2023</option>
                           <option value="2024">2024</option>
                           <option value="2025">2025</option>
                       </select>
                   </div>

                    <button className="edit-pill-2 mt-4" 
                    onClick={() => submitReport()}>Generate Report</button>
               </div>


               {/* report result */}
               {loading === true ? <Loader /> : <> 
               {callReport && callReport?.length > 0 ? <>

               <div className="row-display container-fluid">
                <div>
                    <h5 className="title pt-4">
                        Report Result
                    </h5>
                    <p>Showing <b>{callReport?.length}</b> results out of <b>{count}</b></p> 
                    
                </div>

                
                <div className='row-display mt-2'>
                
                <div>
                <select className="form-control mt-4" value={filterBy} onChange={e => setFilterBy(e.target.value)}>
                    <option value="null">Filter Report By</option>
                    <option value="noCall">No Call Attempts</option>
                    <option value="oneCall">1 Call Attempt</option>
                    <option value="twoCall">2 Call Attempt</option>
                    <option value="noVMs">No Voicemails</option>
                    <option value="noReviews">No Reviews</option>
                </select>
                </div>            
                    
                &nbsp;&nbsp;&nbsp;
                {callReport && <>
                    <CallReportToCSV 
                    csvData={callReport} 
                    fileName={`${'Dr. ' + callReport[0]?.doctorName?.lastname +' , '+ callReport[0]?.doctorName?.firstname} Call Report ` + '.csv'}
                    filterBy = {filterBy}
                    />
                </>}
                &nbsp;&nbsp;&nbsp;

                <button 
                    className="edit-pill-2 mt-4" 
                    onClick={resetReport}
                    style={{backgroundColor: 'red'}}
                    >Reset
                </button>
                </div>
               </div>

               {/* Stats */}
                    <div className="row-display container mt-3 mb-3">

                    <div className='row'>
                        <img src={rejectedImg} alt="img" width="80px" height="80px" />
                        &nbsp;&nbsp;
                        <div>
                            <h5 className="title" style={{color: 'red'}}>Not Called Patients</h5>
                            <h1>{noCallPts && noCallPts < 10 ? '0'+noCallPts : noCallPts}</h1>
                        </div>
                    </div>

                    <div className='row'>
                        <img src={phoneCallImg} alt="img" width="80px" height="80px" />
                        &nbsp;&nbsp;
                        <div>
                            <h5 className="title">Called Only Once</h5>
                            <h1>{oneCallPts && oneCallPts < 10 ? '0'+oneCallPts : oneCallPts}</h1>
                        </div>
                    </div>

                    <div className='row'>
                        <img src={phoneImg} alt="img" width="80px" height="80px" />
                        &nbsp;&nbsp;
                        <div>
                            <h5 className="title" style={{color: 'green'}}>Called Twice or More</h5>
                            <h1>{twiceCallPts && twiceCallPts < 10 ? '0'+twiceCallPts : twiceCallPts}</h1>
                        </div>
                    </div>
                    </div>




                <div className="col-md-12">
                        <table className="styled-table">
                            <thead align="center">
                                <tr>
                                  <th>Sr. #</th>
                                  <th>Patient Name & EHR-ID</th>
                                  <th width="100px">DOB </th>
                                  <th>Total Calls</th>
                                  <th>Call Attempts (VM)</th>
                                  <th>Total Reviews</th>
                                  <th>Month</th>
                                  <th>Category</th>
                                  <th>Doctor Name</th>
                                </tr>
                                </thead>
                            <tbody>


                            {callReport && callReport
                                .filter(item => {
                                    if (filterBy === "null") {
                                      return item; // Return the item as-is
                                    } else if (filterBy === "noCall") {
                                      return item?.totalCall === 0; // Return items where totalCall is 0
                                    } else if (filterBy === "oneCall") {
                                        return item?.totalCall === 1;
                                    }  else if (filterBy === "twoCall") {
                                        return item?.totalCall === 2;
                                    } else if(filterBy === "noVMs"){
                                        return item?.totalVM === 0;
                                    } else if (filterBy === "noReviews"){
                                        return item?.totalReview === 0;
                                    }
                                    else {
                                      return null; // Handle any other cases (optional)
                                    }
                                  })
                                .sort((a, b) => (a.patientName > b.patientName) ? 1 : -1).map((summaryReport, index) => (
                                    <tr key={index}> 
                                      <td><b>{index + 1}</b></td>
                                      <td><Link className="link"  
                                        to={{ pathname: "/patientProfile", state: {patientid: summaryReport?.patientId 
                                        }}}>
                                          {summaryReport?.patientName}
                                          <p><b>{summaryReport?.emrId || 'N/A'}</b></p>
                                          </Link>
                                          </td>
                                    
                                          <td>
                                            <b>{moment(summaryReport?.DOB).format('MM/DD/YYYY')}</b>
                                       </td>
                                    
                                      
                                      {/* Total Calls */}
                                      <td>
                                      {summaryReport?.totalCall < 1 ? <b style={{color: 'red'}}>{summaryReport?.totalCall}</b> : <b style={{color: 'green'}}>{summaryReport?.totalCall}</b>}
                                          <p><b>{summaryReport?.lastCall}</b></p>
                                      </td> 
                                      
                                      {/* Total VMs */}
                                      <td>
                                      {summaryReport?.totalVM < 1 ? <b style={{color: 'red'}}>{summaryReport?.totalVM}</b> : <b style={{color: 'green'}}>{summaryReport?.totalVM}</b>}
                                        <p><b>{summaryReport?.lastVM}</b></p>
                                      </td>         

                                      <td>
                                      {summaryReport?.totalReview < 1 ? <b style={{color: 'red'}}>{summaryReport?.totalReview}</b> : <b style={{color: 'green'}}>{summaryReport?.totalReview}</b>}
                                      <p><b>{summaryReport?.lastReview}</b></p>

                                      </td>

                                      <td><b>{summaryReport?.Month}</b></td> 

                                      <td><b>{summaryReport?.category}</b></td>
                                    
                                      <td><b>{'Dr. ' + summaryReport?.doctorName?.lastname +' , '+ summaryReport?.doctorName?.firstname}</b></td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
               </> : null}          
               
                    </>}

                    <br/>
                    {/* {callReport?.length < count && <>
                        <div className="text-center">
                            <button className="edit-pill" onClick={() => handlePagination()}>Show more</button>
                        </div>
                    </> } */}
                    </div>
                </div>
            </section>
            </>
        )
    }

export default AdminCallReport