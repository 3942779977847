import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { updateDoctor } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';



const EditDoctor = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    let doctorInfo = props?.location?.state?.id;

    const {_id, 
            firstname, 
            lastname, 
            gender, 
            DOB, 
            email, 
            phone1, 
            mobileNo, 
            npinumber, 
            licensenumber,
            signature
        } = doctorInfo;

    const [docfirstname, setDocFirstName] = useState(firstname);
    const [doclastname, setDocLastName] = useState(lastname);
    const [docDOB, setDocDOB] = useState(DOB);
     const [docemail, setDocEmail] = useState(email);
    const [docgender, setDocGender] = useState(gender); 
    const [docphone1, setDocPhone1] = useState(phone1);
    const [docmobileno, setDocMobileNo] = useState(mobileNo);
    const [docnpi, setDocNpi] = useState(npinumber);
    const [doclicenseNumber, setDocLicenseNumber] = useState(licensenumber);
    const [docSig, setDocSig] = useState({});
    const {message, error } = useSelector(state => state.common);

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
            
        }

        if(message){
            alert.success(message);
            props.history.push('/doctors');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
            
    }, [dispatch, error, message]);

    const handleFileChange = e => {
        setDocSig(e.target.files[0]);
    }


    const updateHandler = () => {
        
        if (docSig) {
            let fileSize = docSig.size; // in bytes
            let maxSize = 2 * 1024 * 1024; // 3 MB in bytes

            if (fileSize > maxSize) {
                alert.error('File size should be less than 3 MB.');
                return; // prevent form submission
            }
        }

        dispatch(updateDoctor(_id, 
            docfirstname, 
            doclastname, 
            docDOB, 
            docemail, 
            docgender, 
            docphone1, 
            docmobileno, 
            docnpi, 
            doclicenseNumber,
            docSig,
            ));
    }


    return (
        <>
            <MetaData title="Edit Doctor"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />
              <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                    <div className="row-display">
                        <h5 style={{color: '#00A8A8'}}>
                            <i className="bx bx-edit"></i> <strong>&nbsp;Edit Doctor </strong>
                        </h5>

                        <div>
                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                                <i className='bx bx-home' ></i></Link> &nbsp;

                        </div>
                    </div>
                    <hr />

                        <Formik initialValues={{ }}
                            enableReinitialize={true}
                            onSubmit={updateHandler}
                        >
                        <div>
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-9 col-md-9 rightBorderLine'>
                                        {/* First Name */}
                                    <div className="row">                                
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="firstname" className="form-label">First Name</label>
                                                <input
                                                    type="text" 
                                                    name="firstname"
                                                    className='form-control shadow-none'
                                                    placeholder="First Name"
                                                    value={docfirstname}
                                                    onChange={(e) => setDocFirstName(e.target.value)} 
                                                />
                                        </div>

                                        {/* Last Name */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="lastname" className="form-label">Last Name</label>
                                                <input
                                                    type="text" 
                                                    name="lastname"
                                                    className='form-control shadow-none'
                                                    placeholder="Last Name"
                                                    value={doclastname}
                                                    onChange={(e) => setDocLastName(e.target.value)} 
                                                />
                                        </div>

                                        {/* Email */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="email" className="form-label">Email</label>
                                                <input
                                                    type="email" 
                                                    name="email"
                                                    className='form-control shadow-none'
                                                    placeholder="Email Address"
                                                    value={docemail}
                                                    onChange={(e) => setDocEmail(e.target.value)} 
                                                />
                                        </div>

                                        {/* Gender */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                                            <label htmlFor="gender" className="form-label">Gender</label>
                                            <select
                                                label="Gender"
                                                name="gender"
                                                className="form-control"
                                                defaultValue={docgender}
                                                onChange={(e) => setDocGender(e.target.value)}
                                                >
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>

                                        {/* DOB */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                                            <label htmlFor="gender" className="form-label">DOB</label>
                                            <input
                                                type="date"
                                                label="Gender"
                                                name="gender"
                                                className="form-control"
                                                defaultValue={docDOB}
                                                onChange={(e) => setDocDOB(e.target.value)}
                                            />
                                        </div>

                                        {/* Phone1 */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                                            <label htmlFor="phone1" className="form-label">Primary Phone</label>
                                            <input
                                                type="text"
                                                label="phone1"
                                                name="phone1"
                                                className="form-control"
                                                defaultValue={docphone1}
                                                onChange={(e) => setDocPhone1(e.target.value)}
                                            />
                                        </div>

                                        {/* Mobile No */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="mobileno" className="form-label mt-3">Mobile No</label>
                                            <input
                                                type="text"
                                                label="mobileno"
                                                name="mobileno"
                                                className="form-control"
                                                defaultValue={docmobileno}
                                                onChange={(e) => setDocMobileNo(e.target.value)}
                                            />
                                        </div>

                                        {/* NPI Number */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="npinumber" className="form-label mt-3">NPI</label>
                                            <input
                                                type="text"
                                                label="npinumber"
                                                name="npinumber"
                                                className="form-control"
                                                defaultValue={docnpi}
                                                onChange={(e) => setDocNpi(e.target.value)}
                                            />
                                        </div>

                                        {/* License Number */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <label htmlFor="licensenumber" className="form-label mt-3">License Number</label>
                                            <input
                                                type="text"
                                                label="licensenumber"
                                                name="licensenumber"
                                                className="form-control"
                                                defaultValue={doclicenseNumber}
                                                onChange={(e) => setDocLicenseNumber(e.target.value)}
                                            />
                                        </div>
                                    </div>     
                                    </div>

                                    <div className='col-lg-3 col-md-3'>

                                    {signature ? <>
                                    <label htmlFor="signature" className="form-label">Signature</label>                                       
                                        <img src={`https://www.rpmatrix.com/v1/uploadFiles/${signature}`} 
                                        width='100%' height="80" alt="img" 
                                    /> </> : null }

                                    <br /><br />        
                                    <label htmlFor="signature" className="form-label">Upload Signature</label>
                                    <input  
                                        name="docSig" 
                                        type="file" 
                                        accept=".png"
                                        onChange={handleFileChange}
                                    />
                                    </div>
                                </div>
                            <br/>
                        {/* Buttons */}
                        <div className="row mr-3" style={{ float: 'right'}}>
                            <button className="edit-pill ml-3" type="submit" >Update</button>
                        </div>

                        <br/><br/><br/>
                    </Form>
                </div>
                </Formik>

                    
                    </div>
                </div>
                </> 


                </section>
        </>
    )
}

export default EditDoctor
