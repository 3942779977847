import axios from 'axios';
import { Prod, Prod01 } from '../constants/ActionType';

import {
    ALL_PATIENTS_REQUEST,
    ALL_PATIENTS_SUCCESS,
    ALL_PATIENTS_FAIL,
    ALL_DOCTORS_REQUEST,
    ALL_DOCTORS_SUCCESS,
    ALL_DOCTORS_FAIL,
    DOCTOR_PROFILE_REQUEST,
    DOCTOR_PROFILE_SUCCESS,
    DOCTOR_PROFILE_FAIL,
    DOCTOR_PATIENTS_REQUEST,
    DOCTOR_PATIENTS_SUCCESS,
    DOCTOR_PATIENTS_FAIL,
    PATIENT_PROFILE_REQUEST,
    PATIENT_PROFILE_SUCCESS,
    PATIENT_PROFILE_FAIL,
    ASSIGN_DEVICE_TO_PATIENT_REQUEST,
    ASSIGN_DEVICE_TO_PATIENT_SUCCESS,
    ASSIGN_DEVICE_TO_PATIENT_FAIL,
    GET_PATIENT_DEVICE_DATA_REQUEST,
    GET_PATIENT_DEVICE_DATA_SUCCESS,
    GET_PATIENT_DEVICE_DATA_FAIL,
    GET_DEVICES_LIST_REQUEST,
    GET_DEVICES_LIST_SUCCESS,
    GET_DEVICES_LIST_FAIL,
    GET_DEVICE_DETAILS_REQUEST,
    GET_DEVICE_DETAILS_SUCCESS,
    GET_DEVICE_DETAILS_FAIL,
    SORT_DEVICES_REQUEST,
    SORT_DEVICES_SUCCESS,
    SORT_DEVICES_FAIL,
    ALL_HRS_REQUEST,
    ALL_HRS_SUCCESS,
    ALL_HRS_FAIL,
    ASSIGN_DOCTOR_TO_HR_REQUEST,
    ASSIGN_DOCTOR_TO_HR_SUCCESS,
    ASSIGN_DOCTOR_TO_HR_FAIL,
    ADMIN_STATS_SUCCESS,
    INVENTORY_STATS_SUCCESS,
    INVENTORY_STATS_FAIL,
    UPDATE_PATIENT_REQUEST,
    UPDATE_PATIENT_SUCCESS,
    UPDATE_PATIENT_FAIL,
    GET_ADMIN_NOTIFICATIONS_REQUEST,
    GET_ADMIN_NOTIFICATIONS_SUCCESS,
    GET_ADMIN_NOTIFICATIONS_FAIL,
    GET_PATIENT_REMAINING_READINGS,
    GET_PATIENT_REMAINING_READINGS_FAIL,
    GET_LOGS_REQUEST,
    GET_LOGS_SUCCESS,
    GET_LOGS_FAIL,
    HR_PROFILE_SUCCESS,
    HR_PROFILE_FAIL,
    GET_CAREPLAN_LIST_SUCCESS,
    GET_CAREPLAN_LIST_FAIL,
    GET_DOCTOR_TELEMETARY_REPORT_REQUEST,
    GET_DOCTOR_TELEMETARY_REPORT_SUCCESS,
    GET_DOCTOR_TELEMETARY_REPORT_FAIL,
    TIME_SUMMARY_REPORT_REQUEST,
    TIME_SUMMARY_REPORT_SUCCESS,
    TIME_SUMMARY_REPORT_FAIL,
    SEARCH_LOG_REQUEST,
    SEARCH_LOG_SUCCESS,
    SEARCH_LOG_FAIL,
    ALL_ADMINS_REQUEST,
    ALL_ADMINS_SUCCESS,
    ALL_ADMINS_FAIL,
    DEVICE_SIGNAL_HISTORY_FETCH_SUCCESS,
    DEVICE_SIGNAL_HISTORY_FETCH_FAIL,
    FINANCIAL_REPORT_HISTORY_REQUEST,
    FINANCIAL_REPORT_HISTORY_SUCCESS,
    FINANCIAL_REPORT_HISTORY_FAIL,
    ALL_INACTIVE_PATIENTS_REQUEST,
    ALL_INACTIVE_PATIENTS_SUCCESS,
    ALL_INACTIVE_PATIENTS_FAIL,
    DEVICE_HISTORY_REQUEST,
    DEVICE_HISTORY_SUCCESS,
    DEVICE_HISTORY_FAIL,
    RPM_CRITICAL_DATA_SUCCESS,
    DOCTOR_STATS_SUCCESS,
    DOCTOR_STATS_FAIL,
    RECENT_READINGS_SUCCESS,
    ALL_TARGETS_REQUEST,
    ALL_TARGETS_SUCCESS,
    ALL_TARGETS_FAIL,
    MONTHLY_WISE_STATS_SUCCESS,
    MONTHLY_WISE_STATS_FAIL,
    NURSE_PROGRESS_STATS_SUCCESS,
    NURSE_PROGRESS_STATS_FAIL,
    GET_MESSAGES_REQUEST,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAIL,
    MONTHLY_TELEMETRY_DATA_REQUEST,
    MONTHLY_TELEMETRY_DATA_SUCCESS,
    MONTHLY_TELEMETRY_DATA_FAIL,
    GET_ORGANIZATIONS_REQUEST,
    GET_ORGANIZATIONS_SUCCESS,
    GET_ORGANIZATIONS_FAIL,
    CALL_REPORT_REQUEST,
    CALL_REPORT_SUCCESS,
    CALL_REPORT_FAIL,
    PATIENT_ENCOUNTER_SUCCESS,
    PATIENT_ENCOUNTER_FAIL,
    CALC_AVG_START,
    CALC_AVG_END,
    CALC_AVG_ERROR,
    GET_PATIENT_CHAT_REQUEST,
    GET_PATIENT_CHAT_SUCCESS,
    GET_PATIENT_CHAT_FAIL,
    GET_ORGANIZATIONS_PROFILE_REQUEST,
    GET_ORGANIZATIONS_PROFILE_SUCCESS,
    GET_ORGANIZATIONS_PROFILE_FAIL,
    AVG_BP_REQUEST,
    AVG_BP_SUCCESS,
    AVG_BP_FAIL,
    CCM_CAREPLAN_MANUAL_REQUEST,
    CCM_CAREPLAN_MANUAL_SUCCESS,
    CCM_CAREPLAN_MANUAL_FAIL,
    CLEAR_ERRORS
} from '../constants/adminConstants';

import { NURSE_RPM_CRITICAL_DATA_SUCCESS
} from '../constants/HRConstants';
import {
    FETCH_START,
    SHOW_ALERT_MESSAGE,
    HIDE_ALERT_MESSAGE,
    FETCH_ERROR
} from '../constants/Common';

const moment = require('moment-timezone');


export const getPatients = (resPerPage, currentPage, drId, orgId) => async (dispatch) => {
    let response;

    try {
        dispatch({
            type: ALL_PATIENTS_REQUEST,
        });

        let postData = {}; // Initialize an empty object for postData

        // Determine what data to include in the request body
        if (drId && orgId === undefined) {
            postData = { drId };

        } else if (orgId && drId === null) {
            postData = { orgId };
        }

        response = await axios.post(`${Prod01}/patient/list/${resPerPage}/${currentPage}`, postData, {
            timeout: 10000 // 10 seconds
        });

        dispatch({
            type: ALL_PATIENTS_SUCCESS,
            payload: response.data
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
        } else if (error.code === 'ECONNABORTED') {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: 'Request timed out'
            });
        } else {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
            });
        }
    }
};

export const noDrRPMPts = (resPerPage, currentPage, noDrPts) => async (dispatch) => {
    let response;

    try {
        dispatch({
            type: ALL_PATIENTS_REQUEST,
        });

        response = await axios.post(`${Prod01}/patient/list/${resPerPage}/${currentPage}`,{
            noDrPts: noDrPts
        }, {
            timeout: 10000 // 10 seconds
        });

        dispatch({
            type: ALL_PATIENTS_SUCCESS,
            payload: response.data
        });

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
        } else if (error.code === 'ECONNABORTED') {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: 'Request timed out'
            });
        } else {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
            });
        }
    }
};





export const getOrganizations = () => async(dispatch) => {
    try {
        dispatch({
            type: GET_ORGANIZATIONS_REQUEST,
        })

        const { data } = await axios.get(`${Prod01}/admin/organizations`);

        dispatch({
            type: GET_ORGANIZATIONS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({

            type: GET_ORGANIZATIONS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get Organization Profile (Start)
export const getOrgProfile = (Id) => async(dispatch) => {
    try {
        dispatch({
            type: GET_ORGANIZATIONS_PROFILE_REQUEST,
        })

        const { data } = await axios.get(`${Prod01}/admin/organization/${Id}`);

        dispatch({
            type: GET_ORGANIZATIONS_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({

            type: GET_ORGANIZATIONS_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}
// Get Organization Profile (End)


export const getMonthlyTelemetryPatients = (startDate, endDate, doctorId) => async(dispatch) => {

    try {
        dispatch({
            type: MONTHLY_TELEMETRY_DATA_REQUEST,
        })

       if(doctorId){
        const { data } = await axios.post(`${Prod01}/admin/patients/telemetryData`, {
            startDate: startDate,
            endDate: endDate,
            doctorId: doctorId
        });

        dispatch({
            type: MONTHLY_TELEMETRY_DATA_SUCCESS,
            payload: data?.healthDataPtsNames
        })
       }

    } catch (error) {
        dispatch({
            type: MONTHLY_TELEMETRY_DATA_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getCCMPatients = (resPerPage, currentPage, doctorId, orgId) => async(dispatch) => {

    let data; // declare the variable
    let response;
    try {
        dispatch({
            type: ALL_PATIENTS_REQUEST,
        })

        if(doctorId){
            response = await axios.post(`${Prod01}/patient/patients/ccm/${resPerPage}/${currentPage}`, {
                drId: doctorId
            }, {
                timeout: 10000 // 10 seconds
        })
        } else if(orgId){
            response = await axios.post(`${Prod01}/patient/patients/ccm/${resPerPage}/${currentPage}`,{
                orgId: orgId
            }, {
            timeout: 10000 // 10 seconds
        })
        } else if(!orgId && !doctorId) {
            response = await axios.post(`${Prod01}/patient/patients/ccm/${resPerPage}/${currentPage}`,
            {
                timeout: 10000 // 10 seconds
            })
        }

        data = response?.data;

        dispatch({
            type: ALL_PATIENTS_SUCCESS,
            payload: data
        })
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
            });
        }
    }
}

// No Dr assigned CCM Patients (Start)
export const noDrCCMPatients = (resPerPage, currentPage, noDrPts) => async(dispatch) => {

    let data; // declare the variable
    let response;
    try {
        dispatch({
            type: ALL_PATIENTS_REQUEST,
        })

        response = await axios.post(`${Prod01}/patient/patients/ccm/${resPerPage}/${currentPage}`,{
            noDrPts: noDrPts
        }, {
            timeout: 10000 // 10 seconds
        })

        data = response?.data;

        dispatch({
            type: ALL_PATIENTS_SUCCESS,
            payload: data
        })
    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: ALL_PATIENTS_FAIL,
                payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
            });
        }
    }
}
// No doctor assigned CCM Patients (End)

export const getInactivePatients = () => async(dispatch) => {

    try {
        dispatch({
            type: ALL_INACTIVE_PATIENTS_REQUEST,
        })


        const { data } = await axios.get(`${Prod01}/patient/Inactive`);

        dispatch({
            type: ALL_INACTIVE_PATIENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_INACTIVE_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const filterPatientByAccStatus = (key) => async(dispatch) => {
    try {
        dispatch({
            type: ALL_PATIENTS_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/patient/search/accountStatus`,{
            key: key
        }, );

        dispatch({
            type: ALL_PATIENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updatePatient = (_id, emrId, pFirstName, pLastName, pEmail, pDOB, pGender, pPhone1, pAddress, pCity, pLine2, pState, pZipCode, pSSN, pInsurance, pPatientType, pSetupDate) => async(dispatch) => {
    try {
        dispatch({
            type: UPDATE_PATIENT_REQUEST,
        })

        const { data } = await axios.put(`${Prod01}/patient/edit/${_id}`,{
            emrId: emrId,
            firstname: pFirstName,
            lastname: pLastName,
            email: pEmail,
            password: (pDOB).split('/').join(''),
            DOB: pDOB,
            gender: pGender,
            phone1: pPhone1,
            address: pAddress,
            city: pCity,
            line2: pLine2,
            state: pState,
            zipCode: pZipCode,
            ssn: pSSN,
            insurancecompany: pInsurance,
            patientType: pPatientType,
            setupDate: pSetupDate
        }, );

        dispatch({
            type: UPDATE_PATIENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PATIENT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const patientDeActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: UPDATE_PATIENT_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/patient/block/${_id}`,{
            block: true
        }, );

        await axios.put(`${Prod01}/patient/edit/${_id}`,{
            accountActionPerformed: 'block',
            accountActionPerformedDateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z"),
        }, );

        dispatch({
            type: UPDATE_PATIENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PATIENT_FAIL,
            payload: error.response.data.message
        })
    }
}


export const patientActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: UPDATE_PATIENT_REQUEST,
        })

        const { data } = await axios.post(`${Prod01}/patient/block/${_id}`,{
            block: false
        });

        await axios.put(`${Prod01}/patient/edit/${_id}`,{
            accountActionPerformed: 'active',
            accountActionPerformedDateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z"),
        });

        dispatch({
            type: UPDATE_PATIENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PATIENT_FAIL,
            payload: error.response.data.message
        })
    }
}


export const doctorDeActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        })

        await axios.put(`${Prod01}/doctor/edit/${_id}`,{
            block: true
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Doctor Account Blocked"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update doctor'
          })
    }
}


export const doctorActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        })

       await axios.put(`${Prod01}/doctor/edit/${_id}`,{
            block: false
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Doctor account activated"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update doctor'
          })
    }
}

export const assistantActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        })

       await axios.put(`${Prod01}/admin/assistant/edit/${_id}`,{
            block: false
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Assistant account activated"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update assistant'
          })
    }
}

export const assistantDeActivate = (_id) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        })

       await axios.put(`${Prod01}/admin/assistant/edit/${_id}`,{
            block: true
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Assistant account blocked"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update assistant'
          })
    }
}

// Get list of all doctors => admin
export const getDoctors = (resPerPage, currentPage) => async(dispatch) => {
    try {
        dispatch({ type: ALL_DOCTORS_REQUEST })

        const { data } = await axios.get(`${Prod01}/doctor/list/${resPerPage}/${currentPage}`, );

        dispatch({
            type: ALL_DOCTORS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_DOCTORS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdmins = () => async(dispatch) => {
    try {
        dispatch({ type: ALL_ADMINS_REQUEST })

        const { data } = await axios.get(`${Prod01}/admin/adminlist`, );

        dispatch({
            type: ALL_ADMINS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_ADMINS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteAdminAccount = (id) => async(dispatch) => {
    try {

        const data = await axios.delete(`${Prod01}/admin/delete/${id}`);

        if(data){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Admin Account has been deleted"
                });
        }


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Admin not found'
          })
    }
}

// Search Doctors by Admin

export const searchDoctor = (value) => async(dispatch) => {
    try {
        dispatch({ type: ALL_DOCTORS_REQUEST })

        const { data } = await axios.post(`${Prod01}/doctor/search`,{
            search: value
        }, );

        dispatch({
            type: ALL_DOCTORS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_DOCTORS_FAIL,
            payload: error.response.data.message
        })
    }
}


// Get list of all HRs => admin
export const getHrLists = () => async(dispatch) => {
    try {
        dispatch({ type: ALL_HRS_REQUEST })

        const { data } = await axios.get(`${Prod01}/hr/list`, );

        dispatch({
            type: ALL_HRS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_HRS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Add New HR => admin
export const addHR = (values) => async(dispatch) => {
    try {
        await axios.post(`${Prod01}/hr/signup`, values);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New HR Added"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message
          })
    }
}


// Get doctor profile => admin
export const doctorProfile = (id) => async(dispatch) => {
    try {
        dispatch({
            type: DOCTOR_PROFILE_REQUEST
        })

        const { data } = await axios.get(`${Prod01}/doctor/doctorbyid/${id}`, );

        dispatch({
            loading: false,
            type: DOCTOR_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTOR_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getDoctorStats = (id) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${Prod01}/doctor/doctorStats`, {
            doctorId: id
        });

        dispatch({
            type: DOCTOR_STATS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DOCTOR_STATS_FAIL,
            payload: error.response.data.message
        })
    }
}




export const getHrProfile = (id) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${Prod01}/hr/hrbyid/${id}`, );

        dispatch({
            loading: false,
            type: HR_PROFILE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: HR_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}




// Get Doctor Patients
export const getDoctorPatients = (id, filterBy) => async(dispatch) => {
    try {
        dispatch({
            type: DOCTOR_PATIENTS_REQUEST
        })

        const { data } = await axios.post(`${Prod01}/doctor/patientlist/${id}`, {
            filterBy: filterBy
        }, {
            timeout: 15000 // 15 seconds
          }
        );

        dispatch({
            loading: false,
            type: DOCTOR_PATIENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: DOCTOR_PATIENTS_FAIL,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: DOCTOR_PATIENTS_FAIL,
                payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
            });
        }
    }
}


export const getDoctorCCMPatients = (id) => async(dispatch) => {
    try {
        dispatch({
            type: DOCTOR_PATIENTS_REQUEST
        })

        const { data } = await axios.post(`${Prod01}/doctor/patientlist/ccm/${id}`, {
            name: 'Naveed'
        },);

        dispatch({
            loading: false,
            type: DOCTOR_PATIENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTOR_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const searchDoctorCCMPatient = (id, searchBy, keyword) => async(dispatch) => {
    try {
        dispatch({
            type: DOCTOR_PATIENTS_REQUEST
        })

        const { data } = await axios.post(`${Prod01}/doctor/patientlist/ccm/${id}`, {
            key: searchBy,
            value:keyword
        },);

        dispatch({
            loading: false,
            type: DOCTOR_PATIENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DOCTOR_PATIENTS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const removePatientsDoctor = (patientId, doctorId) => async(dispatch) => {
    try {
        await axios.post(`${Prod01}/patient/unsetHrDr`,{
            patientId: patientId,
            drId: true
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Removed Successfully"
            });

        dispatch(getDoctorPatients(doctorId))

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

export const removeDoctorFromHR = (hrId, drId) => async(dispatch) => {
    try {
        const res = await axios.post(`${Prod01}/hr/removeDr`,{
            hrId: hrId
        }, );

        await axios.post(`${Prod01}/doctor/removeHr`, {
            drId: drId
        });


        if(res){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Doctor removed from hr"
              });

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
           }

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'cannot remove doctor'
          })
          dispatch({
            type: HIDE_ALERT_MESSAGE
          })
    }
}

export const removeHRFromDoctor = (hrId, drId) => async(dispatch) => {
    try {
        const res = await axios.post(`${Prod01}/doctor/removeHr`,{
            drId: drId
        }, );

        await axios.post(`${Prod01}/hr/removeDr`, {
            hrId: hrId
        });


        if(res){
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "HR removed from Doctor"
              });

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
           }

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'cannot remove doctor'
          })
          dispatch({
            type: HIDE_ALERT_MESSAGE
          })
    }
}

// Update doctor Profile -> ADMIN
export const updateDoctor = (_id, docfirstname, doclastname, docDOB, docemail, docgender, docphone1, docmobileno, docnpi, doclicenseNumber, docSig) => async(dispatch) => {
    try {

        let formData = new FormData();
        formData.append('file', docSig);

        if (docSig) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
            }
            )
        }


        await axios.put(`${Prod01}/doctor/edit/${_id}`, {
            firstname: docfirstname,
            lastname: doclastname,
            DOB: docDOB,
            email: docemail,
            gender: docgender,
            phone1: docphone1,
            mobileNo: docmobileno,
            npi: docnpi,
            licensenumber: doclicenseNumber,
            signature: docSig.name
        },  );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Doctor information Updated"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
      }
    }


// Update organization -> ADMIN
export const updateOrganization = (_id, name, city, country, orgSig) => async(dispatch) => {
    try {

        let formData = new FormData();
        formData.append('file', orgSig);

        if (orgSig) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
            }
            )
        }

        await axios.put(`${Prod01}/admin/update/organization/${_id}`, {
            name: name,
            city: city,
            country: country,
            signature: orgSig.name
        },  );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Organization Updated"
        });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
        })
      }
    }


export const updatetelemetary = (Id, sys, dia, pul, dateAdded, createdAt) => async(dispatch) => {
    try {
        await axios.put(`${Prod01}/admin/telemetaryData/${Id}`, {
            sys:sys,
            dia:dia,
            pul:pul,
            dateAdded: dateAdded,
            createdAt: createdAt
        });


        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Telemetary Data Updated"
        });


        dispatch({
            type: HIDE_ALERT_MESSAGE
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'All fields necessary to fill'
          })
        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
      }
    }
// Get patient profile => admin
export const patientProfile = (id) => async(dispatch) => {
    try {
        dispatch({
            type: PATIENT_PROFILE_REQUEST
        })
        const { data }= await axios.get(`${Prod01}/patient/patientprofile/${id}`, );

        dispatch({
            type: PATIENT_PROFILE_SUCCESS,
            loading: false,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PATIENT_PROFILE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Add New Doctor => admin
export const addDoctor = (values) => async(dispatch) => {
    try {
        await axios.post(`${Prod01}/doctor/signup`, values);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New Doctor Added"
            });


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

export const addOrganization = (values) => async(dispatch) => {
    try {
        await axios.post(`${Prod01}/admin/add/organization`, values);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New Organization Added"
            });


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

export const assignPatientToDoctor = (doctorid, patientid) => async(dispatch) => {
 try {
    dispatch({
        type: FETCH_START
    });

    await axios.put(`${Prod01}/patient/edit/${patientid}`, {
        assigned_doctor_id: doctorid
    });

    dispatch({
		type: SHOW_ALERT_MESSAGE,
		payload: "Patient assigned to doctor"
		});


 } catch (error) {
    dispatch({
		type: FETCH_ERROR,
		payload: error.response.data.message
	  })
 }
}

export const assignDoctorToHR = (hrId, doctorId) => async(dispatch) => {
    try {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_REQUEST
       });

       const { data } = await axios.put(`${Prod01}/hr/edit/${hrId}`, {
           assigned_doctor_id: doctorId
       });

       await axios.put(`${Prod01}/doctor/edit/${doctorId}`, {
        assigned_hr_id: hrId
    })

       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_SUCCESS,
       })

    } catch (error) {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_FAIL,
           payload: error.response.data.message
       })
    }
}

// Assign Doctor To Organization
export const assignDoctorToOrganization = (orgId, doctorId, adminId) => async(dispatch) => {

    if(orgId && doctorId && adminId) {
        try {

            await axios.put(`${Prod01}/admin/assignDr/organization/${orgId}`, {
                doctorId: doctorId,
                dateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z"),
                addedBy: adminId
            });

            await axios.put(`${Prod01}/doctor/edit/${doctorId}`, {
                 assigned_org_id: orgId
            })

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Doctor Assigned"
            });


         } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: error.response.data.message
            })
         }
    } else {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Some fields are missing'
        })
    }
}
// Assign Doctor To Organization (End)


// Assign Assistant to Organization (Start)
export const assignAssistantToOrganization = (orgId, assistantId, adminId) => async(dispatch) => {

    if(orgId && assistantId && adminId) {
        try {

            await axios.put(`${Prod01}/admin/assignAsst/organization/${orgId}`, {
                assistantId: assistantId,
                dateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z"),
                addedBy: adminId
            });

            await axios.put(`${Prod01}/assistant/edit/${assistantId}`, {
                 assigned_org_id: orgId
            })

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Clinical Staff Assigned"
            });


         } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: error.response.data.message
            })
         }
    } else {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Error in assigning, try later'
        })
    }
}
// Assign Assistant to Organization (end)

// Assign Nurse to Organization (Start).
export const assignNurseToOrganization = (orgId, nurseId, adminId) => async(dispatch) => {

    if(orgId && nurseId && adminId) {
        try {

            const res = await axios.put(`${Prod01}/admin/assign/nurse/${nurseId}/organization/${orgId}`, {
                dateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z"),
                addedBy: adminId
            }, {
                timeout: 10000 // 10 seconds
            });

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: JSON.stringify(res?.data?.message)
            });

         } catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: FETCH_ERROR,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                });
            }
        }
    } else {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Error in assigning, try later'
        })
    }
}


// Assign Nurse to organization (End).



// Assign Doctor To Organization
export const removeDoctorFromOrganization = (doctorId, Id) => async(dispatch) => {
    if(Id && doctorId) {
        try {

            await axios.put(`${Prod01}/admin/unassignDr/organization/${Id}`, {
                doctorId: doctorId
            });

            await axios.post(`${Prod01}/doctor/removeOrg`, {
                 drId: doctorId
            })

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Doctor Removed"
            });


         } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: error.response.data.message
            })
         }
    } else {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Some fields are missing'
        })
    }
}

export const removeNurseFromOrganization = (nurseId, orgId) => async(dispatch) => {
    if(nurseId && orgId) {
        try {
            await axios.put(`${Prod01}/admin/unassign/nurse/${nurseId}/organization/${orgId}`, {
                timeout: 10000
            });

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Nurse Removed"
            });


         } catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: FETCH_ERROR,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                });
            }
        }
        } else {
            dispatch({
                type: FETCH_ERROR,
                payload: 'Some fields are missing'
            })
        }
}

export const assignPatientToHR = (hrId, patientId) => async(dispatch) => {
    try {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_REQUEST
       });

       const { data } = await axios.put(`${Prod01}/patient/edit/${patientId}`, {
        assigned_hr_id: hrId,
        rpmconsent: true
       });

       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_FAIL,
           payload: error.response.data.message
       })
    }
}


export const assignPatientToHRwithCCM = (hrId, patientId) => async(dispatch) => {
    try {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_REQUEST
       });

       const { data } = await axios.put(`${Prod01}/patient/edit/${patientId}`, {
        assigned_ccm_nurse_id: hrId,
        isCCM: true
       });

       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_FAIL,
           payload: error.response.data.message
       })
    }
}

export const assignPatientToHRwithRPMCCM = (hrId, patientId) => async(dispatch) => {
    try {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_REQUEST
       });

       const { data } = await axios.put(`${Prod01}/patient/edit/${patientId}`, {
        assigned_hr_id: hrId,
        assigned_ccm_nurse_id: hrId,
        isCCM: true,
        rpmconsent: true
       });

       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: ASSIGN_DOCTOR_TO_HR_FAIL,
           payload: error.response.data.message
       })
    }
}


export const assignDeviceToPatient = (patientid, deviceid) => async(dispatch) => {
    try {
       dispatch({
           type: ASSIGN_DEVICE_TO_PATIENT_REQUEST
       });

       const { data } = await axios.post(`${Prod}/v1/admin/assignDeviceToPatient`, {
               patientid: patientid,
               deviceid: deviceid
           });

       dispatch({
           type: ASSIGN_DEVICE_TO_PATIENT_SUCCESS,
           loading: false,
           payload: data.patient
       })

    } catch (error) {
       dispatch({
           type: ASSIGN_DEVICE_TO_PATIENT_FAIL,
           payload: error.response.data.message
       })
    }
   }


   export const removeAssignedDevice = (device, patientid) => async(dispatch) => {
    let data, deviceUpdate;

    try {
        data = await axios.post(`${Prod01}/patient/addremovedevice/${patientid}`, {
            "assignDevice":false,
            "device_id":device._id,
            "deviceId":device.deviceObjectId?._id
        });

        deviceUpdate = await axios.put(`${Prod01}/device/edit/${device?.deviceObjectId?._id}`, {
            assigned_patient_id: null
        })


    if(deviceUpdate){
        dispatch({
            type: ASSIGN_DEVICE_TO_PATIENT_SUCCESS,
            loading: false,
            payload: data
        })
    }


    } catch (error) {
       dispatch({
           type: ASSIGN_DEVICE_TO_PATIENT_FAIL,
           payload: error.response.data.message
       })
    }
   }

   export const getPatientTelemetryData = (patientId, recordsPerPage, currentPage, sort) => async(dispatch) => {

    try {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/patient/filterpatienthealthData/${recordsPerPage}/${currentPage}`, {
               patientId: patientId,
               createdAt: sort
           });

       dispatch({
           type: GET_PATIENT_DEVICE_DATA_SUCCESS,
           payload: data.healthData,
           count: data.Count
       })

    } catch (error) {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_FAIL,
           payload: error.message
       })
    }
}

export const sortTelemetartData = (patientId, startDate, endDate) => async(dispatch) => {
    try {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/patient/filterpatienthealthData/100/1`, {
                patientId: patientId,
                startDate: startDate,
                endDate: endDate,
        });

       dispatch({
           type: GET_PATIENT_DEVICE_DATA_SUCCESS,
           payload: data.healthData,
           count: data.Count
       })

    } catch (error) {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_FAIL,
           payload: error.message
       })
    }
}

export const getRemainingReadings = (id, startDate, endDate) => async(dispatch) => {
    try{

        const {data} = await axios.post(`${Prod01}/patient/getReadingCount/${id}`, {
        startDate: startDate,
        endDate: endDate,
    });

    dispatch({
        type: GET_PATIENT_REMAINING_READINGS,
        payload: data
    })


    } catch(error) {
        dispatch({
            type: GET_PATIENT_REMAINING_READINGS_FAIL,
            payload: error.message
        })
    }
}


export const getPatientChat = (id) => async(dispatch) => {
    try{

    dispatch({
        type: GET_PATIENT_CHAT_REQUEST,
    })

    const {data} = await axios.post(`${Prod01}/hr/getMessage`, {
        patient_id: id
    });

    dispatch({
        type: GET_PATIENT_CHAT_SUCCESS,
        payload: data
    })


    } catch(error) {
        dispatch({
            type: GET_PATIENT_CHAT_FAIL,
            payload: error.message
        })
    }
}


export const sendChatMessage = (message, patientId, nurseId) => async(dispatch) => {
    try{

    await axios.post(`${Prod01}/hr/sendMessage`, {
        patient_id: patientId,
        nurse_id: nurseId,
        sentBy: 'Nurse',
        message: message,
        dateAndTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a")
    });

    dispatch(getPatientChat(patientId));

    } catch(error) {
        // dispatch({
        //     type: GET_PATIENT_CHAT_FAIL,
        //     payload: error.message
        // })
    }
}

export const calcTelemetryAvg = (patientId, deviceId, records) => async(dispatch) => {
    try{

    dispatch({
        type: CALC_AVG_START,
    })

    const {data} = await axios.post(`${Prod01}/patient/calcTelemetaryDataAvg`, {
       deviceId: deviceId,
       patientId: patientId,
       records: records
    });

    dispatch({
        type: CALC_AVG_END,
        payload: data
    })


    } catch(error) {
        dispatch({
            type: CALC_AVG_ERROR,
            payload: error.message
        })
    }
}


   export const insertComment = (deviceid, comment) => async(dispatch) => {
    try {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_REQUEST
       });

        await axios.post(`${Prod}/v1/admin/commentdevicedata`, {
               id : deviceid,
               comment: comment
           });


    } catch (error) {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_FAIL,
           payload: error.response.data.message
       })
    }
   }


//    Search Device Data by Date
   export const getDeviceDataByDate = (deviceId, patientId, searchDate) => async(dispatch) => {
    try {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_REQUEST
       });

       const { data } = await axios.post(`${Prod}/v1/getdevicedataforpatient`, {
               deviceid: deviceId,
               patientid: patientId,
               date: searchDate
           });

       dispatch({
           type: GET_PATIENT_DEVICE_DATA_SUCCESS,
           loading: false,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: GET_PATIENT_DEVICE_DATA_FAIL,
           payload: error.response.data.message
       })
    }
   }

// Get all devices list
export const getAllDevices = (resperpage, currentpage) => async(dispatch) => {
    try {
        dispatch({ type: GET_DEVICES_LIST_REQUEST })

        const { data } = await axios.get(`${Prod01}/device/list/${resperpage}/${currentpage}`, {
            timeout: 10000 // 10 seconds
          })

        dispatch({
            type: GET_DEVICES_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: GET_DEVICES_LIST_FAIL,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: GET_DEVICES_LIST_FAIL,
                payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
            });
        }
    }
}

// Get All Logs
export const getAllLogs = (todayDate) => async(dispatch) => {
    try {
        dispatch({ type: GET_LOGS_REQUEST })

        const { data } = await axios.post(`${Prod01}/admin/logs`, {
            startDate: todayDate,
            endDate: todayDate
        })

        dispatch({
            type: GET_LOGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_LOGS_FAIL,
            payload: error.response.data.message
        })
    }
}



// Get Admin Stats
export const getAdminStats = () => async(dispatch) => {
    try {
        const { data } = await axios.get(`${Prod01}/admin/stats`, {
            timeout: 10000 // 10 seconds
          });

        dispatch({
            type: ADMIN_STATS_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: ADMIN_STATS_SUCCESS,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: ADMIN_STATS_SUCCESS,
                payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
            });
        }
    }
}

export const getInventoryStats = () => async(dispatch) => {
    try {
        const { data } = await axios.get(`${Prod01}/device/stats`);

        dispatch({
            type: INVENTORY_STATS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: INVENTORY_STATS_FAIL,
            payload: error.message
        })
    }
}

// Get device details => admin
export const getDeviceDetails = (id) => async(dispatch) => {
    try {
        dispatch({
            type: GET_DEVICE_DETAILS_REQUEST
        })

        const { data } = await axios.get(`${Prod01}/device/byid/${id}`, );

        dispatch({
            type: GET_DEVICE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_DEVICE_DETAILS_FAIL,
            payload: error.message
        })
    }
}

// Get device signal & battery history
export const getDeviceSignalHistory = (id) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${Prod01}/device/getDeviceSignal/${id}`);

        dispatch({
            type: DEVICE_SIGNAL_HISTORY_FETCH_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DEVICE_SIGNAL_HISTORY_FETCH_FAIL,
            payload: error.message
        })
    }
}


// Sort Device By Broken
export const sortRPMDevicesByBroken = (deviceType) => async(dispatch) => {
    try {
        dispatch({ type: SORT_DEVICES_REQUEST })

        const { data } = await axios.post(`${Prod01}/device/broken`, {
            deviceType: deviceType,
        });

        dispatch({
            type: SORT_DEVICES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SORT_DEVICES_FAIL,
            payload: error.response.data.message
        })
    }
}

// Sort RPM Device by In stock and Out of Stock
export const sortRPMDevices = (stock) => async(dispatch) => {
    try {
        dispatch({ type: SORT_DEVICES_REQUEST })

        const { data } = await axios.post(`${Prod01}/device/liststock`, {
            stock: stock
        });

        dispatch({
            type: SORT_DEVICES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SORT_DEVICES_FAIL,
            payload: error.response.data.message
        })
    }
}

export const addRPMDevice = (values) => async(dispatch) => {
    try {
      const newDevice = await axios.post(`${Prod01}/device/add`, values );

      if(newDevice?.data?.httpStatus !== 404){
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New Device Added"
        });
      }

      else {
        dispatch({
            type: FETCH_ERROR,
            payload: newDevice.data?.error
          })
      }


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

// Add Patient
export const addPatient = (values) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        });

      await axios.post(`${Prod01}/patient/add`, values );


        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "New Patient Added"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })
    }
}

export const checkForDuplicatePatient = (values) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        });

        await axios.post(`${Prod01}/patient/add`, values );


        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: ""
        });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
        })
    }
}


// Doctor Careplan List
export const getDoctorCareplans = (DrId) => async(dispatch) => {
    try {
       const { data }  = await axios.post(`${Prod01}/patient/CarePlanbydrhr`, {
           "doctorId":DrId
        })

        dispatch({
            type: GET_CAREPLAN_LIST_SUCCESS,
            payload: data,
        });


    } catch (error) {
       dispatch({
           type: GET_CAREPLAN_LIST_FAIL,
           payload: error.message
       })
    }
}


// Update RPM Device
export const updateRPMDevice = (dvcId, dvcimei, dvcModelNumber, dvcType, dvcBroken, dvcVendor, dvcFirmwareVersion, dvcHardwareVersion) => async(dispatch) => {

    try {
        dispatch({
            type: FETCH_START
        });

        await axios.put(`${Prod01}/device/edit/${dvcId}`, {
                imei: dvcimei,
                modelNumber:dvcModelNumber,
                deviceType: dvcType,
                broken: dvcBroken,
                vendor: dvcVendor,
                firmwareVersion: dvcFirmwareVersion,
                hardwareVersion:dvcHardwareVersion
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Device Updated"
            });

        // dispatch({
        //     type: HIDE_ALERT_MESSAGE
        // })


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update a device'
          })
        // dispatch({
        //     type: HIDE_ALERT_MESSAGE
        //   })
    }
}

// Update HR
export const updateHR = (id, firstname, lastname, email, gender, DOB, phone1, mobileNo, hrConsentRole) => async(dispatch) => {

    try {

       await axios.put(`${Prod01}/hr/edit/${id}`, {
                firstname: firstname,
                lastname:lastname,
                email: email,
                gender: gender,
                DOB: DOB,
                phone1:phone1,
                mobileNo: mobileNo,
                consentRole: hrConsentRole,
                verified: false
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "HR Information updated"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message
          })
    }
}

export const HRDeactivate = (_id) => async(dispatch) => {
    try {
        await axios.put(`${Prod01}/hr/edit/${_id}`, {
                block: true,
                verified: false
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "HR Account Blocked"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update hr'
          })
    }
}


export const HRActivate = (_id) => async(dispatch) => {
    try {
        await axios.put(`${Prod01}/hr/edit/${_id}`, {
                loginAttemps: 0,
                block: false,
                verified: false
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "HR Account activated"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update hr'
          })
    }
}

// Delete RPM Device
export const deleteRPMDevice = (id) => async(dispatch) => {
    try {
        dispatch({
            type: FETCH_START
        });

        await axios.delete(`${Prod01}/device/delete/${id}`);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Device Deleted"
            })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to delete a device'
          })
        // dispatch({
        //     type: HIDE_ALERT_MESSAGE
        //   })
    }
}


// Assign RPM Device To Patient
export const assignRPMDeviceToPatient = (deviceId, imei, patientId) => async(dispatch) => {

    try {
        dispatch({
            type: FETCH_START
        });

       await axios.post(`${Prod01}/patient/addremovedevice/${patientId}`, {
            "assignDevice":true,
            "deviceId":deviceId,
            "imei":imei
        });

        await axios.put(`${Prod01}/patient/edit/${patientId}`, {
            rpmconsent: true,
        }, )

         await axios.put(`${Prod01}/device/edit/${deviceId}`, {
            assignedTime: moment(new Date()).tz("America/New_York").format("DD/MM/YYYY"),
            assigned_patient_id: patientId
        })


        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Device Assigned to Patient"
            });

        // dispatch({
        //     type: HIDE_ALERT_MESSAGE
        // })


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
          })

          // dispatch({
        //     type: HIDE_ALERT_MESSAGE
        //   })
    }
}

export const getAdminNotifications = () => async(dispatch) => {
    try {
        dispatch({ type: GET_ADMIN_NOTIFICATIONS_REQUEST})

        const data = await axios.post(`${Prod01}/general/notifications`, {
                admin: true
        });

        dispatch({
            type: GET_ADMIN_NOTIFICATIONS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_ADMIN_NOTIFICATIONS_FAIL,
            payload: error
        })
    }
}

export const getPatientTelemetaryReport = (patientId, startDate, endDate) => async(dispatch) => {

    try {
       dispatch({
           type: GET_DOCTOR_TELEMETARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/patient/filterpatienthealthData/200/1`, {
        patientId: patientId,
        startDate: startDate,
        endDate: endDate,
        });

       dispatch({
           type: GET_DOCTOR_TELEMETARY_REPORT_SUCCESS,
           payload: data.healthData
       })

    } catch (error) {
       dispatch({
           type: GET_DOCTOR_TELEMETARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getTimeSummaryReportByDoctor = (doctorId, month, year, category) => async(dispatch) => {
    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/minutereadingtotaldrhr`, {
        drId: doctorId,
        month: month,
        year: year,
        category: category === 'RPM' ? null : true
        });

        // if category is RPM, its mean isCCM is null otherwise it is true.

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getTimeSummaryReportByHR = (docId, hrId, month, year, category) => async(dispatch) => {
    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/minutereadingtotaldrhr`, {
        drId: docId,
        hrId: hrId,
        month: month,
        year: year,
        category: category === 'RPM' ? null : true
        });

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getCCMTimeSummaryReportByHR = (hrId, month, year) => async(dispatch) => {
    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/totalMinutesSpentdrhrInCCM`, {
        hrId: hrId,
        month: month,
        year: year
        });

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getTimeSummaryReportforCCMByDrHr = (drId, hrId, month, year, reportBy, endMonth) => async(dispatch) => {
    if(reportBy === 'hr'){
        try {
            dispatch({
                type: TIME_SUMMARY_REPORT_REQUEST
            });

             const {data} = await axios.post(`${Prod01}/general/totalMinutesSpentdrhrInCCM`, {
                 hrId: hrId,
                 drId: drId,
                 month: month,
                 endMonth:endMonth,
                 year: year,
                 reportBy: 'hr'
                 });

            dispatch({
                type: TIME_SUMMARY_REPORT_SUCCESS,
                payload: data
            })

         } catch (error) {
            dispatch({
                type: TIME_SUMMARY_REPORT_FAIL,
                payload: error.message
            })
         }
    } else {
        try {
            dispatch({
                type: TIME_SUMMARY_REPORT_REQUEST
            });

             const {data} = await axios.post(`${Prod01}/general/totalMinutesSpentdrhrInCCM`, {
                 drId: drId,
                 month: month,
                 year: year,
                 reportBy: 'doctor'
                 });

            dispatch({
                type: TIME_SUMMARY_REPORT_SUCCESS,
                payload: data
            })

         } catch (error) {
            dispatch({
                type: TIME_SUMMARY_REPORT_FAIL,
                payload: error.message
            })
         }
    }
}


export const generateFinancialReportByAll = (month, endMonth, year, hrId) => async(dispatch) => {    
    try {
            dispatch({
                type: TIME_SUMMARY_REPORT_REQUEST
            });

             const {data} = await axios.post(`${Prod01}/general/financialReportByAll`, {
                 month: month,
                 endMonth: endMonth,
                 year: year,
                 hrId: hrId
                 });

            dispatch({
                type: TIME_SUMMARY_REPORT_SUCCESS,
                payload: data
            })

         } catch (error) {
            dispatch({
                type: TIME_SUMMARY_REPORT_FAIL,
                payload: error.message
            })
         }
    }


export const getFinancialReportHistory = (hrId, month, year) => async(dispatch) => {

    try {
       dispatch({
           type: FINANCIAL_REPORT_HISTORY_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/totalMinutesReadingsCountHistory`, {
        hrId: hrId,
        month: month,
        year: year
        });

       dispatch({
           type: FINANCIAL_REPORT_HISTORY_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: FINANCIAL_REPORT_HISTORY_FAIL,
           payload: error.message
       })
    }
}

export const getFinancialReportHistoryOfCCM = (hrId, month, year) => async(dispatch) => {

    try {
       dispatch({
           type: FINANCIAL_REPORT_HISTORY_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/totalMinutesReadingsCountHistoryOfCCM`, {
        hrId: hrId,
        month: month,
        year: year
        });

       dispatch({
           type: FINANCIAL_REPORT_HISTORY_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: FINANCIAL_REPORT_HISTORY_FAIL,
           payload: error.message
       })
    }
}

export const searchAdminLogs = (doctorId, type, startDate, endDate) => async(dispatch) => {

    try {
       dispatch({
           type: SEARCH_LOG_REQUEST
       });

        const { data } = await axios.post(`${Prod01}/admin/logs`, {
            type: type,
            doctor_id: doctorId,
            startDate: startDate,
            endDate: endDate
            });

        dispatch({
            type: SEARCH_LOG_SUCCESS,
            payload: data
        })

    } catch (error) {
       dispatch({
           type: SEARCH_LOG_FAIL,
           payload: error.message
       })
    }
}

export const searchAdminLogsByHR = (hrId, type, startDate, endDate) => async(dispatch) => {

    try {
       dispatch({
           type: SEARCH_LOG_REQUEST
       });

        const { data } = await axios.post(`${Prod01}/admin/logs`, {
            type: type,
            hr_id: hrId,
            startDate: startDate,
            endDate: endDate
            });

        dispatch({
            type: SEARCH_LOG_SUCCESS,
            payload: data
        })

    } catch (error) {
       dispatch({
           type: SEARCH_LOG_FAIL,
           payload: error.message
       })
    }
}


export const searchAdminLogsByPatient = (patientId, type, startDate, endDate) => async(dispatch) => {

    try {
       dispatch({
           type: SEARCH_LOG_REQUEST
       });

        const { data } = await axios.post(`${Prod01}/admin/logs`, {
            type: "patient",
            patient_id: patientId,
            startDate: startDate,
            endDate: endDate
            });

        dispatch({
            type: SEARCH_LOG_SUCCESS,
            payload: data
        })

    } catch (error) {
       dispatch({
           type: SEARCH_LOG_FAIL,
           payload: error.message
       })
    }
}


export const getDeviceHistory = () => async(dispatch) => {
    try {
        dispatch({ type: DEVICE_HISTORY_REQUEST })

        const { data } = await axios.get(`${Prod01}/device/get/deviceHistory`);

        dispatch({
            type: DEVICE_HISTORY_SUCCESS,
            payload: data.devices
        })

    } catch (error) {
        dispatch({
            type: DEVICE_HISTORY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const changeBilledStatus = (patientId, month, category) => async(dispatch) => {

    try {
        dispatch({
            type: FETCH_START
        });

       await axios.post(`${Prod01}/admin/addBill`, {
            "assigned_patient_id":patientId,
            "billedMonth":month,
            "billedCategory":category,
            "isBilled":true,
            "billedBy":'Adeel Ahmad'
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Status changed to billed"
            });

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Already billed for this month.'
          })
        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
    }
}


export const getPatientsWithCriticalData = (pagination, filterReadings) => async(dispatch) => {

    try {
       const {data} = await axios.post(`${Prod01}/admin/getCriticalData/${pagination}`, {
        readingType: filterReadings
       });

        dispatch({
            type: RPM_CRITICAL_DATA_SUCCESS,
            payload: data
        });

    } catch (error) {

    }
}


export const getAvgBPsList = (limit, pagination) => async(dispatch) => {
    try {
        dispatch({
            type: AVG_BP_REQUEST
        });

        const {data} = await axios.post(`${Prod01}/patient//highTrendingBps/${pagination}`, {
            limit: limit
        });

        dispatch({
            type: AVG_BP_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: AVG_BP_FAIL
        });
    }
}


export const getAvgBPsListDoctor = (limit, pagination, drId) => async(dispatch) => {
    try {
        dispatch({
            type: AVG_BP_REQUEST
        });

        const {data} = await axios.post(`${Prod01}/patient//highTrendingBps/${pagination}`, {
            limit: limit,
            drId: drId
        });

        dispatch({
            type: AVG_BP_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: AVG_BP_FAIL
        });
    }
}


export const getPatientsWithCriticalDataDoctorwise = (pagination, filterReadings, selectedDr, days) => async(dispatch) => {

    try {
       const {data} = await axios.post(`${Prod01}/admin/getCriticalData/doctorwise/${pagination}`, {
        drId: selectedDr,
        readingType: filterReadings,
        days: days
       });

        dispatch({
            type: RPM_CRITICAL_DATA_SUCCESS,
            payload: data
        });

    } catch (error) {

    }
}

export const getRecentReadings = (pagination, deviceType, doctorId) => async(dispatch) => {

    try {
       if(doctorId === 'default'){
        const {data} = await axios.post(`${Prod01}/admin/getRecentReadings/${pagination}`, {
            deviceType: deviceType
           });

            dispatch({
                type: RECENT_READINGS_SUCCESS,
                payload: data
            });
        } else if(doctorId !== 'default'){
            const {data} = await axios.post(`${Prod01}/admin/getRecentReadings/${pagination}`, {
                deviceType: deviceType,
                doctorId:doctorId
            });

                dispatch({
                    type: RECENT_READINGS_SUCCESS,
                    payload: data
            });
        }


    } catch (error) {

    }
}


export const getPatientsWithCriticalRPMDataHrDr = (pagination, filterReadings) => async(dispatch) => {
        try {
            const {data} = await axios.post(`${Prod01}/doctor/getCriticalDoctorPatientsOfDrHr/${pagination}`, {
                readingType: filterReadings
            });

             dispatch({
                 type: NURSE_RPM_CRITICAL_DATA_SUCCESS,
                 payload: data
             });

         } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: 'Error to load critical readings'
            })
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })

         }
    }


    export const doctorCriticalReadings = (pagination, filterReadings, nurseDrs) => async(dispatch) => {
        try {
            const {data} = await axios.post(`${Prod01}/doctor/criticalReadingsAssignedDoctor/${pagination}`, {
                readingType: filterReadings,
                nurseDrs
            },{
                headers: {
                'Content-Type': 'application/json',
                }
                }, {
                timeout: 10000 // 10 seconds
              }
            );

             dispatch({
                 type: NURSE_RPM_CRITICAL_DATA_SUCCESS,
                 payload: data
             });

         } catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: FETCH_ERROR,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
                });
            }
        }
    }

    export const oneDoctorCriticalReadings = (pagination, filterReadings, drId) => async(dispatch) => {
        try {
            const {data} = await axios.post(`${Prod01}/doctor/doctorCriticalReadings/${pagination}`, {
                readingType: filterReadings,
                drId: drId
            },{
                headers: {
                'Content-Type': 'application/json',
                }
                }, {
                timeout: 10000 // 10 seconds
              }
            );

             dispatch({
                 type: NURSE_RPM_CRITICAL_DATA_SUCCESS,
                 payload: data
             });

         } catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: FETCH_ERROR,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
                });
            }
        }
    }


        export const deleteHRMinutes = (Id) => async(dispatch) => {

            try {

            await axios.delete(`${Prod01}/admin/delete/hrMinutes/${Id}`);

                dispatch({
                    type: SHOW_ALERT_MESSAGE,
                    payload: "Minutes Deleted"
                    });



            } catch (error) {
                dispatch({
                    type: FETCH_ERROR,
                    payload: 'Cannot delete minutes, please try later'
                })
            }
        }


// Get all devices list
export const getAllTargets = (pagination) => async(dispatch) => {
    try {
        dispatch({ type: ALL_TARGETS_REQUEST })

        const { data } = await axios.get(`${Prod01}/admin/targets/${pagination}`)

        dispatch({
            type: ALL_TARGETS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_TARGETS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getBillingReportByDoctor = (doctorId, month, year, reportBy) => async(dispatch) => {

    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/billingReport`, {
        drId: doctorId,
        month: month,
        year: year,
        category: null,
        reportBy: reportBy
        });

        // if category is RPM, its mean isCCM is null otherwise it is true.

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}


export const getBillingReportByOrganization = (orgId, month, year, reportBy) => async(dispatch) => {

    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/billingReport`, {
        orgId: orgId,
        month: month,
        year: year,
        category: null,
        reportBy: reportBy
        });

        // if category is RPM, its mean isCCM is null otherwise it is true.

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getCCMBillingReportByDoctor = (doctorId, month, endMonth, year, reportBy) => async(dispatch) => {

    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/billingReport/ccm`, {
        drId: doctorId,
        month: month,
        endMonth: endMonth,
        year: year,
        category: 'CCM',
        reportBy: reportBy
        });

        // if category is RPM, its mean isCCM is null otherwise it is true.

       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}


export const getCCMBillingReportByOrganization = (orgId, month, endMonth, year, reportBy) => async(dispatch) => {

    try {
       dispatch({
           type: TIME_SUMMARY_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/general/billingReport/ccm`, {
            orgId: orgId,
            month: month,
            endMonth: endMonth,
            year: year,
            category: 'CCM',
            reportBy: reportBy
        });


       dispatch({
           type: TIME_SUMMARY_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: TIME_SUMMARY_REPORT_FAIL,
           payload: error.message
       })
    }
}

export const getTotalMinutesReportMonthwise = (category) => async(dispatch) => {

    try {
        let isCCM;
        if(category === 'RPM')
        isCCM = false;
        else isCCM = true;

        const { data } = await axios.post(`${Prod01}/admin/minutesMonthlyStats`, {
            isCCM: isCCM
        });

        dispatch({
            type: MONTHLY_WISE_STATS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: MONTHLY_WISE_STATS_FAIL,
            payload: 'Unable to get monthly record.'
        })

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
    }
}

export const getNursesProgressOnDashboard = (month, year, category, endMonth) => async(dispatch) => {

    try {
        if(category === 'RPM'){
            const { data } = await axios.post(`${Prod01}/admin/nurseProgress`, {
                month: month,
                year: year
            });

            dispatch({
                type: NURSE_PROGRESS_STATS_SUCCESS,
                payload: data
            })
        } else {
            const { data } = await axios.post(`${Prod01}/admin/nurseProgress`, {
                month: month,
                endMonth: endMonth,
                year: year,
                isCCM: true
            });

            dispatch({
                type: NURSE_PROGRESS_STATS_SUCCESS,
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: NURSE_PROGRESS_STATS_FAIL,
            payload: 'Unable to get nurses record.'
        })

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
    }
}


export const getMessages = (getFor) => async(dispatch) => {

    try {
        dispatch({
            type: GET_MESSAGES_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/general/getMessages`, {
            getFor:getFor
        });

        dispatch({
            type: GET_MESSAGES_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_MESSAGES_FAIL,
            payload: error?.reposnse?.data?.messages
        })

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
    }
}

export const submitMessage = (assistantId, hrId, patientId, question) => async(dispatch) => {

    try {

        await axios.post(`${Prod01}/general/sendMessage`, {
            assigned_patient_id: patientId,
            assigned_hr_id: hrId,
            assigned_assistant_id: assistantId,
            question: question
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Message Sent"
        })

        await axios.post(`${Prod01}/assistant/addtimeforpatient/${assistantId}`, {
            assigned_patient_id: patientId,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            startTime: moment().tz("America/New_York").format("HH:mm"),
            endTime: moment().tz("America/New_York").add(1, 'minutes').format("HH:mm"),
            encounterType: 'review',
            interactiveMinutes: true,
            timeSpentInMinutes: 1,
            conclusion: question
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "One Minute Added"
        })


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: "Fill out all compulsory fields"
        })
    }
}

export const submitReply = (questionId, answer, repliedBy) => async(dispatch) => {

    try {
        await axios.post(`${Prod01}/general/replyToMessages/${questionId}`, {
            repliedBy: repliedBy,
            answer: answer,
            dateTime:moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z")
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Reply Sent"
            })


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reponse?.data?.message
        })
    }
}


export const changeMessageStatus = (answerId, patientId, hrId, answer) => async(dispatch) => {

    try {

        await axios.put(`${Prod01}/general/message`, {
            answerId: answerId,
            approvalStatus: true
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Reply Approved"
        })

        await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
            assigned_patient_id: patientId,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            startTime: moment().tz("America/New_York").format("HH:mm"),
            endTime: moment().tz("America/New_York").add(1, 'minutes').format("HH:mm"),
            interactiveMinutes: true,
            timeSpentInMinutes: 1,
            conclusion: answer
        });




    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reposnse?.data?.messages
        })
    }
}

// Generate a call report
export const generateCallReport = (doctorId, patientType, month, year, pagination) => async(dispatch) => {

    try {
       dispatch({
           type: CALL_REPORT_REQUEST
       });

       const { data } = await axios.post(`${Prod01}/admin/report/callReport/${pagination}`, {
            patientType: patientType,
            doctorId: doctorId,
            month: month,
            year: year
        });

       dispatch({
           type: CALL_REPORT_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: CALL_REPORT_FAIL,
           payload: error.message
       })
    }
}


// Generate patient encounters
export const getPatientEncounters = (patient_id, month, year, patientType) => async(dispatch) => {
    try {
       const { data } = await axios.post(`${Prod01}/admin/patient/count/encounterTypes`, {
            patientId: patient_id,
            patientType: patientType,
            month: month,
            year: year
        });

       dispatch({
           type: PATIENT_ENCOUNTER_SUCCESS,
           payload: data
       })

    } catch (error) {
       dispatch({
           type: PATIENT_ENCOUNTER_FAIL,
           payload: error.message
       })
    }
}

// Delete comment on reading handler
export const deleteReadingComment = (noteId, readingId) => async (dispatch) => {

    try {
        await axios.put(`${Prod01}/admin/reading/comment/delete/${noteId}`, {
            readingId: readingId
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Comment Deleted"
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reponse?.data?.message
        })
    }
}


export const deleteReading = (readingId) => async (dispatch) => {

    try {
        await axios.delete(`${Prod01}/admin/delete/healthData/${readingId}`);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Reading Deleted"
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reponse?.data?.message
        })
    }
}


export const forwardTelemetryData = (dataToSend) => async (dispatch) => {
    try {

        await axios.post(`${Prod01}/device/forwardtelemetry`, JSON.parse(dataToSend));

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Reading Pushed Successfully"
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reponse?.data?.message
        })
    }
}

export const sendPushNotification = (title, message, deviceToken) => async (dispatch) => {
    try {
        if(title && message) {
            const res = await axios.post(`https://exp.host/--/api/v2/push/send`, {
            to: deviceToken,
            title: title,
            body: message,
            sound: "default"
        });

        // dispatch({
        //     type: SHOW_ALERT_MESSAGE,
        //     payload: "Notification sent successfully"
        // })
        }


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error?.reponse?.data?.message
        })
    }
}


export const submitPtSickness = (patientId, diseases) => async (dispatch) => {
    try {
        await axios.put(`${Prod01}/patient/submit-sickness/${patientId}`, {
            sickness: diseases.map(d => ({
                name: d.disease,
                type: d.type,
                addedDateTime: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A") // Convert to ISO 8601 format
              }))
            });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Sickness Added Successfully"
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update patient'
          })        
    }
}


export const deleteDisease = (diseaseId, patientId) => async (dispatch) => {
    try {
        await axios.put(`${Prod01}/patient/delete-sickness/${patientId}`, {
            diseaseId: diseaseId
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Sickness Deleted Successfully"
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update patient'
          })        
    }
}


export const submitCCMCareplan = (formattedDisease, notes, userId, patientId, role) => async (dispatch) => {
    try {
        if(role === 'HrMedical'){
            await axios.post(`${Prod01}/patient/submit-ccmcareplan-manual`, {
                assigned_patient_id: patientId,
                addedByNurse: userId,
                addedDateTime: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
                notes: notes,
                diseases: formattedDisease
                });
    
            await axios.put(`${Prod01}/patient/edit/${patientId}`, {
                ccmCareplanManualAdded: true
            });
    
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "CCM Careplan Added Successfully "
            })
        } else {
            await axios.post(`${Prod01}/patient/submit-ccmcareplan-manual`, {
                assigned_patient_id: patientId,
                addedByAdmin: userId,
                addedDateTime: moment().tz('America/New_York').format("MM/DD/YYYY hh:mm A"),
                notes: notes,
                diseases: formattedDisease
                });
    
            await axios.put(`${Prod01}/patient/edit/${patientId}`, {
                ccmCareplanManualAdded: true
            });
    
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "CCM Careplan Added Successfully "
            })
        }
        

    } catch (error) {
        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: error?.message
        })
    }
}

export const getPtCCMCareplanManual = (patientId) => async (dispatch) => {
  
     try {
        dispatch({
            type: CCM_CAREPLAN_MANUAL_REQUEST,
            payload: "CCM Careplan Added Successfully "
        })

         const {data} = await axios.get(`${Prod01}/patient/get-ccmcareplan-manual/${patientId}`);
 
         dispatch({
             type: CCM_CAREPLAN_MANUAL_SUCCESS,
             payload: data
         })
 
     } catch (error) {
         dispatch({
             type: CCM_CAREPLAN_MANUAL_FAIL,
             payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
         })
     }
 }


// Clear errors
export const clearErrors = () => async(dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    })
}

