import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import defaultImg from '../../assets/Images/defaultUser.png';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHRPatients } from '../../actions/HRActions';
import { removeDoctorFromHR } from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import folderImg from '../../assets/Images/folder.png';
import {Button} from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const HRProfile = (props) => {

  const dispatch = useDispatch();
  const alert = useAlert();

  let hrInfo = props?.location?.state?.hr;
  let doctor = props?.location?.state?.hr?.assigned_doctor_id;
  let id = hrInfo?._id;

  const [hrId, setHrId] = useState(id); 
  const [doctorDetails, setDoctorDetails] = useState(doctor); 
  const [hrDetails, setHrDetails] = useState(hrInfo);
  const [query, setQuery] = useState("");
  const keys = ["firstname", "lastname", "DOB", "email", "phone1"];

  const { hrpatients} = useSelector(state => state.hrPatients);
  const {error, message} = useSelector(state => state.common);

  useEffect(() => {
    if(message){
        alert.success(message);
    }

    if(error){
        alert.error(error)
    }

    dispatch({
        type: HIDE_ALERT_MESSAGE
      })


    dispatch(getHRPatients(doctor?._id));

  },[dispatch, alert, error, message, doctor])

  const removeDoctor = () => {
    var confirmation = window.confirm(`Are you sure you want to remove doctor from ${hrDetails?.firstname} `);
    if(confirmation === true) {
        dispatch(removeDoctorFromHR(hrId, doctor._id));
        setDoctorDetails("");
        return;
    }  
  }

  return <>
      <MetaData title="Nurse Profile"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>
        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

            <div className="row-display">
                    <div className="col-md-6">   
                        <h5 className='title'> 
                            Nurse Profile 
                        </h5>
                    </div>
                    
                    <div className="row-display">
                            <Button className="btn btn-secondary btn-sm" onClick={() => props.history.goBack()}><i className='bx bx-arrow-back' ></i></Button> &nbsp;
                            
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                            
                            
                            <Link to={{ pathname: "/assignPatientToHr", state: {id: hrDetails}}} 
                                className="edit-pill">Assign patient
                            </Link>

                            &nbsp;
                            {hrDetails?.assigned_doctor_id ? ' ' : <>
                            <Link to={{ pathname: "/assignDrToHr", state: {id: hrDetails}}} 
                                className="edit-pill">Assign Doctor
                            </Link>
                        </>}
                    </div>                          
                </div>
                <hr />

                <div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className='text-center'>
                                    <img src={defaultImg} className="profile-img" alt="profileimg" />     
                                    <p className="profile-name"> {hrDetails?.lastname}, {hrDetails?.firstname} </p>
                                    
                                    <Link to="/hrProfile" 
                                        className="link text-center" >
                                        {hrDetails?.email}
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div>
                                    <div className="card-inner-margin">
                                        <div className="row">
                                        <div className="col-md-6">

                                            <span className="profile-label">Account Status: </span><br />
                                            {hrDetails?.block === false ? 
                                                <b>Activated</b>
                                                 : <b>Inactive</b>
                                            }

                                            <br/><br/>
                                            <span className="profile-label">Gender: </span>
                                            <p className="profile-value-text">
                                                {hrDetails?.gender === 'male' ? 
                                                    'Male' : 'Female'
                                                }
                                            </p>                            
                                            
                                            <span className="profile-label">Mobile No: </span>
                                                <p className="profile-value-text">{hrDetails?.mobileNo ? <span><i className='bx bx-phone'></i> {hrDetails?.mobileNo} </span> : 'N/A'}</p>
                                            </div>


                                            <div className="col-md-6">
                                                <span className="profile-label">DOB : </span>
                                                <p className="profile-value-text">{moment(hrDetails?.DOB).format("MM/DD/YYYY")}</p>

                                                <span className="profile-label">Phone 1: </span>
                                                <p className="profile-value-text">{hrDetails?.phone1 ? <span><i className='bx bx-phone'></i> {hrDetails?.phone1} </span> : 'N/A'}</p>                                            
                                                
                                                <p className="profile-label">Account Created At: </p>
                                                <p className="profile-value-text">{moment(hrDetails?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                            </div>

                                            <div className="col-md-4">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <h5 style={{color: '#00A8A8', textAlign: 'center'}}>
                                    <strong>Assigned Doctor </strong>
                                </h5>
                                {doctorDetails ? doctorDetails && <>
                                    <div className='text-center'>
                                    <img src={defaultImg} className="profile-img" alt="profileimg"/>
                                    <p className="profile-name">Dr. {doctorDetails?.firstname} {doctorDetails?.lastname} </p>
                                    <p className="profile-value-text text-center">{doctorDetails?.email}</p>
                                    <button className="edit-pill" onClick={removeDoctor}>Remove Doctor</button>
                                    </div>
                                    </> : <small style={{color: 'gray'}}>No doctor assigned yet</small>}
                            </div>
                        </div>  

                        <br/><br/>            
                        {/* HRs Patients List */}
                        <div className="container-fluid row-display">
                            <h5 className='title mt-4'> 
                                Assigned Patients ({hrpatients && hrpatients?.length < 10 ? '0'+hrpatients?.length : hrpatients?.length})
                            </h5>

                            <input 
                                type="text" 
                                className="form-control mt-2"
                                onChange={e => setQuery(e.target.value)}
                                placeholder="Search By Name, Email or DOB ..."
                                style={{width: '400px'}}
                            /> 
                            </div>
                        
                        {hrpatients?.length > 0 ? (<>
                                <div className="col-md-12">
                                    <>
                                    <table className="styled-table">
                                        <thead align="center">
                                            <tr>
                                                <th>Name</th>
                                                <th>D.O.B </th>
                                                <th>Account Status</th>
                                                <th>Assigned Doctor</th>
                                                <th>Compliancy Status</th>
                                                <th>Action</th>
                                            </tr> 
                                        </thead>
                                        <tbody>

                                        {hrpatients && hrpatients.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((patient, index) => ( 
                                            <tr key={index}>
                                            
                                        {patient?.block === false ?  <td><Link className="link" to={{ pathname: "/patientProfile", state: {patientid: patient?._id, deviceid: patient?.deviceassigned?.deviceid}}}>
                                                {patient?.firstname} {patient?.lastname}
                                                <p>{patient?.phone1}</p>  
                                                </Link>
                                            </td> : <td>
                                            {patient?.firstname} {patient?.lastname}
                                                <p>{patient?.phone1}</p> 
                                            </td> }

                                            <td> <b>{moment(patient?.DOB).format("MM/DD/YYYY")} </b></td> 

                                            <td>{patient?.block === false ?
                                                <b style={{color: 'green'}}>Activated</b>
                                                 : <b style={{color: 'red'}}>Inactive</b>
                                            } </td>

                                            {patient?.assigned_doctor_id ? <>
                                                <td><b>Dr. {patient?.assigned_doctor_id?.lastname} {patient?.assigned_doctor_id?.firstname}</b></td>
                                                </> : <>
                                                <td>--</td>
                                            </>}


                                            <td><b>{patient?.patientType}</b></td>
                                            
                                        </tr> 
                                        ))}
                                        
                                        </tbody>
                                        </table>    
                                            </>                      
                                          </div>
                                        </> ) : <>
                                        <div>   
                                           
                        <img src={folderImg} className="no-record-found-img"/>
                        <p className="doctor-specilizations">No Patient Assigned Yet...</p>                            
                                    </div>
                                </> }
                      </div>
                    </div>
                </div>
       </section> 
        </>
};

export default HRProfile;
