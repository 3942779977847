import Sidebar from './Sidebar';
import Home from './Home';
import MetaData from '../../layouts/MetaData';

const Dashboard = () => {
    return (
        <>
            <div className="admindashboardbody">
                <MetaData title="Admin Dashboard" />
                <Sidebar />
                <Home />
            </div>
        </>
    )
}

export default Dashboard;
