import {useEffect, useState} from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Image, Modal, Alert } from 'react-bootstrap';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import { Formik, Form } from 'formik';
import { submitCCMCareplan, getPatientCCMCareplan, deleteCCMCareplan } from '../../actions/HRActions';
import moment from 'moment';
import notFound from '../../assets/Images/browser.png';

// import { CCM_CAREPLAN_RESET } from '../../constants/HRConstants';


const PatientCareplans = ({ patientId, isNurse }) => {
 
  const dispatch = useDispatch();

  const [carePlanShow ,setCarePlanShow] = useState(false);
  const [fileName, setFileName] = useState({});
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  
  // const {error : commonError, message} = useSelector(state => state.common);
  const { ccmCareplan } = useSelector(state => state.patientCCMCareplan);
  const { user } = useSelector(state => state.auth);

  const downloadFile = (fileName) => {
    // local link
    // window.open(`http://localhost:5000/v1/uploadFiles/${fileName}`);
    // live link
    window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
  }

  const handleCarePlanModalClose = () => setCarePlanShow(false);
  const handleCarePlanModalShow = () => setCarePlanShow(true);

  useEffect(() => {
    // if(commonError) {
    //   alert.error(commonError);
    //   dispatch({ type: CCM_CAREPLAN_RESET})
    // }

    // if(message){
    //   alert.success(message);
    //   dispatch({ type: CCM_CAREPLAN_RESET})
    // }

    dispatch(getPatientCCMCareplan(patientId));

    },[dispatch]);


  const handleFileChange = e => {
    setFileName(e.target.files[0]);
  }

  const submitCarePlan = () => {
    if(description === '' && fileName.name === undefined){
      setError('description and file required.');
      return;
    }

    if(error !== '' || error !== undefined){
      setCarePlanShow(true);
    }

    dispatch(submitCCMCareplan(fileName, user, patientId, description));
      setFileName({});
       setError('');
       setCarePlanShow(false);
  }

  const deleteHandler = (careplanId) => {
    var confirmation = window.confirm(`Are you sure you want to delete patient CCM careplan ?`);
        if(confirmation){
          dispatch(deleteCCMCareplan(careplanId));
        }
    }

  return (
    <div className='orangeCardDashboardStatsCard p-3' style={{borderRadius: '10px'}}>
          <h5 className='title text-white'>CCM Careplan</h5>
               

        {ccmCareplan ? <>
          <div className="row">
            &nbsp;&nbsp;&nbsp;
            <span className="fileLink" onClick={() => downloadFile(ccmCareplan?.fileName)}>
              {ccmCareplan?.fileName}
            </span>
          </div>
          <br/>
          
          <b>Description</b>
          <p className='text-white'>
            {ccmCareplan?.description}
          </p>
         
          <div className="row-display-secondary">
            <div>
              <Image src={doctorIcon} style={{width: '60px', height: '60px'}} />
            </div>

             <div className="pl-2 m-2">
                <b>{ccmCareplan?.addedBy}</b>
                <p className='text-white'>{moment(ccmCareplan?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A')}</p>
             </div> 
          </div>        
        </> :  <div className="text-center">
          <br />
          <img src={notFound} style={{width: '40px', height: '40px'}} alt="not found" />
            <p><small>No records found</small></p>
        </div> }

        {ccmCareplan ? <div className='text-center'>
          {isNurse === true ? <>
            <button className="edit-pill" style={{backgroundColor: 'red', color: 'white'}} onClick={() => deleteHandler(ccmCareplan?._id)}>
                <i className="bx bx-minus mr-2"></i> Delete CCM Careplan
              </button>
          </> : null} 
        </div> : null}

        {!ccmCareplan ? <div className="text-center">
          <button className="edit-pill" onClick={handleCarePlanModalShow}> 
            <i className="bx bx-plus-medical"></i> Upload Careplan
          </button> 
        </div> : null}    
        
        

          {/* Add CCM Careplan Modal */}
          <Modal show={carePlanShow} onHide={handleCarePlanModalClose}>
            <Modal.Header >
                <h5>
                  Upload CCM Careplan
                </h5> 
                <button onClick={handleCarePlanModalClose} className="btn btn-danger">
                  <i className='bx bx-x'></i>
                </button>
            </Modal.Header>

                <Modal.Body>
                    <Formik initialValues={{
                        description: '', 
                        fileName: ''
                    }}
                    onSubmit={() => {submitCarePlan()}}>
                        <div>
                          {error && <>
                            <Alert key="danger" variant="danger">
                              {error}
                            </Alert>
                          </>}
                          

                            <Form>                       
                            <label htmlFor="description" className="form-label mt-3">Description</label>
                                <textarea 
                                    label="Description" 
                                    name="description"
                                    className="form-control"
                                    rows="4"	
                                    value={description} 
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Type description here .... "
                                />
                                
                                <br />
                            <label htmlFor="description" className="form-label mt-3">Attachment</label>
                                <input 
                                    label="File" 
                                    name="fileName" 
                                    type="file"
                                    accept=".doc, .docx, .pdf"
                                    className="form-control"
                                    style={{border: 'none'}} 
                                    onChange={handleFileChange}
                                />
                            <br/>
                            <div className="row-class" style={{justifyContent: 'space-between'}}>
                                <button className="edit-pill" type="submit"> Save Careplan</button>
                            </div>
                            </Form>
                        </div>
                    
                    </Formik>   
                </Modal.Body>
            </Modal>
    </div>
  )
}

export default PatientCareplans