import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getNursePtsRecentReadings } from '../../actions/HRActions';


const HRRecentReadings = () => {

    const dispatch = useDispatch();

    // useSelectors
    const { user} = useSelector(state => state.auth);
    const {count, recentReadings} = useSelector(state => state.nurseRecentReadings);


    const [filterRecent, setFilterRecent] = useState('bp');
    const [pagination, setPagination] = useState(25);

    let nurseDrs = localStorage.getItem('nurseSelectedDrs');

    let color;
    let sysBPCategory; //variable to store category of BP

    const refreshRecentReadings = () => {
        dispatch(getNursePtsRecentReadings(pagination, nurseDrs));
    }
    
    useEffect(() => {
        if(nurseDrs && nurseDrs.length > 0){
            dispatch(getNursePtsRecentReadings(pagination, nurseDrs));
        }
    }, [dispatch, pagination])


    const updateReadingsPagination = () => {
        setPagination(pagination + 1);
        dispatch(getNursePtsRecentReadings(pagination, filterRecent));
    }


    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                    sysBPCategory = "Hypertension- stage 4"
                    color = '#FE0004'
                    break;
                case (sys >= 180 && sys <= 210):
                    sysBPCategory = "Hypertension-Stage 3"
                    color = '#FE504F'
                    break;
                case (sys >= 160 && sys <= 179):
                    sysBPCategory = "Hypertension-Stage 2"
                    color = '#FE504F'
                break;
                case (sys >= 140 && sys <= 159):
                    sysBPCategory = "Hypertension-Stage 1"
                    color = '#FF32CB'
                break;
                case (sys >= 130 && sys <= 139):
                    sysBPCategory = "Pre-hypertension"
                    color = '#FFFF00'
                break;
                case (sys >= 121 && sys <= 129):
                    sysBPCategory = "High Normal B.P"
                    color = '#CBFE33'
                break;
                case (sys >= 100 && sys <= 120):
                    sysBPCategory = "Normal Blood Pressure"
                    color = '#00FF99'
                break;
                case (sys >= 90 && sys <= 99):
                    sysBPCategory = "Low Normal B.P"
                    color = '#CDFFCC'
                break;
                case (sys >= 70 && sys <= 89):
                    sysBPCategory = "Low Blood Pressure"
                    color = '#DEEAF6'
                break;
                case (sys >= 50 && sys <= 69):
                    sysBPCategory = "Too Low Blood Pressure"
                    color = '#9CC3E4'
                break;
                case (sys < 50):
                    sysBPCategory = "Extremely Low B.P"
                    color = '#2E73B4'
                break;
                default:
                    sysBPCategory = "Invalid B.P"
                    color = '000'
            } 
        }
    


  return (
    <>
    {user.consentRole === 'RPM' || user.consentRole === 'Both' ? <>
                <div className="shadow-lg p-3 mb-2 recent-readings-card background-white">
                            <div className='row-display'>
                                <div>
                                    <h5 className="title">Recent Vitals ( Last 3 days )</h5>
                                    <p><small>Showing <b>{recentReadings?.length}</b> results out of <b>{count && count}</b> </small></p>
                                </div>

                                <button className="btn btn-secondary btn-sm m-3" onClick={() => refreshRecentReadings()}>
                                    <i className="bx bx-refresh"></i>
                                </button>
                            </div>

                            <hr className="mt-1 p-0" />

                            
                            <div className="row-display-secondary">
                                <span className={`smallFilterBtn ${filterRecent === 'bp' ? 'smallFilterBtnActive' : ''}`}
                                    onClick={() => setFilterRecent('bp')}
                                    >Cuff 
                                </span>
                                
                                <span className={`smallFilterBtn ${filterRecent === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                    onClick={() => setFilterRecent('weight')}>
                                    Weight
                                </span>

                                <span className={`smallFilterBtn ${filterRecent === 'sugar' ? 'smallFilterBtnActive' : ''}`}
                                    onClick={() => setFilterRecent('sugar')}>
                                    Glucose
                                </span>

                                <span className={`smallFilterBtn ${filterRecent === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                    onClick={() => setFilterRecent('spo2')}>
                                    Spo2
                                </span>
                            </div>

                            {recentReadings && recentReadings.map((pts, index) => (
                            <>

                            {filterRecent === 'bp' && pts?.telemetaryData?.sys && pts?.telemetaryData?.dia ? <>
                            <div key={index} className="mt-4">    
                                <p>
                                    <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id }}} className="link">
                                        {pts?.assigned_patient_id?.lastname  + " , "+ pts?.assigned_patient_id?.firstname} 
                                    </Link> 
                                    <br/>
                                <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                                </p>  

                                {calcSysBpCategory(pts?.telemetaryData?.sys)} 

                                <div className="row-display">
                                    <div>
                                        <b>Sys / Dia</b>
                                    </div>

                                    <div className="row-display">
                                        <h1 className='display-5'>{pts?.telemetaryData?.sys} &nbsp;</h1> 
                                        <h1 className='display-5'>/ &nbsp;</h1>
                                        <h1 className="display-5">{pts?.telemetaryData?.dia}</h1>          
                                    </div>
                                </div>
                                
                                <div id="scroll-container">
                                    <div id="scroll-text" style={{color: color, letterSpacing: '1px'}}>{sysBPCategory}</div>
                                </div>

                                <p style={{float: 'right'}}>
                                    <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                                </p>
                            
                            </div>
                            </> : filterRecent === 'weight' && pts?.telemetaryData?.wt ? <div className="mt-4">
                            <div key={index}>
                                <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                    {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                </Link>
                                <br/>
                                <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                            </div>

                            <div className="row-display">
                                <div>
                                    <small>Weight Scale</small>
                                </div>

                                <div className="row-display-column">
                                    <h2 className='display-5'>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} <small style={{fontSize: '18px'}}> In Kg </small> </h2>
                                    <h4>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} <small style={{fontSize: '18px'}}> In pounds </small></h4>           
                                </div>

                            </div>

                                <p style={{float: 'left'}}>
                                    <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                                </p>                              
                                <br/>
                        </div>
                        : filterRecent === 'sugar' && pts?.telemetaryData?.data ? <div key={index}>
                            <div key={index} className="mt-3">
                                
                                <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                    {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                </Link>
                                
                                <br/>
                                <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                                </div>

                                <div className="row-display">
                                    <div>
                                        <small>Glucose</small>
                                    </div>

                                    <div className="row-display-column">
                                        <div className="row-display-secondary">
                                            <h1 className='display-5'>{pts?.telemetaryData?.data} &nbsp;</h1> 
                                            <p>{pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}</p>
                                        </div>
                                         
                                        <p><b>Reading Taken : </b>{pts?.telemetaryData?.meal === 0 ? 'Not Selected' : pts?.telemetaryData?.meal === 1 
                                            ? 'Before Meal' : pts?.telemetaryData?.meal === 2 ? 
                                            'After Meal' : null
                                            }
                                        </p>           
                                    </div>
                                </div>

                                <p style={{float: 'left'}}>
                                    <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                                </p>    
                                <br/>
                        </div> : filterRecent === 'spo2' && pts?.telemetaryData?.notification_payload ? <>
                            <div key={index} className="mt-3">                    
                                <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                    {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                </Link>
                                    <br/>
                                    <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>

                            <div className="row-display">
                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.pulse_bpm}</h1>
                                    <b className="m-0 p-0">Pulse BPM</b>        
                                </div>

                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.spo2_pct}</h1>
                                    <b className="m-0 p-0">Spo2 PCT</b>        
                                </div>

                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</h1>
                                    <b className="m-0 p-0">Perfusion</b>        
                                </div>
                            </div>
                            
                            <br/>                    
                                <p><b>Reading Type : </b>{pts?.telemetaryData?.notification_payload?.reading_type}</p>
                                <p><b>Sent Date : </b>{moment(pts?.telemetaryData?.notification_payload?.ts_recorded * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</p>
                                <p><b>Received Date : </b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</p> 
                                
                            <br/>
                            </div>
                            </> : null}    
                        </> 

                        
                        ))}

                        {recentReadings?.length < count && <>
                            <br/><br/>
                                <div className='text-center'>
                                    <button className='edit-pill' onClick={() => updateReadingsPagination()}> Show more </button>
                                </div>
                            <br/><br/>
                        </>}
                        </div>

                </> : null }

                
    </>
  )
}

export default HRRecentReadings