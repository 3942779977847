import {useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LineChart, Line } from 'recharts';
import moment from 'moment';


const PatientProfileGraph = ({healthData }) => {

    const [graphType, setGraphType] = useState('bar');
    const [activeTab, setActiveTab] = useState('showTelemetary');


    let data = healthData && healthData.filter(healthData => healthData?.deviceId?.deviceType === "bp").map(deviceData => {
     
        return {
            'date': moment(deviceData?.createdAt).tz("America/New_York").format("MM/DD/YYYY"),
            'systolic': deviceData?.telemetaryData?.sys,
            'diastolic': deviceData?.telemetaryData?.dia,
            'pulse':deviceData?.telemetaryData?.pul
        } 
      });

  return (
      <>
    <section className="rounded-card">

     <div className='row-display' style={{width: '400px', float: 'right'}}>
        <button className={`${graphType === 'bar' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setGraphType('bar')}> Bar Graph</button>

        <button className={`${graphType === 'line' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setGraphType('line')}>Line Graph</button>
    </div>    
    <br/>

        {graphType === 'bar' ? <>
       <ResponsiveContainer width="100%" aspect={4/1} style={{overflowY: 'hidden !important' }}>
        <BarChart data={data}
                  >
                  <XAxis dataKey="date" />
                  <YAxis dataKey="systolic"/>
                  <CartesianGrid strokeDasharray="1 1" />
                  <Tooltip />
                  <Legend/> 
                  <Bar dataKey="systolic" fill="#00A8A8" />
                      <Bar dataKey="diastolic" fill="#FFBC31" />
                      <Bar dataKey="pulse" fill="#76C044" />
              </BarChart>
      </ResponsiveContainer>
      </> : <>
      <ResponsiveContainer width="100%" aspect={4/1}>
              <LineChart data={data}>
              <XAxis dataKey="diastolic" stroke="#0E194F"/>
              <YAxis/>
                <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />
                <Line type="stroke" dataKey="systolic" stroke="#0E194F" activeDot={{ r: 8 }}/>
                <Line type="stroke" dataKey="diastolic" stroke="#8fbb2c" />
              <Tooltip />
              <Legend />
              </LineChart>
            </ResponsiveContainer>
      </>
    }
    </section>
    </>
  )
}

export default PatientProfileGraph