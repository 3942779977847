import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commentOnReading, commentOnReadingByStaff} from '../../actions/HRActions';
import {deleteReading, deleteReadingComment} from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Badge } from 'react-bootstrap';
const moment = require('moment-timezone');


const CuffTelemetaryData = ({ healthData, isAdmin, patientId}) => {
    let color;
    const  dispatch = useDispatch();
    const alert = useAlert();

    const { user} = useSelector(state => state.auth);
    
    
    let telemetaryData = healthData?.telemetaryData;
    // let deviceDetails = healthData?.deviceId;
    let notes = healthData?.notes;

    const [comment, setComment] = useState('');

    const commentHandler = (readingId, healthData) => {
        if(user?.role === 'HrMedical'){
            if(comment.length === 0 ){
                alert.error('Empty comment cannot be added');
                return;
            }

            let notes = `${comment}. Readings time is
                ${moment(telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}`
            dispatch(commentOnReading(readingId, user?._id, comment, patientId, notes))

        } else {
            if(comment.length === 0 ){
                alert.error('Empty comments cannot be added !');
                return;
            }

            dispatch(commentOnReadingByStaff(readingId, user?._id, comment));
        }
    }


    let sysBPCategory; //variable to store category of BP

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }

    let diaBpCategory;


    function calcDiaBpCategory(dia) {
        switch(true){
            case (dia > 120):
                diaBpCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;

            case (dia >= 110 && dia <= 120):
                diaBpCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;

            case (dia >= 100 && dia <= 109):
                diaBpCategory = "Hypertension-Stage 2"
                color = '#FE504F'
                break;

            case (dia >= 90 && dia <= 99):
                diaBpCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
                break;
                
            case (dia >= 130 && dia <= 139):
                diaBpCategory = "Pre-hypertension"
                color = '#FFFF00'
                break;

            case (dia >= 85 && dia <= 89):
                diaBpCategory = "High Normal B.P"
                color = '#CBFE33'
                break;

            case (dia >= 81 && dia <= 84):
                diaBpCategory = "High Normal Blood Pressure"
                color = '#00FF99'
                break;

            case (dia >= 65 && dia <= 80):
                diaBpCategory = "Normal Blood Pressure"
                color = '#00FF99'
                break;
            
            case (dia >= 60 && dia <= 64):
                diaBpCategory = "Low Normal B.P"
                color = '#CDFFCC'
                break;

            case (dia >= 40 && dia <= 59):
                diaBpCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (dia >= 35 && dia <= 39):
                diaBpCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (dia < 35):
                diaBpCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                diaBpCategory = "Invalid B.P"
                color = '#000'
            } 
        }

        const deleteCommentHandler = (noteId , readingId) => {
            var confirmation = window.confirm(`Please confirm to delete comment ?`);
            if(confirmation){
                dispatch(deleteReadingComment(noteId, readingId));
            }
        }

        var minutesDuration;

        const calcDuration = (receiveDate, sentDate) => {
            minutesDuration = moment(receiveDate).diff(moment(sentDate), 'minutes');
        }

        const deleteHandler = (readingId) => { 
            var confirmation = window.confirm(`Are you sure you want to delete this reading ?`);
                if(confirmation){
                    dispatch(deleteReading(readingId));
                }
            }
            
            // const [selectedUsers, setSelectedUsers] = useState([]);

            const users = [
              { id: 1, name: 'User 1' },
              { id: 2, name: 'User 2' },
              { id: 3, name: 'User 3' },
              { id: 4, name: 'User 4' },
              // Add more users as needed
            ];   
    
    return  <>
    
      <div className="card p-3 mt-4 shadow-lg background-white">

        <div className={`ribbon ribbon-top-left`}>   

            <p>{
                healthData?.deviceId?.deviceType && 
                healthData?.deviceId?.deviceType === 'bp' ? <span style={{backgroundColor: '#00A8A8'}}>B.P</span> :
                healthData?.deviceId?.deviceType === 'sugar' ? <span style={{backgroundColor: '#5C1F4B'}}>Glucose</span> :
                healthData?.deviceId?.deviceType === 'spO2' ? <span style={{backgroundColor: '#FF5C39'}}>Oximeter</span> :
                healthData?.deviceId?.deviceType === 'weight' ? <span style={{backgroundColor: '#FFC508'}}>Weight</span> :
                null }    
            </p>
        </div>
        
        <div className="row" style={{position: 'absolute', top: 0, right: 20}}>
        {isAdmin ===true ? <>
            <div style={{textAlign: 'right', color: 'red',
                    margin: 0, padding: 0}}>
                <button className="btn btn-link"
                onClick={() => deleteHandler(healthData?._id)}
                style={{color: 'red'}}
                >
                Delete Reading
            </button>
            </div>
        </> : null }    
        </div>
        
        <br />
         {healthData?.deviceId?.deviceType === 'bp' ? <>
         <div className="row-display pl-4">

            {calcSysBpCategory(telemetaryData?.sys)} 

            <div>
            <span>Systolic : {telemetaryData?.sys >= 139 || telemetaryData?.sys <= 110 ? 
                    <span style={{color: 'red', fontWeight: 'bold', fontSize: '16px'}}>{telemetaryData?.sys}</span> : 
                    <span>{telemetaryData?.sys}</span>} mmHG</span>
                <br/>
                <small><b>{sysBPCategory}</b> &nbsp;&nbsp;&nbsp;<i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i></small>  
            </div>

            {calcDiaBpCategory(telemetaryData?.dia)}


            <div>
            <span>Diastolic : {telemetaryData?.dia >= 90 || telemetaryData?.dia <= 70 ? 
                    <span style={{color: 'red', fontWeight: 'bold'}}>{telemetaryData?.dia}</span> : 
                    <span>{telemetaryData?.dia}</span>} mmHG</span>
                <br/>
                <small><b>{diaBpCategory}</b> &nbsp;&nbsp;&nbsp;<i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i></small>
            </div>

            <div>
                <span>Pulse : {telemetaryData?.pul} BPM</span>
            </div>

            <div>
                <span>Device ID: {healthData?.deviceId?._id}</span><br/>
                <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
                <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span><br/>

                {healthData?.createdAt && healthData?.telemetaryData?.ts && calcDuration(moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                    moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }  
            </div>

            </div> {/* First Row Ends here */}

         </> : healthData?.deviceId?.deviceType === 'weight' ? <>
         <div className="row-display pl-4"> 
            <div>
                    <span>Weight :  
                        <h2>
                            {telemetaryData?.wt} <small style={{fontSize: '18px'}}> In grams</small> 
                        </h2>
                    </span>  
                <br/>
            </div>

            <div>
                <span>Weight :   
                    <h2>{(telemetaryData?.wt / 1000).toFixed(2)} <small style={{fontSize: '18px'}}> In kgs </small></h2>
                </span>
            </div>

            <div>
            <span>Weight :   
                    <h2>{(telemetaryData?.wt / 453.6).toFixed(2)} <small style={{fontSize: '18px'}}> In pounds </small></h2>
                </span>
            </div>

            <div>
                <span>Device ID: {healthData?.deviceId?._id}</span><br/>
                <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
                <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span><br/>
                
                {healthData?.createdAt && healthData?.telemetaryData?.ts && calcDuration(moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                    moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }

            </div>
            </div> {/* First Row Ends here */}

         </> : healthData?.deviceId?.deviceType === 'sugar' ? <>
           <div className='row-display pl-4'>
                <div>
                        <span>Glucose :  
                            <h2>
                            {telemetaryData?.data} <small style={{fontSize: '18px'}}> {telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}</small> 
                            </h2>
                        </span>  
                    <br/>
                </div>

                <div>
                <span>Reading Taken :   
                    <h2> {telemetaryData?.meal === 0 ? 'Not Selected' : telemetaryData?.meal === 1 
                        ? 'Before Meal' : telemetaryData?.meal === 2 ? 
                        'After Meal' : null
                    }</h2>
                </span>
                </div>

                <div>
                    <span>Device ID: {healthData?.deviceId?._id}</span><br/>
                    <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
                    <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY')}</span><br/>
                    
                    {healthData?.createdAt && healthData?.telemetaryData?.ts && calcDuration(moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                    moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }
                </div>                
            </div>


         </> : healthData?.deviceId?.deviceType === 'spO2' ? <>
         <div className="row-display pl-4"> 
            <div>
                    <span>Pulse BPM :  
                        <h2>
                        {telemetaryData?.notification_payload?.pulse_bpm} <small style={{fontSize: '18px'}}> Pulse BPM</small> 
                        </h2>
                    </span>  
                <br/>
            </div>

            <div>
                <span>Spo2 PCT :   
                    <h2>{telemetaryData?.notification_payload?.spo2_pct} <small style={{fontSize: '18px'}}> Spo2 PCT  </small></h2>
                </span>
            </div>

            <div>
            <span>Perfusion Index PCT :   
                    <h2>{telemetaryData?.notification_payload?.perfusion_index_pct} <small style={{fontSize: '18px'}}> PI_PCT </small></h2>
                </span>
            </div>

            <div>
                <span>Device ID: {healthData?.deviceId?._id}</span><br/>
                <span>Reading Recorded: {moment(telemetaryData?.notification_payload?.ts_recorded * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
                <span>Received Date: {moment(telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span><br/>
            </div>

            </div> {/* First Row Ends here */}
         </> : null}
          
            
            {isAdmin ===true ? <>
            </> : 
                <div className="row-display mt-2">
                        <label className="profile-label mt-3">Comment</label>

                    <div className="col-md-10">
                        <input type="text" 
                        className="form-control"
                        placeholder="Type your comment here ....."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        key={telemetaryData?._id}
                        />
                    </div>
    
                    <div className="col-md-2">
                        <button className="edit-pill" 
                            type="submit" 
                            onClick={() => commentHandler(healthData?._id, healthData)}>
                            Add Comment
                        </button>
                    </div>
                </div>
            } 
            

            {/* Comment */}
            {notes.length > 0 && notes.map((note, index) => ( <div key={index}>
                <div className="row-display-secondary">
                    <div className="mt-3 mr-3">
                        <img src={patientProfileImg} alt="hr/drImg" className='roundedBorderImg' width="50px" height="50px" />
                    </div>
                    <div className="bubble bubble-alt">  
                        
                        <div className="row-display">
                        <b> {note?.conclusion_hr_id ? (<span style={{color: '#FFF'}}>{note?.conclusion_hr_id?.firstname}  {note?.conclusion_hr_id?.lastname}</span>) : (<span>
                            {note?.conclusion_doctor_id?.firstname}  {note?.conclusion_doctor_id?.lastname}
                            </span>)}
                        </b>

                        {isAdmin ===true ?    
                        <button className="edit-pill" onClick={() => deleteCommentHandler(note?._id, healthData?._id)}>
                            Delete
                        </button> : null }

                        </div>
                        
                  
                        <p className="mt-1 mr-3">{note?.conclusion} <br/> 
                         <p className="mt-1" style={{letterSpacing: '1px'}}>{moment(note?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                        </p>
                    </div>
                </div>
            </div> 
            ))}  
        </div>
    </>   
};


export default CuffTelemetaryData;
