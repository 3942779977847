import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { 
    getCCMPatients,   
    patientDeActivate,
    getOrganizations,
    getDoctors
} from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge, Modal } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { ALL_ADMINS_FAIL, 
    ALL_PATIENTS_FAIL, 
    ALL_PATIENTS_REQUEST, 
    ALL_PATIENTS_SUCCESS, 
    UPDATE_PATIENT_RESET
} from '../../constants/adminConstants';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';

const CCMPatients = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [smShow, setSmShow] = useState(false); //small confirm modal
    const [patientModel, setPatientModel] = useState(null);
    const [patientToDelete, setPatientToDelete] = useState(null);
    const [doctorId, setDoctorId] = useState('');
    const [input, setInput] = useState("");
    const [orgId, setOrgId] = useState("");
    const { doctors } = useSelector(state => state.doctor);
    const [selectedRows, setSelectedRows] = useState([]);
         
    const alert = useAlert();
    const { loading, error, patients, count, isUpdated} = useSelector(state => state.admin);
    const { organizations } = useSelector(state => state.orgs);

    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(20);

    useEffect(() =>{
        if(error){
            alert.error(error);
            return
        }

        if(isUpdated){
            alert.success('Status Changed');
            dispatch({type: UPDATE_PATIENT_RESET});
            dispatch(getCCMPatients(resPerPage, currentPage));
            setSmShow(false);
        }


        
        if(doctorId && !orgId){
            if(doctorId !== 'default'){
                dispatch(getCCMPatients(resPerPage, currentPage, doctorId));
            }
        } else if(orgId && !doctorId){
            if(orgId !== 'default'){
                dispatch(getCCMPatients(resPerPage, currentPage, null, orgId));
            }
        } else if (orgId && doctorId){
            dispatch(getCCMPatients(resPerPage, currentPage, doctorId));
        } else {
            dispatch(getCCMPatients(resPerPage, currentPage));
        }

        dispatch(getOrganizations());
        dispatch(getDoctors(50, 1));

    }, [dispatch, error, isUpdated, currentPage, doctorId, orgId]);


        function setCurrentPageNumber(pageNumber) {
            setCurrentPage(pageNumber);
        }

        const refreshhandler =() => {
            dispatch(getCCMPatients(resPerPage, currentPage))
            setOrgId("");
            setDoctorId("");
        }

        const deActivatePatient = () => {
            dispatch(patientDeActivate(patientModel));
        }

    
        const searchHandler = async() => {
            if(input?.length > 0){
                try {
                
                    dispatch({
                        type: ALL_PATIENTS_REQUEST,
                    })
            
                    const { data } = await axios.post(`${Prod01}/admin/ccm/search/${resPerPage}/${currentPage}`,{
                        value:input
                    }, {
                        timeout: 10000 // timeout of API for 10 secs
                    });
                    
                    dispatch({
                        type: ALL_PATIENTS_SUCCESS,
                        payload: data
                    })    
                } 
                catch (error) {
                    if (error.code === 'ECONNABORTED') {
                        dispatch({
                            type: ALL_PATIENTS_FAIL,
                            payload: 'request timed out'
                        });
                    } else {
                        dispatch({
                            type: ALL_ADMINS_FAIL,
                            payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                        });
                    }
                }
            } else {
                alert.error('Please type something to search')
            }
        }

    return (
        <>
                <MetaData title="CCM Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <br/>
              
                

                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        
                        <div className="row-display">
                            <h5 style={{color: '#00A8A8'}}>
                                <i className="bx bx-user"></i> <strong>&nbsp;{count && count} - CCM Patients </strong>
                            </h5>
                            
                            <div>
                            <button className="btn btn-secondary" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i>
                            </button> 
                            &nbsp;&nbsp;

                            <button className="edit-pill" id="refreshHandler" onClick={refreshhandler}>
                                <i className='bx bx-refresh'></i>
                            </button>

                            &nbsp;&nbsp;

                            <Link to ="/Patients/Inactive" className="edit-pill" style={{backgroundColor: 'red'}}>
                                <i className='bx bxs-user'></i> &nbsp; Archieved Patients
                            </Link>
                            &nbsp;&nbsp;

                            <Link to="/addpatient" className="edit-pill">
                                <i className='bx bxs-user'></i> &nbsp;Add New Patient
                            </Link>
                            

                            </div>                    
                        </div>
                        <hr />

                        <div className='row-display'>
                        <div className="row colored-row">
                            <div className="mini-searchBox" >
                                <input 
                                    type="text" 
                                    placeholder="Search by EHR-ID, Fname, Lname and DOB . . . " 
                                    className="mini-searchInput" 
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    autoFocus
                                    maxLength="15"
                                />
                                <i className='bx bx-user mini-searchbar-Icon'></i>
                            </div>
                            &nbsp;&nbsp;
                            <button className='edit-pill m-2' onClick={() => searchHandler()}>Search</button>
                        </div>

                        <div className="row colored-row">                       
                            <select className='form-control'
                                    value={orgId}
                                    onChange={e => setOrgId(e.target.value)}
                                    style={{width: '280px'}}
                                    >
                                    <option key="151546" value="default">Select Organization to Search</option>
                                        {organizations && organizations.map((org) =>  
                                            <option key={org?._id} value={org?._id}>{org?.name}</option>
                                        )}
                            </select>
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            {orgId && <>
                                <select className='form-control'
                                    value={doctorId}  
                                    onChange={e => setDoctorId(e.target.value)}
                                    style={{width: '280px'}}
                                    >
                                        <option value="default" disabled key="15151">Select Doctor</option>   
                                        {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) =>  
                                            <option key={doctor?._id} value={doctor?._id}>Dr. {doctor?.lastname}, {doctor?.firstname}</option>
                                        )}
                                </select>
                            </> }                
                            
                        </div>
                    </div>
                    </div>  
                    <br />


                    {/* Patient List Card */}
                        <div className="col-md-12">
                        {patients && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>EHR - ID</th>    
                                <th>Name</th>
                                <th>DOB </th>
                                <th width="60px">CCM Careplan Added</th>
                                <th>Acc Status</th>
                                <th>Assigned Dr.</th>
                                <th>Program Enrolled</th>
                                <th>Action</th> 
                                </tr>
                            </thead>
                            <tbody>
                            
                            {patients && patients?.length > 0 ? <> 
                                {patients && patients.map((patient) => (
                                <tr key={patient?._id}>
                              

                                <td><b>{patient?.emrId || 'N/A'}</b></td>  
                                <td>  
                                    <Link className="link" to={{ pathname: "/patientProfile", state: {patientid: patient?._id}}}>
                                        {patient?.lastname +', '+ patient?.firstname}
                                    </Link>
                                </td>
                                
                                <td>
                                    <b>{moment(patient?.DOB).format("MM-DD-YYYY")}</b> <br/>
                                    <Badge bg="info text-white" style={{fontSize:'14px'}}>
                                        {patient?.gender === 'male' ? 'Male' : 'Female'}
                                    </Badge>
                                </td> 
                                
                                <td>
                                    {patient?.ccmCareplanManualAdded === true ? <i className="fa fa-check" style={{color: 'green'}}></i> : <i className="fa fa-times" style={{color: 'red'}}></i>}
                                </td>

                                <td style={{color: 'green', fontWeight: 'bold'}}>
                                   {patient?.block === false ? <span style={{color: 'green', fontWeight: 'bold'}}>Active</span> : <span style={{color: 'red', fontWeight: 'bold'}}>Deactivated</span>}
                                </td> 
                                    

                                {patient?.assigned_doctor_id ? <>
                                <td>
                                    <Link to={{ pathname: "/doctorProfile", state: {id: patient?.assigned_doctor_id?._id}}} className="link">
                                        Dr. {patient?.assigned_doctor_id?.lastname +" , "+ patient?.assigned_doctor_id?.firstname}
                                    </Link>
                                </td>
                                </> :
                                <td>---</td>
                                }

                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>
                                
                                {/* Actions */}
                                <td>
                                <div className="row-display">    
                                    <Link className="link" 
                                        style={{fontSize: '20px'}}
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        <i className="bx bx-user"></i>
                                    </Link>
                                
                                    <Link className="link" 
                                        style={{fontSize: '20px'}}
                                        to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}}>
                                        <i className="bx bx-edit"></i>
                                    </Link>
                                
                                    <Link className="link" 
                                        style={{color: 'red', fontSize: '20px'}} to="/CCM/Patients" 
                                        onClick={() => {setSmShow(true); setPatientModel(patient?._id); 
                                        setPatientToDelete(patient?.lastname)}}>
                                            <i className="bx bx-block"></i></Link>     
                                </div>
                                </td>
                            </tr>    
                            ))}
                            </> : <tr key="no-patients">
                                <td colSpan="7">Patients Not Found</td>
                            </tr> }
                            
                            </tbody>
                            
                            </table>
                            <p className='text-muted'>Showing {resPerPage} records per page</p> 

                            {patients?.length < count && 
                            <div className="d-flex justify-content-center mt-5"> 
                                <Pagination 
                                    activePage={currentPage} 
                                    itemsCountPerPage={resPerPage} 
                                    totalItemsCount = {count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                        }    

                                <Modal
                                    size="sm"
                                    show={smShow}
                                    onHide={() => setSmShow(false)}
                                    aria-labelledby="example-modal-sizes-title-sm"
                                >
                                    <Modal.Body>
                                        <p>Are you sure you want to Inactivate 
                                            <span style={{color: '#000'}}> {patientToDelete}</span> ?
                                        </p>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-danger" onClick={deActivatePatient}>Inactive</button>
                                    </Modal.Footer>
                                </Modal>  
                        </>}                      
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default CCMPatients
