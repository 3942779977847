import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { 
    getInactivePatients,
    patientActivate 
} from '../../actions/adminActions';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge, Modal } from 'react-bootstrap';
import moment from 'moment';
import {UPDATE_PATIENT_RESET} from '../../constants/adminConstants';



const InactivePatients = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        blockPts
    } = useSelector(state => state.adminStat);

    const [query, setQuery] = useState("");

    const keys = ["emrId", "firstname", "lastname", "DOB"];
    const [selectedRows, setSelectedRows] = useState([]);

    const [smShow, setSmShow] = useState(false); //small confirm modal
    const alert = useAlert();
    const { loading, error, inactivePts} = useSelector(state => state.inactivePatients);
    const { isUpdated} = useSelector(state => state.admin);



    useEffect(() => {
        if(error){
            return alert.error(error);
        }  
        
        if(isUpdated){
            alert.success('Status Changed');
            dispatch({type: UPDATE_PATIENT_RESET});
            dispatch(getInactivePatients());
            setSmShow(false);
        }

        dispatch(getInactivePatients());    
    
    }, [dispatch, alert, error, isUpdated]);
    

    const refreshHandler = () => {
        dispatch(getInactivePatients());
    }

    const handleRowCheck = (rowId) => {
        if (selectedRows.includes(rowId)) {
          setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
          setSelectedRows([...selectedRows, rowId]);
        }
      };

    const handleActivateSelected = () => {
        var confirmation = window.confirm(`Are you sure you want to activate ${selectedRows?.length} patient(s) ?`);
        if(confirmation){
            selectedRows.forEach(rowId => {
                const row = inactivePts && inactivePts.find(patient => patient?._id === rowId);
                if (row) {
                    dispatch(patientActivate(row?._id));
                    setSelectedRows([]);
                }
            });
        };
    }
    
  return (
    <>
        <MetaData title="Archived Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br/>

                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    
                        <div className="container-fluid row-display">
                            
                            <h5 className='title' style={{color: 'red'}}> 
                                {inactivePts && inactivePts?.length} - Archieved Patients 
                            </h5>

                         
                            <div>
                                <button className="btn btn-secondary" onClick={refreshHandler}><i className='bx bx-refresh'></i></button>    
                                &nbsp;&nbsp;
                                <button className="btn btn-secondary" onClick={() => history.goBack()}>
                                    <i className='bx bx-arrow-back' ></i>
                                </button>
                            </div>                            
                        </div>  
                    <hr />

                    <div className="row-display ml-3 mb-3">   
                        <input 
                            type="text" 
                            className="form-control"
                            onChange={e => setQuery(e.target.value)}
                            placeholder="Search by EHR-ID, name, email, DOB . . ." 
                            style={{width: '320px'}}
                            maxLength={15}
                        />
                            {selectedRows?.length > 0 ? <>
                                <button 
                                className="edit-pill mr-3 m-2" 
                                onClick={handleActivateSelected} 
                                disabled={selectedRows.length === 0}
                                style={{backgroundColor: 'green'}}
                            >
                                Activate {selectedRows?.length < 10 ? '0'+selectedRows?.length : selectedRows?.length} Patient(s)
                            </button>
                        </> : null}
                        
                    </div>
                    

                    {/* Patient List Card */}
                        <div className="col-md-12">
                        {inactivePts && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th></th>
                                <th>Name & EHR-ID</th>
                                <th>DOB </th>
                                <th>Doctor</th>
                                <th>Account Status</th>
                                <th>Program Enrolled</th>
                                <th>Acc. Block Date</th>
                                <th style={{width:'100px'}}>Action</th> 
                                </tr>
                            </thead>
                            <tbody>
                            
                            {inactivePts && inactivePts.length > 0 ? <> 
                                {inactivePts && inactivePts.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((patient, index) => (
                                <tr key={index}>  
                                 <td>
                                 <label className="checkbox-wrapper">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(patient?._id)}
                                        onChange={() => handleRowCheck(patient?._id)}
                                    />
                                <div className="checkbox-custom"></div>
                                </label>
                                </td>

                                <td>
                                    <Link className="link" 
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        {patient?.firstname} {patient?.lastname}
                                    </Link>
                                    <p>{patient?.emrId}</p>
                                </td>
                                
                                <td> <b>{moment(patient?.DOB).format("MM/DD/YYYY")} <p><Badge bg="info text-white">{patient?.gender}</Badge></p></b></td> 
                                
                                <td><b> Dr. {patient?.assigned_doctor_id?.lastname +" , "+ patient?.assigned_doctor_id?.firstname } </b></td>
                                
                                {patient?.block === false ? <td>
                                    <b style={{ color: 'green'}}>Activated</b>
                                    </td> : <td><b style={{ color: 'red'}}>Deactivated</b>
                                </td>}
                                

                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>

                                <td><b>
                                    {patient?.accountActionPerformed === 'block' ? moment(patient?.accountActionPerformedDateTime).tz("America/New_York").format("MM-DD-YYYY hh:mm a") : null}
                                    </b>
                                </td>

                                <td>   
                                    <Link className="link" 
                                        style={{fontSize: '24px'}}
                                        to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}}>
                                        <i className="bx bx-edit"></i>
                                    </Link>
                                </td>
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            </tbody>
                            </table> 
                        </>}                      
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default InactivePatients