import { useState, useEffect } from 'react';
import MetaData from '../../layouts/MetaData';
import HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import { jsPDF } from 'jspdf';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeReportMonthlyLog } from '../../actions/HRActions';
import Loader from '../../layouts/Loader';
import { useAlert } from 'react-alert';
import { MONTHLY_LOG_REPORT_FAIL } from '../../constants/HRConstants';


const HRTimeReportMonthlyLog = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const { assignedDoctors } = useSelector(state => state.hrStats);
  const { loading, count, patientlist } = useSelector(state => state.timeReportMonthlyLog);

  const [selectedRows, setSelectedRows] = useState([]);
  const [doctorId, setDoctorId] = useState('');
  const [month, setMonth] = useState(moment().format('MM'));
  const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
  const [endMonth, setEndMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState("2024");
  const [reportCategory, setReportCategory] = useState('RPM');
  const [pagination, setPagination] = useState(10);

  useEffect(() => {
    dispatch({
      type: MONTHLY_LOG_REPORT_FAIL
    })

  }, [pagination]); // Dependency array with pagination

  const handleRowCheck = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const submitHandler = () => {
    resetSelection();
    if (!doctorId) {
      alert.error('Please select Doctor');
      return;
    } else {
      if (reportCategory === 'RPM') {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, month, year, pagination));
      } else {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, startMonth, year, pagination, endMonth)); 
      }
    }
  }

  const updateListPagination = () => {
    setPagination(pagination + 20);
    if (reportCategory === 'RPM') {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, month, year, pagination));
      } else {
        dispatch(getTimeReportMonthlyLog(doctorId, reportCategory, startMonth, year, pagination, endMonth)); 
      }
  }

  const resetSelection = () => {
    setSelectedRows([]);
  }

  const handleDownloadPDF = () => {
    // Generate the PDF for selected rows
    const margin = 40;
    const footerHeight = 10;

    const renderFooter = (doc, pageNumber) => {
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(10);
        doc.setTextColor(150, 150, 150); // Light gray
        doc.text(`Page ${pageNumber}`, margin, pageHeight - margin / 2);
        doc.line(margin, pageHeight - margin, pageWidth - margin, pageHeight - margin);
        doc.setTextColor(0, 0, 0); // Reset to black
    };

    selectedRows.forEach(rowId => {
        const doc = new jsPDF();
        const headerText = 'Time Tracking Report';
        let pageNumber = 1;
        let y = 110;
        const maxWidth = doc.internal.pageSize.getWidth() - 30; 

        const printEntryDetails = (entry, index) => {
            doc.text(`(${index + 1})`, 10, y);

            const participant = entry?.assigned_hr_id || entry?.assigned_assistant_id || entry?.assigned_doctor_id;
            const participantName = participant ? `${participant.lastname}, ${participant.firstname}` : null;
            
            doc.text(participantName || '', 20, y, { maxWidth });
            y += 6;
            doc.text('Conclusion: ', 20, y);
            y += 6;
            doc.setTextColor(64, 64, 64); // Custom color

            // Wrap the conclusion text
            const conclusionLines = doc.splitTextToSize(entry?.conclusion, maxWidth);
            doc.text(20, y, conclusionLines);
            y += conclusionLines.length * 7;

            // Clinical follow-up section
            if (entry.clinicalFollowUp) {
              const followUp = entry?.clinicalFollowUp;
              doc.setTextColor(0, 0, 0); // Reset text color to black
          
              // Print first question
              doc.text('Q. How have you been managing your health?', 20, y);
              y += 10;
              doc.text(followUp?.healthManagement || '', 20, y);
              y += 10; // Adjust y position after each block of text
          
              // Array of follow-up questions
              const followUpQuestions = [
                  { question: 'Any New Problems or conditions?', answer: followUp?.isNewProblem === 'yes' ? 'Yes' : 'No', details: followUp?.newProblemDetails },
                  { question: 'Any Worsening of Conditions?', answer: followUp?.isWorseningConditions === 'yes' ? 'Yes' : 'No', details: followUp?.worseningConditionsDetails },
                  { question: 'Medication Problems Identified?', answer: followUp?.isMedicationProblems === 'yes' ? 'Yes' : 'No', details: followUp?.medicationProblemsDetails },
                  { question: 'Recent Fall in last 30 days?', answer: followUp?.isRecentFall === 'yes' ? 'Yes' : 'No', details: followUp?.recentFallDetails },
                  { question: 'Recent Hospitalization / ED Visit?', answer: followUp?.isRecentlyHospitalized === 'yes' ? 'Yes' : 'No', details: followUp?.recentlyHospitalizedDetails },
                  { question: 'Medication Refill Request?', answer: followUp?.medicationRefillRequest === 'yes' ? 'Yes' : 'No', details: followUp?.medicationRefillRequestDetails }
              ];
          
              // Loop through the follow-up questions and print each one with proper page break handling
              followUpQuestions.forEach((q, idx) => {
                  if (y + 20 > doc.internal.pageSize.height - 20) {
                      // If the current y position exceeds the page height, add a new page
                      doc.addPage();
                      y = 20; // Reset y to the top of the new page
                  }
          
                  // Print the question, answer, and details
                  doc.text(`Q. ${q.question}`, 20, y);
                  y += 5;
                  doc.text(`Ans. ${q.answer || ''}`, 20, y);
                  y += 5;
                  doc.text(q.details || '', 110, y);
                  y += 10; // Adjust y for the next question block
              });
          }
          

            // Careplan follow-up section
            if (entry?.careplanFollowUp) {
              entry?.careplanFollowUp?.forEach(careplan => {
                  y += 8;
                  
                  // Short Term Goals
                  doc.setTextColor(0, 168, 168);
                  doc.setFontSize(12);
                  doc.text('Short Term Goals', 20, y);
                  y += 5; // Adjust for spacing
          
                  careplan?.shortTermGoals.forEach(goal => {
                      const goalLines = doc.splitTextToSize(goal, maxWidth);
                      
                      // Check if y exceeds page height, if so, add new page
                      if (y + (goalLines.length * 5) > doc.internal.pageSize.height - 20) {
                          doc.addPage();
                          y = 20; // Reset y to the top for the new page
                      }
          
                      doc.setTextColor(0, 0, 0);
                      doc.setFontSize(10);
                      y += 5;
                      doc.text(25, y, goalLines);
                      y += goalLines.length * 5; // Adjust y based on the number of lines
                  });
          
                  // Short-term action plan notes
                  y += 10;
                  if (y + 10 > doc.internal.pageSize.height - 20) {
                      doc.addPage();
                      y = 20;
                  }
                  doc.text('Notes : ', 20, y);
                  const stNotes = doc.splitTextToSize(careplan?.shortTermNote, maxWidth);
                  y += 5;
                  doc.text(20, y, stNotes);
                  y += stNotes.length * 5;
          
                  // Long Term Goals
                  y += 8;
                  if (y + 10 > doc.internal.pageSize.height - 20) {
                      doc.addPage();
                      y = 20;
                  }
                  doc.setTextColor(0, 168, 168);
                  doc.setFontSize(12);
                  doc.text('Long Term Goals', 20, y);
                  y += 5;
          
                  careplan?.longTermGoals.forEach(goal => {
                      const goalLines = doc.splitTextToSize(goal, maxWidth);
          
                      if (y + (goalLines.length * 5) > doc.internal.pageSize.height - 20) {
                          doc.addPage();
                          y = 20;
                      }
          
                      doc.setTextColor(0, 0, 0);
                      doc.setFontSize(10);
                      y += 5;
                      doc.text(25, y, goalLines);
                      y += goalLines.length * 5;
                  });
          
                  // Long-term action plan notes
                  y += 10;
                  if (y + 10 > doc.internal.pageSize.height - 20) {
                      doc.addPage();
                      y = 20;
                  }
                  doc.text('Notes :', 20, y);
                  const ltNotes = doc.splitTextToSize(careplan?.longTermNote, maxWidth);
                  y += 5;
                  doc.text(20, y, ltNotes);
                  y += ltNotes.length * 5;
          
                  // Interventions
                  y += 8;
                  if (y + 10 > doc.internal.pageSize.height - 20) {
                      doc.addPage();
                      y = 20;
                  }
                  doc.setTextColor(0, 168, 168);
                  doc.setFontSize(12);
                  doc.text('Interventions', 20, y);
                  y += 5;
          
                  careplan?.interventions.forEach(goal => {
                      const goalLines = doc.splitTextToSize(goal, maxWidth);
          
                      if (y + (goalLines.length * 5) > doc.internal.pageSize.height - 20) {
                          doc.addPage();
                          y = 20;
                      }
          
                      doc.setTextColor(0, 0, 0);
                      doc.setFontSize(10);
                      y += 5;
                      doc.text(25, y, goalLines);
                      y += goalLines.length * 5;
                  });
          
                  // Intervention action plan notes
                  y += 10;
                  if (y + 10 > doc.internal.pageSize.height - 20) {
                      doc.addPage();
                      y = 20;
                  }
                  doc.text('Notes : ', 20, y);
                  const itnNotes = doc.splitTextToSize(careplan?.interventionNote, maxWidth);
                  y += 5;
                  doc.text(20, y, itnNotes);
                  y += itnNotes.length * 5;
              });
          }
          

            doc.text('Start Date:', 20, y);
            doc.text('Start Time:', 60, y);
            doc.text('Minutes Added', 110, y);
            doc.text('Minutes Category', 160, y);
            y += 6;
            doc.text(entry?.startDate || '', 20, y);
            doc.text(entry?.startTime || '', 60, y);
            doc.setFontSize(18);
            doc.text(`${entry?.timeSpentInMinutes || 0}`, 120, y);
            doc.setFontSize(10);

            // Color-coding minutes
            doc.setTextColor(entry?.interactiveMinutes ? 0 : 255, entry?.interactiveMinutes ? 100 : 0, 0);
            doc.text(entry?.interactiveMinutes ? 'Interactive Minutes' : 'Non-Interactive Minutes', 160, y);
            doc.setTextColor(0, 0, 0); // Reset to black
            y += 10;
            doc.text('End Date:', 20, y);
            doc.text('End Time:', 60, y);
            y += 6;
            doc.text(entry?.endDate || '', 20, y);
            doc.text(entry?.endTime || '', 60, y);
            y += 10;
            doc.setDrawColor(200);
            doc.line(10, y, maxWidth, y);
            y += 10;

            // Handle pagination
            if (y + footerHeight + 40 > doc.internal.pageSize.getHeight() - margin) {
                renderFooter(doc, pageNumber);
                doc.addPage();
                pageNumber++;
                y = margin;
            }
        };

        const rowData = patientlist.find(item => item?._id === rowId); // Retrieve row data by ID

        // Header section
        doc.setTextColor(0, 168, 168);
        doc.setFontSize(16);
        doc.text('Remote Monitoring', 10, 10);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(14);
        const reportTitle = `Time Tracking Report of ${reportCategory}`;
        const pageWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getTextWidth(reportTitle);
        doc.text(reportTitle, (pageWidth - textWidth) / 2, 25);
        doc.text(`Created At: ${moment().tz("America/New_York").format("MM/DD/YYYY")}`, (pageWidth - textWidth) / 2, 35);

        // Draw a horizontal line
        doc.line(10, 40, pageWidth - 10, 40);

        // Patient details section
        doc.setTextColor(0, 168, 168);
        doc.setFontSize(14);
        doc.text('Patient Details:', 10, 48);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text('EHR-ID:', 10, 55);
        doc.text(rowData?.emrId || '', 10, 62);
        doc.text('Patient Name:', 60, 55);
        doc.text(`${rowData?.lastname}, ${rowData?.firstname}`, 60, 62);
        doc.text('DOB:', 130, 55);
        doc.text(moment(rowData?.DOB).format("MM/DD/YYYY"), 130, 62);
        doc.text('Gender:', 160, 55);
        doc.text(rowData?.gender === 'male' ? 'Male' : 'Female', 160, 62);

        // Time summary section
        doc.setTextColor(0, 168, 168);
        doc.setFontSize(14);
        doc.text('Time Summary', 10, 72);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text('Month & Year:', 10, 80);
        doc.text(`${reportCategory === 'CCM' ? startMonth + '-' + year + ' to ' + endMonth + '-' + year : month + '-' + year}`, 10, 87);
        doc.text('Total Minutes Added:', 60, 80);
        doc.text(`${rowData?.totalMinutesSpent || 0} mins`, 60, 87);
        doc.setTextColor(0, 100, 0); // Green for interactive minutes
        doc.text('Interactive Minutes:', 110, 80);
        doc.text(`${rowData?.totalInteractiveMinutes || 0} mins`, 110, 87);
        doc.setTextColor(255, 0, 0); // Red for non-interactive minutes
        doc.text('Non-Interactive Minutes:', 160, 80);
        doc.text(`${rowData?.totalNonInteractiveMinutes || 0} mins`, 160, 87);

        // Generate Time Entries Section
        doc.setTextColor(0, 168, 168);
        doc.setFontSize(14);
        doc.text('Time Entries', 10, 100);
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        // rowData?.minutesEntries?.forEach(printEntryDetails);
        rowData?.minutesEntries.map((entry, index) => printEntryDetails(entry, index));

        // Add final footer
        renderFooter(doc, pageNumber);

        // Save or open the PDF
        doc.save(`${rowData?.lastname || ''}_${rowData?.firstname || ''}_minutes.pdf`);
    });
};


  return (
    <>
      <MetaData title="Multi Patient Time Report" />
      <HRSidebar />

      <section className="home-section">
        {/* TopBar */}
        <HRTopBar />

        <br />
        <div className="shadow-lg p-3 m-2 background-white rounded">
          <div className="home-content">
            <div>
              <div className="row-display">

                <h5 className='title'>
                  <i className='bx bx-filter'></i> 
                  &nbsp;Time Report for RPM & CCM 
                </h5>

                <div className="row-display">
                  <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                    <i className='bx bx-arrow-back'></i>
                  </button>

                  &nbsp;&nbsp;
                  <Link to="/Nurse/Report/TimeReport" className="link">
                    Individual Patient Time Report
                  </Link>

                  &nbsp;&nbsp;
                  <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm">
                    <i className='bx bxs-home'></i>
                  </Link>
                </div>
              </div>
              <hr />


              <div className="row-display mt-3 reportFormFields">
                <div>
                  <label className="form-label" htmlFor="year">Mins. Category </label>
                  <select
                    name="category"
                    className="form-control"
                    value={reportCategory}
                    onChange={e => setReportCategory(e.target.value)}
                    style={{ width: '200px' }}
                  >
                    <option value="RPM">RPM</option>
                    <option value="CCM">CCM</option>
                  </select>
                </div>

                <div>
                  <label className="form-label" htmlFor="from">Select Doctor </label>
                  <select 
                    className="form-control" 
                    value={doctorId} 
                    onChange={e => setDoctorId(e.target.value)}
                    style={{ width: '200px' }}
                    >
                    <option value="default">Select Doctor</option>
                    {assignedDoctors && assignedDoctors.map((doctor, index) => (
                      <option key={index} value={doctor?._id}>
                        Dr. {doctor.firstname} {doctor?.lastname}
                      </option>
                    ))}
                  </select>
                </div>

                {reportCategory === 'RPM' ?
                  <div>
                    <label className="form-label" htmlFor="month">Select Month </label>
                    <select
                      name="month"
                      className="form-control"
                      value={month}
                      onChange={e => setMonth(e.target.value)}
                      style={{ width: '200px' }}
                    >
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div> :
                  // CCM minutes 
                  <>
                    <div>
                      <label className="form-label" htmlFor="month">Start Month </label>
                      <select
                        name="month"
                        className="form-control"
                        value={startMonth}
                        onChange={e => setStartMonth(e.target.value)}
                        style={{ width: '200px' }}
                      >
                        <option value="01">January - 26</option>
                        <option value="02">February - 26</option>
                        <option value="03">March - 26</option>
                        <option value="04">April - 26</option>
                        <option value="05">May - 26</option>
                        <option value="06">June - 26</option>
                        <option value="07">July - 26</option>
                        <option value="08">August - 26</option>
                        <option value="09">September - 26</option>
                        <option value="10">October - 26</option>
                        <option value="11">November - 26</option>
                        <option value="12">December - 26</option>
                      </select>
                    </div>


                    <div>
                      <label className="form-label" htmlFor="month">End Month </label>
                      <select
                        name="month"
                        className="form-control"
                        value={endMonth}
                        onChange={e => setEndMonth(e.target.value)}
                        style={{ width: '200px' }}
                      >
                        <option value="01">January - 25</option>
                        <option value="02">February - 25</option>
                        <option value="03">March - 25</option>
                        <option value="04">April - 25</option>
                        <option value="05">May - 25</option>
                        <option value="06">June - 25</option>
                        <option value="07">July - 25</option>
                        <option value="08">August - 25</option>
                        <option value="09">September - 25</option>
                        <option value="10">October - 25</option>
                        <option value="11">November - 25</option>
                        <option value="12">December - 25</option>
                      </select>
                    </div>
                  </>
                }


                <div>
                  <label className="form-label" htmlFor="year">Select Year </label>
                  <select
                    name="year"
                    className="form-control"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                    style={{ width: '200px' }}
                  >
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>

                <div className="mt-1" style={{ float: 'right' }}>
                  <button
                    name="submit"
                    type="submit"
                    className="edit-pill-2 mt-4"
                    onClick={submitHandler}
                  >
                    <i className='bx bx-paper-plane'></i> Generate Report
                  </button>
                </div>
              </div>  {/* First Row Ends Here */}
              <hr />

              
                
                <div>
                {patientlist && patientlist?.length > 0 ? <>
                  <div className="row-display ml-2">
                    <div>
                      <h5 className="title">{count && count < 10 ? '0'+count : count} Results Found.</h5>
                      <p className="m-0 p-0">You can download multiple reports at a time, check the rows you want to download.</p>
                    </div>  

                    {selectedRows?.length > 0 ? <>
                      <div>
                        <button 
                            className="edit-pill mr-3 m-2" 
                            onClick={handleDownloadPDF} 
                            style={{backgroundColor: 'green'}}
                        >
                            Download {selectedRows?.length < 10 ? '0'+selectedRows?.length : selectedRows?.length} Patient(s) Report
                        </button>

                        <button 
                            className="edit-pill mr-3 m-2" 
                            onClick={resetSelection} 
                            style={{backgroundColor: 'red'}}
                        >
                            Reset
                        </button>
                        </div>
                    </> : null}
                    </div>
                </> : null}
                
              </div>

              {/* Report results starts from here */}
              {loading ? <Loader /> : <>
                {patientlist && patientlist?.length > 0 ? <>
                  <div className="col-md-12">
                    <br />
                    <table className="styled-table">
                      <thead align="center">
                        <tr>
                          <th></th>
                          <th>Sr.</th>
                          <th>PatientName & EHR-ID</th>
                          <th>D.O.B</th>
                          <th>Total Minutes</th>
                          <th>Month & Year</th>
                          <th>Category</th>
                        </tr>
                      </thead>

                      <tbody>
                      {patientlist && patientlist.map((pts, index) => (
                        <tr key={index} className={pts?.totalMinutesSpent && pts?.totalMinutesSpent > 0 ? 'coloredRow' : 'reddishRow'}>
                             <td>
                                 <label className="checkbox-wrapper">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(pts?._id)}
                                        onChange={() => handleRowCheck(pts?._id)}
                                        disabled={pts?.totalMinutesSpent > 0 ? false : true}
                                    />
                                <div className="checkbox-custom"></div>
                                </label>
                              </td>

                            <td>{index + 1}</td>

                            <td>
                              <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?._id}}} className="link">
                                {pts?.lastname + " , "+  pts?.firstname} </Link>
                                <p><b>{pts?.emrId}</b></p>      
                            </td>

                            <td> <b>{moment(pts?.DOB).format("MM-DD-YYYY")}</b> </td>

                            <td><b>{pts?.totalMinutesSpent}</b></td>

                            <td><b>{month && year}</b></td>

                            <td><b>{reportCategory && reportCategory}</b></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                    
                    <br />
                    {patientlist?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateListPagination()}>Load more</button>
                            </div>
                        </> }
                  </div>
                </> : null
                }


              </>
              }
            </div>
          </div>
        </div>


      </section>
    </>
  )
}

export default HRTimeReportMonthlyLog