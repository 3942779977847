import "./alterbox.css"
import { Modal, Button } from 'react-bootstrap';

export default function BaseModal({
                                      className,
                                      innerClassName,
                                      isBoxOpen, setIsBoxOpen,
                                      autoClose = true,
                                      defaultHeader = true,
                                      title,
                                      ...props
                                  }) {

    function close() {
        setIsBoxOpen(false)
    }

    return (
        <Modal show={isBoxOpen} onHide={close} className={className} centered>
            <Modal.Header closeButton={autoClose}>
                <Modal.Title>{title}</Modal.Title>

            </Modal.Header>
            <Modal.Body className={innerClassName}>
                {props.children}
            </Modal.Body>
        </Modal>
    )
}
