import {Button} from "react-bootstrap";

export default function Page404() {
    const Header = function () {
        return <h1 className="text-white">404 Not found</h1>;
    };

    function Display() {
        return (
            <div className="display">
                <div className="display__img">
                    <img className={"w-25"} src={require("../../assets/Images/scareCrow.png")} alt="404-Scarecrow" />
                </div>
                <div className="display__content">
                    <br />
                    <h2 className="display__content--info">Let's navigate back to healthy content.</h2>
                    <p className="display__content--text">
                        The page you are looking for might be removed or is temporarily
                        unavailable
                    </p>
                    <Button onClick={()=>{
                        window.history.back();
                    }} className="btn bg-gradient-3 shadow ">Go Back</Button>
                </div>
            </div>
        );
    }

    return (

        <div className=" vh-100 w-100  position-absolute top-0 start-0 bg-gradient h-100 d-flex  flex-column text-center py-5 h-100 justify-content-center ">
            <Header />
            <Display />
        </div>
    )
}
