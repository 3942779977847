import {useEffect} from 'react'
import  Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useSelector } from 'react-redux';
import moment from 'moment';
import doctorProfileImg from '../../assets/Images/patientProfile.png';
import { useHistory } from 'react-router-dom';

const StaffProfile = () => {

    const history = useHistory();
    const { loading, isAuthenticated, user} = useSelector(state => state.auth);
    
    useEffect(() => {
		
		if(isAuthenticated === false) {
			history.push("/doctor/login");
		}

	}, [isAuthenticated])

    return (
        <>
            <MetaData title="Doctors Profile"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />
                
                    {loading ? <Loader /> : <>
                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">
                        <div className="row-display">
                                <h5 className="title">My Profile </h5> 

                                <button className="btn btn-secondary btn-sm" 
                                onClick={() => history.goBack()}>
                                    <i className='bx bx-arrow-back'></i>
                                </button>
                        </div>
                         <hr/>
                        

                         {user && <>
                            <div className="row">
                            <div className="col-md-4">
                                <div className='text-center'>
                                    <img src={doctorProfileImg} className="profile-img"/>
                                        
                                    <p className="profile-name">Dr. {user?.firstname} {user?.lastname} </p>
                                    {user?.verified === true ? <p style={{color: 'green'}}>Verified</p> : <p style={{color: 'red'}}>Not Verified</p>}
                                    
                                    {user.specialization && user?.specialization.map((spec, index) => (
                                        
                                            <div key={index}>
                                                <p className="doctor-specilizations">{spec?.fieldname }</p>
                                                <br />
                                            </div>
                        
                                    ))}
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div>
                                    <div className="card-inner-margin">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <span className="profile-label">Email: </span>
                                                <p>{user?.email}</p>

                                                <span className="profile-label">Gender: </span>
                                                <p>{user?.gender}</p>

                                                <span className="profile-label">Contact #: </span>
                                                <p>{user?.contactno || 'N/A'}</p>

                                                <span className="profile-label">Phone # 1: </span>
                                                <p>{user?.phone1 || 'N/A'}</p>
                                            </div>


                                            <div className="col-md-6">
                                            <span className="profile-label">Phone # 2: </span>
                                                <p> {user?.phone2 || 'N/A'} </p>

                                                <span className="profile-label">License #: </span>
                                                <p>{user?.npinumber}</p>

                                                <span className="profile-label">NPI #: </span>
                                                <p>{user?.licensenumber}</p>

                                                <span className="profile-label">Account Created Date: </span>
                                                <p>{moment(user?.createdAt).format("lll")}</p>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                        </>}
                    </div>
                    </div>
                    </>
                    }
            </section>
        </>
    )
}

export default StaffProfile
