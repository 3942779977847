import {useEffect} from 'react';
import  Sidebar from '../../components/Assistant/Sidebar';
import MetaData from '../../layouts/MetaData';
import TopBar from '../../components/Assistant/Topbar';
import { useSelector, useDispatch } from 'react-redux';
import { getAssistantStats } from '../../actions/assistantActions';
import AssistantCriticalReadingsComponent from '../../components/Assistant/AssistantCriticalReadingsComponent';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import { getOrgProfile } from '../../actions/adminActions'; 


const AssistantDashboard = () => {
    
    const dispatch = useDispatch();

    const { totalPatients, RPM_Patients, CCM_Patients } = useSelector(state => state.asstStats);
    const { user} = useSelector(state => state.auth);
    const { organization } = useSelector(state => state.orgProfile);
    
    // let asstAssignedDr = localStorage.getItem('AssistantDr');

    let drIds = localStorage.getItem('myArray');

    useEffect(() => {
        if(user?.assigned_org_id){
            dispatch(getOrgProfile(user?.assigned_org_id?._id));
        }

        dispatch(getAssistantStats(drIds));

        
  }, [dispatch]);


    return (
    <>
        <MetaData title="Assistant Dashboard" />
            <Sidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <TopBar />

                <div className="row-display">
                <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard greenCardDashboardStatsCard">
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <h1>{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients }</h1>
                            <div>
                            <b>Total Patients</b><br/>
                        <small>Updated Just now</small>
                        </div>
                        </div>   
                    </div>
                    </div>
                    
                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard orangeCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{RPM_Patients && RPM_Patients < 10 ? '0'+RPM_Patients : RPM_Patients}</h1>
                                <div>
                                <b>RPM Patients</b><br/>
                            <small>Updated Just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard blueCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{CCM_Patients && CCM_Patients < 10 ? '0'+CCM_Patients : CCM_Patients}</h1>
                                <div>
                                <b>CCM Patients</b><br/>
                            <small>Updated Just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                        <div className="card dashboardStatsCard redCardDashboardStatsCard">
                             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                <img src={doctorIcon} width="58px" height="57px" />
                                 <div>
                                    <h5>Assigned Organization</h5>
                                    <p className='m-0 p-0'>{user && user?.assigned_org_id?.name} </p>
                                </div>
                             </div>   
                        </div>
                    </div>

                </div>
                
                {user?.assigned_org_id && drIds?.length > 0 && <AssistantCriticalReadingsComponent /> }

            </section>
    </>
  )
}

export default AssistantDashboard