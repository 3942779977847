import axios from 'axios';
import { Prod01 } from '../constants/ActionType';
import moment from 'moment';

import {
    HR_PATIENTS_REQUEST,
    HR_PATIENTS_SUCCESS,
    HR_PATIENTS_FAIL,
    ADDING_COMMENT_REQUEST,
    ADDING_COMMENT_SUCCESS,
    ADDING_COMMENT_FAIL,
    ADDING_TIME_SPENT_SUCCESS,
    ADDING_TIME_SPENT_FAIL,
    TIME_REPORT_REQUEST,
    TIME_REPORT_SUCCESS,
    TIME_REPORT_FAIL,
    TARGETED_MINUTES_REQUEST,
    TARGETED_MINUTES_SUCCESS,
    TARGETED_MINUTES_FAIL,
    INITIAL_MONTH_REPORT_REQUEST,
    INITIAL_MONTH_REPORT_SUCCESS,
    INITIAL_MONTH_REPORT_FAIL,
    ADDING_CARE_PLAN_SUCCESS,
    ADDING_CARE_PLAN_FAIL,
    PATIENT_CARE_PLAN_SUCCESS,
    PATIENT_CARE_PLAN_FAIL,
    UPDATE_CARE_PLAN_SUCCESS,
    UPDATE_CARE_PLAN_FAIL,
    TIME_SPENT_OF_CURRENT_MONTH_SUCCESS,
    TIME_SPENT_OF_CURRENT_MONTH_FAIL,
    GET_CAREPLAN_LIST_REQUEST,
    GET_CAREPLAN_LIST_SUCCESS,
    GET_CAREPLAN_LIST_FAIL,
    HR_STATS_SUCCESS,
    HR_STATS_FAIL,
    TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_SUCCESS,
    TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_FAIL,
    CLEAR_ERRORS,
    GET_CCM_CONSENT_REQUEST,
    GET_CCM_CONSENT_SUCCESS,
    GET_RPM_CONSENT_REQUEST,
    GET_RPM_CONSENT_SUCCESS,
    GET_CCM_CAREPLAN_REQUEST,
    GET_CCM_CAREPLAN_SUCCESS,
    CHAT_PATIENT_REQUEST,
    CHAT_PATIENT_SUCCESS,
    CHAT_PATIENT_FAIL,
    NURSE_RECENT_READINGS_SUCCESS,
    MONTHLY_LOG_REPORT_REQUEST,
    MONTHLY_LOG_REPORT_SUCCESS,
    MONTHLY_LOG_REPORT_FAIL

} from '../constants/HRConstants';

import { 
    SHOW_ALERT_MESSAGE,
    HIDE_ALERT_MESSAGE,
    FETCH_ERROR
} from '../constants/Common';



export const getHRPatients = (nurseDrs, pagination, currentPage) => async (dispatch) => {
    try {
        dispatch({
            type: HR_PATIENTS_REQUEST,
        })


        const { data } = await axios.post(`${Prod01}/hr/patientlist/${pagination}/${currentPage}`, {
            nurseDrs
         },{
           headers: {
           'Content-Type': 'application/json',
           }
           }, {
           timeout: 10000 // 10 seconds
         });

        dispatch({
            type: HR_PATIENTS_SUCCESS,
            payload: data
        })


    } catch (error) {
        if (error.code === 'ECONNABORTED') {
            dispatch({
                type: HR_PATIENTS_FAIL,
                payload: 'request timed out'
            });
        } else {
            dispatch({
                type: HR_PATIENTS_FAIL,
                payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
            });
        }
    }
}

export const getPatientChat = (PatientId, id) => async (dispatch) => {
    try {
        dispatch({
            type: CHAT_PATIENT_REQUEST,
        })


        const { data } = await axios.post(`${Prod01}/general/getMessages`, {
            patientId: PatientId, 
            nurseId: id
        });

        dispatch({
            type: CHAT_PATIENT_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CHAT_PATIENT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const submitPatientChat = (PatientId, nurseId, message) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${Prod01}/general/sendMessage`, {
            patient_id: PatientId, 
            nurse_id: nurseId,
            message: message
        });

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Message Sent"
            });
        
        dispatch({
            type: HIDE_ALERT_MESSAGE
        })     

        dispatch(getPatientChat(PatientId, nurseId));


    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Server Error! Cannot send message'
          })
        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
    }
}


export const getHRCCMPatients = (nurseDrs, resPerPage, currentPage) => async (dispatch) => {

        try {
            dispatch({
                type: HR_PATIENTS_REQUEST,
            })
    
            const { data } = await axios.post(`${Prod01}/general/ccmpatients/list/${resPerPage}/${currentPage}`, {
                nurseDrs
            },{
                headers: {
                'Content-Type': 'application/json',
              }
            }, {
                timeout: 10000 // 10 seconds
            });
    
            dispatch({
                type: HR_PATIENTS_SUCCESS,
                payload: data
            })
    
    
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: HR_PATIENTS_FAIL,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: HR_PATIENTS_FAIL,
                    payload: error?.response && error?.response?.data ? error?.response?.data?.message : 'An error occurred'
                });
            }
        }    
    }


export const getHRStats = (hrId, nurseDrs) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${Prod01}/hr/hrStats`, {
            hrId: hrId,
            nurseDrs
        },{
            headers: {
            'Content-Type': 'application/json',
            }
            }, {
            timeout: 10000 // 10 seconds
          }
        );

        dispatch({
            type: HR_STATS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: HR_STATS_FAIL,
            payload: error.response.data.message
        })
    }
}

// HR comment on reading
export const commentOnReading = (readingId, hrId, comment, patientId, notes) => async (dispatch) => {

    try {
        dispatch({
            type: ADDING_COMMENT_REQUEST
        });

        const { data } = await axios.put(`${Prod01}/patient/commentonreading/${readingId}`, {
            conclusion_hr_id: hrId,
            conclusion: comment,
            dateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z")
        });

        dispatch({
            type: ADDING_COMMENT_SUCCESS,
            payload: data
        });

        await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
            assigned_patient_id: patientId,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            startTime: moment().tz("America/New_York").format("HH:mm"),
            endTime: moment().tz("America/New_York").add(1, 'minutes').format("HH:mm"),
            interactiveMinutes: true,
            timeSpentInMinutes: 1,
            conclusion: notes,
            encounterType: 'review'
        });


    } catch (error) {
        dispatch({
            type: ADDING_COMMENT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const commentOnReadingByStaff = (readingId, staffId, comment) => async (dispatch) => {

    try {
        dispatch({
            type: ADDING_COMMENT_REQUEST
        });

        const { data } = await axios.put(`${Prod01}/patient/commentonreading/${readingId}`, {
            conclusion_doctor_id: staffId,
            conclusion: comment,
            dateTime: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z")
        });

        dispatch({
            type: ADDING_COMMENT_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ADDING_COMMENT_FAIL,
            payload: error.response.data.message
        })
    }
}



// Time Spent on Patient
export const timeSpentOnPatientByDr = (patientId, drId, startDate, endDate, startTime, endTime, isCCM, interactiveMinutes, minutes, note, fileName, eType) => async (dispatch) => {
    

    let data = {};
    try {
        if(isCCM == false){

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                }
                )
            }

             data = await axios.post(`${Prod01}/doctor/addtimeforpatient/${drId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName:fileName.name,
                encounterType: eType
            });
        } else {

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                }
                )
            }

            data = await axios.post(`${Prod01}/doctor/addtimeforpatient/${drId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                isCCM:isCCM,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName: fileName.name,
                encounterType: eType
            });
        } 
        
        dispatch({
            type: ADDING_TIME_SPENT_SUCCESS,
            payload: data
        });
     
    } 
   
    catch (error) {
        dispatch({
            type: ADDING_TIME_SPENT_FAIL,
            payload: error.message
        })
    }
}



// Time Spent on Patient
export const timeSpentOnPatient = (patientId, hrId, startDate, endDate, startTime, endTime, isCCM, interactiveMinutes, minutes, note, fileName, eType) => async (dispatch) => {
    

    let data = {};
    try {
        if(isCCM == false){

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                }
                )
            }

             data = await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName:fileName.name,
                encounterType: eType
            });
        } else {

            let formData = new FormData();
            formData.append('file', fileName);

            if (fileName) {
                fetch(`${Prod01}/general/uploadfile`, {
                    method: 'POST',
                    body: formData,
                }
                )
            }

            data = await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
                assigned_patient_id: patientId,
                startDate: startDate,
                endDate: endDate,
                startTime: startTime,
                endTime: endTime,
                isCCM:isCCM,
                interactiveMinutes: interactiveMinutes,
                timeSpentInMinutes: minutes,
                conclusion: note,
                fileName: fileName.name,
                encounterType: eType
            });
        } 
        
        dispatch({
            type: ADDING_TIME_SPENT_SUCCESS,
            payload: data
        });
     
    } 
   
    catch (error) {
        dispatch({
            type: ADDING_TIME_SPENT_FAIL,
            payload: error.message
        })
    }
}

export const timeSpentOnPatientAuto = (patientId, hrId, minutes, description) => async(dispatch) => {
    try {
       const { data } = await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
                assigned_patient_id : patientId,
                timeSpentInMinutes:minutes,
                conclusion: description
           });    
           
        dispatch({ 
            type: ADDING_TIME_SPENT_SUCCESS,
            payload: data
        });
        
    } catch (error) {
       dispatch({
           type: ADDING_TIME_SPENT_FAIL,
           payload: error.message
       })
    }   
}


export const carePlanOfPatient = (patientId, hrId, description, readingsPerMonth, readingsInDay, readingsInNight, fileName) => async (dispatch) => {
    let data;
    try {
    
        data = await axios.post(`${Prod01}/patient/addCarePlan`, {
            assigned_hr_id: hrId,
            assigned_patient_id: patientId,
            Description: description,
            readingsPerMonth: readingsPerMonth,
            readingsInSlot1: readingsInDay,
            readingsInSlot2: readingsInNight,
            fileName: fileName.name
        });


        let formData = new FormData();
        formData.append('file', fileName);

        if (fileName) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
              }
            )
        }

        dispatch({
            type: ADDING_CARE_PLAN_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ADDING_CARE_PLAN_FAIL,
            payload: 'Care plan already exists'
        })
    }
}

export const getPatientRPMTargetedMinutes = (patientId, month, year, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TARGETED_MINUTES_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltime/${pagination}`, {
            patientId: patientId,
            month: month,
            year: year
        });

        dispatch({
            type: TARGETED_MINUTES_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TARGETED_MINUTES_FAIL,
            payload: error.message
        })
    }
}


export const getTimeReport = (patientId, month, year, encounterType, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltime/${pagination}`, {
            patientId: patientId,
            month: month,
            year: year,
            encounterType: encounterType
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportByHR = (hrId, month, year, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltime/${pagination}`, {
            hrId: hrId,
            month: month,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportByDate = (patientId, startDate, category) => async (dispatch) => {
    try {     
            dispatch({
                type: TIME_REPORT_REQUEST
            });

            const { data } = await axios.post(`${Prod01}/admin/patientTimeReport`, {
                patientId: patientId,
                startDate: startDate,
                isCCM: category === 'CCM' ? true : null
            });
    
            dispatch({
                type: TIME_REPORT_SUCCESS,
                payload: data,
            });
    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportByDateRange = (patientId, startDate, endDate, category) => async (dispatch) => {
    try {     
            dispatch({
                type: TIME_REPORT_REQUEST
            });

            const { data } = await axios.post(`${Prod01}/admin/patientTimeReport`, {
                patientId: patientId,
                startDate: startDate,
                endDate: endDate,
                isCCM: category === 'CCM' ? true : null
            });
    
            dispatch({
                type: TIME_REPORT_SUCCESS,
                payload: data,
            });
    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}


export const getTimeReportByAssistant = (assistantId, month, year) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltime`, {
            assistantId: assistantId,
            month: month,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}


export const getTimeReportByDR = (drId, month, year) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeOfDoctor`, {
            drId: drId,
            month: month,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportCCM = (patientId, month, endMonth, year, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeofCCMCategory/${pagination}`, {
            patientId: patientId,
            month: month,
            endMonth: endMonth,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportCCMByHR = (hrId, month, endMonth, year, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeofCCMCategory/${pagination}`, {
            hrId: hrId,
            month: month,
            endMonth: endMonth,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportCCMByAssistant = (assistantId, month, endMonth, year) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeofCCMCategory`, {
            assistantId: assistantId,
            month: month,
            endMonth: endMonth,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}


export const getTimeReportCCMByDR = (drId, month, year, endMonth) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeOfDoctor`, {
            drId: drId,
            month: month,
            endMonth: endMonth,
            year: year,
            isCCM: true
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportCCMByAll = (month, year, category, endMonth) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/ccmTimeReportByAll`, {
            month: month,
            endMonth: endMonth,
            year: year,
            isCCM: category === 'CCM' ? true : null 
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getTimeReportCCMByPatient = (patientId, month, endMonth, year, pagination) => async (dispatch) => {
    try {
        dispatch({
            type: TIME_REPORT_REQUEST
        });

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeofCCMCategory/${pagination}`, {
            patientId: patientId,
            month: month,
            endMonth: endMonth,
            year: year
        });

        dispatch({
            type: TIME_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const hrTimeSpentOfCurrentMonth = (patientId, hrId, startDate, endDate) => async (dispatch) => {
    try {

        const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltime`, {
            hrId: hrId,
            patientId: patientId,
            startDate: startDate,
            endDate: endDate
        });

        dispatch({
            type: TIME_SPENT_OF_CURRENT_MONTH_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TIME_SPENT_OF_CURRENT_MONTH_FAIL,
            payload: error.message
        })
    }
}

// export const hrTimeSpentOfCurrentMonthinCCMCategory = (patientId, hrId, startDate, endDate) => async (dispatch) => {
//     try {

//         const { data } = await axios.post(`${Prod01}/hr/listtargets&totaltimeofCCMCategory`, {
//             hrId: hrId,
//             patientId: patientId,
//             startDate: startDate,
//             endDate: endDate
//         });

//         dispatch({
//             type: TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_SUCCESS,
//             payload: data,
//         });

//     } catch (error) {
//         dispatch({
//             type: TIME_SPENT_OF_CURRENT_MONTH_IN_CCM_FAIL,
//             payload: error.message
//         })
//     }
// }

export const getInitialMonthReport = (doctorId, month) => async (dispatch) => {
    try {
        dispatch({
            type: INITIAL_MONTH_REPORT_REQUEST
        });

        let data;

        if (month) {
            data = await axios.post(`${Prod01}/general/report/initialsetup`, {
                month: month+"/"+"2024"
            });
        }

        if (month && doctorId) {
            data = await axios.post(`${Prod01}/general/report/initialsetup`, {
                month: month+"/"+"2024",
                doctorId: doctorId
            });
        }


        dispatch({
            type: INITIAL_MONTH_REPORT_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: INITIAL_MONTH_REPORT_FAIL,
            payload: error.message
        })
    }
}

export const getPatientCarePlan = (patientId) => async (dispatch) => {
    try {

        const data = await axios.get(`${Prod01}/patient/CarePlan/${patientId}`);

        dispatch({
            type: PATIENT_CARE_PLAN_SUCCESS,
            payload: data,
        });


    } catch (error) {
        dispatch({
            type: PATIENT_CARE_PLAN_FAIL,
            payload: error.message
        })
    }
}

export const getHRCareplans = (hrId) => async (dispatch) => {
    try {

        dispatch({
            type: GET_CAREPLAN_LIST_REQUEST
        })

        const { data } = await axios.post(`${Prod01}/patient/CarePlanbydrhr`, {
            "hr_Id": hrId
        });

        dispatch({
            type: GET_CAREPLAN_LIST_SUCCESS,
            payload: data,
        });


    } catch (error) {
        dispatch({
            type: GET_CAREPLAN_LIST_FAIL,
            payload: error.message
        })
    }
}

export const updateCarePan = (description, readingsPerMonth, readingsPerDay, careplanId) => async (dispatch) => {
    try {
        const { data } = await axios.put(`${Prod01}/patient/editcareplan/${careplanId}`, {
            Description: description,
            readingsPerMonth: readingsPerMonth,
            readingsPerDay: readingsPerDay
        });

        dispatch({
            type: UPDATE_CARE_PLAN_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_CARE_PLAN_FAIL,
            payload: error.message
        })
    }
}

export const changePassword = (id, oldPassword, newPassword) => async(dispatch) => {
    try {
        
    await axios.put(`${Prod01}/hr/changePassword/${id}`, {
        oldPassword: oldPassword,
        password: newPassword
    });

    dispatch({
        type: SHOW_ALERT_MESSAGE,
        payload: "Password Updated"
    });
        
    dispatch({
        type: HIDE_ALERT_MESSAGE
    }) 
    

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message
        })
    }
}

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    })
}

export const submitCCMConsent = (fileName, user, patientId, type, description) => async(dispatch) => {
    try { 
        let formData = new FormData();
            formData.append('file', fileName);

        if (fileName) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
            }
            )
        }

        await axios.post(`${Prod01}/patient/add/CCMConsent`,{
            assigned_patient_id: patientId,
            addedBy: user?.firstname ? user?.firstname +' '+ user?.lastname : user?.name,
            fileName: type === 'Verbal' ? " " : fileName.name,
            assigned_hr_id: user?._id,
            type: type,
            consentType: 'CCM',
            description: type === "Verbal" ? description : null   
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "CCM Consent Added"
            });
                
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to add consent'
          })
    }
}

export const submitRPMConsent = (fileName, user, patientId, type, description) => async(dispatch) => {
    try { 
        let formData = new FormData();
            formData.append('file', fileName);

        if (fileName) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
            }
            )
        }

        await axios.post(`${Prod01}/patient/add/CCMConsent`,{
            assigned_patient_id: patientId,
            addedBy: user?.firstname ? user?.firstname +' '+ user?.lastname : user?.name,
            fileName:fileName.name,
            assigned_hr_id: user?._id,
            type: type,
            consentType: 'RPM',
            description: type === "Verbal" ? description : null   
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "RPM Consent Added"
            });
            
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to add RPM consent'
          })
    }
}


export const submitCCMCareplan = (fileName, user, patientId, description) => async(dispatch) => {
    try { 
        let formData = new FormData();
            formData.append('file', fileName);

        if (fileName) {
            fetch(`${Prod01}/general/uploadfile`, {
                method: 'POST',
                body: formData,
            }
            )
        }

        await axios.post(`${Prod01}/patient/add/CCMCarePlan`,{
            assigned_patient_id: patientId,
            addedBy: user?.firstname ? user?.firstname +' '+ user?.lastname : user?.name,
            fileName:fileName.name,
            description: description
        }, );

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "CCM Careplan Added"
        });
        
                
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to add consent'
          })
    }
}

export const getPatientCCMConsent = (patientId, consentType) => async(dispatch) => {
    if(consentType === 'CCM'){
        try { 
            dispatch({
                type: GET_CCM_CONSENT_REQUEST
            });
    
            const {data} = await axios.get(`${Prod01}/patient/get/CCMConsent/${patientId}/${consentType}`);
    
            dispatch({
                type: GET_CCM_CONSENT_SUCCESS,
                payload: data
            })
    
        } catch (error) {
            return
            // dispatch({
            //     type: FETCH_ERROR,
            //     payload: error.message
            // })
        }
    } else {
        try { 
            dispatch({
                type: GET_RPM_CONSENT_REQUEST
            });
    
            const {data} = await axios.get(`${Prod01}/patient/get/CCMConsent/${patientId}/${consentType}`);
    
            dispatch({
                type: GET_RPM_CONSENT_SUCCESS,
                payload: data
            })
    
        } catch (error) {
            return
            // dispatch({
            //     type: FETCH_ERROR,
            //     payload: error.message
            // })
        }
    }
}

export const getPatientCCMCareplan = (patientId) => async(dispatch) => {
    try { 
        dispatch({
            type: GET_CCM_CAREPLAN_REQUEST
        });

        const {data} = await axios.get(`${Prod01}/patient/get/CCMCarePlan/${patientId}`);

        dispatch({
            type: GET_CCM_CAREPLAN_SUCCESS,
            payload: data
        })

    } catch (error) {
        return
    }
}

export const deleteCCMCareplan = (careplanId) => async(dispatch) => {
    try { 
        await axios.delete(`${Prod01}/patient/remove/CCMCarePlan/${careplanId}`);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "CCM Careplan Deleted"
            });
        
                
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to delete CCM Careplan'
          })
    }
}

export const deleteCCMConsent = (consentId) => async(dispatch) => {
    try { 
        await axios.delete(`${Prod01}/patient/delete/CCMConsent/${consentId}`);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "CCM Consent Deleted"
            });
            
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to delete CCM Consent'
          })
    }
}

export const deleteRPMConsent = (consentId) => async(dispatch) => {
    try { 
        await axios.delete(`${Prod01}/patient/delete/CCMConsent/${consentId}`);

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "RPM Consent Deleted"
            });
        
                
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to delete CCM Consent'
          })
    }
}


export const getNursePtsRecentReadings = (pagination, nurseDrs) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${Prod01}/hr/recentReadings/${pagination}`, {
            nurseDrs
        },{
            headers: {
            'Content-Type': 'application/json',
            }
            },
            {
            timeout: 10000 // 10 seconds
        });

        dispatch({
            type: NURSE_RECENT_READINGS_SUCCESS,
            payload: data
        });


    } catch (error) {
        
    }
}

export const addExtraNote = (userId, extraNote, patientId, role) => async(dispatch) => {
    try { 
        const roleKey = role === 'HrMedical' ? 'assigned_hr_id' :
                        role === 'assistant' ? 'assigned_assistant_id' :
                        role === 'admin' ? 'adminId' : null;

        if(roleKey) {
            await axios.post(`${Prod01}/hr/add/extraNote/${patientId}`, {
                [roleKey]: userId,
                note: extraNote,
                createdAt: moment().tz("America/New_York").format("MM-DD-YYYY hh:mm a Z")
            });

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Note Added"
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Cannot add a new note'
        });
    }
}


export const deleteExtraNote = (patientId, noteId, isDeleted) => async(dispatch) => {
    try { 
            await axios.post(`${Prod01}/hr/extraNote/${noteId}`, {
                patientId: patientId,
                toggleValue: isDeleted === true ? false : true
            });

            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Note Modified"
            });

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Error in modifying a note'
        });
    }
}


export const getTimeReportMonthlyLog = (drId, category, month, year, pagination, endMonth) => async(dispatch) => {
    try { 
        if(category === 'RPM'){
            dispatch({
                type: MONTHLY_LOG_REPORT_REQUEST
            });

            const {data} = await axios.post(`${Prod01}/hr/rpmccmmonthlylog/${pagination}`, {
                drId: drId,
                month: month,
                year: year
            });
    
            dispatch({
                type: MONTHLY_LOG_REPORT_SUCCESS,
                payload: data
            });
            

        } else if (category === 'CCM'){
            dispatch({
                type: MONTHLY_LOG_REPORT_REQUEST
            });

            const {data} = await axios.post(`${Prod01}/hr/rpmccmmonthlylog/${pagination}`, {
                drId: drId,
                month: month,
                endMonth: endMonth,
                year: year,
                isCCM: true
            });
    
            dispatch({
                type: MONTHLY_LOG_REPORT_SUCCESS,
                payload: data
            });
        }
            
    } catch (error) {
        dispatch({
            type: MONTHLY_LOG_REPORT_FAIL,
            payload: 'Error in getting response'
          })
    }
}


export const addClinicalFollowUp = (patientId, hrId, formData, month, year, day) => async (dispatch) => {
    try {
        const isAdded = await axios.post(`${Prod01}/patient/recordFollowUp/${patientId}`, {
            day,
            month,
            year,
            type: "clinicalFollowUp"
        });

        if(isAdded) {
            await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
                assigned_patient_id: patientId,
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                startTime: moment().tz("America/New_York").format("HH:mm"),
                endTime: moment().tz("America/New_York").add(20, 'minutes').format("HH:mm"),
                interactiveMinutes: true,
                timeSpentInMinutes: 20,
                conclusion: 'Clinical Followup',
                encounterType: 'call',
                isCCM: true,
                completeCallFollowUpStatus:"clinicalFollowUpOnly",
                clinicalFollowUp: formData
            });        
    
            dispatch({
                type: SHOW_ALERT_MESSAGE,
                payload: "Clinical Followup Added."
            })
        }
        

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update patient'
          })        
    }
}

export const addCCMCareplanFollowUp = (patientId, hrId, notes, month, year, day) => async (dispatch) => {
    try {
        await axios.post(`${Prod01}/patient/recordFollowUp/${patientId}`, {
            day,
            month,
            year,
            type: 'ccmCareplanFollowUp'
        });
        
        await axios.post(`${Prod01}/hr/addtimeforpatient/${hrId}`, {
            assigned_patient_id: patientId,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            startTime: moment().tz("America/New_York").format("HH:mm"),
            endTime: moment().tz("America/New_York").add(30, 'minutes').format("HH:mm"),
            interactiveMinutes: true,
            timeSpentInMinutes: 30,
            conclusion: 'Careplan Followup',
            encounterType: 'call',
            isCCM: true,
            completeCallFollowUpStatus:"careplanFollowUpOnly",
            careplanFollowUp: notes
        });        

        dispatch({
            type: SHOW_ALERT_MESSAGE,
            payload: "Careplan Followup Added."
        })

    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: 'Unable to update patient'
          })        
    }
}