import {useEffect} from 'react'
import  Sidebar from '../../components/Staff/Sidebar';
import MetaData from '../../layouts/MetaData';
import TopBar from '../../components/Staff/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorStats } from '../../actions/adminActions';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import StaffCriticalReadings from '../../components/Staff/StaffCriticalReadings';
    


const StaffDashboard = (props) => {

    const { user, isAuthenticated} = useSelector(state => state.auth);
    
    const {totalPatients, RpmPatients, CcmPatients} = useSelector(state => state.doctorStats);

    let id = user._id;

    const dispatch = useDispatch();

    useEffect(() => {	
		if(isAuthenticated === false) {
			props?.history?.push("/auth/signin/doctor");
		}

        dispatch(getDoctorStats(id));

	}, [isAuthenticated])


    return (
        <>
            <MetaData title="Doctor Dashboard" />
            <Sidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <TopBar />

                <div className="row-display">
                    <div className="col-md-3 mt-2">
                        <div className="card dashboardStatsCard blueCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                <h1>{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients}</h1>
                                <div>
                                <b>Assigned Patients</b><br/>
                                <small>Updated Just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard orangeCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>{RpmPatients && RpmPatients < 10 ? '0'+RpmPatients : RpmPatients}
                            </h1>
                            <div>
                            <b>RPM Patients</b><br/>
                            
                            <small> Updated just now</small>
                            </div>
                            </div>   
                    </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                    <div className="card dashboardStatsCard greenCardDashboardStatsCard">
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <h1>
                            {CcmPatients && CcmPatients < 10 ? '0'+CcmPatients : CcmPatients}
                            </h1>
                                <div>
                                <b>CCM Patients</b><br/>
                                <small> Updated just now</small>
                            </div>
                            </div>   
                        </div>
                    </div>

                    <div className="col-md-3 dashboardStatsCard">
                        <div className="card dashboardStatsCard redCardDashboardStatsCard">
                             <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                <img src={doctorIcon} width="58px" height="57px" />
                                 <div>
                                    <h5>Assigned Org.</h5>
                                    <p className='m-0 p-0'>{user && user?.assigned_org_id?.name} </p>
                                </div>
                             </div>   
                        </div>
                    </div>

                </div>

                <br />    
                {/* List of Patients with Critical Data */}
                <div className="container-fluid">
                    <div className="col-md-12">
                        <StaffCriticalReadings assignedDr={id} />
                    </div>
                </div>
                    
                </section>
        </>
    )
}

export default StaffDashboard;
