import { useState, useEffect } from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { assignNurseToOrganization, getHrLists, getHrProfile } from '../../actions/adminActions';
import patientProfileImg from '../../assets/Images/nurse.png';
import {useDispatch, useSelector} from 'react-redux';
import { useAlert } from 'react-alert';
import moment from 'moment';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';


const AssignOrganizationToNurse = (props) => {
    
    const history = useHistory();

    let orginfo = props?.location?.state?.orgInfo;
    
    const orgId = orginfo?._id;

    const dispatch = useDispatch();
    const alert = useAlert();    
    
    const [nurseId, setNurseId] = useState('');

    const { error, hrs } = useSelector(state => state.hrslist);
    const { hrProfile } = useSelector(state => state.hrprofile);

    const { user } = useSelector(state => state.auth);
    const {error : commonError, message } = useSelector(state => state.common);

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(commonError) {
            alert.error(commonError);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(message){
            alert.success(message);
            history.push('/organizations');

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        dispatch(getHrLists());

        if(nurseId){
            dispatch(getHrProfile(nurseId));
        }       
   
    }, [dispatch, alert, error, commonError, message, nurseId]);

    // const assignAssistant = (assistantId) => {
    //     dispatch(assignAssistantToOrganization(orgId, assistantId, user?._id))
    // }

    const funcSetNurseId = (hr) =>{
        setNurseId(hr.value);
    }

    const options = []
    hrs && hrs.length > 0 && hrs.filter(item => item?.block === false).map((hr) => (
        options.push({value:hr?._id , label:[hr?.firstname, hr?.lastname, hr?.npinumber].join(" ") })
    ))

    const assignHandler = (hrId) => {
        dispatch(assignNurseToOrganization(orgId, hrId, user?._id))
    }   
    

    return (
    <>
    <MetaData title="Assign Nurse To Organization"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="col-md-0 shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                <div className="row-display">    
                    <h5 className="title mt-2">
                        <i className='bx bx-plus-medical'></i> <strong>&nbsp;Assign Nurse To {orginfo?.name} </strong>
                    </h5>

                    <div>
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                        <i className='bx bx-home' ></i></Link> &nbsp;
                    </div>
                </div>
                <hr />
                
                <div className="row">
                    <div className="col-md-4">
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bx-search-alt-2' ></i> <strong>&nbsp;Select Nurse </strong>
                        </h5>

                        <br/>    
                        <span><b style={{color: 'red'}}> Note : </b> One nurse could be enrolled in multiple organizations. </span>
                        <br/><br/>
                            <Select
                                options={options}
                                onChange={funcSetNurseId}
                            />
                        
                    </div>
                    

                    <div className="col-md-8">
                        

                        {nurseId && hrProfile && <>
                            <h5 style={{color: '#00A8A8'}} className="mt-4">
                                 <strong>&nbsp;Nurse Details </strong>
                            </h5>
                            <br />
                                    <div className="col-md-12">
                                        <div className="row">
                                            <img src={patientProfileImg} className="patient-profile-card-img" alt="patientProfile" />
                                            <p className="profile-name pl-3 pb-2">{hrProfile?.lastname || '--'}, {hrProfile?.firstname || '--'} </p>
                                        </div>

                                        <br />

                                    <div className="row">
                                        <div className="col-4">    
                                            <span className="profile-label">Email Address: </span>
                                            <p className="profile-value-text" style={{wordWrap: 'break-word'}}>{hrProfile?.email ? hrProfile?.email : 'N/A'}</p>

                                            <br/>
                                            <span className="profile-label">License #: </span>
                                            <p className="profile-value-text">{hrProfile?.licensenumber || 'N/A'}</p>    
                                        </div>

                                        <div className="col-3">    
                                            <span className="profile-label">D.O.B: </span>
                                        <p className="profile-value-text">{moment(hrProfile?.DOB).format("MM/DD/YYYY")}</p>

                                        <br/>
                                        <span className="profile-label">Role: </span>
                                            <p className="profile-value-text">{hrProfile?.role}</p>                                 
                                        </div>
                                        

                                        <div className="col-3">    
                                            <span className="profile-label">Gender: </span>
                                            <p>{hrProfile?.gender === 'male' ? 'Male' : 'Female'}</p>

                                        <br/>
                                        <span className="profile-label">NPI: </span>
                                            <p className="profile-value-text">{hrProfile?.npinumber ? hrProfile?.npinumber : 'N/A'}</p>                                
                                        </div>

                                        {/*  */}
                                        <div className="col-2">    
                                            <span className="profile-label">Ver. Status: </span>
                                            <p>
                                                {hrProfile?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}
                                            </p>

                                        <br/>
                                        <span className="profile-label">Acc. Status: </span>
                                            <p>
                                                {hrProfile?.block === false ? <b style={{color: 'green'}}>Active</b> : <b style={{color: 'red'}}>Deactivated</b>}
                                            </p>                                
                                        </div>
                                    </div>
                                    <hr />

                                    {hrProfile?.organizations?.length > 0 &&
                                    <>
                                    <h5 className="title"> Already Assigned Organization(s) : </h5>
                                     {hrProfile?.organizations?.map((orgs, index) => (
                                        <p key={index} className="m-0 p-0">
                                            <ul className="ml-4">
                                                <li><b>{orgs?.assigned_org_id?.name}</b></li>
                                            </ul>
                                        </p>
                                        ))
                                      }
                                    </>
                                    }
                            </div>

                          
                            <button className="edit-pill mr-5" 
                            style={{ float: 'right' }} 
                            onClick={() => assignHandler(hrProfile?._id)}
                            > Assign Nurse</button>
                                        
                            
                                         
                            </>}
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AssignOrganizationToNurse