import { 
    REQUEST_ASSISTANTS,
    REQUEST_ASSISTANTS_SUCCESS,
    REQUEST_ASSISTANTS_FAIL,
    ASSISTANT_STATS_SUCCESS,
    ASSISTANT_STATS_FAIL,
    ASSISTANT_PROFILE_REQUEST,
    ASSISTANT_PROFILE_SUCCESS,
    ASSISTANT_PROFILE_FAIL,
    ASSISTANT_PATIENTS_REQUEST,
    ASSISTANT_PATIENTS_SUCCESS,
    ASSISTANT_PATIENTS_FAIL,
    CLEAR_ERRORS,
} from '../constants/assistantConstants';


export const assistantListReducers = (state = {assistants:{}}, action) => {
    switch (action.type) {
        case REQUEST_ASSISTANTS: 
            return {
                loading: true,
            }
        
        case REQUEST_ASSISTANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                assistants: action.payload.data
            }

        case REQUEST_ASSISTANTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS: 
        return {
            ...state,
            error: null   
        }    

        default: 
            return state; 
    } 
}

// Assistant Stats Reducer
export const assistantStatsReducers = (state = { totalPatients:0, RPM_Minutes: 0, CCM_Minutes:0  }, action) => {
    switch (action.type) {
        case ASSISTANT_STATS_SUCCESS: 
            return {
                loading: false,
                totalPatients: action.payload.total_Patients,
                RPM_Patients: action.payload.RPM_Patients,
                CCM_Patients: action.payload.CCM_Patients
            }

        case ASSISTANT_STATS_FAIL:
            return {
                ...state,
                error: action.payload
            } 
            
        default: // need this for default case
        return state    
    }
}

export const assistantProfileReducers = (state = {assistant: {}}, action) => {
    switch (action.type) {
        case ASSISTANT_PROFILE_REQUEST: 
            return {
                loading: true,
            }
        
        case ASSISTANT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                assistant: action.payload
            }
        
        case ASSISTANT_PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS: 
         return {
            ...state,
            error: null   
         }    
        default: 
            return state; 
    } 
}

export const assistantPatientsReducers = (state = { patients: []}, action) => {
    switch(action.type) {
        case ASSISTANT_PATIENTS_REQUEST: 
        return { 
            loading: true,  
            patients: []            
        }

        case ASSISTANT_PATIENTS_SUCCESS: 
        return { 
            loading: false,  
            patients: action?.payload       
        }

        case ASSISTANT_PROFILE_FAIL:
        return { 
            loading: false,  
            error: action.payload        
        }

        case CLEAR_ERRORS: 
        return { 
            ...state,
            error: null 
        }

        default: 
         return state;
    }
} 



