import {useState, useEffect} from 'react';
import { ProgressBar } from 'react-bootstrap';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { useDispatch, useSelector } from 'react-redux';
import {getTimeReportCCMByPatient} from '../../actions/HRActions';
import moment from 'moment';


const CCMMinutesProgress = ({patientId}) => {

    const dispatch = useDispatch();
    const [pagination, setPagination] = useState(10);
        
    // const [month, setMonth] = useState(moment().format('MM'));
    // const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));

    const [month, setMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));


    const [year, setYear] = useState("2024");

    const { totalTime } = useSelector(state => state.target);

    useEffect(() =>{
        dispatch({ type: RESET_TIME_REPORT_DATA });

        dispatch(getTimeReportCCMByPatient(patientId, month, endMonth, year, pagination)); 

    }, [])

    const searchHandler = () => {
        dispatch(getTimeReportCCMByPatient(patientId, month, endMonth, year, pagination)); 
    }    


  return (
    <>
    {/* Select box for selecting month  */}
    <label className="profile-label">Start Month & End Month</label>
    <div className="row-display">
        <select name="month" 
            className="form-control" 
            placeholder="Select Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            >
            <option value="01">Jan-26</option>
            <option value="02">Feb-26</option>
            <option value="03">March-26</option>
            <option value="04">April-26</option>
            <option value="05">May-26</option>
            <option value="06">June-26</option>
            <option value="07">July - 26</option>
            <option value="08">Aug - 26</option>
            <option value="09">Sept - 26</option>
            <option value="10">Oct - 26</option>
            <option value="11">Nov - 26</option>
            <option value="12">Dec - 26</option>
        </select>
        &nbsp;

        <select name="end" 
            className="form-control" 
            placeholder="End Month"
            value={endMonth}
            onChange={(e) => setEndMonth(e.target.value)}
            >
            <option value="01">Jan - 25</option>
            <option value="02">Feb - 25</option>
            <option value="03">March-25</option>
            <option value="04">April-25</option>
            <option value="05">May - 25</option>
            <option value="06">June - 25</option>
            <option value="07">July - 25</option>
            <option value="08">Aug - 25</option>
            <option value="09">Sept - 25</option>
            <option value="10">Oct - 25</option>
            <option value="11">Nov - 25</option>
            <option value="12">Dec - 25</option>
        </select>
        &nbsp;
        <button type="submit" name="submit" className="edit-pill" onClick={() => searchHandler()}>
            <i className='bx bx-search'></i>
        </button>
    </div>
    <br />   

    {/* <div className="row-display">
        <h5>CCM Minutes:</h5> <br/>
        <h3>{totalTime} / 120 </h3> 
    </div> */}
     <div>
        <h5>CCM Minutes Added:</h5>
        Following minutes are from: <b>{month}-26-{year} to {endMonth}-25-{year}</b>
        <div className='row-display-secondary mt-3'>
            <h1>{totalTime} mins / 150</h1>
        </div>
        {totalTime && totalTime > 0 && totalTime < 150 ? <>
            <ProgressBar striped animated variant="success" now={totalTime} label={`${((totalTime / 150) * 100).toFixed(2)}%`}/>
        </> : totalTime >= 150 ? <p className="text-center">
            <b style={{color: 'green', letterSpacing: '3px'}}>CCM Minutes Completed</b>
            </p>
            : null }
        <br />
    </div>


    <h5>CPT Codes Calculation: </h5>

    {totalTime >= 20 && totalTime < 40 ?
        <div className="row-display cptCompleted">
            <h4><span >99490</span></h4>
        </div> : totalTime >= 40 && totalTime < 60 ?  
        <div className="row-display cptCompleted">
            <h4><span >99490, 99439</span></h4>
        </div>
        : totalTime >= 60 && totalTime < 90 ?  
        <div className="row-display cptCompleted">
            <h5><span >99487</span></h5>
        </div> : 
            totalTime >= 90 && totalTime < 120 ?  
        <div className="row-display cptCompleted">
            <h5><span >99487, 99489</span></h5>
        </div>
        : 
        totalTime >= 120 && totalTime < 150 ?  
        <div className="row-display cptCompleted">
            <h5><span >99487, 99489, 99489</span></h5>
    </div> : totalTime > 150 ?  
        <div className="row-display cptCompleted">
            <h5><span >99487, 99489, 99489, 99489</span></h5>
    </div>
    :
    <b><br/><br/>No CPT codes completed yet.</b> }        
    {/* <h5>{totalTime >= 20 && totalTime < 40 ? <span style={{opacity: 1, color: 'green', fontWeight: 'bold'}}>99490</span> : <span style={{opacity: 0.4}}>99490</span>} </h5> 
    <h5>{totalTime >= 40 && totalTime < 60 ? <span style={{opacity: 1, color: 'green', fontWeight: 'bold'}}>99490, 99439</span> : <span style={{opacity: 0.4}}>99490, 99439</span>} </h5>
    <h5>{totalTime >= 60 && totalTime < 90 ? <span style={{opacity: 1, color: 'green', fontWeight: 'bold'}}>99487</span> : <span style={{opacity: 0.4}}>99487</span>} </h5> 
    <h5>{totalTime >= 90 && totalTime < 120 ? <span style={{opacity: 1, color: 'green', fontWeight: 'bold'}}>99487, 99489</span> : <span style={{opacity: 0.4}}>99487, 99489</span>} </h5>
    <h5>{totalTime >= 120 && totalTime < 150 ? <span style={{opacity: 1, color: 'green', fontWeight: 'bold'}}>99487, 99489, 99489, 99489</span> : <span style={{opacity: 0.4}}>99487, 99489, 99489, 99489</span>} </h5>  */}


    </>
  )
}

export default CCMMinutesProgress