
import React from 'react';
import moment from 'moment';

export default class CCMCareplanManualPDF extends React.PureComponent {
    render() {
        const { ccmCareplan } = this.props;
        const { patient } = this.props;

        return (
        <div>
            <div className="row-display">
                <h1 style={{color: '#00A8A8'}}><b>Remote Monitoring</b></h1>
            </div>

            <hr/><br/><br/>        
                <h3 className="text-center">CCM Careplan</h3> 
                <h4 className="text-center">Print Date : {moment().tz("America/New_York").format("MM/DD/YYYY")}</h4>       
                {/* Footer */}
                <div style={{ textAlign: 'center' }}>
                    <p>This is a system-generated report and does not require authorization.</p>
                </div>
            <br /><br />

            <div style={{marginLeft: '30px', marginRight: '30px'}}>
            
            <div className="row-display">
                <div>
                    <p className="form-label m-0 p-0">Patient Name: </p>
                    <h5 className="title">{patient?.lastname}, {patient?.firstname}</h5>
                    <br />
                    <p className="form-label m-0 p-0">Patient EHR-ID: </p>
                    <p> <b>{patient?.emrId}</b></p>
                </div>

                <div>
                    <p className="form-label m-0 p-0">Date of Birth (D.O.B): </p>
                    <b>{moment(patient?.DOB).format('MM/DD/YYYY')}</b>

                    <br /><br />
                    <p className="form-label m-0 p-0">Program Enrolled: </p>
                    <b>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</b>
                </div>

                {ccmCareplan && ccmCareplan.map((plan, index) => (
                <div key={index}>
                    <p className="form-label m-0 p-0">Created By: </p>
                    <b> {plan?.addedByAdmin ? plan?.addedByAdmin?.name : plan?.addedByNurse?.lastname +' , '+ plan?.addedByNurse?.firstname} </b>
                       
                    
                    <br /><br />
                    <p className="form-label m-0 p-0">Created Date: </p>
                       <b> {plan?.addedDateTime} </b>    
                </div>
                ))}
            </div>
            
            {ccmCareplan && ccmCareplan.map((plan, index) => (
                    <div key={index}>
                    <p className="form-label m-0 p-0">Patient Disease(s): </p>

                    <div className="container ml-4">
                        <ul>
                            {plan.diseases.map((disease, index) => (
                                <li key={index}>
                                    <b>{disease.name}</b>
                                </li>
                            ))}
                        </ul>   
                    </div>
                    <hr className="blue-hr" />
        
                    <h5><b>Goal & Interventions Details : </b></h5>
                    
                    {plan.diseases.map((disease, index) => (
                        <div key={index}>
                            <br />
                            <b className="coloredRow p-2 mt-2">Disease {index + 1} : {disease.name} </b> <br /><br />
                            <br />

                            <h5 className="coloredUnderline"><b>Short Term Goals</b></h5>
                                {disease.shortTermGoals.map((disease, index) => (
                                    <div key={index}>
                                        <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                        <span className="row ml-1">
                                            <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                                            </span>
                                    </div>
                                ))}
                            
                            <br /><br />
                            <h5 className="coloredUnderline"><b>Long Term Goals</b></h5>
                                {disease.longTermGoals.map((disease, index) => (
                                      <div key={index}>
                                      <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                      <span className="row ml-1">
                                          <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                                          </span>
                                  </div>
                                ))}

                            <br /><br />
                            <h5 className="coloredUnderline"><b>Interventions</b></h5>
                                {disease.interventions.map((disease, index) => (
                                      <div key={index}>
                                      <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                      <span className="row ml-1">
                                          <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                                          </span>
                                  </div>
                                ))}
                        </div>
                    ))}

                    <br />
                    <b>Care Plan Notes : </b> <br />
                    {plan?.notes}
                
                    </div>
                ))}
            <br /><br />
            </div>
        </div>
        )
    }
}