import { useEffect, useState } from 'react';
import { getAllTargets } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../layouts/Loader';
import {Link } from 'react-router-dom';
import moment from 'moment';
import { Badge } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';


const AllTargetedMinutes = () => {
    
    const dispatch = useDispatch();
    const {loading, error, alltargets, count } = useSelector(state => state.adminTargets);
    const [pagination, setPagination] = useState(25);

    const alert = useAlert();

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        dispatch(getAllTargets(pagination));
        
    }, [dispatch, error, pagination])

    const updateMinutesPagination = () => {
        setPagination(pagination + 25);
        dispatch(getAllTargets(pagination));
    }

    const refreshHandler = () => {
        dispatch(getAllTargets(pagination));
        setPagination(25);
    }
 
  return (
    <>
        {loading ? <Loader /> : ( <> 
        <div className="shadow-lg p-3 mb-2 mr-4 ml-4 rounded targetedMinutesCard">
            <div className="home-content">
                <div className="row-display">
                    <div>
                        <h5 className='title'>
                            <i className='bx bx-time'></i> <strong>&nbsp;Minutes Progress </strong> 
                        </h5>
                        <p>Showing <b>{alltargets?.length}</b> results out of <b>{count}</b></p>   
                    </div>

                    <button className='edit-pill m-3' onClick={refreshHandler}>
                        Refresh
                    </button>
                </div>
                <hr />
            </div>

            <div className='container'>
            {alltargets && alltargets.map((trgt, index) => (
                <div key={index}>

                        <div>
                            <div className='row-display'>
                                <div>
                                    <b>
                                    {trgt?.assigned_assistant_id ? 
                                        trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                        : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                        : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                    }</b><br/>
                                    <Link to={{ pathname: "/patientProfile", state: {patientid: trgt?.assigned_patient_id?._id}}} className="link"> {trgt?.assigned_patient_id?.lastname +" , "+ trgt?.assigned_patient_id?.firstname} </Link>
                                </div>

                                <div className="ml-5">
                                    <b>Minutes Added</b><br/>
                                    <h5>{trgt?.timeSpentInMinutes} min(s) &nbsp;&nbsp; {trgt?.isCCM === true ? 'CCM' : 'RPM'}</h5>
                                </div>

                                

                                <div className='ml-5'>
                                    <b>Date & Time</b><br/>
                                    <p>{moment(trgt?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                    
                                </div>

                                <div className='ml-3'>
                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                    : null 
                                    }
                                </div>
                            </div>   
                        </div> 

                        <p className="profile-label m-0 p-0">Conclusion:</p>            
                        <p style={{textAlign: "justify"}}>{trgt?.conclusion}</p>
                        <hr />
                </div>
            ))}

                    {alltargets?.length < count && <>
                        <br/><br/>
                            <div className='text-center'>
                                <button className='edit-pill' onClick={() => updateMinutesPagination()}> Show more </button>
                            </div>
                        <br/><br/>
                    </>}            
            </div>
            </div>
            </> )}
    </>
  )
}

export default AllTargetedMinutes;