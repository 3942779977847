    import {useEffect, useState} from 'react'
    import Sidebar from '../../components/Assistant/Sidebar';
    import TopBar from '../../components/Assistant/Topbar';
    import MetaData from '../../layouts/MetaData';
    import Loader from '../../layouts/Loader';
    import {useSelector, useDispatch} from 'react-redux';
    import { patientProfile, getPatientTelemetryData} from '../../actions/assistantActions';
    import CuffTelemetaryData from '../../components/Patient/CuffTelemetaryData';
    import { Tab, Col} from 'react-bootstrap';
    import { useAlert } from 'react-alert';
    import Pagination from 'react-js-pagination';
    import PatientProfileGraph from '../../components/PatientProfileGraph';
    import { HIDE_ALERT_MESSAGE } from '../../constants/Common';
    import AssistantPatientInfo from '../../components/Assistant/AssistantPatientInfo';
    import CCMMinutesSpent from '../../components/HR/CCMMinutesSpent';
    import NurseRPMMinutesSpent from '../../components/HR/NurseRPMMinutesSpent';



    const AssistantRPMPtProfile = (props) => {
        
        const dispatch = useDispatch();
        const alert = useAlert();
        const [view, setView] = useState('showTelemetary');
        const [accordion, setAccordion] = useState(false);
        const [deviceId, setDeviceId] = useState("default");

        
        let patientid = props?.location?.state?.patientid;
        let readingmonth;

        
        const { loading, error, patient, isUpdated} = useSelector(state => state.patientProfile);
        const { deviceData, Count } = useSelector(state => state.deviceData);
        const { user, isAuthenticated} = useSelector(state => state.auth);
        const { count } = useSelector(state => state.readingsCount);
        const { error: commonError, message: commonMessage} = useSelector(state => state.common);

        const [readingPerPage, setReadingsPerPage] = useState(10);
        const [currentPage, setCurrentPage] = useState(1);
        const [sort, sortBy] = useState(-1);
        const [addTimeShow, setAddTimeShow] = useState(false);
        const[toShow, setToShow] = useState("Telemetary");

        
        let asstId = user?._id;

    
        useEffect(() => {
            if(isAuthenticated === false) {
                props?.history?.push("/login");
            }

            if(error){
                alert.error(error);

            }

            if(commonError){
                alert.error(error);

                dispatch({
                    type: HIDE_ALERT_MESSAGE
                })
            }
        
            if(commonMessage){
                alert.success(commonMessage);
                setAddTimeShow(false);

                dispatch({
                    type: HIDE_ALERT_MESSAGE
                })
            }
        
            dispatch(patientProfile(patientid));
            dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));

            if(isUpdated) {
                alert.success('Updated Successfully');
            }

        }, [dispatch, error, commonError, commonMessage, currentPage, toShow]);


        function setCurrentPageNumber(currentPage) {
            setCurrentPage(currentPage);
        }

        let readingsThisMonth;
        let ReadingsperMonth; 
    
        readingsThisMonth = count;

        const refreshHandler = () => {
            dispatch(patientProfile(patientid));
            dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        }


        return (
            <>
            <MetaData title="Patient Profile"/>

            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />
            <br/>
            
            {loading ? <Loader /> : <>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
        <div className="home-content">
            <div>    

            {patient && <>
                <AssistantPatientInfo 
                    patient={patient}
                    telemetaryReadings={deviceData}
                    asstId={asstId}
                />

                    <br/>
                            <div className="row mr-3" style={{float: 'right'}}>        
                                <select 
                                    name="selectRole" 
                                    className="form-control" 
                                    style={{width: '250px', float: 'right'}}
                                    value={view}
                                    onChange={e => setView(e.target.value)}
                                >
                                    {patient?.patientType === 'Both' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : patient?.patientType === 'RPM' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    </> : patient.patientType === 'CCM' ? <>
                                    <option value="showCCMMinutes">Select Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : null}
                                       
                                </select>     
                                &nbsp;&nbsp;&nbsp;
                                {patient?.assigned_devices?.length > 1 ? <>
                                    <select className='form-control' 
                                        value={deviceId} 
                                        onChange={e => setDeviceId(e.target.value)}
                                        style={{width: '250px'}}
                                    >
                                        <option value="default">Sort By Device Type</option>
                                        {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                                        <option value={deviceass?.deviceObjectId?.deviceType} key={index}>
                                            {deviceass?.deviceObjectId?.deviceType}
                                        </option>
                                        ))}
                                    </select>
                                </> : null}
                                   

                            </div>
                            <br/>

                        {view === "showTelemetary" ? <>
                            {deviceData && deviceData.length > 0 ? <>
                            <br />


                        <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        
      
                        {/* Accordion for graphical representation */}
                        <div className="graphWrapper">
                            {accordion === true ? <div className="panel">
                                <PatientProfileGraph healthData={deviceData} />
                            </div> : ""}
                        </div>
                        
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                {deviceData && deviceData.filter(item => deviceId !== 'default' ? item?.deviceId?.deviceType === deviceId : item).map((devicedata, index) => (
                                    <div key={index}>
                                        <CuffTelemetaryData key={index} healthData={devicedata} isAdmin={true} patientId={patientid} />
                                    </div>
                                ))}

                                {/* Pagination */}
                                {readingPerPage <= Count && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={readingPerPage} 
                                    totalItemsCount = {Count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                                </div>
                                )} 
                                </Tab.Pane>
                                
                            </Tab.Content>
                            </Col>
                    
                    </Tab.Container>
                    </div>
                    
                       
                        </> : 
                        <div className="text-center mt-3 pb-3">
                        <small>
                            <p>No telemetary data found </p>
                            <button className="btn btn-outline-primary btn-sm"onClick={refreshHandler}>
                                Refresh List
                            </button>
                        </small>
                        </div>}
                        </> : view === 'showCCMMinutes' ? <>
                         <br />
                         <CCMMinutesSpent
                            patientId={patientid}
                         />
                        </> : view === 'showRPMMinutes' ? <>
                        <br />
                            <NurseRPMMinutesSpent
                                patientId={patientid}
                            />
                        </> : null }

                                        
                            </> }
                            </div>

                            </div>
                            </div>
                            </> 
                            }


                </section>
            </>
        )
    }

    export default AssistantRPMPtProfile
