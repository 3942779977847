        import { useEffect } from 'react';
        import Sidebar  from '../../components/AdminDashboard/Sidebar';
        import TopBar  from '../../components/AdminDashboard/TopBar';
        import MetaData from '../../layouts/MetaData';
        import { Link } from 'react-router-dom';
        import { useDispatch, useSelector } from 'react-redux';
        import { getOrganizations } from '../../actions/adminActions';
        import Loader from '../../layouts/Loader';
        import { useAlert } from 'react-alert';

        const Organizations = () => {
        
        const alert = useAlert();
        const dispatch = useDispatch();
        const {loading, message, error, organizations } = useSelector(state => state.orgs);


        if(error){
            alert.error(error);
        }

        if(message){
            alert.success(message);
        }

        useEffect(() => {
            dispatch(getOrganizations());
        }, [dispatch, error, message])


        const refreshHandler = () => {
            
        }

        return (
        <>
        <MetaData title="Organizations "/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <br/>

            {loading ? <Loader /> : <>
            <div className="shadow-lg p-3 mb-2 mr-4 ml-4 background-white">
                <div className="home-content">
                
                    <div className="row-display">
                        
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bxs-buildings'></i> <strong>&nbsp;Manage Organizations </strong>
                        </h5>
                            
                        <div className="row-display">
                            <Link to="adminDashboard" className="btn btn-secondary pt-2"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                            
                            <button className="btn btn-secondary pt-2" onClick={refreshHandler}><i className='bx bx-refresh'></i></button> &nbsp;
                            
                            <Link to="/add/organization" className="edit-pill">
                                Add New Organization
                            </Link>
                        </div>
                    </div>
                    <hr />
                    
                            {organizations && <>
                                <table className="styled-table">
                                <thead align="center">
                                    <tr>
                                        <th>Sr #</th>
                                        <th>Name</th>
                                        <th>City</th>
                                        <th>Country</th>
                                        <th>No. of Patients</th>
                                        <th>No. Of Doctors</th>
                                        <th>No. Of Assistant</th>
                                        <th>No. Of Nurses</th>
                                        <th>H.O.D Signature</th>
                                        
                                        <th width="80px">Actions</th>
                                    </tr> 
                                </thead>

                        <tbody>      
                        {organizations && organizations.map((organization, index) => (                      
                            
                            <tr align="center" key={index}>
                                <td><b>{index + 1}</b></td>
                                <td><Link to={{pathname: "/organization", state: {orgsDetails: organization}}} className="link">{organization?.name}</Link></td>
                                
                                <td><b>{organization?.city}</b></td>
                                
                                <td><b>{organization?.country}</b></td>

                                <td className="text-center"><b style={{color: '#00A8A8', fontSize: '18px'}}>
                                    <i className='bx bx-user'></i> &nbsp; {organization?.totalPatientCount} </b>
                                </td>


                                <td><b>{'0' + organization?.doctors?.length}</b></td>
                                
                                <td><b>{'0' + organization?.assistants?.length}</b></td>

                                <td><b>{'0' + organization?.nurses?.length}</b></td>

                                <td>{organization?.signature ? <b>
                                    <i className='bx bx-check' style={{color: 'green', fontSize: '28px'}}></i>
                                    </b> : '---'}
                                </td>

                                {/* Actions */}
                                <td>
                                <div className="row-display">
                                    <Link to={{ pathname:"/organization", state: {orgsDetails: organization}}} className="link"><i className="fa fa-user"></i></Link>
                                    <Link to={{ pathname:"/edit/organization", state: {orgsDetails: organization}}} className="link"><i className="fa fa-edit"></i></Link>
                                </div>
                                </td>
                                {/* Actions ended*/}
                            </tr>                      
                        ))}
                            </tbody>
                        </table>
                            </>}

                        </div>
                        </div>
            </> }


            <br/>    
        </section>
        </>
        )
        }

        export default Organizations