import {useEffect, useState} from 'react';
import MetaData from '../../layouts/MetaData';
import TopBar from '../../components/AdminDashboard/TopBar';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctors, doctorProfile, getHrLists, getHrProfile } from '../../actions/adminActions';
import { getAssistants, getAssistantProfile } from '../../actions/assistantActions';
import DoctorProfile from '../../components/AdminDashboard/DoctorProfile';
import HRProfile from '../../components/AdminDashboard/HRProfile';
import { Link } from 'react-router-dom';
import AssistantProfile from '../../components/AdminDashboard/AssistantProfile';

const AdminResetPassword = () => {

  const dispatch = useDispatch();
  
  const [doctorId, setDoctorId] = useState('');
  const [hrId, setHRId] = useState('');
  const [assistantId, setAssistantId] = useState('');


  const {  error, doctors} = useSelector(state => state.doctor);
  const { doctor } = useSelector(state => state.doctorProfile);
  const { hrs } = useSelector(state => state.hrslist);
  const { hrProfile } = useSelector(state => state.hrprofile);
  const { assistants } = useSelector(state => state.assistantsList);
  const { assistant } = useSelector(state => state.assistantProfile);

  const [userType, setUserType] = useState('doctor');


  useEffect(() => {
    dispatch(getDoctors(50, 1));
    dispatch(getHrLists());
    dispatch(getAssistants());

    if(doctorId){
        dispatch(doctorProfile(doctorId))
    }

    if(hrId){
      dispatch(getHrProfile(hrId))
    }

    if(assistantId){
      dispatch(getAssistantProfile(assistantId));
    }
    
}, [dispatch, error, doctorId, hrId, assistantId]);

  
  return (
    <>
        <MetaData title="Reset Password"/>
              <Sidebar />    

            <section className="home-section">
                {/* TopBar */}
              <TopBar />
              <br/>

              <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                  <div className="home-content">
                      <div className="row-display">
                      <h5 className="pt-2 mt-2 title">
                        <i className='bx bx-lock-open-alt'></i> 
                        Hard Reset Password
                      </h5>

                      <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm mt-3">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;
                        
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm mt-3">
                                <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                    </div>
                    <hr />
                  </div>

                  <div className='row-display' style={{width: '75%'}}>
                    <button className={`${userType === 'doctor' ? 'tabsBtnActive' : 'tabsBtn'}`}
                        onClick={() => setUserType('doctor')}>Doctor's Account
                    </button>
                    
                    <button className={`${userType === 'hr' ? 'tabsBtnActive' : 'tabsBtn'}`}
                        onClick={() => setUserType('hr')}>Nurse's Account
                    </button>

                    <button className={`${userType === 'assistant' ? 'tabsBtnActive' : 'tabsBtn'}`}
                        onClick={() => setUserType('assistant')}>Clinical Staff's Account
                    </button>
                  </div>
                  <br/><br/>

                  {userType === 'doctor' ? <>
                  <label className='form-label'>Select Doctor</label>
                  <div className='row'>
                  <div className="col-md-4">
                      <select 
                      className="form-control"
                      value={doctorId}
                      onChange={(e) => setDoctorId(e.target.value)}

                      >
                      <option>Select Doctor</option>
                      {doctors && doctors.map((doc, index) => (
                              <option value={doc?._id} key={index}> {doc?.firstname} {doc?.lastname} {doc?.npinumber} </option>
                          ))} 
                      </select>
                    </div>
                      {doctor && doctor?.firstname && <>
                        <div className="col-md-8">  
                        <DoctorProfile doctor={doctor} />
                      </div>
                      </>
                      }
                    </div>
                     </> : userType === 'hr' ? <>
                     {/* HRs List */}
                     <label className='form-label'>Select Nurse</label>
                     <div className='row'>
                      <div className="col-md-4">
                      <select 
                        className="form-control"
                        value={hrId}
                        onChange={(e) => setHRId(e.target.value)}
                      >

                      <option>Select Nurse</option>
                      {hrs && hrs.map((hr, index) => (
                            <option value={hr?._id} key={index}> {hr?.lastname +', '+ hr?.firstname} </option>
                          ))} 
                      </select>
                    </div>
                      
                    {hrProfile && hrProfile?.firstname && <>
                        <div className="col-md-8">  
                        <HRProfile hrprofile={hrProfile} />
                      </div>
                      </>
                      }

                    </div>
                     </> : userType === 'assistant' ? <>
                     <label className='form-label'>Select Clinical Staff</label>
                     <div className='row'>
                      <div className="col-md-4">
                      <select 
                        className="form-control"
                        value={assistantId}
                        onChange={(e) => setAssistantId(e.target.value)}
                      >

                      <option>Select Clinical Staff</option>
                      {assistants && assistants.map((asst, index) => (
                            <option value={asst?._id} key={index}> {asst?.lastname +', '+ asst?.firstname} </option>
                          ))} 
                      </select>
                    </div>

                    {assistant && <>
                        <div className="col-md-8">  
                        <AssistantProfile assistantProfile={assistant} />
                      </div>
                      </>
                      }      
                          
                    </div>
                     </> : null}
                  
                  </div>


            </section>
    </>
  )
}

export default AdminResetPassword