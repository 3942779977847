import { useState } from 'react';
import { AssistantLogout } from '../../actions/authActions';
import { useDispatch } from 'react-redux';
import SessionTimeout from '../../components/SessionTimeout';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';
import { Link } from 'react-router-dom';



const TopBar = () => {
    
    const dispatch = useDispatch();
    
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    
    let drIds = localStorage.getItem('myArray');
    let countLength = JSON.parse(drIds);

    const fetchData = (value) => {
            axios.post(`${Prod01}/assistant/search`, {
                value: value,
                drIds
            },{
                headers: {
                'Content-Type': 'application/json',
              }
            })
            .then((result) => {
                value && setResults(result?.data);
            }
          )
      }
    
      const handleChange = (value) => {
        setInput(value);
        if(value?.length > 3) {
            fetchData(value);
        }
      }

    const logoutHandler = () => {
        var confirmation = window.confirm(`Are you sure you want to logout ?`);
           if(confirmation){
               window.location.replace("/");
               dispatch(AssistantLogout());
           }

           localStorage.removeItem('token');
           localStorage.removeItem('assistant');
       }


    return (
        <>
            <SessionTimeout />
            {/* Top Header */}
            <div className="row-display" id='topbar' style={{backgroundColor: '#00A8A8'}}>
            <div className="searchBox" >
                <input 
                    type="text" 
                    placeholder={drIds?.length > 0 ? 'Search patient . . .' : 'Select your working Dr. first'} 
                    className="searchInput" 
                    value={input}
                    onChange={e => handleChange(e.target.value)}
                    disabled={countLength?.length > 0 ? false : true}
                />
                <i className='bx bx-search searchbar-Icon'></i>
            </div>

            <div className='row'>
            <Link to="/clinical_staff/settings" className='link'>
            <i className='bx bx-cog mr-4 mt-2' style={{fontSize: '28px', 
                    color: 'white', 
                    marginLeft: '5px', 
                    cursor: 'pointer'
                }}></i>
            </Link>

            
            <i className='bx bx-log-out mr-4 mt-2' style={{fontSize: '28px', 
                    color: 'white', 
                    marginLeft: '5px', 
                    cursor: 'pointer'
                }} onClick={logoutHandler}></i> 
            </div>
        </div>

        {input && results && results.length > 0 &&                             
            <div className="searchResults">
                {results.map((result, id) => (
                    <div key={id} className='row-display-secondary searchResult'>
                        
                        <Link style={{textDecoration: 'none', color: '#000'}}
                           to={{ pathname: "/clinical_staff/patient", state: {patientid: result?._id}}}>
                           <b><i className="bx bx-search"></i> &nbsp;&nbsp;&nbsp;&nbsp;{result?.emrId} &nbsp;&nbsp;&nbsp;&nbsp; {result?.lastname +" "+ result?.firstname } </b> 
                           {result?.assigned_doctor_id && <><span> of Organization </span>  <b>{result?.assigned_doctor_id?.lastname +" "+ result?.assigned_doctor_id?.firstname} </b></>}  
                        </Link>

                    </div>
                ))}
            </div>
        }


        </>
    )
}

export default TopBar;
