import {useEffect } from 'react';
import { getInventoryStats } from '../../actions/adminActions';
import {useDispatch, useSelector } from 'react-redux';

const InventoryAnalytics = () => {

    const dispatch = useDispatch();
    const {totalDevices, 
        instockDevices, 
        outstockDevices, 
        brokenDevices, 
        cuffDevices, 
        weightDevices, 
        spo2Devices,
        sugarDevices
    } = useSelector(state => state.inventoryStats);

    useEffect(() => {   
        dispatch(getInventoryStats())
    }, [dispatch]);


  return (
    <>
        <h5 className='title'>
            Devices Stats
        </h5>
        
        <div className="row">
            <div className="col-md-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#00A8A8', width: '30%'}}>{totalDevices && totalDevices} </span> 
                    <span className="mr-4 mt-2"> Total Devices</span>                    
                </div>
            </div>

            <div className="col-md-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#00A8A8', width: '30%'}}>{outstockDevices && outstockDevices < 10 ? '0'+outstockDevices : outstockDevices} </span> <span className="mr-4 mt-2"> Assigned Devices</span>                                    
                </div>
            </div>

            <div className="col-md-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#00A8A8', width: '30%'}}>{instockDevices && instockDevices < 10 ? '0'+instockDevices : instockDevices} </span> <span className="mr-4 mt-2">Instock Devices</span>                                                       
                </div>
            </div>

            <div className="col-md-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#00A8A8', width: '30%'}}>{brokenDevices && brokenDevices < 10 ? '0'+brokenDevices : brokenDevices} </span> <span className="mr-4 mt-2">Broken Devices</span>                                                       
                </div>
            </div>

            <br />
            <div className="col-md-3 mt-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#00A8A8', width: '30%'}}>
                        {cuffDevices && cuffDevices} </span> 
                    <span className="mr-4 mt-2"> B.P Devices</span>                    
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#FFC508', width: '30%'}}>
                        {weightDevices && weightDevices} </span> 
                    <span className="mr-4 mt-2"> Weight Scale Devices</span>                    
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#FF5C39', width: '30%'}}>
                        {spo2Devices && spo2Devices} </span> 
                    <span className="mr-4 mt-2"> Spo2 Devices</span>                    
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="row-display card">
                    <span className="deviceStatsNumber" style={{backgroundColor: '#5C1F4B', width: '30%'}}>
                        {sugarDevices && sugarDevices < 10 ? '0'+sugarDevices : sugarDevices} </span> 
                    <span className="mr-4 mt-2"> Glucose Meter Devices</span>                    
                </div>
            </div>

        </div>
        </>
  )
}

export default InventoryAnalytics