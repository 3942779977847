import {useState, useEffect} from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import Loader from '../../layouts/Loader';
import { patientProfile, getPatientTelemetryData, sortTelemetartData, getPatientChat, sendChatMessage} from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tab, Modal, Image} from 'react-bootstrap';
import CuffTelemetaryData from '../../components/Patient/CuffTelemetaryData';
import { COMMENT_RESET } from '../../constants/HRConstants';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import HRPatientInfo from '../../components/HR/HRPatientInfo';
import PatientProfileGraph from '../../components/PatientProfileGraph';
import systolicImg from '../../assets/Images/blood-pressure.png'; 
import heartRate from '../../assets/Images/heart.png';
import bpChartImg from '../../assets/Images/charts/BPCategories.jpg';
import heartRateChart from '../../assets/Images/charts/HeartRateChart.jpg';
import CCMMinutesSpent from '../../components/HR/CCMMinutesSpent';
import AdminRPMMinutesComponent from '../../components/AdminDashboard/AdminRPMMinutesComponent';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';
import nurseImg from '../../assets/Images/nurse.png';
import NotFound from '../../components/NotFound';
import userImg from '../../assets/Images/user.png';



const HRPatientProfile = (props) => {
    const alert = useAlert();

    let patientid = props?.location?.state?.patientid;

    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');

    const [view, setView] = useState('showTelemetary');
    const [viewChat, setViewChat] = useState(false);

    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth);

    const { loading, error, patient} = useSelector(state => state.patientProfile);
    const { deviceData, Count } = useSelector(state => state.deviceData);
    const { commentSuccess} = useSelector(state => state.comments);
    const { error: commonError, message: commonMessage} = useSelector(state => state.common);
    const [accordion, setAccordion] = useState(false);
    const [chatMessage, setChatMessage] = useState('');
    const [deviceId, setDeviceId] = useState("default");


    const [readingPerPage, setReadingsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, sortBy] = useState(-1);
    const [smShow, setSmShow] = useState(false); //charts modal
    const [imageToShow, setImageToShow] = useState(); //setting chart images
    const {chat} = useSelector(state => state.chatMessages);


  useEffect(() => {
    if(error){
        alert.error(error);
    }

    if(commonError){
        alert.error(commonError);
        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
    }

    if(commonMessage){
        alert.success(commonMessage);
        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
    }

    if(patientid){
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort))
    }

    dispatch(getPatientChat(patientid));
    

    if(commentSuccess) {
        alert.success('Comment Added');
        dispatch({ type: COMMENT_RESET });
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort))
    }

}, [dispatch, error, commentSuccess, currentPage, sort, commonError, commonMessage, patientid]);


    const sortPatientTelemetaryData = (date) => { 
        dispatch(sortTelemetartData(patientid, filterStartDate, filterEndDate));
    }

    const refreshHandler = () => {
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid))
        setFilterStartDate("");
        setFilterEndDate("");
    }

    function setCurrentPageNumber(currentPage) {
        setCurrentPage(currentPage);
    }

    const sendMessageHandler = () => {
        dispatch(sendChatMessage(chatMessage, patientid, user?._id));
        setChatMessage('');
    }
    
  return <>
      <MetaData title={ patient?.lastname + ' , ' + patient?.firstname +' '+ 's profile'}/>
        <HRSidebar />    
          
        <section className="home-section">
        {/* TopBar */}
        <HRTopBar />

        {loading ? <Loader /> : <>
                <div className="shadow-lg m-3 background-white">        
                        <div className="home-content">
                            <div>    

                            {patient && <>
                                <HRPatientInfo 
                                    patient={patient}
                                    telemetaryReadings={deviceData}
                                />
                        
                        <br/>
                            <div>        
                                <select 
                                    name="selectRole" 
                                    className="form-control" 
                                    style={{width: '250px', float: 'right'}}
                                    value={view}
                                    onChange={e => setView(e.target.value)}
                                >
                                    {patient?.patientType === 'Both' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : patient?.patientType === 'RPM' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    </> : patient.patientType === 'CCM' ? <>
                                    <option value="showCCMMinutes">Select Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : null}
                                       
                                </select>        
                            </div>
                            <br/>
                

                            {view === "showTelemetary" ? <>
                            {deviceData && deviceData.length > 0 ? <>
                            
                                <button className="float-2"
                                    onClick={() => {setSmShow(true); setImageToShow(bpChartImg)}}
                                >
                                    <Image src={systolicImg} style={{width: '42px', height: '42px'}} /> 
                                </button>

                                <button className="float-1"
                                    onClick={() => {setSmShow(true); setImageToShow(heartRateChart)}}
                                >
                                    <Image src={heartRate} style={{width: '42px', height: '42px'}} /> 
                                </button>

                                <button className="float">
                                    <i className='bx bxs-paper-plane' style={{width: '60px', height: '60px', marginTop: '20px'}} 
                                        onClick={() => setViewChat(true)}
                                        >
                                    </i>
                                </button>
                        
                                <div className="container-fluid ml-3">
                                    <h5 className="title mt-3">Search Vitals Data </h5>
                                    <p style={{ color: '#00A8A8'}}>
                                        Total Readings Received : {Count}  
                                    </p>
                                </div>

                            <div className="row-display patient-profile-col-heading" style={{ 
                                padding: 10,
                                borderRadius: '10px',
                                margin: '0px 20px'
                                }}
                            > 
                            
                            <div style={{width: '30%'}}>
                              <input 
                                type="date"
                                value={filterStartDate}
                                onChange={e => setFilterStartDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                                />
                            </div>
                            
                            &nbsp;&nbsp;
                            <div style={{width: '30%'}}>
                              <input 
                                type="date"
                                value={filterEndDate}
                                onChange={e => setFilterEndDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                             />
                             </div>
                            
                             &nbsp;&nbsp;
                             <div style={{width: '30%'}}>
                                    <select 
                                    value={sort}
                                    onChange={e => sortBy(e.target.value)}
                                    className="form-control">
                                        <option value="-1">Descending (last to first)</option>
                                        <option value="1">Ascending (first to last)</option>
                                    </select>
                             </div>
                            
                            &nbsp;&nbsp;
                                <button 
                                    className="edit-pill"
                                    onClick={sortPatientTelemetaryData}>Search
                                </button>    
                                
                                &nbsp;&nbsp;
                                
                                <button
                                    onClick={refreshHandler} 
                                    className="edit-pill">Reset
                                </button>    
                            
                            </div>

                        <br />

                        <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="row-display mr-3" style={{float: 'right'}}>
                        {patient?.assigned_devices?.length > 1 ? <>
                            <select className='form-control' 
                                value={deviceId} 
                                onChange={e => setDeviceId(e.target.value)}
                                style={{width: '250px'}}
                            >
                                <option value="default">Sort By Device Type</option>
                                {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                                <option value={deviceass?.deviceObjectId?.deviceType} key={index}>
                                    {deviceass?.deviceObjectId?.deviceType}
                                </option>
                                ))}
                            </select>
                        </> : null}
                        

                            
                        {deviceData && deviceData.filter(healthdata => healthdata?.deviceId?.deviceType === 'bp') ? <>
                            <div className="container graphWrapper">
                                <button className="edit-pill" onClick={() => setAccordion(accordion => !accordion)}>
                                    Show Graphically &nbsp;&nbsp;&nbsp;
                                    <i className={accordion ? `bx bx-minus` : `bx bx-plus`}></i>
                                </button>
                            </div> 
                        </> : <></> }
                        </div>
                      
                            
                        {/* Accordion for graphical representation */}
                        <div className="graphWrapper">
                            {accordion === true ? <div className="panel">
                                <PatientProfileGraph healthData={deviceData} />
                            </div> : ""}
                        </div>
                        
                        {/* Accordion for graphical representation ends here */}
                            <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                {deviceData && deviceData.filter(item => deviceId !== 'default' ? item?.deviceId?.deviceType === deviceId : item).map((devicedata, index) => (
                                    <div key={index}>
                                        <CuffTelemetaryData 
                                            key={index} 
                                            healthData={devicedata} 
                                            isAdmin={false} 
                                            patientId={patientid} 
                                            />
                                    </div>
                                ))}

                                {/* Pagination */}
                                {readingPerPage <= Count && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={readingPerPage} 
                                    totalItemsCount = {Count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                                </div>
                                )} 
                                </Tab.Pane>
                                
                            </Tab.Content>
                            </Col>
                    </Tab.Container>
                    </div>        
                       
                        </> : 
                        <div className="text-center mt-3 pb-3">
                        <small>
                            <p>No telemetary data found </p>
                            <button className="btn btn-outline-primary btn-sm"onClick={refreshHandler}>
                                Refresh List
                            </button>
                        </small>
                        </div>}
                        </> : view === 'showCCMMinutes' ? <>
                         <br />
                         <CCMMinutesSpent
                            patientId={patientid}
                            isNurse={true}
                         />
                        </> : view === 'showRPMMinutes' ? <>
                        <br />
                        <AdminRPMMinutesComponent 
                            patientId={patientid} 
                        />
                        </> : null }
                        </>
                        }
                        </div>
                    </div>
                </div>
                </>
                }
    </section>

    <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
    >
        <Modal.Body>
            {/* <AddNewAdminForm onHandleClose={setSmShow} />   */}
            <Image src={imageToShow} className="determinationChartsImages" /> 
        </Modal.Body>
    </Modal>

    {/* View Chat Section */}
    {viewChat === true ? <>
            <div>
            <div className="chat-container">
            <div>
                <div className="row-display p-2">
                    <div className='m-0 p-0'>
                    <h5 className="title">{patient?.lastname +', '+ patient?.firstname}</h5>
                    {chat?.length > 0 ? <>
                        <p className="m-0 p-0">Last conversation: {
                        moment(chat[chat?.length - 1]?.dateAndTime).format("MM-DD-YYYY hh:mm a")
                        }</p>
                    </> : '---'
                    }
                    </div>
                    <button className="btn btn-link" style={{fontSize: '26px'}} onClick={() => setViewChat(false)}>&times;</button>
                </div>
                <hr className="p-0 m-0"/>
            </div>

        <div className="chat-inner">
        {chat && chat.length > 0 ? <>
            {chat && chat.map((message, index) => (
                <div className={message?.sentBy === 'Patient' ? 'chatContainer darker' : 'chatContainer'} key={index}>
                 {message?.sentBy === 'Patient' ? <>
                    <img src={userImg} alt="Avatar" className="right" />
                 </> : <>
                    <img src={nurseImg} alt="Avatar" />
                 </>}   
                {message?.sentBy === 'Patient' ? <>
                    <b style={{color: 'green'}}>{message?.patient_id?.lastname +', '+ message?.patient_id?.firstname}</b>
                </> : <>
                    <b style={{color: 'orange'}}>RN. {message?.nurse_id?.lastname +', '+ message?.nurse_id?.firstname}</b>
                </>}
                <p>{message?.message}</p>
                <span className="time-right">{moment(message?.dateAndTime).format("MM-DD-YYYY hh:mm a")}</span>
                </div>    
            ))}
            </> : <NotFound title="No Conversation yet" />
            }
            </div>

            <div className="chatFooter">
                <div className="row-display">
                    <input 
                        type="text" 
                        className="chat-form" 
                        placeholder="Type your message..." 
                        value={chatMessage}
                        onChange={e => setChatMessage(e.target.value)}
                        />
                    <button className='edit-pill' onClick={sendMessageHandler}>
                        <i className='bx bxs-paper-plane'></i>
                    </button>
                </div>
            </div>   
        </div>
        </div>
        </> : null }

  </>
};

export default HRPatientProfile;
