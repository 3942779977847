    import { useEffect, useState } from 'react';
    import TopBar from '../AdminDashboard/TopBar';
    import { useSelector, useDispatch } from 'react-redux';
    import { getAdminStats, getOrganizations } from '../../actions/adminActions';
    import PatientsWithCriticalReadingsAdmin from '../../components/AdminDashboard/PatientsWithCriticalReadingsAdmin';
    import DoctorWisePatientGraph from '../../components/Graphs/DoctorWisePatientGraph';
    import AllTargetedMinutes from '../../components/AdminDashboard/AllTargetedMinutes';
    import RPMTotalMonthlyMinutesGraph from '../../components/Graphs/RPMTotalMonthlyMinutesGraph';
    import RecentReadings from './RecentReadings';
    import patientImg from '../../assets/Images/patient.png';
    import doctorImg from '../../assets/Images/doctorIcon.png';
    import nurseImg from '../../assets/Images/nurse.png';
    import devicesImg from '../../assets/Images/pngegg.png';
    import asstsImg from '../../assets/Images/network.png';
    import orgsImg from '../../assets/Images/organizations.png'
    import {Link } from 'react-router-dom';
    import NurseProgressComponent from './NurseProgressComponent';

    const Home = () => {
        const dispatch = useDispatch();

        const [category, setCategory] = useState('RPM');
        const { user } = useSelector(state => state.auth);
        const {loading, organizations } = useSelector(state => state.orgs);

        const { totalPatients,
            blockPts,
            rpmPts,
            ccmPts,
            totalHrs,
            totalDrs,
            totalDevices,
            totalOrgs,
            totalAssts
        } = useSelector(state => state.adminStat);


        useEffect(() => {
            try {
                // dispatch(getAllLogs(todayDate));
                dispatch(getAdminStats());
                dispatch(getOrganizations());
            } catch (error) {
                alert.error(error);
            }

        }, [dispatch]);

        return (
            <>
            <section className="home-section">
                <TopBar />


                {/*  Home content  */}
                <div className="background-white">



                    <br />
                    <div className="row-display">
                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard orangeCardDashboardStatsCard">
                                <img src={patientImg} alt="patientImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients }</h2>
                                <h5 className="text-white m-0 p-0">Patients</h5>
                                <Link to="/patients" className="text-white">Go to patients</Link>
                           </div>
                        </div>

                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard redCardDashboardStatsCard">
                                <img src={orgsImg} alt="asstsImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalOrgs && totalOrgs < 10 ? '0'+totalOrgs : totalOrgs}</h2>
                                <h5 className="text-white m-0 p-0">Organizations</h5>
                                <Link to="/organizations" className="text-white">Go to organizations</Link>
                           </div>
                        </div>

                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard greenCardDashboardStatsCard">
                                <img src={doctorImg} alt="doctorImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalDrs && totalDrs < 10 ? '0'+totalDrs : totalDrs}</h2>
                                <h5 className="text-white m-0 p-0">Doctors</h5>
                                <Link to="/doctors" className="text-white">Go to doctors</Link>
                           </div>
                        </div>

                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard orangeCardDashboardStatsCard">
                                <img src={asstsImg} alt="asstsImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalAssts && totalAssts < 10 ? '0'+totalAssts : totalAssts}</h2>
                                <h5 className="text-white m-0 p-0">Assistants</h5>
                                <Link to="/Assistants" className="text-white">Go to assistants</Link>
                           </div>
                        </div>

                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard redCardDashboardStatsCard">
                                <img src={nurseImg} alt="nurseImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalHrs && totalHrs < 10 ? '0'+totalHrs : totalHrs}</h2>
                                <h5 className="text-white m-0 p-0">Nurses</h5>
                                <Link to="/hrlist" className="text-white">Go to nurses</Link>
                           </div>
                        </div>

                        <div className="col-md-2">
                           <div className="row-display-column text-center dashboardStatsCard blueCardDashboardStatsCard">
                                <img src={devicesImg} alt="nurseImg" width="80px" height="80px" className='mx-auto'/>
                                <h2 className="text-white m-0 p-0">{totalDevices && totalDevices < 10 ? '0'+totalDevices : totalDevices}</h2>
                                <h5 className="text-white m-0 p-0">Devices</h5>
                                <Link to="/devices" className="text-white">Go to devices</Link>
                           </div>
                        </div>
                    </div>
                    <br />

                    <div className="row-display container-fluid">
                        <div className="col-md-4">
                            <DoctorWisePatientGraph
                                orgPts={organizations}
                                />
                        </div>


                        <div className='col-md-2'>
                            <div>
                                <div className='adminAnalyticsCard text-center'>
                                    <h1 className='text-white'>{rpmPts && rpmPts}</h1>
                                    <p className='text-white m-0 p-0'>RPM Patients</p>
                                <Link to="/patients" className="text-white">View</Link>

                                </div>
                                <br/>

                                <div className='adminAnalyticsCard blueCardDashboardStatsCard text-center'>
                                    <h1 className='text-white'>{ccmPts && ccmPts}</h1>
                                    <p className='text-white m-0 p-0'>CCM Patients</p>
                                <Link to="/patients" className="text-white">View</Link>

                                </div>
                                <br/>

                                <div className='adminAnalyticsCard redCardDashboardStatsCard text-center'>
                                    <h1 className='text-white'>{blockPts && blockPts}</h1>
                                    <p className='text-white m-0 p-0'>Archived Patients</p>
                                    <Link to="/Patients/Inactive" className="text-white">View</Link>

                                </div>

                            </div>
                        </div>&nbsp;

                        <div className="col-md-6 shadow-lg background-white p-3 scrollableYProgress">
                        {/* <NurseProgressComponent /> */}

                        {user?._id === '640826226439e492a4e52f33' ? <>
                            <NurseProgressComponent />
                        </> : <>
                        <div className='row-display'>
                            <h5 className='title m-0 p-0'> {category} Monthly Minutes Stats</h5>
                            <select
                                className="form-control"
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                                style={{width: '80px'}}
                            >
                                <option value="RPM">RPM</option>
                                <option value="CCM">CCM</option>
                            </select>
                        </div>

                        <hr />
                        <RPMTotalMonthlyMinutesGraph category={category} />
                        </> }
                        </div>
                    </div>

                    <PatientsWithCriticalReadingsAdmin />

                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-md-8">
                            <AllTargetedMinutes />
                        <br/>
                    </div>

                    <div className="col-md-4">
                        <RecentReadings />
                  </div>
                </div>
                </div>

                </div>
                </section>

            </>
        )
    }

    export default Home
