import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import defaultImg from '../../assets/Images/defaultUser.png';
import { useDispatch, useSelector } from 'react-redux';
import { doctorProfile, 
    getDoctorPatients, 
    removePatientsDoctor, 
    removeHRFromDoctor,
    getDoctorStats
} from '../../actions/adminActions';
import folderImg from '../../assets/Images/folder.png';
import Loader from '../../layouts/Loader';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {Badge, Modal} from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';



const DoctorProfile = (props) => {
    const dispatch = useDispatch();
    const alert = useAlert();
    let compliantStatus; // variable to store compliant status of patient


    let docId = props?.location?.state?.id;
    const [patientToRemove, setpatientToRemove] = useState(""); //set patient id to remove.
    const [doctorId, setDoctorID] = useState(docId); 
    const [query, setQuery] = useState("");
    const keys = ["emrId", "firstname", "lastname", "DOB", "email", "phone1"];
    const [selectedRows, setSelectedRows] = useState([]);

    const { loading, doctor } = useSelector(state => state.doctorProfile);
    const { doctorpatients } = useSelector(state => state.docPatients);
    const { error, message } = useSelector(state => state.common);

    const [filterBy, setFilterBy] = useState('rpm');
    
    const {
        totalPatients, 
        RpmPatients, 
        CcmPatients,
        bothPatients, 
        malepts, 
        femalepts, 
        activepts, 
        inActivepts
    } = useSelector(state => state.doctorStats);


    useEffect(() => {
        if(message){
            alert.success(message);
        }
    
        if(error){
            alert.error(error)
        }

        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
        
        dispatch(doctorProfile(doctorId));
        dispatch(getDoctorPatients(doctorId, filterBy));
        dispatch(getDoctorStats(doctorId));

    }, [dispatch, error, doctorId, message, doctorId, filterBy]);


    const removeHR = (hrId) => {
        var confirmation = window.confirm(`Are you sure you want to remove nurse from doctor ?`);
        if(confirmation){
            dispatch(removeHRFromDoctor(hrId, doctorId));
            props.history.push('/doctors');
        }
  }

    const sendEmail = (email) => {
        window.open(`mailto:${email}`)
    }

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }

    const handleRowCheck = (rowId) => {
        if (selectedRows.includes(rowId)) {
          setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
          setSelectedRows([...selectedRows, rowId]);
        }
      };

    const handleActivateSelected = () => {
        var confirmation = window.confirm(`Are you sure you want to remove ${selectedRows?.length} patient(s) from this doctor?`);
        if(confirmation){
            selectedRows.forEach(rowId => {
                const row = doctorpatients && doctorpatients.find(patient => patient?._id === rowId);
                if (row) {
                    dispatch(removePatientsDoctor(row?._id, doctorId));
                    setSelectedRows([]);
                }
            });
        };
    }

    return (
        <>
            <MetaData title="Doctor Profile"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                
                    {loading ? <Loader /> : <>
                    <br/> 
                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">

                        
                        <div className="row-display">
                            <div className="col-md-9">   
                                <h5 style={{color: '#00A8A8'}}>
                                    <i className="bx bx-user"></i> <strong>&nbsp;Doctor's Profile </strong>
                                </h5>
                            </div>
                            
                            <div className="row-display">
                                <button className="btn btn-secondary btn-sm" onClick={() => props.history.goBack()}><i className='bx bx-arrow-back' ></i></button> &nbsp;
                                    <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                                    <Link to={{ pathname: "/assigndoctor", state: {id: doctor?._id, lastName: doctor?.lastname,  firstName: doctor?.firstname}}} 
                                    className="edit-pill">Assign Patient
                                </Link>
                            </div>                          
                        </div>
                         <hr />

                        
                        <div>
                        {doctor && <>
                            <div className="row">
                            <div className="col-md-2">
                                <img src={defaultImg} className="profile-img" alt="profileimg" />
                                <div className="text-center mt-3" >
                                    <Link className="edit-pill" to={{ pathname: "/Doctor/Edit", state: {id: doctor}}}>Edit Profile</Link>
                                </div>
                            </div>

                            <div className="col-md-3">            
                                    <h4 className="title m-0 p-0">Dr. {doctor?.lastname}, {doctor?.firstname} </h4>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.name}</p>
                                    
                                    {doctor?.assigned_hr_id ? <>
                                        <div className="row">
                                        <p> &nbsp;&nbsp;&nbsp;&nbsp;{doctor?.assigned_hr_id?.lastname}, {doctor?.assigned_hr_id?.firstname}</p>
                                        &nbsp;&nbsp;
                                        <Link to="doctorProfile" className="link" 
                                        onClick={() => removeHR(doctor?.assigned_hr_id?._id)}
                                        style={{color: 'red'}}
                                        ><small>Remove</small></Link>  
                                    </div>
                                    </> : null}    
                                    

                                    <hr className='m-0 p-0'/>
                                    <br />
                                    <p className="profile-label m-0 p-0">License Number :</p>
                                    <p className="m-0 p-0">{doctor?.licensenumber ? doctor?.licensenumber : 'N/A'}</p>

                                    <br />    
                                    <p className="profile-label m-0 p-0">Email Address : </p>
                                    <Link to="/doctorProfile" 
                                        className="link" 
                                        onClick={() => sendEmail(doctor?.email)}>{doctor?.email}
                                    </Link>

                                    <br/><br/>
                                    <p className="profile-label m-0 p-0">Member Since :</p>
                                    <p className="m-0 p-0">{moment(doctor?.createdAt).format("MM/DD/YYYY hh:mm z A")}</p>                                    
                            </div>

                                <div className="col-md-3">            
                                    <h5>Organization Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Name :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.name}</p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">City :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.city}</p>

                                    <br/>
                                    <p className="profile-label m-0 p-0">Country :</p>
                                    <p className="m-0 p-0">{doctor?.assigned_org_id?.country}</p>

                                </div>

                                <div className="col-md-2">            
                                <h5>Contact Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">NPI Number :</p>
                                    <p className="m-0 p-0">{doctor?.npinumber ? 
                                        doctor?.npinumber : 'N/A'}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Primary Phone :</p>
                                    <p className="m-0 p-0">{doctor?.phone1 ? 
                                        <span><i className='bx bx-phone'></i> {doctor?.phone1} </span> : 'N/A'}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Mobile No :</p>
                                    <p className="m-0 p-0">{doctor?.mobileNo ? 
                                        <span style={{color: 'dodgerblue'}}><i className='bx bx-phone'></i> {doctor?.mobileNo} </span> : 'N/A'}
                                    </p>
                                    <br/>
                                </div>

                                <div className="col-md-2">            
                                <h5>Authorization Details</h5>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Account Status :</p>
                                    <p className="m-0 p-0">{doctor?.block === true ? <b style={{color: 'red'}}>Blocked</b> : 
                                        <b style={{color: 'green'}}>Active</b>}
                                    </p>
                                    <br/>

                                    <p className="profile-label m-0 p-0">Verification Status :</p>
                                        <p className="m-0 p-0">{doctor?.verified === false ? <b style={{color: 'red'}}>Not Verified</b> : <b style={{color: 'green'}}>Verified</b>}
                                    </p>
                                    <br/>
                                    
                                    {/* <img src={`https://www.rpmatrix.com/v1/uploadFiles/${doctor?.signature}`}  */}

                                    <p className="profile-label m-0 p-0">Signature :</p>
                                    <p className="m-0 p-0">
                                        {doctor?.signature ? <>
                                            <img src={`https://www.rpmatrix.com/v1/uploadFiles/${doctor?.signature}`} 
                                            width='100%' height="80" alt="img" 
                                        /> </> : 'Not Added Yet' }
                                        </p>
                                    <br/>
                                </div>
                        </div>    

                        <br/>

                        <div className='container-fluid doctorStatsWrapper text-center'>
                                   <div className="docStats">
                                        <h5 className="title">Total Patients</h5>
                                        <h2>{totalPatients && totalPatients < 10 ? '0'+totalPatients : totalPatients}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Only RPM</h5>
                                       <h2>{RpmPatients && RpmPatients < 10 ? '0'+RpmPatients : RpmPatients}</h2>
                                   </div>  

                                   <div className="docStats">
                                       <h5 className="title">Only CCM</h5>
                                       <h2>{CcmPatients && CcmPatients < 10 ? '0'+CcmPatients : CcmPatients}</h2>
                                   </div>
                                   
                                   <div className="docStats">
                                       <h5 className="title">RPM & CCM</h5>
                                       <h2>{bothPatients && bothPatients < 10 ? '0'+bothPatients : bothPatients}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Male</h5>
                                       <h2>{malepts && malepts < 10 ? '0'+malepts : malepts}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Female</h5>
                                       <h2>{femalepts && femalepts < 10 ? '0'+femalepts : femalepts}</h2>
                                   </div>   

                                   <div className="docStats">
                                       <h5 className="title">Active</h5>
                                       <h2 style={{color: 'green'}}>{activepts && activepts < 10 ? '0'+activepts : activepts}</h2>
                                   </div>

                                   <div className="docStats">
                                       <h5 className="title">Inactive</h5>
                                       <h2 style={{color: 'red'}}>{inActivepts && inActivepts < 10 ? '0'+inActivepts : inActivepts}</h2>
                                   </div>
                                </div>
                            <hr />
                            <br />     

                        {/* Search Filters  */}
                        <div className="row-display">
                        <label className="profile-label mt-2">Search Filters </label>
                            <span className={`smallFilterBtn ${filterBy === 'rpm' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('rpm')}>
                                    RPM Patients
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'ccm' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('ccm')}>
                                    CCM Patients 
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'both' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('both')}>
                                    RPM & CCM Patients 
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'male' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('male')}>
                                    Male Patients
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'female' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('female')}>
                                    Female Patients
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'active' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('active')}>
                                    Active Patients
                            </span>

                            <span className={`smallFilterBtn ${filterBy === 'inactive' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterBy('inactive')}>
                                    Inactive Patients
                            </span>
                        </div>
                        <br/><br/>
                        
                        {doctorpatients && doctorpatients?.length > 0 ? (<>
                            <div className="row-display">
                                <input 
                                    type="text" 
                                    className="form-control mb-2"
                                    onChange={e => setQuery(e.target.value)}
                                    placeholder="Search by EHR-ID, name, email, DOB . . ." 
                                    style={{width: '320px'}}
                                />

                            {selectedRows?.length > 0 ? <>
                                <button 
                                className="edit-pill mr-3 m-2" 
                                onClick={handleActivateSelected} 
                                disabled={selectedRows.length === 0}
                                style={{backgroundColor: 'red'}}
                            >
                                Remove {selectedRows?.length < 10 ? '0'+selectedRows?.length : selectedRows?.length} Patient(s)
                            </button>
                            </> : null}

                            </div>

                                <div>
                                    <>
                                        <table className="styled-table">
                                        <thead align="center">
                                            <tr>
                                            <th></th>
                                            <th>EHR - ID</th>
                                            <th>Name</th>
                                            <th>DOB </th>
                                            <th>Clinician</th>
                                            <th>Compliancy Status</th>
                                            <th>Last Reading </th>
                                            <th>Consent Role</th>
                                            <th>Last Call</th>
                                            <th>Action</th>
                                            </tr> 
                                        </thead>
                                        <tbody>
                                        
                                        {doctorpatients && doctorpatients.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((patient, index) => ( 
                                            <tr align="center" key={index}>

                                        <td>
                                            <label className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(patient?._id)}
                                                    onChange={() => handleRowCheck(patient?._id)}
                                                />
                                            <div className="checkbox-custom"></div>
                                            </label>
                                        </td>  

                                        <td><b>{patient.emrId || 'N/A'}</b></td>

                                        {patient?.block === false ? <td><Link className="link"
                                                to={{ pathname: "/patientProfile", state: {patientid: patient?._id, deviceid: patient?.deviceassigned?.deviceid}}}>
                                                {patient?.lastname}, {patient?.firstname}
                                                <sup><i className="bx bxs-circle pl-2" style={{color: 'green'}}></i></sup>  
                                                </Link>
                                                <p>{patient?.accountActionPerformed === "active" ? 'Reactivated : '+ moment(patient?.accountActionPerformedDateTime).tz("America/New_York").format("MM-DD-YYYY hh:mm a") : null}</p>

                                            </td> : <td>
                                                <b>{patient?.lastname}, {patient?.firstname}</b>
                                                <sup><i className="bx bxs-circle pl-2" style={{color: 'red'}}></i></sup>  
                                                <p>{patient?.accountActionPerformed === 'block' ? moment(patient?.accountActionPerformedDateTime).tz("America/New_York").format("MM-DD-YYYY hh:mm a") : null}</p>
                                            </td> }

                                            <td><b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b></td> 
                                        

                                                {patient?.assigned_doctor_id ? <>
                                                    <td><b>Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</b></td>
                                                    </> : <>
                                                    <td><Badge bg="danger text-white" className="not-assigned-tag">Not Assigned</Badge></td>
                                                </>}

                                                {patient?.patientType === 'RPM' || patient?.patientType === 'Both' ? calculateCompliancyStatus(moment(patient?.lastReading)) : null}    
                        
                                                <td>
                                                    {patient?.lastReading ? 
                                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                                    Non Compliant</p> : null : '--'}
                                                </td>

                                                <td><span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                                </td>

                                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>        

                                                <td><b>{patient?.lastcallrpm && moment(patient?.lastcallrpm).format('MM-DD-YYYY')}</b></td>            
                                            <td>
                                                <Link to="/doctorProfile" className="link" style={{color: 'red'}} onClick={() => setpatientToRemove(patient?._id)}>Remove</Link> &nbsp;
                                            </td>
                                        </tr> 
                                        ))}    
                                            
                                        </tbody>
                                    </table>    
                                    </>                      
                            </div>
                        </> ) : <>

                        <div>    
                        <h5 className="pt-2 mt-2">Patient's List </h5> 
                            <hr className="blue-hr"/>
                                           
                                <img src={folderImg} className="no-record-found-img"/>
                                <p className="doctor-specilizations">No Patient Found...</p>
                            
                             
                        </div>
                        </> }               

                        </>}
                        
                        </div>
                    </div>
                    </div>
                    </>
                    }
            </section> 
        </>
    )
}

export default DoctorProfile
