export const REQUEST_ASSISTANTS = 'REQUEST_ASSISTANTS';
export const REQUEST_ASSISTANTS_SUCCESS = 'REQUEST_ASSISTANTS_SUCCESS';
export const REQUEST_ASSISTANTS_FAIL = 'REQUEST_ASSISTANTS_FAIL';

export const ASSISTANT_STATS_SUCCESS = 'ASSISTANT_STATS_SUCCESS';
export const ASSISTANT_STATS_FAIL = 'ASSISTANT_STATS_FAIL';

export const ASSISTANT_PROFILE_REQUEST = 'ASSISTANT_PROFILE_REQUEST';
export const ASSISTANT_PROFILE_SUCCESS = 'ASSISTANT_PROFILE_SUCCESS';
export const ASSISTANT_PROFILE_FAIL = 'ASSISTANT_PROFILE_FAIL';

export const ASSISTANT_PATIENTS_REQUEST = 'ASSISTANT_PATIENTS_REQUEST';
export const ASSISTANT_PATIENTS_SUCCESS = 'ASSISTANT_PATIENTS_SUCCESS';
export const ASSISTANT_PATIENTS_FAIL = 'ASSISTANT_PATIENTS_FAIL';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';