import { Link } from 'react-router-dom';
import MetaData from '../../layouts/MetaData';

const Header = () => {

  return (
 <>
  {/****************** Top Bar  ************************/}
  
  {/* Header  */}
  <MetaData title="Home"/>
  
  <section className="home" id="home">
    <section id="home" className="d-flex align-items-center">
    <div className="container">
    <h4 className="text-white display-4">Your Vision, Our Solutions.</h4>
    <div style={{width: '600px'}}>
      <h5 className="text-white">At RP Matrix, we're dedicated to revolutionizing healthcare through 
      innovative IT solutions. Our services empower physicians and patients 
      with real-time health insights, ensuring proactive care and better outcomes.</h5>

      <br />
     
      <span 
        className='emailSupportBtn'
        onClick={() => {window.location.href ='mailto:support@rpmatrix.com';}}>
          support@rpmatrix.com 
      </span>
  
    </div>

     
    <br/><br/><br/>
      <div className='row-display-secondary'>
        <Link to="/auth/signin/hr" className="link">
          <span className='signInBtn'>Sign in as HR </span>
        </Link>

        <Link to="/auth/signin/clinical_staff" className="link">
          <span className='signInBtn'>Sign in as Clinical Staff </span>
        </Link>

        <Link to="/auth/signin/doctor" className="link">
          <span className='signInBtn'>Sign in as Doctor </span>
        </Link>

        <Link to="/auth/signin/admin" className="link">
          <span className='signInBtn'>Sign in as Management </span>
        </Link>

      </div>
      
    </div>
  </section>
  </section>         
    </>
    )
}

export default Header;
