import {useState} from 'react';
import womanIcon from '../../assets/Images/woman.png';
import patientProfileImg from '../../assets/Images/user.png';
import defaultUserImg from '../../assets/Images/doctorIcon.png';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {removePatientsDoctor, sendPushNotification} from '../../actions/adminActions';
import {Modal} from 'react-bootstrap';
import PatientSickness from './PatientSickness';
import PatientCCMCareplanManual from './PatientCCMCareplanManual';
import PatientCCMCareplanManualView from './PatientCCMCareplanManualView';
const moment = require('moment-timezone');


const PatientOverview = ({patient}) => {

const dispatch = useDispatch();
const history = useHistory();
const {lastActivity, FCC} = useSelector(state => state.readingsCount);

// Push Notifications
const [title, setTitle] = useState('');
const [message, setMessage] = useState('');

// Note Modal states
const [noteModalShow, setNoteModalShow] = useState(false);
const handleNoteModalShow = () => setNoteModalShow(true);
const handleNoteModalClose = () => setNoteModalShow(false);
// Note Modal states end

// Push Notifications Modal States
const [pnModalShow, setPNModalShow] = useState(false);
const handlePNModalShow = () => setPNModalShow(true);
const handlePNModalClose = () => setPNModalShow(false);
// Push Notifications Modal States


const removeDoctor = (patientId, doctorId) => {
    var confirmation = window.confirm('Are you sure you want to remove Doctor from' +
    ` ${patient?.firstname} ` + `${patient?.lastname}` + '?' );

    if(confirmation){
        dispatch(removePatientsDoctor(patientId, doctorId));
    }
}

const sendNotificationHandler = () => {
    dispatch(sendPushNotification(title, message, patient?.notificationToken));
}

  return (
    <>

        <div className="row-display">
            <h5 className="title">Patient Overview</h5>
            <div>
                {patient?.assigned_doctor_id?._id === "65b977be420e3eaf115e4317" &&
                <button className='edit-pill' onClick={handleNoteModalShow}>Blanchard B.P Criteria</button>}
                &nbsp;&nbsp;
                <button className="btn btn-secondary btn-sm"
                    onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                </button> &nbsp;
                <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;

                <button className="edit-pill" onClick={handlePNModalShow}>
                    Send Notification
                </button>
            </div>
        </div>

        <hr/>
        <div className="row-display">
            <div className="col-md-6">
                <div className="row">
                    <div className="text-center  col-6" style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
                        <img src={patient?.gender === 'male' ? patientProfileImg : womanIcon} className="patient-profile-card-img" alt="patientProfile" />
                        <h5 className="title mt-2">{patient?.lastname}, {patient?.firstname}</h5>
                        <p><b>{patient?.emrId}</b></p>

                        <div className="row">
                            <div className='col-6'>
                                <b>D.O.B</b>
                                <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                            </div>

                            <div className='col-6'>
                                <b>Age</b>
                                <p>{moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years</p>
                            </div>
                            <br/>

                            <div className='col-6'>
                                <b>Gender</b>
                                <p>{patient?.gender}</p>
                            </div>

                            <div className='col-6'>
                                <b>Program Enrolled</b>
                                <p>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</p>
                            </div>

                            <div className='col-12'>
                            <b>Devices Assigned </b>
                                <div className="row p-2">
                                {patient?.assigned_devices?.length > 0 ? <>
                                    {patient?.assigned_devices?.length > 0 && patient?.assigned_devices.map((deviceass, index) => (
                                    <div className="col-12" key={index}>
                                        <b>{deviceass?.deviceObjectId?.deviceType === 'bp' ? <span className='bpDeviceType'>B.P</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'weight' ? <span className='weightDeviceType'>Weight</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'sugar' ? <span className='glucoseDeviceType'>Glucose</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'spO2' ? <span className='spo2DeviceType'>Oximeter</span> :
                                            null
                                        }</b>
                                        <br /><br />
                                    </div>
                                ))}
                                </> : 
                                <div className='col-12'>
                                        <p>No Device Assigned</p>
                                    </div>
                                }    
                                    
                                </div>
                            </div>

                            <div className='col-12'>
                                <Link to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}} className="edit-pill" style={{width: '100%', backgroundColor: '#00A8A8', color: '#FAFAFA'}}>
                                    Edit Details
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <b>Home Address</b>
                        <p className="shrink-text">
                            {patient?.address || null}, {patient?.line2 || null} - {patient?.city || null} - {patient?.state || null} - {patient?.zipCode || null}
                        </p>

                        <b>Primary Phone #</b>
                        <p className="shrink-text">
                            {patient?.phone1 || '--'}
                        </p>

                        <b>Email Address</b>
                        <p className="shrink-text link">
                            {patient?.email || '--'}
                        </p>

                        <b>Insurance Company</b>
                        <p className="shrink-text">
                            {patient?.insurancecompany || '--'}
                        </p>

                        <b>Account Created Date</b>
                        <p className="shrink-text">
                            {moment(patient?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </div>
                </div>
            </div>


            <div className="col-md-3">
                <div>
                    <PatientSickness 
                        isAuthenticated={true}
                        patient={patient}
                    />
                </div>
            </div>

            <div className="text-center col-md-3">
                <div className="diseaseSection">
                    <b>Disease(s)</b>
                    <p>{patient?.diseases || '--'}</p>
                </div>
                {/* <br/> */}

                <b>Assigned Doctor</b>
                <br/><br/>
                {patient?.assigned_doctor_id ?
                <div className="chip">
                    <img src={defaultUserImg} alt="Doctor" />
                    <Link to="/patientProfile" className='link text-white'>Dr. {patient?.assigned_doctor_id?.lastname || null}, {patient?.assigned_doctor_id?.firstname || null}</Link>
                    <span className="closebtn" onClick={() => removeDoctor(patient?._id, patient?.assigned_doctor_id?._id)}>
                        &times;
                    </span>
                </div> : <b style={{color: 'red'}}>No Doctor Assigned</b> }

                <br /><br />

                {/* Last Activity Date */}
                <b>First Cycle Completion Date</b>
                <p>{FCC !== null ? moment(FCC?._id).format('MM-DD-YYYY') : <b style={{color: 'red'}}> Not Completed Yet</b>}</p>


                {/* Last Activity Date */}
                <b>
                    Last Activity Date
                </b>
                <p>
                    {lastActivity ? moment(lastActivity).format("MM-DD-YYYY") : null}
                </p>
                
                {patient?.ccmCareplanManualAdded === false && patient?.sickness?.length > 0  ? 
                        <PatientCCMCareplanManual 
                        patient={patient} 
                    />
                    : patient?.sickness?.length > 0 && <b style={{color: 'green'}}>
                        <i className='bx bx-check'></i> CCM Careplan Added
                      </b>
                }
                    
                {patient?.ccmCareplanManualAdded === true ? <PatientCCMCareplanManualView
                    patient={patient} 
                /> : null}
                
                

            </div>
        </div>


        {/* Add Special Instruction Modal */}
        <Modal
            show={noteModalShow}
            onHide={handleNoteModalClose}
            size="lg"
            animation={true}
            backdrop="static">
            <Modal.Header >
                <h5 className="title">Blanchard B.P Criteria</h5>
                <button className='edit-pill'onClick={handleNoteModalClose}>
                    <i className='bx bx-x'></i></button>
            </Modal.Header>

                <Modal.Body>
                <table className="styled-table" style={{backgroundColor: '#EDEADE'}}>
                    <thead align="center">
                        <tr>
                            <th width="80px">Blanchard</th>
                            <th width="150px">Systolic</th>
                            <th width="150px">Diastolic</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                       <tr>
                            <td><b>Above</b></td>
                            <td>140</td>
                            <td>90</td>
                            <td>Create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>110</td>
                            <td>60</td>
                            <td>Create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>150</td>
                            <td>90</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>170</td>
                            <td>100</td>
                            <td>Call or text provider</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call or text provider</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>180</td>
                            <td>110</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>90</td>
                            <td>50</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr>
                    </tbody>
                </table>
                </Modal.Body>
            </Modal>
            {/* Extra note modeal ends here*/}

            {/* Push Notification Modal */}
            <Modal
                show={pnModalShow}
                onHide={handlePNModalClose}
                size="md"
                animation={true}
                backdrop="static"
            >
            <Modal.Header >
                <h5 className="title">Send Push Notification</h5>
                <button className='edit-pill'onClick={handlePNModalClose}>
                <i className='bx bx-x'></i></button>
            </Modal.Header>

            <Modal.Body>

            <div>
                <label className="form-label mt-3">Title</label>
                    <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        required
                        placeholder='Enter Title'
                    />
            </div>

            <div>
                <label className="form-label mt-3">Message</label>
                <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter message here ..."
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                >
                </textarea>
            </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="edit-pill" onClick={sendNotificationHandler}>
                    Send
                </button>
            </Modal.Footer>
            </Modal>
    </>
  )
}

export default PatientOverview
