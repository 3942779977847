import  {useState, useEffect} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientRPMTargetedMinutes } from '../../actions/HRActions';
import { getRemainingReadings} from '../../actions/adminActions';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { ProgressBar } from 'react-bootstrap';


const RPMMinutesProgress = ({patientId}) => {

    const dispatch = useDispatch();

    const { rpmTotalMinutes } = useSelector(state => state.targetedMinutes);
    const { count } = useSelector(state => state.readingsCount);
    const [pagination, setPagination] = useState(2);
    
    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState('2024');

    // Get Readings count
    let start = `${moment().clone().startOf('month').format('YYYY/MM/DD')}`;
    let end = `${moment().clone().endOf('month').format('YYYY/MM/DD')}`;


    useEffect(() =>{

        dispatch({ type: RESET_TIME_REPORT_DATA });

        dispatch(getPatientRPMTargetedMinutes(patientId, month, year, pagination));
        dispatch(getRemainingReadings(patientId, start, end));

    }, [])
    
    const searchHandler = () => {
        let strtDate = `${year}/${month}/01`;   
        let edDate = `${year}/${month}/31`;

        dispatch(getPatientRPMTargetedMinutes(patientId, month, year, pagination)); 
        dispatch(getRemainingReadings(patientId, strtDate, edDate));
    }    

  
    return (
    <>

    {/* Select box for selecting month  */}
    <div className="row-display">
        <select name="month" 
            className="form-control" 
            placeholder="Select Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            >
            <option value="01">Jan</option>
            <option value="02">Feb</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">Aug</option>
            <option value="09">Sept</option>
            <option value="10">Oct</option>
            <option value="11">Nov</option>
            <option value="12">Dec</option>
        </select>
        &nbsp;
        <select 
            name="month" 
            className="form-control" 
            placeholder="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
        </select>
        &nbsp;
        <button type="submit" name="submit" className="edit-pill m-2" onClick={() => searchHandler()}>
            <i className='bx bx-search'></i>
        </button>
    </div>

    <br />
            <small>

                <div className="row-display">
                    <h5>Readings Count</h5> <br/>
                    <h3>{count} / 16 </h3> 
                </div>

                <div className="row-display">
                    <h5>CPT Code: </h5> <br/>
                    <h5>{count >= 16 ? 
                        <span style={{opacity: 1, color: 'green'}}>99454</span> : 
                        <span style={{opacity: 0.3}}>99454</span>} 
                    </h5> 
                </div>    

                <hr className="blue-hr"/>               
                <div>
                    <h5>RPM Minutes Added:</h5>
                    <div className='row-display-secondary'>
                        <h1>{rpmTotalMinutes} mins / 100</h1>
                    </div>
                    {rpmTotalMinutes && rpmTotalMinutes > 0 && rpmTotalMinutes < 100 ? <>
                        <ProgressBar striped animated variant="info" now={rpmTotalMinutes} label={`${rpmTotalMinutes}%`}/>
                    </> : rpmTotalMinutes >= 100 ? <p className="text-center">
                        <b style={{color: 'green', letterSpacing: '3px'}}>RPM Minutes Completed</b>
                        </p>
                        : null }
                    <br />
                </div>

                <h5>CPT Codes Calculation: </h5> 
                
                {rpmTotalMinutes >= 20 && rpmTotalMinutes < 40 ?
                    <div className="row-display cptCompleted">
                        <h4><span >99457</span></h4>
                    </div> : rpmTotalMinutes >= 40 && rpmTotalMinutes < 60 ?  
                    <div className="row-display cptCompleted">
                        <h4><span >99457,99458</span></h4>
                    </div>
                    : rpmTotalMinutes >= 60 && rpmTotalMinutes < 80 ?  
                    <div className="row-display cptCompleted">
                        <h5><span >99457,99458, 99458</span></h5>
                    </div> : 
                     rpmTotalMinutes >= 80 && rpmTotalMinutes < 100 ?  
                    <div className="row-display cptCompleted">
                        <h5><span >99457,99458, 99458, 99458</span></h5>
                    </div>
                    : 
                     rpmTotalMinutes >= 100 ?  
                    <div className="row-display cptCompleted">
                        <h5><span >99457,99458, 99458, 99458, 99458</span></h5>
                    </div> : null}
                </small>
    </>
  )
}

export default RPMMinutesProgress