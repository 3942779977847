import { useState, useEffect} from 'react';
import HRSidebar from '../../components/HR/HRSidebar';
import HRTopbar from '../../components/HR/HRTopbar';
import MetaData from '../../layouts/MetaData';
import defaultImg from '../../assets/Images/defaultUser.png';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getMessages, submitReply } from '../../actions/adminActions';
import Loader from '../../layouts/Loader';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import assistantImg from '../../assets/Images/assistant.png';


const HRMessages = () => {

 const dispatch = useDispatch();
 const alert = useAlert();
 const history = useHistory();

    
 const {error, message} = useSelector(state => state.common);
 const {loading, messages} = useSelector(state => state.messages);
 
 const [answer, setAnswer] = useState('');
 
 const { user} = useSelector(state => state.auth);

 let hrId = user?._id;
 let repliedBy = user?.firstname +" "+ user?.lastname;

   useEffect(() => {
        if(message){
            alert.success(message);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }
    
        if(error){
            alert.error(error)

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }
        
        dispatch(getMessages({getFor:"nurse"}));
    
      },[dispatch, alert, error, message])
    
    const replyHandler = (questionId) => {
        dispatch(submitReply(questionId, answer, repliedBy ));
    }
    

  return (
    <>
    <MetaData title="Messages"/>
        <HRSidebar />    

        <section className="home-section">
        {/* TopBar */}
        <HRTopbar />

        <br/>
        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

            <div className="row-display">
                <div className="col-md-6">   
                    <h5 style={{color: '#00A8A8', marginTop: '10px'}}>
                        <i className="bx bx-user"></i> <strong>&nbsp;Messages </strong>
                    </h5>
                </div>
                
                <div className="row-display">
                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                        <i className='bx bx-arrow-back' ></i>
                    </button>
                </div>                          
            </div>
            <hr />

            {loading ? <Loader /> : <>
            {messages && messages?.length > 0 ? <>
            {messages.map((message, index) => (
                <div key={index} className="ml-5">
                <div className="row-display-secondary">
                    <div className="mt-3 mr-3">
                        <img src={assistantImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                    </div>    
                    <div className="mt-4">
                    <small>Asked : {moment(message?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}
                        <b className="ml-3">Asked By : <span style={{color:'dodgerblue'}}>( Assistant ) &nbsp;{message?.assigned_assistant_id?.lastname}  {message?.assigned_assistant_id?.firstname} </span></b> 
                        <b className="ml-3">
                            To: <span style={{color:'dodgerblue'}}>( {message?.assigned_hr_id?.role} ) &nbsp; {message?.assigned_hr_id?.firstname} {message?.assigned_hr_id?.lastname} </span>
                        </b>
                        <b className="ml-3">
                            Related To Patient: <span style={{color: 'dodgerblue'}}> {message?.assigned_patient_id?.lastname} {message?.assigned_patient_id?.firstname} </span>
                        </b> 
                        </small>
                        <p><b>{message?.question} ?</b></p>
                    </div>
                </div>

                {message?.assigned_hr_id?._id === hrId ? <>
                    <div className="row mt-4">
                    <div className="col-md-10">
                        <input type="text" 
                            className="form-control"
                            key={message?._id}
                            placeholder="Type your reply here ....."
                            onChange={(e) => setAnswer(e.target.value)}
                        />
                    </div>
    
                    <div className="col-md-2">
                        <button className="edit-pill mt-2" type="submit" onClick={() => replyHandler(message?._id)}>Reply</button>
                    </div>
                </div>
                </> : <div className="text-center">
                        <p style={{color: 'red'}}>Only Subjected Nurse can reply.</p>
                    </div>}


                {message?.answers?.map((answers, index) => (
                <div key={index} className="mt-3 ml-4">
                <div className="row-display-secondary">
                    <div className="mr-3">
                        <img src={defaultImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                    </div>
                    <div>  
                        
                        <b>Replied By : {answers?.repliedBy}</b><br/>
                                            
                        <p className="mt-1 mr-3">{answers?.answer} <br/> 
                            <div className="row-display-secondary">
                                
                                {answers?.approvalStatus === true ? 
                                <div className="row">
                                <p className="mt-1 ml-3" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                    <small className="approvedBadge ml-4">Approved by admin</small>
                                    <small style={{color: 'green'}} className="ml-4">+1 minute added</small> 
                                </div> : 
                                <div className="row">
                                <p className="mt-1 ml-3" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                    <small className="declinedMessageBadge ml-4">Sent for approval</small>
                                </div>}
                            </div>
                        </p>
                    </div>
                </div>
                </div>
                ))}
                <hr className='blue-hr'/>
                </div>
            ))}
            
            </> : <></>}
        
            </>}

            </div>
        </div>
           
        </section>
    </>
  )
}

export default HRMessages;