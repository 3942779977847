import { useState, useEffect } from 'react';
import Sidebar  from '../../components/AdminDashboard/Sidebar';
import TopBar  from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDevices, sortRPMDevicesByBroken, sortRPMDevices, deleteRPMDevice } from '../../actions/adminActions';
import { useAlert } from 'react-alert'; 
import Loader from '../../layouts/Loader';
import Pagination from 'react-js-pagination';
import { Modal, ProgressBar } from 'react-bootstrap';
import InventoryAnalytics from '../../components/inventory/InventoryAnalytics';
import moment from 'moment';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import { 
    SORT_DEVICES_REQUEST,
    SORT_DEVICES_SUCCESS,
    SORT_DEVICES_FAIL,
} from '../../constants/adminConstants';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';



const RPMDevices = (props) => {

    const dispatch = useDispatch();

    const alert = useAlert();

    const { loading, deviceCount, devices } = useSelector(state => state.devices);
    const {message, error } = useSelector(state => state.common);

    const [deviceModel, setDeviceModel] = useState(null);
    const [deviceToDelete, setDeviceToDelete] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); //for pagination, current page
    const [resPerPage, setResPerPage] = useState(20); //for pagination, how many responses we want to show
    
    const [input, setInput] = useState("");

    const [smShow, setSmShow] = useState(false); //small confirm modal

    useEffect(() => {
        if(error){
        alert.error(error);

        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
        }

        if(message) {
            alert.success(message);
            props?.history.push('/devices');
            setSmShow(false);    

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        dispatch(getAllDevices(resPerPage, currentPage));

    }, [dispatch, alert, error, message, currentPage])

    const sortDevices = (event) => {
        let updatedValue = event.target.value;

        if (updatedValue === "true" || updatedValue == "false") {
            updatedValue = JSON.parse(updatedValue);
        }
        dispatch(sortRPMDevices(updatedValue));
    }

    const sortDevicesByBroken = (event) => {
        let sortValue = event.target.value;
        dispatch(sortRPMDevicesByBroken(sortValue));
    }

    const deleteHandler = () => {
        dispatch(deleteRPMDevice(deviceToDelete));
    }

    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    } 

    const refreshHandler = () => {
        dispatch(getAllDevices(resPerPage, currentPage));
        setInput('');
    }


    const searchHandler = async() => {
        if(input?.length > 0){
            try {
                dispatch({ type: SORT_DEVICES_REQUEST })
        
                const { data } = await axios.post(`${Prod01}/device/search`, {
                    value: input
                }, {
                    timeout: 10000 // timeout of 10 secs
                });
                
                dispatch({
                    type: SORT_DEVICES_SUCCESS,
                    payload: data
                })    
            } 
            catch (error) {
                if (error.code === 'ECONNABORTED') {
                    dispatch({
                        type: SORT_DEVICES_FAIL,
                        payload: 'request timed out'
                    });
                } else {
                    dispatch({
                        type: SORT_DEVICES_FAIL,
                        payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                    });
                }
            }
        } else {
            alert.error('Please type something to search')
        }
        
      }

    return (
        <>
              <MetaData title="RPM Devices "/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />


                {loading ? <Loader /> : ( <>
                <br/>    
                <div className="shadow-lg p-3 mb-2 mr-4 ml-4 background-white">
                    <div className="home-content">
                    
                    <div className="row-display">
                        
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bxs-devices'></i> <strong>&nbsp;Inventory Management </strong>
                        </h5>
                            
                        <div className="row-display">
                            <Link to="/adminDashboard" className="btn btn-secondary pt-2"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                            <button className="btn btn-secondary pt-2" onClick={refreshHandler}><i className='bx bx-refresh'></i></button> &nbsp;
                            <Link to="/device" className="edit-pill">
                                Add New Device
                            </Link>
                        </div>
                    </div>
                    <hr />

                     {/* Component of devices list */}
                    <InventoryAnalytics />        
                     {/* Devices List Card */}
                    <br />

                            <div className="searchArea row-display m-2">
                                
                                <div className="row">
                                    <div className="mini-searchBox ml-4" >
                                        <input 
                                            type="text" 
                                            placeholder="Search by IMEI & Device ID . . . " 
                                            className="mini-searchInput" 
                                            value={input}
                                            onChange={e => setInput(e.target.value)}
                                            autoFocus
                                            maxLength="30"
                                        />
                                        <i className='bx bx-search mini-searchbar-Icon'></i>
                                    </div>

                                    <button className="edit-pill m-2" onClick={() => searchHandler()}>Search</button>
                                </div>

                                

                                    <div className="row-display-secondary m-2">
                                        <select name="listDevice" 
                                            className="form-control shadow-none"
                                            defaultValue={'List Device By'}
                                            onChange={sortDevices}
                                        >
                                            <option disabled>List Device By</option>
                                            <option value="true"> In Stock</option>
                                            <option value="false"> Assigned </option>
                                        </select>
  
                                        
                                        <div>
                                            <select name="listDevice" 
                                                className="form-control"
                                                defaultValue="default"
                                                onChange={sortDevicesByBroken}
                                                style={{width: '150px'}}
                                            >
                                                <option value="default">Sort By</option>
                                                <option value="bp"> Cuff</option>
                                                <option value="weight"> Weight Scale</option>
                                                <option value="sugar"> Glucose Meter</option>
                                                <option value="spO2"> Spo2</option>

                                            </select>
                                        </div>
                                        
                                        <Link to="/Admin/Inventory/Download" className="edit-pill m-1">
                                            <i className='bx bxs-download'></i> 
                                        </Link>
                                        
                                </div>
                            </div>
                        </div>
                
                        <br /> 

                        <div className="col-md-12">
                         <>
                         {devices && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                    <th>Device ID</th>
                                    <th>IMEI</th>
                                    <th>Device Type</th>
                                    <th>Status</th>
                                    <th>Signal</th>
                                    <th>Battery</th>
                                    <th>Assigned To</th>
                                    <th>Vendor</th>
                                    <th>Action</th>
                                </tr> 
                            </thead>

                            <tbody>      
                            {devices && devices.map((device, index) => (                      
                                <tr align="center" key={index}>
                                    <td><Link className="link" to={{ pathname:"/devicedetails", state: {id: device?._id}}}>{device?._id}</Link></td>
                                    
                                    <td style={{fontWeight: 'bold'}}>{device?.imei ? device?.imei : 'N/A'}</td>
                                    
                                    <td><b>{device?.deviceType === 'bp' ? <span className='bpDeviceType'>B.P</span> : 
                                    device?.deviceType === 'weight' ? <span className='weightDeviceType'>Weight</span> : 
                                    device?.deviceType === 'sugar' ? <span className='glucoseDeviceType'>Glucose</span> :
                                    device?.deviceType === 'spO2' ? <span className='spo2DeviceType'>Oximeter</span> :
                                    null
                                    }</b></td>
                                    {device?.assigned_patient_id ? <td style={{color: 'green', fontWeight: 'bold'}}>Assigned</td> : <td style={{color: 'dodgerblue', fontWeight: 'bold'}}>In Stock</td>}
                                    
                                    <td>{device?.signal?.sig >= 0 && device?.signal?.sig <= 10 ? 
                                        <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : device?.signal?.sig > 10 && device?.signal?.sig <= 20 ? 
                                        <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : device?.signal?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : null} 
                                        <p>{device?.signal?.sig ? moment(device?.dateAdded).format("MM/DD/YYYY") : null} &nbsp;
                                            {device?.signal?.sig ? device?.time : null}
                                        </p>
                                    </td>

                                    <td>{device?.signal?.bat ? <ProgressBar striped variant="info" now={device?.signal?.bat} label={`${device?.signal?.bat}%`} /> : null} </td>

                                    <td>{device?.assigned_patient_id ? <span style={{ color: '#00A8A8', fontWeight: 'bold'}}> {device?.assigned_patient_id?.lastname}, {device?.assigned_patient_id?.firstname} </span> : 'N/A'}</td>

                                    <td><b style={{textTransform: 'capitalize'}}>{device?.vendor}</b></td>    
                                    {/* Actions */}
                                    <td>
                                    <div className="row-display">
                                        <Link to={{ pathname:"/devicedetails", state: {id: device?._id}}} className="link"><i className="fa fa-user"></i></Link>
                                        <Link to={{ pathname:"/updatedevice", state: {deviceDetails: device}}} className="link"><i className="fa fa-edit"></i></Link>
                                        <Link to="/devices" className="link" style={{color: 'red'}} onClick={() => {setSmShow(true); setDeviceModel(device?._id); setDeviceToDelete(device?._id)}}><i className="bx bx-folder-minus"></i></Link>

                                    </div>
                                    </td>
                                    {/* Actions ended*/}
                                </tr>                      
                            ))}
                             </tbody>
                            </table>  
                            <small style={{color: 'gray'}}>Showing {resPerPage} records per page</small>  

                            {resPerPage <= deviceCount && (
                            <div className="d-flex justify-content-center mt-5"> 
                                <Pagination activePage={currentPage} 
                                itemsCountPerPage={resPerPage} 
                                totalItemsCount = {deviceCount}
                                onChange={setCurrentPageNumber} 
                                nextPageText = {'⟩'}
                                prevPageText = {'⟨'}
                                firstPageText = {'«'}
                                lastPageText = {'»'}
                                itemClass='page-item'
                                linkClass="page-link"
                                />           
                            </div>
                            )}  
                            </> 
                            } 
                          </>                
                         </div>
                        </div>

                        <Modal
                            size="sm"
                            show={smShow}
                            onHide={() => setSmShow(false)}
                            aria-labelledby="example-modal-sizes-title-sm"
                        >
                            <Modal.Body>
                                <small style={{color: 'gray'}}>Are you sure you want to delete RPM device having model number
                                    <span style={{color: '#000'}}> {deviceModel}</span> ?
                                 </small>
                            </Modal.Body>

                            <Modal.Footer>
                                <button className="btn btn-sm btn-danger" onClick={deleteHandler}>Delete</button>
                            </Modal.Footer>
                        </Modal>
                    </> 
                    )}
                </section>     
        </>
    )
}

export default RPMDevices
