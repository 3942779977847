import Header from "../components/LandingPage/Header";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {isVerificationSaved} from "../actions/authActions";


const LandingPage = ({history}) => {

    const { isAuthenticated, user } = useSelector(state => state.auth);
    useEffect(() => {
        if(isVerificationSaved(user?.role, user?.email)) {

            if (isAuthenticated === true && user.role === 'admin') {
                history.push("/adminDashboard");
            }
            if (isAuthenticated === true && user.role === 'HrMedical') {
                history.push("/Nurse/Dashboard");
            }
            if (isAuthenticated === true && user.role === 'doctor') {
                history.push("/doctor/dashboard");
            }
            if (isAuthenticated === true && user.role === 'assistant') {
                history.push("/clinical_staff/dashboard");
            }
        }

    }, [isAuthenticated, user.role]);

    return (
        <>
            <div>
                <Header />
            </div>
        </>
    )
}


export default LandingPage
