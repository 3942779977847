import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';

const DoctorWisePatientGraph = ({ orgPts }) => {
  const data = [];

  const colors = ['#FDA005', 
    '#EC506C', 
    '#02C78A', 
    '#00A8A8', 
    '#92A8D1', 
    '#FF6F61', 
    '#88B04B', 
    '#F4C724', 
    '#F19CBB', 
    '#FF6347', 
    '#BA55D3', 
    '#FFD700',
    '#4B0082',
    '#8A2BE2',
    '#E69138'
  ]; // Custom color scheme

  orgPts &&
  orgPts.filter(item => item?.totalPatientCount > 100).forEach((org, index) => {
      data.push({
        name: `${org?.name}`,
        patientCount: org?.totalPatientCount,
        fill: colors[index % colors.length], // Use modulus to cycle through colors array
      });
    });

  return (
    <div>
        <div className='shadow-lg background-white'>
        <h5 className='title pl-3 pt-3'> Organizationwise Patients</h5>
        <RadialBarChart
          width={400}
          height={270}
          cx={200}
          cy={200}
          innerRadius={20}
          outerRadius={140}
          startAngle={180}
          endAngle={0}
          barSize={40}
          data={data}
        >
        <PolarAngleAxis
          type="number"
          // domain={[0, 25]}
          angleAxisId={0}
          tick={false}
      />
      <RadialBar
        label={{ position: 'insideStart', fill: '#000' }} dataKey="patientCount" 
      />
      


    </RadialBarChart>

    <div className='container row'>
        {orgPts && orgPts.filter(item => item?.totalPatientCount > 5).map((org, index) => (
          <div style={{width: '100%', padding: '5px', margin: 0, padding: 0}} key={index}>
          <i className='bx bxs-circle ml-2' style={{fontSize: '15px', color: colors[index]}}></i> &nbsp;
              <b style={{color: colors[index]}}>{org?.name} &nbsp;&nbsp; {org?.totalPatientCount}</b>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default DoctorWisePatientGraph;
