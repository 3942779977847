import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ isAdmin, component: Component, ...rest }) => {
    
    const { isAuthenticated, user } = useSelector(state => state.auth);

    return (
        <>
            {user?.role === 'admin' && isAdmin === true ? (
                <Route {...rest} render={props => {
                    
                    if(isAuthenticated === false){
                        <Redirect to="/" />
                    }
                    
                    else if(isAdmin === true && user?.verified === true && isAuthenticated === true){
                        <Redirect to="/adminDashboard" />
                    }
                    
                    return <Component {...props} />
                }}
                />
            ) : <Route to="/auth/signin/admin" /> 
        }
        </>
    )
}

export default ProtectedRoute;