import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {getRecentReadings, getDoctors, forwardTelemetryData} from '../../actions/adminActions';
import {Modal} from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import { useAlert } from "react-alert";
const moment = require('moment-timezone');



const RecentReadings = () => {
    const dispatch = useDispatch();
    const alert = useAlert();

    const defaultData = JSON.stringify({
        deviceId: "59882000658",
        data: {
          ihb: true,
          pul: 78,
          tz: "UTC-5",
          tri: false,
          sys: 181,
          sig: 8,
          iccid: "8944500711213728643f",
          bat: 100,
          data_type: "bpm_gen2_measure",
          imei: "11125568448798",
          sn: "100234501703",
          user: 1,
          dia: 81,
          hand: false,
          ts:1709151434
        }
      });

    const [filterRecent, setFilterRecent] = useState('bp');
    const [pagination, setPagination] = useState(25);
    
    const [dataToSend, setDataToSend] = useState(defaultData);

    const [doctorId, setDoctorId] = useState('default');

    const {count, recentReadings} = useSelector(state => state.recentReadings);
    const { doctors } = useSelector(state => state.doctor);
    const { message, error } = useSelector(state => state.common);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let role = "admin";
    let sysBPCategory; //variable to store category of BP
    let color;

    let currentPage;

    const updateReadingsPagination = () => {
        setPagination(pagination + 25);
        dispatch(getRecentReadings(pagination, filterRecent));
    }

    const handleReadingToPush = () => {
        if(dataToSend === " " || setDataToSend === undefined) {
            alert.error('cannot send empty data');
        }
        dispatch(forwardTelemetryData(dataToSend));
    }

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            handleClose();
            setDataToSend(defaultData);

            alert.success(message);
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }


        if(doctorId === 'default'){
            dispatch(getRecentReadings(pagination, filterRecent));
        } else if(doctorId) {
            dispatch(getRecentReadings(pagination, filterRecent, doctorId));
        }
        
        dispatch(getDoctors(50, currentPage = 1));

    }, [pagination,filterRecent, doctorId, message, error]);

    const refreshRecentReadings = () => {
        dispatch(getRecentReadings(pagination, filterRecent));
    }

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }

        function getTimeDifference(dateTime) {
            const currentDateTime = new Date();
            const targetDateTime = new Date(dateTime);
        
            // Calculate the difference in milliseconds
            const difference = currentDateTime - targetDateTime;
        
            // Convert milliseconds to seconds, minutes, hours, and days
            const seconds = Math.floor(difference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
        
            if (days > 0) {
                return days === 1 ? "1 day ago" : `${days} days ago`;
            } else if (hours > 0) {
                return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
            } else if (minutes > 0) {
                return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
            } else {
                return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
            }
        }
        

    return (
    <div className="shadow-lg m-1 recent-readings-card background-white">
                        
                        <div className="row-display">
                            
                            <div>
                                <h5 className='title m-0 p-0'>Recent Vitals</h5>
                                <p><small>Showing <b>{recentReadings?.length}</b> results out of <b>{count && count}</b> </small></p>
                            </div>

                            <div className="row-display">
                                <button className="edit-pill m-3" onClick={() => refreshRecentReadings()}>
                                    <i className="bx bx-refresh"></i>
                                </button>

                                <button className="edit-pill m-3" onClick={handleShow}>
                                    Push Reading
                                </button>
                            </div>
                            <br/>
                        </div>
                        
                        <hr className="mt-1 p-0"/>
                         <p className="profile-label">Filter by Readings Category.</p>       
                        <div className="row-display">
                            <span className={`smallFilterBtn ${filterRecent === 'bp' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterRecent('bp')}
                                >Cuff Data
                            </span>
                            
                            <span className={`smallFilterBtn ${filterRecent === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterRecent('weight')}>
                                Weight Data
                            </span>

                            <span className={`smallFilterBtn ${filterRecent === 'sugar' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterRecent('sugar')}>
                                Glucose Meter
                            </span>

                            <span className={`smallFilterBtn ${filterRecent === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterRecent('spo2')}>
                                spo2
                            </span>
                        </div>
                        <hr className="mt-2 p-0"/>

                        <p className="profile-label">Filter by Doctor.</p>

                        <select name="readingFilter" 
                            value={doctorId} 
                            onChange={e => setDoctorId(e.target.value)} 
                            className="form-control"
                            >
                            <option value="default">Select Doctor</option>
                            {doctors && doctors.map((doctor, index) => (
                                <option value={doctor?._id} key={index}>Dr. {doctor?.lastname}, {doctor?.firstname}</option>
                            ))}
                        </select>

                        <hr />
                        

                        {recentReadings && recentReadings.map((pts, index) => (
                            <div key={index}>
                            {filterRecent === 'bp' && pts?.telemetaryData?.sys && pts?.telemetaryData?.dia ? <>
                                <div key={index} className='mt-3'>
                                    <div>
                                    {role === "assistant" ? <Link to={{ pathname: "/clinical_staff/patient", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link> : <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link>}
                                    <br/>
                                    <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                                </div>
                             

                                {calcSysBpCategory(pts?.telemetaryData?.sys)} 

                                <div className="row-display">
                                    <div>
                                        <small>Sys / Dia</small>
                                    </div>

                                    <div className="row-display">
                                        <h1 className='display-5'>{pts?.telemetaryData?.sys} &nbsp;</h1> 
                                        <h1 className='display-5'>/ &nbsp;</h1>
                                        <h1 className="display-5">{pts?.telemetaryData?.dia}</h1>          
                                    </div>
                                </div>

                            <div id="scroll-container">
                                <div id="scroll-text" style={{color: color, letterSpacing: '1px'}}>{sysBPCategory}</div>
                            </div>

                            <p style={{float: 'right'}}>
                                {moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}
                                &nbsp;&nbsp;&nbsp;
                                {pts?.telemetaryData?.ts ? 
                                    <b style={{color: '#00A8A8'}}>{getTimeDifference(pts?.telemetaryData?.ts * 1000)}</b>
                                    : null 
                                }
                            </p>

                            <br/>

                            </div>

                            </> : filterRecent === 'weight' && pts?.telemetaryData?.wt ? <div className='mt-4'>
                                <div key={index}>
                                    {role === "assistant" ? <Link to={{ pathname: "/clinical_staff/patient", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link> : <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link>}
                                    <br/>
                                    <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                                </div>

                                <div className="row-display">
                                    <div>
                                        <small>Weight Scale</small>
                                    </div>

                                    <div className="row-display-column">
                                        <h1 className='display-5'>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;<small>Kg</small> </h1>
                                        <h3>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</h3>           
                                    </div>

                                </div>

                                    <p style={{float: 'left'}}>
                                        <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                                    </p>
                            <br/>                          
                            </div> : filterRecent === 'sugar' && pts?.telemetaryData?.data ? <div className="mt-4">
                            <div key={index}>
                                    {role === "assistant" ? <Link to={{ pathname: "/clinical_staff/patient", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link> : <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link>}
                                    <br/>
                                    <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>
                                </div>

                                <div className="row-display">
                                    <div>
                                        <small>Glucose Monitor</small>
                                    </div>

                                    <div className="row-display-column">
                                        <div className="row-display-secondary">
                                            <h1 className='display-5'>{pts?.telemetaryData?.data} &nbsp;</h1> 
                                            <p>{pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}</p>
                                        </div>
                                         
                                        <p><b>Reading Taken: </b>{pts?.telemetaryData?.meal === 0 ? 'Not Selected' : pts?.telemetaryData?.meal === 1 
                                            ? 'Before Meal' : pts?.telemetaryData?.meal === 2 ? 
                                            'After Meal' : null
                                            }
                                        </p>           
                                    </div>
                                </div>

                                <p style={{float: 'left'}}>
                                    <small>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</small>
                                </p>    
                                <br/>
                            </div> : filterRecent === 'spo2' && pts?.telemetaryData?.notification_payload ? <>
                            <div key={index} className="mt-3">                    
                            {role === "assistant" ? <Link to={{ pathname: "/clinical_staff/patient", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link> : <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">
                                        {pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } 
                                    </Link>}
                                    <br/>
                                    <small className="text-muted"><b>{'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname}</b></small>

                            <div className="row-display">
                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.pulse_bpm}</h1>
                                    <b className="m-0 p-0">Pulse BPM</b>        
                                </div>

                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.spo2_pct}</h1>
                                    <b className="m-0 p-0">Spo2 PCT</b>        
                                </div>

                                <div className="row-display-column ml-3">
                                    <h1 className="display-4 m-0 p-0">{pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</h1>
                                    <b className="m-0 p-0">Perfusion</b>        
                                </div>
                            </div>
                            
                            <br/>                    
                                <p><b>Reading Type : </b>{pts?.telemetaryData?.notification_payload?.reading_type}</p>
                                <p><b>Sent Date : </b>{moment(pts?.telemetaryData?.notification_payload?.ts_recorded * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</p>
                                <p><b>Received Date : </b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</p> 
                                
                            <br/>
                            </div>
                            </>
                             : null}
                            </div>
                        ))}


                        {recentReadings?.length < count && <>
                            <br/><br/>
                                <div className='text-center'>
                                    <button className='edit-pill' onClick={() => updateReadingsPagination()}> Show more </button>
                                </div>
                            <br/><br/>
                        </>}

                        <Modal show={show} onHide={handleClose} size="lg">
                            <Modal.Header closeButton>
                            <h5 className='title'>Add Reading Manually</h5>
                            </Modal.Header>
                            <Modal.Body>
                                <b>Note : </b> Here you can add readings manually of BP, Weight and glucometer.
                                <br/><br/>
                                <label className='form-label'>Add Reading</label>
                                <textarea
                                    id="21566"
                                    name="addReading"
                                    rows={7}
                                    cols={40}
                                    className="form-control"
                                    value={dataToSend}
                                    onChange={(e) => setDataToSend(e.target.value)
                                }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                            <button className="edit-pill" onClick={() => handleReadingToPush()}>
                                Push
                            </button>
                            </Modal.Footer>
                        </Modal>
                        
                    </div>
  )
}

export default RecentReadings