import { useState, useEffect } from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { getDoctors, doctorProfile, assignDoctorToOrganization } from '../../actions/adminActions';
import patientProfileImg from '../../assets/Images/patientProfile.png';
import {useDispatch, useSelector} from 'react-redux';
import { useAlert } from 'react-alert';
import moment from 'moment';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';


const AssignOrganizationToDoctor = (props) => {
    
    const history = useHistory();

    let orginfo = props?.location?.state?.orgInfo;
    
    const orgId = orginfo?._id;

    const dispatch = useDispatch();
    const alert = useAlert();    
    
    const [doctorId, setDoctorId] = useState('')

    const { error, doctors} = useSelector(state => state.doctor);
    const { doctor } = useSelector(state => state.doctorProfile);
    const { user } = useSelector(state => state.auth);
    const {error : commonError, message } = useSelector(state => state.common);

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(commonError) {
            alert.error(commonError);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(message){
            alert.success(message);
            history.push('/organizations');

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        dispatch(getDoctors(50, 1));

        if(doctorId){
            dispatch(doctorProfile(doctorId))
        }
        
    }, [dispatch, alert, error, commonError, message, doctorId]);

    const assignDoctor = (doctorId) => {
        dispatch(assignDoctorToOrganization(orgId, doctorId, user?._id))
    }

    const funcSetDrId = (dr) =>{
        setDoctorId(dr.value);
    }

    const options = []
    doctors && doctors.filter(item => !item?.assigned_org_id).map((doc) => (
       options.push({value:doc?._id , label:[doc?.firstname, doc?.lastname, doc?.npinumber].join(" ") })
    ))
  
    return (
    <>
    <MetaData title="Assign Doctor To Organization"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="col-md-0 shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                <div className="row-display">    
                    <h5 className="title mt-2">
                        <i className='bx bx-plus-medical'></i> <strong>&nbsp;Assign Doctor To {orginfo?.name} </strong>
                    </h5>

                    <div>
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                        <i className='bx bx-home' ></i></Link> &nbsp;
                    </div>
                </div>
                <hr />
                
                <div className="row">
                    <div className="col-md-4">
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bx-search-alt-2' ></i> <strong>&nbsp;Select Doctor </strong>
                        </h5>

                        <br/>    
                        <span><b style={{color: 'red'}}> Note : </b> One doctor could enrolled in only one organization. </span>
                        <br/><br/>
                            <Select
                                options={options}
                                onChange={funcSetDrId}
                            />
                        
                    </div>
                    

                    <div className="col-md-8">
                        

                        {doctorId && doctor && <>
                            <h5 style={{color: '#00A8A8'}} className="mt-4">
                                 <strong>&nbsp;Doctor Details </strong>
                            </h5>
                            <br />
                                    <div className="col-md-12">
                                        <div className="row">
                                            <img src={patientProfileImg} className="patient-profile-card-img" alt="patientProfile" />
                                            <p className="profile-name pl-3 pb-2">Dr. {doctor?.lastname}, {doctor?.firstname} </p>
                                        </div>

                                        <br />

                                    <div className="row">
                                        <div className="col-md-4">    
                                            <span className="profile-label">Email Address: </span>
                                            <p className="profile-value-text" style={{wordWrap: 'break-word'}}>{doctor?.email ? doctor?.email : 'N/A'}</p>

                                            <span className="profile-label">License #: </span>
                                            <p className="profile-value-text">{doctor?.licensenumber || 'N/A'}</p>    
                                        </div>

                                        <div className="col-md-4">    
                                            <span className="profile-label">D.O.B: </span>
                                        <p className="profile-value-text">{moment(doctor?.DOB).format("MM/DD/YYYY")}</p>

                                        <br/>
                                        <span className="profile-label">Role: </span>
                                            <p className="profile-value-text">{doctor?.role}</p>                                 
                                        </div>
                                        

                                        <div className="col-md-4">    
                                            <span className="profile-label">Gender: </span>
                                            <p>{doctor?.gender === 'male' ? 'Male' : 'Female'}</p>

                                        <br/>
                                        <span className="profile-label">NPI: </span>
                                            <p className="profile-value-text">{doctor?.npinumber ? doctor?.npinumber : 'N/A'}</p>                                
                                        </div>
                                    </div>
                            </div>
                            <button className="add-staff-btn mr-5" style={{ float: 'right' }} onClick={() => assignDoctor(doctor?._id)}> Assign Doctor </button>             
                        </>}
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AssignOrganizationToDoctor
