import { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal,ProgressBar} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { getDoctorPatients,
         patientProfile, 
         assignRPMDeviceToPatient, 
         getDeviceDetails, 
         getDeviceSignalHistory,
         getDoctors 
        } from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import patientProfileImg from '../../assets/Images/user.png';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';



const RPMDeviceBasicInformation = (props) => {

    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();

    let deviceDetails = props?.deviceData;

    const { doctorpatients } = useSelector(state => state.docPatients);
    const { doctors, error } = useSelector(state => state.doctor);
    const { patient } = useSelector(state => state.patientProfile);
    const { error: deviceError, isUpdated} = useSelector(state => state.device);
    const {error:commonError, message} = useSelector(state => state.common);
    const { signalHistory } = useSelector(state => state.deviceSignalHistory)

    const [patientId, setPatientId] = useState('');
    const [smShow, setSmShow] = useState(false); //RPM Consent modal
    const [RPMConsent, setRPMConsent] = useState(false); 
    
    const [doctorId, setDoctorId] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(300);


    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(deviceError){
            alert.error(error);
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(commonError) {
            alert.error(commonError);
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        dispatch(getDoctors(resPerPage, currentPage));
        
        if(doctorId) {
            // dispatch(getPatients(resPerPage, currentPage));
            dispatch(getDoctorPatients(doctorId));
        }
        dispatch(getDeviceSignalHistory(deviceDetails?._id));

        if(patientId){
            dispatch(patientProfile(patientId))
        }

        if(message) {
            alert.success(message);
            history.push('/devicedetails');
            dispatch(getDeviceDetails(deviceDetails?._id));

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
        
    }, [dispatch, alert, error, message, patientId, commonError, doctorId]);

    const AssignDeviceToPatient = () => {
        dispatch(assignRPMDeviceToPatient(deviceDetails?._id, deviceDetails?.imei, patientId));
    }

    const getPatientProfile = (patient) => {
        // e.preventDefault();
        setPatientId(patient.value)
    }

    const options = []
    doctorpatients && doctorpatients.map((patient) => (
        options.push({ value: patient?._id, label: [patient?.emrId, patient?.firstname, patient?.lastname].join(" ")  })
    ))

    const doctorOptions = []
        doctors && doctors.map((doctor) => (
            doctorOptions.push({ value: doctor?._id, label: [doctor?.lastname +" ,", doctor?.firstname].join(" ")})
        ))

    const getDoctorProfile = (doctor) => {
        setDoctorId(doctor.value);
    }
    
    return (
        <>
            <small><b>Basic Information: </b></small>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <th scope="col-md-3"><h5 className="title">Device ID </h5></th>
                            <td scope="col-md-3">{deviceDetails?._id ? deviceDetails?._id : 'N/A'}</td>
                            <th scope="col-md-3"><h5 className="title">IMEI  </h5></th>
                            <td scope="col-md-3">{deviceDetails?.imei ? deviceDetails?.imei : 'N/A'}</td>
                        </tr>

                        <tr>
                            <th scope="col-md-3">Type</th>
                            <td>Cuff</td>
                            <th scope="col-md-3">Physical Status</th>
                            <td scope="col-md-3" style={{color: deviceDetails?.broken === true ? 'red': 'green'}}><b>{deviceDetails?.broken  === true ? 'broken' : 'unbroken'}</b></td>
                        </tr>

                        <tr>
                            <th scope="col-md-3">Added Date</th>
                            <td scope="col-md-3">{moment(deviceDetails?.createdAt).format("MM/DD/YYYY")}</td>
                            <th scope="col-md-3">Firmware Version</th>
                            <td scope="col-md-3">{deviceDetails?.firmwareVersion ? deviceDetails?.firmwareVersion : 'N/A'}</td>
                        </tr>

                        <tr>
                            <th scope="col-md-3">hardware Version</th>
                            <td scope="col-md-3">{deviceDetails?.hardwareVersion ? deviceDetails?.hardwareVersion : 'N/A'}</td>
                            <th scope="col-md-3">Device Status</th>
                            {deviceDetails?.assigned_patient_id ?  <td scope="col-md-3" style={{fontWeight: 'bold'}}>
                                Assigned</td> : <td style={{fontWeight: 'bold'}}> In Stock</td>
                             } 
                        </tr>

                        {deviceDetails?.assigned_patient_id && <>
                            <tr> 
                            <th scope="col-md-3">Assigned To: </th>
                            <td scope="col-md-3" style={{backgroundColor: '#00A8A8', 
                            color: '#FFF', letterSpacing:1}}>
                                {deviceDetails?.assigned_patient_id?.firstname} {deviceDetails?.assigned_patient_id?.lastname} ( {deviceDetails?.assigned_patient_id?.gender} )</td>
                            <th scope="col-md-3">Assigned Date: </th>
                            <td scope="col-md-3" style={{backgroundColor: '#00A8A8', color: '#FFF', 
                            letterSpacing:1}}>{deviceDetails?.assignedTime || 'N/A'}</td>
                        </tr>
                        </> 
                        }
                        
                    </tbody>
                </table>
                <div>

                <div>
                {signalHistory && signalHistory.length > 0 && <>
                    <small><b style={{color: '#0044ad'}}>Device Signals & battery history: </b></small>

                    <table className="styled-table"> 
                    <thead align="center">
                        <tr>
                            <th>Device ID</th>
                            <th>Signal strength</th>
                            <th style={{width: '15%'}}>Battery %</th>
                            <th>Updated Date / Time</th>
                        </tr> 
                    </thead>
                    
                    <tbody>
                    {signalHistory && signalHistory.map((signals, index) => (
                        <tr align="center" key={index}>
                            <td>{signals?.deviceId}</td>
                            <td>{signals?.signalData?.sig >= 0 && signals?.signalData?.sig <= 10 ? 
                                <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : signals?.signalData?.sig > 10 && signals?.signalData?.sig <= 20 ? 
                                <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : signals?.signalData?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : null} 
                            </td>

                            <td>
                                {signals?.signalData?.bat ? <ProgressBar striped variant="info" now={signals?.signalData?.bat} label={`${signals?.signalData?.bat}%`} /> : null} </td>
                            <td>
                                <p>{signals ? moment(signals?.dateAdded).format("MM/DD/YYYY") : null} &nbsp;
                                    {signals ? signals?.time : null}
                                </p>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </>}
                </div>

                <br/>                
                {deviceDetails && deviceDetails?.assigned_patient_id?._id == null ? <>
                
                <h5 className='title'>Assign Device to Patient</h5>

                <div className="row">
                    <div className="row-display-column col-md-4">
                        <div>
                        <label className="form-label mt-3">Select Doctor</label>
                           <Select
                                options={doctorOptions}
                                onChange={getDoctorProfile}
                            />
                        </div>
                       {doctorId ? <>
                        <div>
                            <label className="form-label mt-3">Select Patient</label>
                            <Select
                                options={options}
                                onChange={getPatientProfile}
                            />
                        </div>
                       </> : null}  
                       
                    </div>

                    {/* Patient Profile */}
                    <div className="col-md-8">

                    {patientId && patient && <>

                                    <div className="col-md-12">
                                        <div className="row">
                                    
                                        <img src={patientProfileImg} className="patient-profile-card-img" alt="patientProfile" />
                                    
                                        <p className="profile-name pl-3 pb-2">{patient?.firstname} {patient?.lastname}  ( {patient?.emrId} )</p>
                                       
                                    </div>
                                    <br />
                                <div className="row">
                                    <div className="col-md-4">    
                                        <span className="profile-label">Gender: </span>
                                        <p>{patient?.gender === 'male' ? 'Male' : 'Female'}</p>

                                        <span className="profile-label">DOB: </span>
                                        <p className="profile-value-text">{moment(patient?.DOB).format("MM/DD/YYYY")}</p>
                                    </div>

                                    <div className="col-md-4">    
                                        <span className="profile-label">Email Address: </span>
                                        <p className="profile-value-text">{patient?.email ? patient?.email : 'N/A' }</p>

                                        <span className="profile-label">SSN Number: </span>
                                        <p className="profile-value-text">{patient?.ssn ? patient?.ssn : 'N/A'}</p>                                 
                                    </div>

                                    <div className="col-md-4">    
                                        <span className="profile-label">Phone 1: </span>
                                        <p className="profile-value-text">{patient?.phone1 ? patient?.phone1 : 'N/A' }</p>
                                    </div>

                                    <br />
                                </div>

                                {/* RPM Consent */}
                                <div className="row-display">
                                        
                                <div style={{float: 'right'}}>
                                    <input type="checkbox" 
                                        name="rpmconsent" 
                                        id="rpmconsent"
                                        onChange={(e) => setRPMConsent(e.target.checked ? true : false)}

                                    />
                                    &nbsp;&nbsp;<label htmlFor="rpmconsent" style={{fontWeight: 'bold'}} >I have read RPM Consent</label>
                                </div>

                                <button className="edit-pill" onClick={() => setSmShow(true)}> Read RPM Consent</button>
                                </div>
                                <br />


                                {/* RPM Consent */}
                                {RPMConsent === true ? <>
                                        <div >
                                        {patient?.block === false ? 
                                        <>
                                            <button 
                                            className="edit-pill" 
                                            onClick={AssignDeviceToPatient}
                                            >Assign Device</button>
                                        </>
                                        : 
                                        <>
                                            <small style={{ color: "red", marginBottom: "30px"}}>Cannot assigned device, {patient?.firstname} {patient?.lastname}'s account is de-activated.</small>
                                        </>    
                                    }
                                        
                                    </div>
                                    </> : ''}
                            </div>                             
                        </> }
                    </div>
                </div>    

                </> : <>
                <b>Device Assigned.</b> <br/>
                <small>This device is already assigned to Patient 
                <Link to={{ pathname: "/patientProfile", state: {patientid: deviceDetails?.assigned_patient_id?._id }}} className="link"> {deviceDetails?.assigned_patient_id?.firstname} {deviceDetails?.assigned_patient_id?.lastname}</Link>, 
                if you want to assign this device to other patient, first remove it from patient profile. 
                <Link to={{ pathname: "/printReceipt", state: {deviceAssigned: deviceDetails, patientDetails: patient }}} className="ml-3 mt-2">Print Receipt</Link>
                </small>
                <br />
                
                
                </>}
                 
            </div> 


            {/* RPM Consent Modal */}
            <Modal
                size="lg"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header>
                    <h5 className="title">RPM Consent</h5>
                </Modal.Header>
                <Modal.Body>
                   <p>It is agreed to by both parties that reimbursement for medical billing services will be
                    paid by the client in the amount of $500 per month, or 6.0%, whichever is greater, if the 
                    number of clients the client has within a month period is less than three (3) patients. Should
                    the client’s number of patients services increase beyond three (3) patients, but less than six (6), 
                    then client shall by DULU $750 per month, or 6.0%, whichever is greater. <br/><br/>
                    Additionally, it is understood that as a part of this agreement, DULU shall provide credentialing
                    services for government and commercial health insurance. Whereas credentialing involves many parties
                     and movies parts. Provider credentialing requires organization verify the credentials of healthcare 
                     provides to ensure they have the required licenses, certifications, and skills to properly care for 
                     patients. Every health insurance company checks the credentials of a healthcare entity before it 
                     includes the entity as an in-network provider. In order to expedite the credentialing process, DULU 
                     will make all attempts to process paperless credentialing. Should DULU encounter costs that exceed the 
                     monthly agreed amount herein Exhibit A, DULU shall discuss these costs with the client to determine 
                     reimbursement. <br/><br/>
                     The agreement within his Exhibit A shall only be applicable for the duration of the first
                     three (3) months upon execution of this agreement. Where upon, the terms of Exhibit A will 
                     expire, and the Terms of this Agreement shall follow $3.2 of the medical services Agreement.
                     Should client’s patient base fail to meet at least five (5) patients within three (3) months,
                     an extension of the Exhibit A agreement may be granted on a month-to -month basis for up to 
                     six (6) months. Such execution of agreement shall be made in writing and signed by both parties
                     no later than the 10th day of each month, and the Agreement will form part of this entire Agreement.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RPMDeviceBasicInformation
