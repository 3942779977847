import {useEffect, useState} from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import {useAlert} from 'react-alert';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { getTimeSummaryReportByHR, getHrProfile } from '../../actions/adminActions';
import { useHistory } from 'react-router-dom';
import { Table, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';


const HRSummaryReport = () => {

  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState('2024');
  const [category, setCategory] = useState('CCM');


  const { user } = useSelector(state => state.auth);
  const { hrProfile } = useSelector(state => state.hrprofile);
  const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);

  let docId = hrProfile?.assigned_doctor_id?._id;


  useEffect(() => {
    
    dispatch({
      type: TIME_SUMMARY_REPORT_RESET
    })

    dispatch(getHrProfile(user?._id));

}, [dispatch, month, year]);

  const generateTimeSummaryByHR = () => {
     if(!month){
      alert.error('Please select month');
      return;
    } else if(!year){
      alert.error('Please select month');
      return;
    } else {
      dispatch(getTimeSummaryReportByHR(docId, user?._id, month, year,category));
    }
  }

  const resetReport = () => {
    dispatch({
        type: TIME_SUMMARY_REPORT_RESET
    })
  }  


  return (
    <>
     <MetaData title="Financial Report RPM" />
            <HRSidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <HRTopBar />

        <>
            <br />
             <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white rounded">        
                <div className="home-content">
                    <div>
                    <div className="row-display">
                        <h5 className='title'>
                            Financial Report for RPM 
                        </h5>
                      <div>
                        <Link to="/Nurse/Report/Financial_Report/CCM" className="link pr-3">CCM Financial Report</Link>  
                        <button className="btn btn-secondary btn-sm" 
                          onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                        </button>
                      </div>
                    </div>


                            <hr />
                            <div className="row-display">
                              
                            <div className="col-md-3">  
                            <label className='profile-label pt-1'>Select Month <span style={{color: 'red'}}>*</span></label> 
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={month} 
                                  onChange={e => setMonth(e.target.value)}
                                  >
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                            <label className='profile-label pt-1'>Select Year <span style={{color: 'red'}}>*</span></label> 
                              
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={year} 
                                  onChange={e => setYear(e.target.value)}
                                  >
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                </select>              
                            </div>

                            <div className='col-md-3'>
                            <label className='profile-label pt-1'>Category <span style={{color: 'red'}}>*</span></label> 
                            <select 
                                  name="ctg" 
                                  className="form-control"
                                  disabled
                                  >
                                  <option value="RPM">RPM</option>
                                </select>
                            </div>
                          
              
                            <div className="col-md-2">       
                                <br />           
                                <button className="edit-pill mt-3" onClick={generateTimeSummaryByHR}>Generate Report</button>
                            </div>
                            </div>

                        </div>
                  </div>

                
                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                    <hr />

                    <div className="reddishRow p-2">
                          <span style={{color: 'red'}}>Note : </span> Following report is showing number of readings received of your organization's patients and minutes added <i>Collectively & By YOU</i> in RPM category in the selected month and year.   
                        </div>
                    <br />

                        <div className="container-fluid row-display">

                        <div>
                          <b>Generated Report Preview</b>
                          <p><small style={{color: '#00A8A8', fontWeight: 'bold'}}>Total {timeSummaryReport?.length} records found.</small></p>
                        </div>

                        <div>
                          <button className="edit-pill" onClick={() => resetReport()}>Reset Report</button>
                        </div>
                          
                          
                        </div>

                        <div className="col-md-12">
                        <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Sr.</th>
                                <th>EHR_ID</th>
                                <th>Patient Name</th>
                                <th>D.O.B</th>
                                <th width="100px">Readings</th>
                                <th>Total Minutes</th>
                                <th>Your Contribution</th>
                                <th>CPT Codes</th>
                                <th>Month & Year</th>
                                <th>Category</th>
                                </tr>
                              </thead>

                              <tbody>
                                {timeSummaryReport && timeSummaryReport.map((summaryReport, index) => (
                                  <tr key={index}> 
                                    <td><b>{index + 1}</b></td>

                                    <td><b>{summaryReport?.emrId}</b></td>
                                    
                                    <td><Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: summaryReport?.patientId }}} className="link">
                                      {summaryReport?.patientName}
                                    </Link>
                                    </td>

                                    <td><b>{moment(summaryReport?.DOB).format("MM-DD-YYYY")}</b></td>
                                    
                                    {summaryReport.totalReadings > 16 ? 
                                      <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td> : 
                                      <td style={{color: 'red', fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td>
                                    }

                                    {summaryReport?.totalMinutes < 20 ? 
                                      <td style={{color: 'red' , fontWeight: 'bold'}}>{summaryReport?.totalMinutes} mins</td> : 
                                      <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalMinutes} mins</td>
                                    }
                                    
                                    <td><b>{summaryReport?.nurseContributed} mins</b><p>
                                      
                                      <ProgressBar  
                                        animated 
                                        variant='success' 
                                        now={((summaryReport?.nurseContributed / summaryReport?.totalMinutes) * 100).toFixed()} 
                                          />  
                                      </p></td>

                                      <td>
                                        {summaryReport?.setupDate && summaryReport.totalReadings >= 16 ? 
                                        <text style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}> 99454</text> : null}
                                        
                                        {summaryReport.totalMinutes >= 20 && summaryReport.totalMinutes < 40 ?  <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457</p> :
                                        summaryReport.totalMinutes >= 40 && summaryReport.totalMinutes < 60 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 </p> :
                                        summaryReport.totalMinutes >= 60 && summaryReport.totalMinutes < 80 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458  </p> :
                                        summaryReport.totalMinutes >= 80 && summaryReport.totalMinutes < 100 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458 <br /> 99458  </p> : 
                                        summaryReport.totalMinutes >= 100 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457 <br /> 99458 <br /> 99458 <br /> 99458 <br /> 99458  </p> 
                                        : null}
                                      </td>

                                    <td style={{wordWrap: 'break-word'}}>
                                      <b>{summaryReport?.Month} - {year && year}</b>
                                    </td>

                                    <td style={{fontWeight: 'bold'}}>RPM</td>
                                  </tr>
                                ))}
                            
                            </tbody>
                          </table>
                        </div>


                   </> : null}
                </>}

              </div>
        </>
      </section>
    
    </>
  )
}

export default HRSummaryReport;