import {useEffect, useState} from 'react';
import  Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import {useSelector, useDispatch} from 'react-redux';
import { patientProfile, getPatientTelemetryData, getRemainingReadings, sortTelemetartData} from '../../actions/adminActions';
import CuffTelemetaryData from '../../components/Patient/CuffTelemetaryData';
import StaffPatientInfo from '../../components/Staff/StaffPatientInfo';
import moment from 'moment';
import { COMMENT_RESET } from '../../constants/HRConstants';
import { Tab, Row, Col } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import Pagination from 'react-js-pagination';
import PatientProfileGraph from '../../components/PatientProfileGraph';
import CCMMinutesSpent from '../../components/HR/CCMMinutesSpent';
import NurseRPMMinutesSpent from '../../components/HR/NurseRPMMinutesSpent';


const StaffPatientProfile = (props) => {
    
    const dispatch = useDispatch();
    const alert = useAlert();

    let patientid = props?.location?.state?.patientid;
    let readingmonth;

    const { loading, error, patient, isUpdated} = useSelector(state => state.patientProfile);
    const { commentSuccess} = useSelector(state => state.comments);
    const { deviceData, Count } = useSelector(state => state.deviceData);
    const { count } = useSelector(state => state.readingsCount);

    const [readingPerPage, setReadingsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, sortBy] = useState(-1);
    const [accordion, setAccordion] = useState(false);
    const [view, setView] = useState('showTelemetary');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [deviceId, setDeviceId] = useState("default");

    const { totalTime } = useSelector(state => state.totalTimeSpent);

    // Get Readings count
    let start = `${moment().clone().startOf('month').format('YYYY/MM/DD')}`;
    let end = `${moment().clone().endOf('month').format('YYYY/MM/DD')}`;

    useEffect(() => {
    
        if(error){
            return alert.error(error);
        }


        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        dispatch(getRemainingReadings(patientid, start, end));
        
        if(commentSuccess) {
            alert.success('Comment added');
            dispatch({ type: COMMENT_RESET });
            dispatch(patientProfile(patientid));
            dispatch(getPatientTelemetryData(patientid))
        }

        if(isUpdated) {
            alert.success('Updated Successfully');
        }

    }, [dispatch, alert, error, isUpdated, commentSuccess, currentPage, sort]);


    function setCurrentPageNumber(currentPage) {
        setCurrentPage(currentPage);
    }

    const refreshHandler = () => {
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        setDeviceId('default');
        // setSort('')
    }

    const sortPatientTelemetaryData = () => {
        dispatch(sortTelemetartData(patientid, filterStartDate, filterEndDate, readingPerPage, currentPage));
    }

    return (
        <>
           <MetaData title={ patient?.lastname + ' , ' + patient?.firstname +' '+ 's profile'}/>

                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />

                
                {loading ? <Loader /> : <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">
                            <div>    

                            {patient && <>
                                <StaffPatientInfo 
                                    patient={patient}
                                    telemetaryReadings={deviceData}
                                    totalTime={totalTime}
                                />

                            <br/>
                            <div>        
                                <select 
                                    name="selectRole" 
                                    className="form-control" 
                                    style={{width: '250px', float: 'right'}}
                                    value={view}
                                    onChange={e => setView(e.target.value)}
                                >
                                    {patient?.patientType === 'Both' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : patient?.patientType === 'RPM' ? <>
                                    <option value="showTelemetary">Show Telemetary Data</option>
                                    <option value="showRPMMinutes">Show RPM Minutes</option>
                                    </> : patient.patientType === 'CCM' ? <>
                                    <option value="showCCMMinutes">Select Minutes</option>
                                    <option value="showCCMMinutes">Show CCM Minutes</option>
                                    </> : null}
                                       
                                </select>        
                            </div>
                            <br/>


                            {patient?.patientType === 'RPM' || view === "showTelemetary" ? <>
                            {deviceData && deviceData.length > 0 ? <>
                                <div className="container-fluid ml-3">
                                    <h5 className="title mt-3">Search Vitals Data </h5>
                                    <p style={{ color: '#00A8A8'}}>
                                        Total Readings Received : {Count}  
                                    </p>
                                </div>

                            <div className="row-display patient-profile-col-heading" style={{ 
                                padding: 10,
                                borderRadius: '10px',
                                margin: '0px 20px'
                                }}
                            > 
                            
                            <div style={{width: '30%'}}>
                              <input 
                                type="date"
                                value={filterStartDate}
                                onChange={e => setFilterStartDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                                />
                            </div>
                            
                            &nbsp;&nbsp;
                            <div style={{width: '30%'}}>
                              <input 
                                type="date"
                                value={filterEndDate}
                                onChange={e => setFilterEndDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                             />
                             </div>
                            
                             &nbsp;&nbsp;
                             <div style={{width: '30%'}}>
                                    <select 
                                    value={sort}
                                    onChange={e => sortBy(e.target.value)}
                                    className="form-control">
                                        <option value="-1">Descending (last to first)</option>
                                        <option value="1">Ascending (first to last)</option>
                                    </select>
                             </div>
                            
                            &nbsp;&nbsp;
                             
                                <button 
                                    className="edit-pill m-2"
                                    onClick={sortPatientTelemetaryData}>
                                        Search
                                </button>    
                                &nbsp;&nbsp;
                                <button
                                    onClick={refreshHandler} 
                                    className="edit-pill m-2">Reset
                                </button>     
                            </div>

                        <br />

                        <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="row-display mr-3" style={{float: 'right'}}>
                        {patient?.assigned_devices?.length > 1 ? <>
                            <select className='form-control' 
                                value={deviceId} 
                                onChange={e => setDeviceId(e.target.value)}
                                style={{width: '250px'}}
                            >
                                <option value="default">Sort By Device Type</option>
                                {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                                <option value={deviceass?.deviceObjectId?.deviceType} key={index}>
                                    {deviceass?.deviceObjectId?.deviceType}
                                </option>
                                ))}
                            </select>
                        </> : null}
                        

                            
                            {deviceData.every(element => element.deviceId?.deviceType === "bp") ? <>
                                <div className="container graphWrapper">
                                    <button className="edit-pill" onClick={() => setAccordion(accordion => !accordion)}>
                                        Show Graphically &nbsp;&nbsp;&nbsp;
                                        <i className={accordion ? `bx bx-minus` : `bx bx-plus`}></i>
                                    </button>
                                </div> 
                                </> : <></> }
                        </div>
                      
                        

                        {/* Accordion for graphical representation */}
                        <div className="graphWrapper">
                            {accordion === true ? <div className="panel">
                                <PatientProfileGraph healthData={deviceData} />
                            </div> : ""}
                        </div>
                        
                        {/* Accordion for graphical representation ends here */}
                            <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                {deviceData && deviceData.filter(item => deviceId !== 'default' ? item?.deviceId?.deviceType === deviceId : item).map((devicedata, index) => (
                                    <div key={index}>
                                        <CuffTelemetaryData 
                                            key={index} 
                                            healthData={devicedata} 
                                            isAdmin={false} 
                                            patientId={patientid} 
                                            />
                                    </div>
                                ))}

                                {/* Pagination */}
                                {!filterStartDate && !filterEndDate && !deviceId && readingPerPage <= Count && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={readingPerPage} 
                                    totalItemsCount = {Count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                                </div>
                                )} 
                                </Tab.Pane>
                                
                            </Tab.Content>
                            </Col>
                    </Tab.Container>
                    </div>
                    
                       
                        </> : 
                        <div className="text-center mt-3 pb-3">
                        <small>
                            <p>No telemetary data found </p>
                            <button className="btn btn-outline-primary btn-sm"onClick={refreshHandler}>
                                Refresh List
                            </button>
                        </small>
                        </div>}
                        </> : view === 'showCCMMinutes' ? <>
                         <br />
                         <CCMMinutesSpent
                            patientId={patientid}
                         />
                        </> : view === 'showRPMMinutes' ? <>
                        <br />
                            <NurseRPMMinutesSpent
                                patientId={patientid}
                            />
                        </> : null }


                            </> }
                            </div>
                            </div>
                           </div>
                         </> 
                         }
                </section>
        </>
    )
}

export default StaffPatientProfile
