import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import CuffTelemetaryData from '../../components/Patient/CuffTelemetaryData'; 
import PatientInfo from '../../components/Patient/PatientInfo';
import { patientProfile, getPatientTelemetryData, sortTelemetartData} from '../../actions/adminActions';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { Tab, Col, Image, Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import PatientProfileGraph from '../../components/PatientProfileGraph';
import CCMMinutesSpent from '../../components/HR/CCMMinutesSpent';
import AdminRPMMinutesComponent from '../../components/AdminDashboard/AdminRPMMinutesComponent';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';
import "react-datepicker/dist/react-datepicker.css";


const PatientProfile = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    let patientid = props?.location?.state?.patientid;
    let batterySignalStatus = props?.location?.state?.batterySignalStatus;

    const { loading, error, patient, isUpdated} = useSelector(state => state.patientProfile);
    const { deviceData, Count } = useSelector(state => state.deviceData);
    const { careplan } = useSelector(state => state.careplan);
    const { count } = useSelector(state => state.readingsCount);
    const { error: deleteCommentError, message } = useSelector(state => state.common);


    const [readingPerPage, setReadingsPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, sortBy] = useState(-1);
    const [smShow, setSmShow] = useState(false); //charts modal
    const [imageToShow, setImageToShow] = useState();


    const [view, setView] = useState('showTelemetary');

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(patientid){
            dispatch(patientProfile(patientid));
            dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort));
        }
           
        if(isUpdated) {
            alert.success('Updated Successfully'); 
        }

    }, [dispatch, alert, error, isUpdated, currentPage, sort, patientid]);

    const sortPatientTelemetaryData = () => {
        dispatch(sortTelemetartData(patientid, startDate, endDate, readingPerPage, currentPage));
    }
    
    const refreshHandler = () => {
        dispatch(patientProfile(patientid));
        dispatch(getPatientTelemetryData(patientid, readingPerPage, currentPage, sort))
        setStartDate('');
        setEndDate('');
    }

    
    let readingsThisMonth;
    let ReadingsperMonth; 
 
    readingsThisMonth = count;
    ReadingsperMonth = careplan?.data?.readingsPerMonth;
    
    function setCurrentPageNumber(currentPage) {
        setCurrentPage(currentPage);
    }

    return (
        <>
            <MetaData title={ patient && patient?.lastname +', '+ patient?.firstname }/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                <br/>    
                {loading ? <Loader /> : <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">
                            <div>    

                            {patient && <>
                                {/* Patient Info Component */}
                                <PatientInfo 
                                    patient={patient}
                                    count={count}
                                    telemetaryReadings={deviceData}
                                    patientid={patientid}
                                    batterySignal={batterySignalStatus}
                                />

                        <br />

                        <div className="d-block mr-0 ml-auto" style={{width: '500px'}}>        
                            <div className='row-display'>
                                <button className={`${view === 'showTelemetary' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setView('showTelemetary')}> Telemetry Data</button>

                                <button className={`${view === 'showRPMinutes' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setView('showRPMinutes')}>RPM Notes</button>
                                
                                <button className={`${view === 'showCCMMinutes' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setView('showCCMMinutes')}>CCM Notes</button>

                            </div>
                        </div>
                        <br/>


                        {view === "showTelemetary" ? <>    
                            {deviceData && deviceData.length > 0 ? <>
                            

                            <div className="row-display mt-3"> 
                            <div style={{width: '25%'}}>
                            <b>To: </b>
                              <input 
                                type="date"
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                                />
                            </div>
                            
                            &nbsp;&nbsp;
                            <div style={{width: '25%'}}>                            
                            <b>From: </b>
                              <input 
                                type="date"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                                max={moment().format("YYYY-MM-DD")} 
                                className="form-control"
                             />
                             </div>
                            
                             &nbsp;&nbsp;
                             <div style={{width: '25%'}}>                            
                                <b>Sort By: </b>
                                    <select 
                                    value={sort}
                                    onChange={e => sortBy(e.target.value)}
                                    className="form-control">
                                        <option value="-1">Descending (last to first)</option>
                                        <option value="1">Ascending (first to last)</option>
                                    </select>
                             </div>
                            
                            &nbsp;&nbsp;
                             <div> 
                                <button 
                                    className="edit-pill mt-4"
                                    onClick={sortPatientTelemetaryData}>Search
                                </button>    
                            </div>
                            &nbsp;

                            <div> 
                                <button
                                    onClick={refreshHandler} 
                                    className="edit-pill mt-4"
                                    >
                                    <i className='bx bx-reset'></i> Refresh
                                </button>    
                            </div>
                            </div>
                        <br />
                             
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                        {deviceData.every(element => element.deviceId?.deviceType === "bp") ? <>        
                        <Col sm={12}>
                             <div className="panel">
                                <PatientProfileGraph healthData={deviceData} />
                            </div>
                        </Col>    
                        </> : null }
                        
                        {/* Accordion for graphical representation */}
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    {deviceData && deviceData.map((cuffData, index) => (
                                        <div key={index}>
                                            <CuffTelemetaryData 
                                            healthData={cuffData} 
                                            count={Count} 
                                            readingsPerPage={readingPerPage} 
                                            currentPage={currentPage} 
                                            isAdmin={true} 
                                            />
                                        </div>
                                    ))}    
                                

                                {readingPerPage <= Count && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={readingPerPage} 
                                    totalItemsCount = {Count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                                </div>
                                )} 
                                
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>

                    </Tab.Container>
                    
                    </> : <small className="text-center" style={{color: 'gray', marginLeft: '350px'}}>
                        No telemetary data found 
                    <button className="btn btn-link" onClick={refreshHandler}>Refresh List</button>
                    </small>}
                    </> : view === 'showRPMinutes' ? <>
                    <br />
                        <AdminRPMMinutesComponent patientId={patientid} />
                    </> : view === 'showCCMMinutes' ? <>
                        <br />
                        <CCMMinutesSpent
                            patientId={patientid}
                            isAdmin={true}
                        />
                    </> : null }
                    </> }
                    
                       
                </div>
                
                <br /><br />
            </div>                           
        </div>
    </> 
    }         
    </section>
    
    <Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
    >
        <Modal.Body>
            <Image src={imageToShow} className="determinationChartsImages" /> 
        </Modal.Body>
    </Modal>

    </>
    )
}

export default PatientProfile
