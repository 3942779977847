import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { adminReducers } from './reducers/adminReducers';
import { authReducers } from './reducers/authReducers';
import { doctorProfileReducers } from './reducers/adminReducers'; 
import { patientProfileReducers } from './reducers/adminReducers';
import { doctorReducers } from './reducers/adminReducers';
import { deviceDataReducers } from './reducers/adminReducers';
import { devicesReducers } from './reducers/adminReducers';
import { deviceDetailsReducers } from './reducers/adminReducers';
import { newDeviceReducers } from './reducers/adminReducers';
import { doctorpatientsReducers } from './reducers/adminReducers';
import { hrsReducers } from './reducers/adminReducers';
import { adminStatsReducers } from './reducers/adminReducers';
import { hrpatientsReducers } from './reducers/HRReducers'; 
import { commentReducers } from './reducers/HRReducers';
import { hrtimeSpentReducers } from './reducers/HRReducers';
import { targetedMinutesReducers } from './reducers/HRReducers';
import { hrTimeReportReducers } from './reducers/HRReducers';
import { carePlanReducers } from './reducers/HRReducers';
import {initialMonthReportReducers} from './reducers/HRReducers';
import { timeSpentCurrentMonthReducer } from './reducers/HRReducers';
import {adminNotificationsReducers} from './reducers/adminReducers';
import { inventoryStatsReducers } from './reducers/adminReducers';
import { remainingReadingsReducer } from './reducers/adminReducers';
import { logsReducers } from './reducers/adminReducers';
import { hrProfilesReducer } from './reducers/adminReducers';
import { careplanListReducers } from './reducers/HRReducers'; //Get list of careplans for doctor and hrs 
import {doctorTelemetaryReportReducer} from './reducers/adminReducers';
import { timeSummaryReportReducer } from './reducers/adminReducers';
import {searchLogReducer} from './reducers/adminReducers';
import {adminsListReducers} from './reducers/adminReducers';
import {timeSpentCurrentMonthinCCMReducer} from './reducers/HRReducers';
import {hrStatsReducers} from './reducers/HRReducers';
import { deviceSignalHistoryReducers } from './reducers/adminReducers';
import {financialReportHistoryReducers} from './reducers/adminReducers';
import { patientCCMConsentReducers } from './reducers/HRReducers';
import { patientRPMConsentReducers } from './reducers/HRReducers';
import { patientCCMCareplanReducers } from './reducers/HRReducers';
import {inactivePatientsReducers} from './reducers/adminReducers'
import { devicesHistoryReducers } from './reducers/adminReducers';
import { chatReducers } from './reducers/HRReducers';
import { criticalRPMReducers } from './reducers/adminReducers';
import { doctorStatsReducers } from './reducers/adminReducers';
import { recentReadingsReducers } from './reducers/adminReducers';
import { assistantListReducers } from './reducers/assistantReducers';
import { allTargetsReducer } from './reducers/adminReducers';
import { adminProfileReducers } from './reducers/authReducers';
import { monthwiseTotalMinutesReducers } from './reducers/adminReducers';
import { nurseProgressReducers } from './reducers/adminReducers';
import { messagesReducers } from './reducers/adminReducers';
import { nurseRecentReadingsReducers } from './reducers/HRReducers';
import { nurseCriticalReadingsReducers } from './reducers/HRReducers';
import { monthlyTelemetryDataReducers } from './reducers/adminReducers';
import {organizationsReducers} from './reducers/adminReducers';
import {assistantStatsReducers} from './reducers/assistantReducers';
import { callReportReducer } from './reducers/adminReducers';
import { patientEncounterReducers} from './reducers/adminReducers';
import { avgCalculatorReducer } from './reducers/adminReducers';
import { chatReducer } from './reducers/adminReducers';
import { assistantProfileReducers } from './reducers/assistantReducers';
import { organizationProfileReducers } from './reducers/adminReducers';
import { assistantPatientsReducers } from './reducers/assistantReducers';
import {avgBPAdminReducers} from './reducers/adminReducers';
import {timeReportMonthlyLogReducer} from './reducers/HRReducers';
import {ccmCareplanManualReducers} from './reducers/adminReducers';

// COMMON REDUCERS
import { commonReducers } from './reducers/Common';

const reducer = combineReducers({
    admin: adminReducers,
    auth: authReducers,
    doctorProfile: doctorProfileReducers,
    assistantProfile: assistantProfileReducers,
    patientProfile: patientProfileReducers,
    doctor: doctorReducers,
    deviceData: deviceDataReducers,
    devices: devicesReducers,
    deviceDetails: deviceDetailsReducers,
    device : newDeviceReducers,
    docPatients: doctorpatientsReducers,
    hrslist: hrsReducers,
    hrPatients:hrpatientsReducers,
    comments: commentReducers,
    timeSpent: hrtimeSpentReducers,
    target : hrTimeReportReducers,
    targetedMinutes: targetedMinutesReducers,
    adminStat: adminStatsReducers,
    careplan: carePlanReducers,
    initialMonthReport:initialMonthReportReducers,
    adminNoti: adminNotificationsReducers,
    totalTimeSpent: timeSpentCurrentMonthReducer,
    totalTimeSpentInCCM:timeSpentCurrentMonthinCCMReducer,
    inventoryStats: inventoryStatsReducers,
    readingsCount: remainingReadingsReducer,
    log: logsReducers,
    common: commonReducers,
    hrprofile: hrProfilesReducer,
    careplans: careplanListReducers,
    telemetaryReport: doctorTelemetaryReportReducer,
    summaryReport: timeSummaryReportReducer,
    searchLogResult: searchLogReducer,
    adminsList: adminsListReducers,
    hrStats: hrStatsReducers,
    deviceSignalHistory: deviceSignalHistoryReducers,
    financialReportHistory:financialReportHistoryReducers,
    patientCCMConsent: patientCCMConsentReducers,
    patientRPMConsent: patientRPMConsentReducers,
    patientCCMCareplan : patientCCMCareplanReducers,
    inactivePatients: inactivePatientsReducers,
    deviceHistory: devicesHistoryReducers,
    chatHistory: chatReducers,
    criticalPatients: criticalRPMReducers,
    recentReadings: recentReadingsReducers,
    doctorStats: doctorStatsReducers,
    assistantsList: assistantListReducers,
    adminTargets: allTargetsReducer,
    adminsProfile: adminProfileReducers,
    monthlyTargetMinutes: monthwiseTotalMinutesReducers,
    progress: nurseProgressReducers,
    messages: messagesReducers,
    nurseRecentReadings: nurseRecentReadingsReducers,
    nurseCriticalReadings:nurseCriticalReadingsReducers,
    monthlyTelemetryPatients: monthlyTelemetryDataReducers,
    orgs: organizationsReducers,
    asstStats: assistantStatsReducers,
    calls : callReportReducer,
    ptEncounter: patientEncounterReducers,
    avgCalc: avgCalculatorReducer,
    chatMessages:chatReducer,
    orgProfile: organizationProfileReducers,
    asstPatients: assistantPatientsReducers,
    avgBPs: avgBPAdminReducers,
    timeReportMonthlyLog: timeReportMonthlyLogReducer,
    ccmCareplanManual : ccmCareplanManualReducers
  })

let initialState = {}

const middleware = [thunk];


// const store = createStore(reducer, initialState, disableReactDevTools(applyMiddleware(...middleware)))


let mode = "production"
const devTools =
  mode === "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(reducer, initialState, devTools);

export default store;