import { staffLogout } from '../../actions/authActions';
import { useDispatch } from 'react-redux';
import SessionTimeout from '../../components/SessionTimeout';

const TopBar = () => {
    
    const dispatch = useDispatch();

    const logoutHandler = () => {
        var confirmation = window.confirm(`Are you sure you want to logout ?`);
            if(confirmation){
                // history.push("/");
                dispatch(staffLogout());
                window.location.replace("/");
            }
            localStorage.removeItem('token');
            localStorage.removeItem('doctor');
        }

    
    return (
        <>
            <SessionTimeout />
            
            
            <div className="row-display" id='topbar' style={{backgroundColor: '#00A8A8'}}>

            <h4 className="title text-white mt-3"> Doctor's Dashboard</h4>        
            <i className='bx bx-log-out mr-4 mt-2' style={{
                fontSize: '28px',  
                color: 'white', 
                marginLeft: '20px', 
                cursor: 'pointer'
                }} onClick={logoutHandler}></i> 
            </div>
        </>
    )
}

export default TopBar;
