import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import MetaData from '../../layouts/MetaData';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import patientProfileImg from "../../assets/Images/patientProfile.png";
import { Modal } from 'react-bootstrap';
import { changePassword, clearErrors } from '../../actions/HRActions';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux'; 


const HR = () => {
  
    const {user} = useSelector(state => state.auth);
    const {message, error } = useSelector(state => state.common);
    
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const handleShowResetModal = () => setShowResetPasswordModal(true);
    const handleCloseResetModal = () => setShowResetPasswordModal(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if(error){
            alert.error(error);
            dispatch(clearErrors());
        }

        if(message){
            alert.success(message);
            dispatch(clearErrors());
        }
            
    }, [dispatch, error, message]);


    const handleChangePassword = () => {
        if(newPassword !== confirmPassword){
            alert.error('New Passwords not matched');
            return;
        }

        dispatch(changePassword(user?._id, oldPassword, newPassword));
        setShowResetPasswordModal(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

  return <>
    <MetaData title="My Profile" />
            <HRSidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <HRTopBar />

                <br/>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                  <div className="home-content">
                    <div>

                    <div className="row-display">
                      
                        <h5 className="pt-2 mt-2 title"> My Profile</h5> 

                        <div className="row-display">
                                <button onClick={() => history.goBack()} className="btn btn-secondary btn-sm mt-3">
                                    <i className='bx bx-arrow-back'></i>
                                </button>
                            
                            &nbsp;&nbsp;
                            <button onClick={handleShowResetModal} className="edit-pill mt-3">
                                <i className='bx bx-key'></i> Change Password
                            </button>
                        </div> 
                    </div>  
                    <hr />

                    <div className="row">
                    <div className="col-md-3">
                        <div className='text-center'>
                            <img src={patientProfileImg} className="profile-img" />
                            <p className="profile-name">{user?.lastname}, {user?.firstname} </p>
                            <p className="m-0 p-0">{user?.role}</p>                   
                        </div>
                    </div>
                    
                    <div className="col-md-9">
                      <div>
                          <div className="card-inner-margin">
                              <div className="row">
                              <div className="col-md-4">
                                  <span className="profile-label">Email: </span>
                                  <p className="profile-value-text" style={{wordWrap: 'break-word'}}>{user?.email}</p>

                                  <span className="profile-label">Gender: </span>
                                  <p className="profile-value-text">{user?.gender}</p>

                                  <span className="profile-label">Role: </span>
                                  <p className="profile-value-text">{user?.consentRole === 'Both' ? 'RPM & CCM' : user?.consentRole}</p>                            
                                  </div>


                                  <div className="col-md-4">
                                      <span className="profile-label">DOB : </span>
                                      <p className="profile-value-text">{moment(user.DOB).format("MM/DD/YYYY")}</p>

                                      <span className="profile-label">Phone 1: </span>
                                      <p className="profile-value-text">{user?.phone1 || 'N/A'}</p>                                            
                                  </div>

                                  <div className="col-md-4">
                                      <span className="profile-label">Mobile No: </span>
                                      <p className="profile-value-text">{ user?.mobileNo || 'N/A'}</p>

                                      <span className="profile-label">Account Created Date: </span>
                                      <p className="profile-value-text">{moment(user?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                </div>
            </div>

          </div>
        </section>

        {/* Change Password */}
        <Modal show={showResetPasswordModal}>
                    <Modal.Header>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="oldPassword">Current Password: </label>
                        <input
                            type="password"
                            name="oldPassword"
                            className="form-control"
                            placeholder="Enter Email Address"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />

                        <br />
                        <label htmlFor="newPassword">New Password: </label>
                        <input
                            type="password"
                            name="newPassword"
                            className="form-control"
                            placeholder="Enter New Address"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <br />

                        <label htmlFor="confirmPassword">Confirm New Password: </label>
                        <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <br />

                        <span><b style={{color: 'red'}}>Note: </b> <small>New Password should be alphanumeric and minimum of 8 characters. </small></span>
                        
                        {/*...*/}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleCloseResetModal}>Cancel</button>
                        <button className="edit-pill" onClick={() => handleChangePassword()}>Update</button>
                    </Modal.Footer>
                </Modal>
      </>
};

export default HR;
