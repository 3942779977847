import React from 'react'
import { CSVLink } from 'react-csv';
import moment from 'moment';

const ExportCCMSummaryReportToCSV = ({csvData, fileName, filterPatient}) => {
    
    let CptCode = '';
    let CptCharges = '';
    let totalCharges = '';
  
    // function calcCPT(minutes) {
    //   switch(true){
    //     case (minutes >= 20 && minutes < 40):
    //       CptCode = '99490'
    //       CptCharges = '$49.73'
    //       totalCharges = '$49.73'
    //       break; 
    //     case (minutes >= 40 && minutes < 60):
    //         CptCode = '99490, 99439'
    //         CptCharges = '$49.73, 37.49'
    //         totalCharges = '$87.22'
    //         break;
    //     case (minutes >= 60 && minutes < 80):
    //         CptCode = "99487"
    //         CptCharges = '$73.70'
    //         totalCharges = '$73.70'
    //         break;
    //     case (minutes >= 80 && minutes < 90):
    //           CptCode = '99489, 99439'
    //           CptCharges = '$35.70, $37.49'
    //           totalCharges = '$73.19'
    //           break;
    //     case (minutes >= 90 && minutes < 110):
    //           CptCode = '99487, 99489'
    //           CptCharges = '$73.70, $35.70'
    //           totalCharges = '109.40'
    //           break; 
    //     case (minutes >= 110 && minutes < 120):
    //           CptCode = '99489, 99487, 99439'
    //           CptCharges = '$35.70, $73.70, $37.49'
    //           totalCharges = '146.89'
    //           break;
    //     case (minutes >= 120):
    //           CptCode = '99487, 99489, 99489'
    //           CptCharges = '$35.70, $73.70, $73.70'
    //           totalCharges = '183.10'
    //           break;

    //     default: 
    //         CptCode = ''
    //         CptCharges=''
    //         totalCharges = ''
    //     break;
    //     }
    //   }
    function calcCPT(minutes) {
      switch(true){
       case (minutes >= 20 && minutes < 40):
          CptCode = '99490'
          break; 
        case (minutes >= 40 && minutes < 60):
            CptCode = '99490, 99439'
            break;
        case (minutes >= 60 && minutes < 90):
            CptCode = "99487"
            break;
        case (minutes >= 90 && minutes < 120):
              CptCode = '99487, 99489'
              break;
        case (minutes >= 120 && minutes < 150):
              CptCode = '99487, 99489,99489'
              break; 
        case (minutes >= 150):
              CptCode = '99487, 99489, 99489,99489'
              break;
  
        default: 
            CptCode = ''
        break;
        }
      }

      
    let result = csvData.filter(item => filterPatient === true ? item.totalMinutes > 0 : item).map((report, index) => {

    calcCPT(report?.totalMinutes);
        
    return {
            'Sr No':index + 1,
            'EHR-ID':report?.emrId || 'N/A',
            'Patient Name':report?.patientName,
            'D.O.B': moment(report?.DOB).format("MM/DD/YYYY"),
            'Total Minutes': report?.totalMinutes,
            'Nurse Contribution':report?.nurseContributed,
            'CPT Code': CptCode,
            'Payment': CptCharges, 
            'Total Payment':totalCharges,
            'Category': 'CCM',
            'Month': report?.Month 
        }
    })


    return (
    <button className="edit-pill"> 
      <CSVLink style={{color: '#FFF'}} data={result} filename={fileName}> <span>Download .csv</span></CSVLink> 
    </button>
  )
}

export default ExportCCMSummaryReportToCSV