import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';


const ExportBillingReportToCSV = ({csvData, fileName, month, year}) => {
  let CptCode = '';

  let monthArray = ["01", "02", "03", "04", "05", "06", "07",
  "08", "09", "10", "11", "12"];

  
  function calcInitialCPT(minutes) {
    switch(true){
        case (minutes < 20):
            CptCode = ''
        break;
        
        case (minutes >= 20 && minutes < 40):
            CptCode = '99457'
        break;

        case (minutes >= 40 && minutes < 60):
            CptCode = '99457, 99458'
        break;

        case (minutes >= 60 && minutes < 80):
            CptCode = '99457, 99458, 99458'
        break;

        case (minutes >= 80 && minutes < 100):
            CptCode = '99457, 99458, 99458, 99458'
        break;

        case (minutes >= 100):
            CptCode = '99457, 99458, 99458, 99458, 99458'
        break;
    }
  }


  function calcCPT(readings, minutes) {
    switch(true){
      case (readings < 16 && minutes < 20):
        CptCode = ''
        break; 
      case (readings < 16 && minutes >=20 && minutes < 40):
          CptCode = "99457"
      break;
      case (readings < 16 && minutes >=40 && minutes < 60):
          CptCode = "99457, 99458"
      break;
      case (readings < 16 && minutes >=60 && minutes < 80):
          CptCode = "99457, 99458, 99458"
      break;
      case (readings < 16 && minutes >=80 && minutes < 100):
          CptCode = "99457, 99458, 99458, 99458"
      break;

      case (readings < 16 && minutes >=100):
          CptCode = "99457, 99458, 99458, 99458, 99458"
      break;

      case (readings >= 16 && minutes < 20):
          CptCode = '99454'
          break;
      
      case (readings >= 16 && minutes >= 20 && minutes < 40):
            CptCode = '99454, 99457'
            break; 
      case (readings >= 16 && minutes >= 40 && minutes < 60):
          CptCode = '99454, 99457, 99458'
      break;
      
      case (readings >= 16 && minutes >= 60 && minutes < 80):
          CptCode = '99454, 99457, 99458, 99458'
      break;
      
      case (readings >= 16 && minutes >= 80 && minutes < 100):
          CptCode = '99454, 99457, 99458, 99458, 99458'
      break;

      case (readings >= 16 && minutes >= 100):
          CptCode = '99454, 99457, 99458, 99458, 99458, 99458'
      break;

      default: 
      break;
      }
    }


    let result = csvData.map((report, index) => {

        report?.setupDate && moment(report?.setupDate).format('MM') === monthArray[month - 1] && moment(report?.setupDate).format('YYYY') === year ? calcInitialCPT(report?.totalMinutes) : calcCPT(report?.totalReadings, report?.totalMinutes);

        return {
            'Sr No':index + 1,
            'Month': report?.Month,
            'EHR-ID':report?.emrId,
            'Patient Name':report?.patientName,
            'D.O.B':moment(report?.DOB).format('MM/DD/YYYY'),
            'Total Readings':report?.totalReadings,
            'Total Minutes': report?.totalMinutes,
            'Call Minutes':report?.callMins,
            'VM Minutes':report?.vmMins,
            'Review Minutes': report?.reviewMins,
            'Setup Date':report?.setupDate && moment(report?.setupDate).format('MM-DD-YYYY'),
            'FCC-Date': report?.FCC?._id ? moment(report?.FCC?._id).format("MM-DD-YYYY") : null,
            'Month Span': report?.monthSpanStart !== 0 && report?.monthSpanEnd !== 0 ? 
                moment(report?.monthSpanStart).format('MM-DD-YYYY') +' to '+
                moment(report?.monthSpanEnd).format('MM-DD-YYYY') 
                : '--',
            'Readings': report?.monthDurationReadings,
            'FCC Date CPT': report?.FCC?._id && moment(report?.FCC?._id).format('MM') === monthArray[month - 1] && moment(report?.FCC?._id).format('YYYY') === year ? 99453 : null,
            'CPT Code': CptCode,
            'Last Activity': report?.lastActivity ? moment(report?.lastActivity).tz("America/New_York").format("MM/DD/YYYY hh:mm A") : null,
            'Doctor':report?.doctorName,
            'Category': 'RPM' 
          }
    })


  return (
    <button className="edit-pill">
        <i className='bx bxs-cloud-upload'></i> <CSVLink style={{color: '#FFF'}} className='link' data={result} filename={fileName}> <span>Download .csv</span></CSVLink> 
    </button>
  )
}

export default ExportBillingReportToCSV;