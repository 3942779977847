import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar  from '../../components/AdminDashboard/Sidebar';
import TopBar  from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { useSelector, useDispatch } from 'react-redux'
import {getPatientsWithCriticalDataDoctorwise} from '../../actions/adminActions';
import moment from 'moment';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import {getDoctors} from '../../actions/adminActions';
import { Modal, Badge } from 'react-bootstrap';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { useAlert } from 'react-alert';

const AdminCriticalReadingsDoctorWise = () => {
    let resPerPage;
    let currentPage;

    const alert = useAlert();
    const dispatch = useDispatch();
    const {count, respondedReadings, notRespondedReadings, highSystolic, lowDiastolic,  criticalPts, weightReadings, glucoseReadings, spo2Readings} = useSelector(state => state.criticalPatients);
    const { doctors } = useSelector(state => state.doctor);

    const [filterReadings, setFilterReadings] = useState('all');
    const [pagination, setPagination] = useState(25);  
    const [selectedDr, setSelectedDr] = useState();
    const [days, setDays] = useState(3);
    const [conclusion, setConclusion] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
      dispatch(getDoctors(resPerPage = 50, currentPage = 1));
      dispatch(getPatientsWithCriticalDataDoctorwise(pagination, filterReadings, selectedDr, days));

  }, [dispatch, filterReadings, pagination, selectedDr, days]);

  
      const updateCriticalReadingsPagination = () => {
          setPagination(pagination + 25);
          dispatch(getPatientsWithCriticalDataDoctorwise(pagination, filterReadings, selectedDr, days));
      }
  
      let sysBPCategory; //variable to store category of BP
      let color
  
      function calcSysBpCategory(sys) {
          switch(true){
              case (sys > 210):
                  sysBPCategory = "Hypertension- stage 4"
                  color = '#FE0004'
                  break;
              case (sys >= 180 && sys <= 210):
                  sysBPCategory = "Hypertension-Stage 3"
                  color = '#FE504F'
                  break;
              case (sys >= 160 && sys <= 179):
                  sysBPCategory = "Hypertension-Stage 2"
                 color = '#FE504F'
              break;
              case (sys >= 140 && sys <= 159):
                  sysBPCategory = "Hypertension-Stage 1"
                  color = '#FF32CB'
              break;
              case (sys >= 130 && sys <= 139):
                  sysBPCategory = "Pre-hypertension"
                  color = '#FFFF00'
              break;
              case (sys >= 121 && sys <= 129):
                  sysBPCategory = "High Normal B.P"
                  color = '#CBFE33'
              break;
              case (sys >= 100 && sys <= 120):
                  sysBPCategory = "Normal Blood Pressure"
                  color = '#00FF99'
              break;
              case (sys >= 90 && sys <= 99):
                  sysBPCategory = "Low Normal B.P"
                  color = '#CDFFCC'
              break;
              case (sys >= 70 && sys <= 89):
                  sysBPCategory = "Low Blood Pressure"
                  color = '#DEEAF6'
              break;
              case (sys >= 50 && sys <= 69):
                  sysBPCategory = "Too Low Blood Pressure"
                  color = '#9CC3E4'
              break;
              case (sys < 50):
                  sysBPCategory = "Extremely Low B.P"
                  color = '#2E73B4'
              break;
              default:
                  sysBPCategory = "Invalid B.P"
                  color = '000'
              } 
          }   

        let selectedRows = [];  
        const refreshHandler = () => {
            dispatch(getPatientsWithCriticalDataDoctorwise(pagination, filterReadings, selectedDr, days));
            selectedRows = [];
        }

          function handleConclusion(notes) {
            setConclusion([notes])
            setShow(true);
        }
     
        var minutesDuration;
        const calcDuration = (receiveDate, sentDate) => {
            minutesDuration = moment(receiveDate).diff(moment(sentDate), 'minutes');
        }

        const handleCopyRows = (pts) => {
            selectedRows.push(pts);
            let clipboardText = '';

            for(let i = 0; i < selectedRows.length; i++) {
                clipboardText += 'B.P Readings ' + selectedRows[i]?.telemetaryData?.sys + '/'+ selectedRows[i]?.telemetaryData?.dia + '\t' +
                'Pulse ' + selectedRows[i]?.telemetaryData?.pul + '\t'+
                'Date : ' + moment(selectedRows[i]?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY") + `\t`+  
                'Time : ' + moment(selectedRows[i]?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A") + '\n \n';        
            }

            navigator.clipboard.writeText(clipboardText)
            .then(() => {
                alert.success(`${selectedRows?.length} row(s) copied to clipboard`);
            })
            .catch((error) => {
                alert.error('Error copying to clipboard: ', error);
            });
        };



        

  return (
    <>
        <MetaData title="Critical Readings"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

       <br/>    
        <div className="shadow-lg p-3 mb-2 mr-4 ml-4 background-white">
                <div className="home-content">
                
                <div className="row-display-secondary">
                    <div className="col-md-4">                    
                     <div>
                            <h5 className="title"> 
                                Doctorwise Critical Vitals
                            </h5>    
                            <p>Showing <b>{criticalPts?.length}</b> results out of <b>{count && count}</b> </p>
                        </div>
                    </div>

                    <div className="col-md-3">
                    {criticalPts[0]?.assigned_doctor_id ? <>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <img src={doctorIcon} width="58px" height="57px" />
                                <div>
                                <b>Selected Doctor</b>
                                <h4 className='m-0 p-0'>Dr. {criticalPts[0]?.assigned_doctor_id?.lastname}, {criticalPts[0]?.assigned_doctor_id?.firstname} </h4>
                                <Link to="/adminDashboard" className="link">
                                    Go to dashboard
                                </Link>
                            </div>
                        </div>
                    </> : null}
                    </div>

                    <div className="col-md-1">

                    </div>


                    <div className="row-display-secondary">
                        <div>
                            <label className='form-label'>Select Doctor</label>
                            <select className="form-control" value={selectedDr} onChange={e => setSelectedDr(e.target.value)}>
                                <option value="default">Select Doctor</option>
                                {doctors && doctors.map((doctor, index) => (
                                    <option key={index} value={doctor._id}>
                                        {doctor.firstname} {doctor?.lastname}
                                    </option>
                                ))}    
                            </select>
                        </div>

                        <div>
                            <label className='form-label' >Days</label>
                            <select className="form-control" value={days} onChange={e => setDays(e.target.value)}>
                                <option value="3">Last 3 Days</option>
                                <option value="5">Last 5 Days</option>
                                <option value="7">Last 7 Days</option>
                                <option value="9">Last 9 Days</option>
                                <option value="11">Last 11 Days</option>
                                <option value="13">Last 13 Days</option>
                                <option value="15">Last 15 Days</option>
                                <option value="17">Last 17 Days</option>
                                <option value="19">Last 19 Days</option>
                            </select>
                        </div>

                    </div>

                    <div>
                        <label className='form-label'>Refresh List</label> <br />
                        <button className="btn btn-secondary btn-sm mt-2"  onClick={refreshHandler}>
                            Refresh
                        </button>
                    </div>

                    
                        
                    
                </div>

                <div>

                </div>
                <hr />

                <div className="row-display">
                            <label className="profile-label mt-2">Search Filters </label>
                            <span className={`smallFilterBtn ${filterReadings === 'all' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('all')}>
                                    All Readings
                            </span> 
                            
                            <span className={`smallFilterBtn ${filterReadings === 'responded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('responded')}>
                                    Responded <span className="notiNumber ml-2" style={{backgroundColor: 'green'}}> {respondedReadings > 10 ? respondedReadings :'0'+ respondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'notResponded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('notResponded')}>
                                    Not Responded <span className="notiNumber ml-2"> {notRespondedReadings > 10 ? notRespondedReadings :'0'+ notRespondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'highSystolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('highSystolic')}>
                                    High Systolic {'>'} 180 <span className="notiNumber ml-2"> {highSystolic > 10 ? highSystolic :'0'+ highSystolic} </span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'lowDiastolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('lowDiastolic')}>
                                    Low Diastolic {'<'} 55 <span className="notiNumber ml-2"> {lowDiastolic > 10 ? lowDiastolic :'0'+ lowDiastolic} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('weight')}>
                                    Weight <span className="notiNumber ml-2"> {weightReadings > 10 ? weightReadings :'0'+ weightReadings}</span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'glucose' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('glucose')}>
                                    Glucose <span className="notiNumber ml-2"> {glucoseReadings > 10 ? glucoseReadings :'0'+ glucoseReadings} </span>  
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('spo2')}>
                                    Spo2 <span className="notiNumber ml-2"> {spo2Readings > 10 ? spo2Readings :'0'+ spo2Readings} </span> 
                            </span>
                        </div>
                        <br/>

                        
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Vitals</th>
                                    <th width="90px">Device</th>
                                    <th width="90px">Pulse</th>
                                    <th>Status</th>
                                    <th>Doctor</th>
                                    <th width="100px"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {criticalPts && criticalPts.map((pts, index) => (
                                <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                                    <td>
                                        <div className="row-display">
                                        <span className="roundedNameIcon">{pts?.assigned_patient_id?.lastname[0]}{pts?.assigned_patient_id?.firstname[0]}</span>
                                        
                                        <Link to={{ pathname: "/patientProfile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } </Link>
                                        </div>
                                    </td>

                                    <td>{pts?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p className='p-0 m-0'>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>

                                        {/* function to calculate time duration */}
                                        {pts?.createdAt && pts?.telemetaryData?.ts && calcDuration(moment(pts?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                                            moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                                            {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }
                                            {/* function to calculate time duration ends */}
                                    </>}
                                    </td>

                                    {calcSysBpCategory(pts?.telemetaryData?.sys)}
                                    
                                     
                                    {pts?.deviceId?.deviceType === "bp" ? <td> 
                                            <b>{pts?.telemetaryData?.sys} / {pts?.telemetaryData?.dia} mmHG </b><p>
                                                {pts?.telemetaryData?.sys >= 139 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                                pts?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                                pts?.telemetaryData?.dia >= 90 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                                pts?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                                </p>
                                                </td> : pts?.deviceId?.deviceType === "weight" ? <td>
                                                <b>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                                <p>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                            </b>
                                        </td> : pts?.deviceId?.deviceType === "sugar" ? <td>
                                            <b> {pts?.telemetaryData?.data} &nbsp;
                                                {pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                            </b>
                                        </td> : pts?.deviceId?.deviceType === "spO2" ? <td>
                                            <b>{pts?.telemetaryData?.notification_payload?.spo2_pct}
                                            <p>Perfusion: {pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                        </td> : <td>---</td> }

                                    {/* Device type */}
                                    <td><b>{pts?.deviceId?.deviceType === "bp" ? 
                                    <span className='bpDeviceType'>B.P</span> : 
                                    pts?.deviceId?.deviceType === 'weight' ? 
                                    <span className='weightDeviceType'>Weight</span> : 
                                    pts?.deviceId?.deviceType === 'sugar' ? 
                                    <span className="glucoseDeviceType">Glucose</span> :
                                    pts?.deviceId?.deviceType === 'spO2' ? 
                                    <span className="spo2DeviceType">Spo2</span> : null } 
                                    </b>
                                    </td>        

                                     {/* B.P Pulse rate  */}
                                     <td>
                                        <b>{pts?.telemetaryData?.pul ? pts?.telemetaryData?.pul +' bpm' : pts?.telemetaryData?.notification_payload?.pulse_bpm ? pts?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} </b>
                                    </td>
                                                                  
                                    {/* B.P Category */}
                                    <td> {pts?.telemetaryData?.sys ? <><i className='bx bxs-circle' style={{color: color, fontSize: '24px'}}></i>&nbsp;&nbsp;<b>{sysBPCategory}</b></> : '---'}</td> 
                                    
                                    {/* Doctor Name */}
                                    <td><b>{pts?.assigned_doctor_id ? 'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname : null}</b></td>
                                    
                                    {/* Responded or Not */}
                                    <td>{pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                        <i className="fas fa-times" style={{color: 'red'}}></i>}
                                        &nbsp; &nbsp;
                                        {pts?.notes?.length > 0 && <Link className="link" onClick={() => handleConclusion(pts?.notes[0])}>
                                            <i className='bx bx-dots-horizontal-rounded'></i>
                                            </Link>
                                        }

                                        <button 
                                            className="edit-pill" 
                                            onClick={() => handleCopyRows(pts)}
                                            >
                                            <i className='bx bx-copy'></i>
                                        </button>

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                        <br/>
                        {criticalPts?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateCriticalReadingsPagination()}>Show more</button>
                            </div>
                        </> }

            </div>
        </div>         

        <Modal show={show} onHide={handleClose}>
                    <Modal.Header style={{backgroundColor: '#EDEADE'}}><h5 className='title'>Conclusion</h5></Modal.Header>

                    <Modal.Body style={{backgroundColor: '#EDEADE'}}>
                        {conclusion && <>
                            {conclusion?.map((con, index) => (
                                <div key={index}>
                    
                    <div>      
                    <b className="profile-label">Conclusion : </b>
                    <p className="mt-1 mr-3">{con?.conclusion} </p>

                    <div className="row-display-secondary">                
                            
                        <img src={patientProfileImg} alt="hr/drImg" className='roundedBorderImg' width="50px" height="50px" />
                        
                        <div>
                        <b className="profile-label">{con?.conclusion_hr_id?.lastname +' , '+ con?.conclusion_hr_id?.firstname} </b>
                        
                        <p className="mt-1" style={{letterSpacing: '1px'}}>
                        {moment(con?.dateTime).format("MM-DD-YYYY hh:mm z A")}</p>
                        </div>
                        
                       </div> 
                    </div>
                </div>
                ))}
                        </>}
                    </Modal.Body>
                    
                    <Modal.Footer style={{backgroundColor: '#EDEADE'}}>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleClose}>
                            Close
                        </button>
                    </Modal.Footer>
            </Modal>
    </section>
    </>
  )
}

export default AdminCriticalReadingsDoctorWise