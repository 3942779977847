import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import adminImg from '../../assets/Images/defaultUser.png';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import { getOrgProfile } from '../../actions/adminActions'; 



const Sidebar = (props) => {

    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { organization } = useSelector(state => state.orgProfile);
    
    const [active, setActive] = useState('Dashboard');

    const dispatch = useDispatch();
    let asstAssignedDr = localStorage.getItem('myArray');
    let countLength = JSON.parse(asstAssignedDr);
    
    useEffect(() => {
		
		if(isAuthenticated === false) {
			props?.history?.push("/login");
		}

        if(user?.assigned_org_id){
            dispatch(getOrgProfile(user?.assigned_org_id?._id));
        }
        
	}, [isAuthenticated])

    return (
        <>
            <div className="sidebar">
            <br/>

            <p className="text-white">
                <div className="row" style={{paddingLeft: '50px', color: 'white'}}>
                    <img src={adminImg} width="50" height="50" className="roundedBorderImg" alt="img" />
                    &nbsp;&nbsp;<p> {user && user.firstname +" "+ user?.lastname } {user?.verified === true ? 
                    <i className='bx bxs-badge-check' style={{color: 'white'}}></i> : null} <p className="text-white">Clinical Staff Dashboard</p></p>
                    </div>
            </p>

            
                {user && countLength?.length > 0  ? <>
                <ul>
                <li className={`${active === 'Dashboard' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="Dashboard">
                    <Link 
                        key="asstDashboard"
                        to="/clinical_staff/dashboard" 
                        onClick={() => setActive('Dashboard')} className={`${active === 'Dashboard' ? 'activeLink' : 'link'}`}>
                        <span>Dashboard</span>
                    </Link>
                </li>

                <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/patients" className="sidebar-link"  key="asstPpmPts">
                        <span> RPM Patients</span>
                    </Link>
                </li>

                <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/ccm/patients" className="sidebar-link"  key="asstCcmPts">
                        <span> CCM Patients</span>
                    </Link>
                </li> 

                 <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/report/telemetry" className="sidebar-link"  key="asstTelemetryReport">
                        <span> Telemetry Data Report</span>
                    </Link>
                </li>  

                <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/minutes/report" className="sidebar-link"  key="targetedminutesHistory">
                        <span> Targeted Minutes History</span>
                    </Link>
                </li>

                <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/report/timereport" className="sidebar-link"  key="targetedminutesReport">
                        <span> Time Report</span>
                    </Link>
                </li>

                <li className="sidebarLinkBtn">
                    <Link to="/clinical_staff/messages" className="sidebar-link"  key="targetedMessages">
                        <span> Messages</span>
                    </Link>
                </li>

                {/* {workingDr &&  <div className='text-center text-white mt-4'>
                    <h5>Working Doctor</h5> 
                    <img src={doctorIcon} width="85px" height="85px" />
                    <p className='mt-2'><b>
                        Dr. {JSON.stringify(workingDr?.assigned_doctor_id?.lastname +', '+ workingDr?.assigned_doctor_id?.firstname).replace(/"/g, '')}
                    </b></p>

                    <Link 
                        to="/clinical_staff/settings" 
                        className='edit-pill'
                        >Switch Doctor</Link>                   
                </div>
                }         */}
                <div className="text-white text-center mt-3">
                {asstAssignedDr && organization?.doctors?.length > 0 ? <>
                    <h5>Working Doctor(s)</h5>
                    <img src={doctorIcon} width="85px" height="85px" />
                    
                    {organization?.doctors.map((item, index) => (
                        asstAssignedDr?.includes(item?.assigned_doctor_id?._id) ? 
                        <li key={index} className="m-1 p-1">{index + 1 +') '}  Dr. {item?.assigned_doctor_id?.lastname +', '+ item?.assigned_doctor_id?.firstname}</li> : null
                    ))}    

                    <Link 
                        to="/clinical_staff/settings" 
                        className='edit-pill'
                    >Switch Doctor</Link>
                </> : null
                }
                </div>
                
                {/* {organization?.doctors.map((item, index) => (
                    asstAssignedDr?.includes(item?.assigned_doctor_id?._id) ? <li key={index}>{item?.assigned_doctor_id?.lastname}</li> : null
                ))} */}
               
            </ul>
                </> : <>
                <ul>
                    <li className={`${active === 'Dashboard' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="Dashboard">
                        <Link 
                            key="asstDashboard"
                            to="/clinical_staff/dashboard" 
                            onClick={() => setActive('Dashboard')} className={`${active === 'Dashboard' ? 'activeLink' : 'link'}`}>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                            
                    <li className="sidebarLinkBtn">
                        <Link to="/clinical_staff/settings" className="sidebar-link"  key="settings">
                            <i className="bx bx-cog"></i>
                            <span> Settings </span>
                            &nbsp;&nbsp;<span className='newSpan'>New</span> 
                        </Link>
                    </li>
                </ul>
                
                </> }
        </div>    
        </>
    )
}

export default Sidebar;
