import { useEffect, useState } from 'react';
import Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import doctorProfileImg from '../../assets/Images/patientProfile.png';
import { getOrgProfile } from '../../actions/adminActions'; 
import { Form } from 'react-bootstrap';
import { useAlert } from 'react-alert';

const Settings = () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const { user} = useSelector(state => state.auth);
    const { organization } = useSelector(state => state.orgProfile);
    const [reset, setReset] = useState(false);

    let asstAssignedDr = localStorage.getItem('myArray');
    
    const getInitialCheckedDrs = () => {
        const storedDrs = localStorage.getItem('myArray');
        return storedDrs ? JSON.parse(storedDrs) : [];
    };

    // const [checkedDrs, setCheckedDrs] = useState(JSON.parse(localStorage.getItem('myArray')));
    const [checkedDrs, setCheckedDrs] = useState(getInitialCheckedDrs);

    const handleCheckboxChange = (e) => {
        if(e.target.checked === true){
            setCheckedDrs([...checkedDrs, e.target.value]);
        } else if(e.target.checked === false){
            let freshArray = checkedDrs.filter(val => val !== e.target.value);
            setCheckedDrs([...freshArray]);
        }
      };

    useEffect(() => {
        dispatch(getOrgProfile(user?.assigned_org_id?._id));

        localStorage.setItem('myArray', JSON.stringify(checkedDrs));
    }, [dispatch, checkedDrs]);

    const resetHandler = () => {
        var confirmation = window.confirm(`Are you sure to reset all selected Doctors ?`);
        if(confirmation){
            localStorage.removeItem('myArray');   
            setReset(true);   
            setCheckedDrs([]);
            alert.success('Doctors have been reset successfully')
        }
    }


  return (
        <>
            <MetaData title="Settings"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />
            <br/>

            <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        
                        <div className="row-display">
                            <div className="col-md-11">
                                <h5 className='title'>
                                    <i className="bx bx-cog"></i> <strong>&nbsp;Settings </strong>
                                </h5>
                            </div>

                            <Link to="/clinical_staff/dashboard" className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back' ></i></Link> &nbsp;
                        </div>
                        <hr />

                        {user && <>
                            <div className="row">
                            <div className="col-md-4">
                                <div className='text-center'>
                                    <img src={doctorProfileImg} className="profile-img"/>
                                        
                                    <p className="profile-name">{user?.firstname} {user?.lastname} </p>
                                    {user?.verified === true ? <p style={{color: 'green'}}>Verified</p> : <p style={{color: 'red'}}>Not Verified</p>}
                                    
                                    {user.specialization && user?.specialization.map((spec, index) => (
                                        
                                            <div key={index}>
                                                <p className="doctor-specilizations">{spec?.fieldname }</p>
                                                <br />
                                            </div>
                        
                                    ))}
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div>
                                    <div className="card-inner-margin">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <span className="profile-label">Email: </span>
                                                <p>{user?.email}</p>

                                                <span className="profile-label">Gender: </span>
                                                <p>{user?.gender}</p>

                                                <span className="profile-label">Contact #: </span>
                                                <p>{user?.contactno || 'N/A'}</p>

                                                <span className="profile-label">Phone # 1: </span>
                                                <p>{user?.phone1 || 'N/A'}</p>
                                            </div>


                                            <div className="col-md-6">
                                            <span className="profile-label">Phone # 2: </span>
                                                <p> {user?.phone2 || 'N/A'} </p>

                                                <span className="profile-label">License #: </span>
                                                <p>{user?.npinumber}</p>

                                                <span className="profile-label">NPI #: </span>
                                                <p>{user?.licensenumber}</p>

                                                <span className="profile-label">Account Created Date: </span>
                                                <p>{moment(user?.createdAt).format("lll")}</p>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                        </>}
                        <hr />


                        {user && user?.assigned_org_id ? <>
                            <div className='row-display'>
                                <h5 className='title'>
                                <i className="bx bx-user"></i> <strong>&nbsp;Select Your Working Doctor </strong>
                                </h5>

                                <button className='edit-pill m-3' 
                                    style={{backgroundColor: 'red'}}
                                    onClick={resetHandler}
                                    >
                                    Reset All
                                </button>

                            </div>
                        
                        <br />

                        
                         <div className="row">           
                         <div className="col-md-6">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label">Your Organization</label>                
                                    <p>
                                        <h4>{user && user?.assigned_org_id?.name}</h4>
                                        </p>
                                </div>

                                <div className="col-6">

                                <Form>
                                 <div> 
                                 <label className="form-label">Select Your Working Doctors</label>               
                                 {organization?.doctors && organization.doctors.map((org) => (
                                    <div key={org?.assigned_doctor_id?._id} className="checkbox-container">
                                        <label className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            value={org?.assigned_doctor_id?._id}
                                            checked={asstAssignedDr?.includes(org?.assigned_doctor_id?._id) ? true : false}
                                            onChange={e => handleCheckboxChange(e)}
                                        />
                                        <div className="checkbox-custom"></div>
                                        </label>
                                        <span className="checkbox-label">
                                        {org.assigned_doctor_id?.lastname + ', ' + org.assigned_doctor_id?.firstname}
                                        </span>
                                    </div>
                                    ))}
                                </div>
                                </Form>
                                </div>
                            </div>
                         </div>

                         </div>           
                        
                        </> : <>
                        <div className="text-center">
                            <h5 style={{color: 'red'}}>No Organization assigned to you, please contact admin </h5>
                        </div>
                        </>}                

                    </div>
                </div>  


            </section>
        </>
  )
}

export default Settings