import { useState, useEffect } from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import { Link } from 'react-router-dom';
import { getHRCCMPatients } from '../../actions/HRActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useAlert } from 'react-alert';
import Loader from '../../layouts/Loader';
import notFound from '../../assets/Images/notFound.png';
import Pagination from 'react-js-pagination';
import { 
    HR_PATIENTS_REQUEST,
    HR_PATIENTS_SUCCESS,
    HR_PATIENTS_FAIL,
} from '../../constants/HRConstants';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';


const NurseCCMPatients = () => {
   const alert = useAlert(); 
   const dispatch = useDispatch(); 
   const [currentPage, setCurrentPage] = useState(1);
   const [pagination, setPagination] = useState(15);

   const { loading, error, hrpatients, count} = useSelector(state => state.hrPatients);
   const [input, setInput] = useState("");
   
   let nurseDrs = localStorage.getItem('nurseSelectedDrs');   
   
    useEffect(() => {
        if(error) {
            alert.error(error);
        }

        if(nurseDrs && nurseDrs?.length > 0){
            dispatch(getHRCCMPatients(nurseDrs, pagination, currentPage))
        }

    }, [dispatch, error, pagination, currentPage]);


    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    }    

    const refreshhandler =() => {
        dispatch(getHRCCMPatients(nurseDrs, pagination, currentPage));
        setInput("");
    }


    const searchHandler = async() => {
        if(input?.length > 0){
            try {
                dispatch({
                    type: HR_PATIENTS_REQUEST,
                })
        
                await axios.post(`${Prod01}/patient/ccm/search/${pagination}/${currentPage}`, {
                        value: input,
                        nurseDrs
                    },{
                        headers: {
                        'Content-Type': 'application/json',
                      }
                    }, {
                        timeout: 10000 // 10 seconds
                    })
                .then((result) => {
                    dispatch({
                        type: HR_PATIENTS_SUCCESS,
                        payload: result.data
                    })
                })    
            } 
            catch (error) {
                if (error.code === 'ECONNABORTED') {
                    dispatch({
                        type: HR_PATIENTS_FAIL,
                        payload: 'request timed out'
                    });
                } else {
                    dispatch({
                        type: HR_PATIENTS_FAIL,
                        payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                    });
                }
            }
        } else {
            alert.error('Please type something to search');
        }
      }

    

  return (
    <>
    <MetaData title="CCM Patients" />
          <HRSidebar />
          
          <section className="home-section">
              {/* TopBar */}  
              <HRTopBar />
              
              <br />
              {loading ? <Loader /> : (  
              <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white rounded">        
                <div className="home-content">
                    <div>
                        <div className="row-display">
                        <div>
                            <h5 className="title">
                                {count} - CCM Patients
                            </h5>
                            <p>Showing <b>{pagination}</b> results per page. </p>
                        </div>

                        <div className="row-display m-3"> 
                                <Link to="/Nurse/Dashboard" className="btn btn-secondary"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                &nbsp;&nbsp;
                                <button className="edit-pill" onClick={refreshhandler}>
                                    <i className='bx bx-refresh'></i>
                                </button>
                                &nbsp;&nbsp;
                                <Link to="/Nurse/Add_New_Patient" className="edit-pill">+ Add New Patient</Link> 
                        </div>
                        </div>

                        <hr />
                        <div className="row colored-row">       
                            <div className="mini-searchBox ml-3" >
                                <input 
                                    type="text" 
                                    placeholder="Search CCM Pts. by ID, Name and DOB" 
                                    className="mini-searchInput" 
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    autoFocus
                                    maxLength="10"
                                />
                                <i className='bx bx-search mini-searchbar-Icon'></i>
                            </div>

                            <button className='edit-pill m-2' onClick={() => searchHandler()}>Search</button>
                        </div>    
                        <br />

                        {/* Patient List Card */}
                        <div className="col-md-12">
                        {hrpatients && hrpatients.length > 0 ? <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>EHR - ID</th>    
                                <th>Name</th>
                                <th>DOB </th>
                                <th width="60px">CCM Careplan Status</th>
                                <th>Acc Status</th>
                                <th>Assigned Doctor</th>
                                <th>Assigned Nurse</th>
                                <th>Consent Role</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {hrpatients && hrpatients.length > 0 ? <> 
                                {hrpatients && hrpatients.map((patient, index) => (
                                <tr key={index}>
                                <td><b>{patient?.emrId || 'N/A'}</b></td>  
                                <td>
                                    {patient?.block === false ? <>
                                        <Link className="link" 
                                            to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id}}}>
                                            {patient?.lastname +', '+ patient?.firstname}
                                        </Link>
                                    </> : <>
                                    <b style={{color: 'red'}}>
                                         {patient?.lastname +', '+ patient?.firstname}
                                    </b>
                                    </>
                                    }
                                </td>
                                
                                <td> <b>{moment(patient?.DOB).format("MM-DD-YYYY")}</b> <p>
                                    {moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years old
                                </p></td> 

                                <td>
                                    {patient?.ccmCareplanManualAdded === true ? <i className="fa fa-check" style={{color: 'green'}}></i> : <i className="fa fa-times" style={{color: 'red'}}></i>}
                                </td>
                                
                                {patient?.block === false ? <td>
                                    <p style={{color: 'green', fontWeight: 'bold'}}>Activated</p>
                                    </td> : <td><p style={{color: 'red', fontWeight: 'bold'}}>Deactivated</p>
                                </td>}
                                    
                                {patient?.assigned_doctor_id ? <>
                                <td> <b>Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</b></td>
                                </> : <>
                                <td>---</td>
                                </>}

                                {patient?.assigned_hr_id ? <>
                                <td> <b>{patient?.assigned_hr_id?.firstname} {patient?.assigned_hr_id?.lastname}</b></td>
                                </> : <>
                                <td>---</td>
                                </>}

                                <td><b>{patient?.patientType}</b></td>
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            </tbody>        
                        </table>
                        
                        {hrpatients?.length < count && 
                            <div className="d-flex justify-content-center mt-5"> 
                                <Pagination 
                                    activePage={currentPage} 
                                    itemsCountPerPage={pagination} 
                                    totalItemsCount = {count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                        }    
                        </> : <>
                            <div className="text-center">
                                <img src={notFound} style={{width: '40px', height: '40px'}} alt="not found" />
                                    <p><small>No CCM Patient found of your assigned doctor.</small></p>
                            </div>
                        </>}                      
                        </div>
                    </div>
                </div>
            </div>
          )}
        </section>
    </>
  )
}

export default NurseCCMPatients