import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Modal, Alert } from 'react-bootstrap';
import doctorIcon from '../../assets/Images/doctorIcon.png';
import {useAlert} from 'react-alert';
import { Formik, Form } from 'formik';
import { submitCCMConsent, getPatientCCMConsent, deleteCCMConsent } from '../../actions/HRActions';
import moment from 'moment';
import notFound from '../../assets/Images/browser.png';


const PatientCCMConsent = ({patientId, isNurse}) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const {error : commonError, message} = useSelector(state => state.common);
  const {ccmConsent} = useSelector(state => state.patientCCMConsent);

  const [carePlanShow ,setCarePlanShow] = useState(false);
  const [fileName, setFileName] = useState({});
  const [error, setError] = useState('');
  const [type, setType] = useState('Written');
  const [consentType, setConsentType] = useState('CCM');
  const [description, setDescription] = useState('');
  
  
  useEffect(() => {
    // if(commonError) {
    //   alert.error(commonError);
      
    //   dispatch({
    //     type: HIDE_ALERT_MESSAGE
    //   })
    // }

    // if(message){
    //   alert.success(message);
      
    //   dispatch({
    //     type: HIDE_ALERT_MESSAGE
    //   })
    // }

    dispatch(getPatientCCMConsent(patientId, consentType));

    },[dispatch]);


  const handleCarePlanModalClose = () => setCarePlanShow(false);
  const handleCarePlanModalShow = () => setCarePlanShow(true);


  const handleFileChange = e => {
    setFileName(e.target.files[0]);
  }

  const downloadFile = (fileName) => {
    // local link
    // window.open(`http://localhost:5000/v1/uploadFiles/${fileName}`);
    // live link
    window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
  }



  const submitConsent = () => {
    if(!fileName.name && !description){
      alert.error('description or file is required');
      return
    }

    dispatch(submitCCMConsent(fileName, user, patientId, type, description));
       setFileName({});
       setError('');
       setCarePlanShow(false);
    }

  const deleteHandler = (consentId) => {
    var confirmation = window.confirm('Are you sure you want to delete CCM Consent for this patient ?');

    if(confirmation) {
      dispatch(deleteCCMConsent(consentId));
      return;
    }
    return;
  }

  const onOptionChange = e => {
    setType(e.target.value)
  }

  return (
    <div className='blueCardDashboardStatsCard p-3' style={{borderRadius: '10px'}}>    
        <div className="row-display">
          <h5 className="title text-white">CCM Consent</h5>
          <p><b>{ccmConsent && ccmConsent?.type}</b></p>
        </div>
    
        {ccmConsent ? <>
          <div className="row">
          
          &nbsp;&nbsp;&nbsp;   
          {ccmConsent?.fileName !== " " && <span className="fileLink" 
            onClick={() => downloadFile(ccmConsent?.fileName)}>
              {ccmConsent && ccmConsent?.fileName}
          </span> }
        
          &nbsp;&nbsp;&nbsp;
         
          </div>
          
          {ccmConsent?.type === "Verbal" ? 
          <><b>Description</b><br />
          <p className='text-white'>
            {ccmConsent?.description}
          </p> </>: null}

          <br/>

            <div className="row-display-secondary">
              <div>
                <Image src={doctorIcon} style={{width: '60px', height: '60px'}} />
              </div>

              <div className="pl-2">
                  <b>{ccmConsent?.addedBy}</b>
                  <p className="text-white">{moment(ccmConsent?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A')}</p>
              </div> 
            </div>

            {ccmConsent ? <div className='text-center'>
              {isNurse === true ? <>
                <button className="edit-pill" style={{backgroundColor: 'red', color: 'white'}} onClick={() => deleteHandler(ccmConsent?._id)}>
                  <i className="bx bx-minus mr-2"></i> Delete CCM Consent
                </button>
              </> : null}
            </div> : null
            }
        </> : 
        <div className="text-center">
          <img src={notFound} style={{width: '40px', height: '40px'}} alt="not found" />
              <p><small>No records found</small></p>

              {isNurse === true ? <>
                <button className="edit-pill" onClick={handleCarePlanModalShow}> 
                <i className='bx bxs-cloud-upload mt-1'></i> &nbsp; Upload Consent
                </button>
              </> : null}
          </div>
      }      
        

           {/* Add CCM Careplan Modal */}
           <Modal show={carePlanShow} onHide={handleCarePlanModalClose}>
            <Modal.Header >
                <h5>
                  Add CCM Consent
                </h5> 
                <button onClick={handleCarePlanModalClose} className="btn btn-danger">
                  <i className='bx bx-x'></i>
                </button>
            </Modal.Header>

                <Modal.Body>
                  <Alert key="danger" variant="danger">
                    Please attach a file
                  </Alert>
                  

                    <Formik initialValues={{ 
                        fileName: ''
                    }}
                    onSubmit={() => {submitConsent()}}>
                        <div>
                            <Form>
                              {type === "Written" ? <>
                              <input 
                                    label="File" 
                                    name="fileName" 
                                    type="file"
                                    accept=".doc, .docx, .pdf"
                                    className="form-control"
                                    style={{border: 'none'}} 
                                    onChange={handleFileChange}
                                />
                              </> : null}
                                

                                <br/>

                                <div className="container">
                                  <label className='profile-label'>Select Type</label>
                                  <br/>

                                  <input type="radio" 
                                    id="written" 
                                    name="fav_language" 
                                    value="Written"
                                    checked={type === "Written"}
                                    onChange={onOptionChange}
                                  />
                                   <label for="written">Written</label>

                                  &nbsp;&nbsp;
                                   <input 
                                    type="radio" 
                                    id="verbal" 
                                    name="fav_language" 
                                    value="Verbal"
                                    checked={type === "Verbal"}
                                    onChange={onOptionChange}
                                  />
                                   <label for="verbal">Verbal</label>
                                

                                
                                {type === "Verbal" ? <>
                                <br />
                                   <label className="profile-label">Consent Description</label>
                                  <textarea
                                    name="description" 
                                    className='form-control'
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}  
                                    placeholder="Type description ..."
                                    >
                                  </textarea>
                                </> : null}
                                </div>
                                <br />

                            <div className="row-class" style={{justifyContent: 'space-between'}}>
                                <button className="edit-pill" type="submit"> Upload Consent</button>
                            </div>
                            </Form>
                        </div>
                    
                    </Formik>   
                </Modal.Body>
            </Modal>
    </div>
  )
}

export default PatientCCMConsent