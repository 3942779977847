import { useState, useEffect, useRef } from "react";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { getPtCCMCareplanManual } from '../../actions/adminActions';
import { useDispatch, useSelector } from "react-redux";
import {useAlert } from 'react-alert';
import Loader from '../../layouts/Loader';
import CCMCareplanManualPDF from '../../components/CCMCareplanManualPDF';
import { useReactToPrint } from 'react-to-print';



const PatientCCMCareplanManualView = ({patient}) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const componentRef = useRef();

    const {loading, error, ccmCareplan} = useSelector(state => state.ccmCareplanManual);

    useEffect(() => {
      if(error){
        alert.error(error)
      }
      {patient?.ccmCareplanManualAdded === true &&
        dispatch(getPtCCMCareplanManual(patient?._id));
      }

    }, [error])
    
    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle:`${patient?.lastname}, ${patient?.firstname} CCM Careplan ` + moment().tz("America/New_York").format("MM/DD/YYYY"),
        content: () => componentRef.current,
    });


    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
    <div>
        <div>
            <button className="edit-pill mt-2" onClick={handleShow}>
            <i className='bx bx-file-blank'></i> View</button>    
        </div>


        <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <h5 className="title mt-2">CCM Careplan Manual</h5>
        
          <div>
          <div style={{ display: "none" }}> 
            <CCMCareplanManualPDF 
            ccmCareplan={ccmCareplan}
            patient={patient}
            ref={componentRef} 
            />
            </div>

            <button className="edit-pill" style={{backgroundColor: 'red'}} onClick={handlePrint}>
                <i className='bx bxs-file-pdf'></i> Download PDF
            </button>
            &nbsp;&nbsp;
            <button className="edit-pill" onClick={handleClose}>
                <i className='bx bx-x'></i>
            </button>
          </div>
        </Modal.Header>

        <Modal.Body>
        
            <div className="row-display">
                <div>
                    <p className="form-label m-0 p-0">Patient Name: </p>
                    <h5 className="title">{patient?.lastname}, {patient?.firstname}</h5>
                    <br />
                    <p className="form-label m-0 p-0">Patient EHR-ID: </p>
                    <p> <b>{patient?.emrId}</b></p>
                </div>

                <div>
                    <p className="form-label m-0 p-0">Date of Birth (D.O.B): </p>
                    <b>{moment(patient?.DOB).format('MM/DD/YYYY')}</b>

                    <br /><br />
                    <p className="form-label m-0 p-0">Program Enrolled: </p>
                    <b>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</b>
                </div>

                {ccmCareplan && ccmCareplan.map((plan, index) => (
                <div key={index}>
                    <p className="form-label m-0 p-0">Created By: </p>
                    <b> {plan?.addedByAdmin ? plan?.addedByAdmin?.name : plan?.addedByNurse?.lastname +' , '+ plan?.addedByNurse?.firstname} </b>
                       
                    
                    <br /><br />
                    <p className="form-label m-0 p-0">Created Date: </p>
                       <b> {plan?.addedDateTime} </b>    
                </div>
                ))}
            </div>

            {loading ? <Loader /> : <>
                {ccmCareplan && ccmCareplan.map((plan, index) => (
                    <div key={index}>
                    <p className="form-label m-0 p-0">Patient Disease(s): </p>

                    <div className="container ml-4">
                        <ul>
                            {plan.diseases.map((disease, index) => (
                                <li key={index}>
                                    <b>{disease.name}</b>
                                </li>
                            ))}
                        </ul>   
                    </div>
                    <hr className="blue-hr" />
        
                    <h5><b>Goal & Interventions Details : </b></h5>
                    
                    {plan.diseases.map((disease, index) => (
                        <div key={index}>
                            <br />
                            <b className="coloredRow p-2 mt-2">Disease {index + 1} : {disease.name} </b> <br /><br />
                            <br />

                            <h5 className="coloredUnderline"><b>Short Term Goals</b></h5>
                                {disease.shortTermGoals.map((disease, index) => (
                                    <div key={index}>
                                        <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                        <span className="row ml-1">
                                            <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;

                                            Status : {disease?.achievedStatus === "notachieved" ? <b style={{color: 'orange'}}>
                                                &nbsp; In Progress
                                            </b> : <b style={{color: 'green'}}> Achieved </b>
                                            }
                                            </span>
                                    </div>
                                ))}
                            
                            <br /><br />
                            <h5 className="coloredUnderline"><b>Long Term Goals</b></h5>
                                {disease.longTermGoals.map((disease, index) => (
                                      <div key={index}>
                                      <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                      <span className="row ml-1">
                                          <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                                          Status : {disease?.achievedStatus === "notachieved" ? <b style={{color: 'orange'}}>
                                              &nbsp; In Progress
                                          </b> : <b style={{color: 'green'}}> Achieved </b>
                                          }
                                          </span>
                                  </div>
                                ))}

                            <br /><br />
                            <h5 className="coloredUnderline"><b>Interventions</b></h5>
                                {disease.interventions.map((disease, index) => (
                                      <div key={index}>
                                      <b>{index + 1 +' ) '+ disease?.goalname}</b>
                                      <span className="row ml-1">
                                          <p>{disease?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                                          Status : {disease?.achievedStatus === "notachieved" ? <b style={{color: 'orange'}}>
                                              &nbsp; In Progress
                                          </b> : <b style={{color: 'green'}}> Achieved </b>
                                          }
                                          </span>
                                  </div>
                                ))}
                        </div>
                    ))}

                    <br />
                    <b>Care Plan Notes : </b> <br />
                    {plan?.notes}
                
                    </div>
                ))}

            <br /><br />
            </>}
        
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PatientCCMCareplanManualView