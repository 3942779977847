import {useEffect, useState } from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctors, doctorDeActivate, doctorActivate } from '../../actions/adminActions';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Pagination from 'react-js-pagination';
import {Badge, Modal } from 'react-bootstrap';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';

const DoctorsList = () => {

    const dispatch = useDispatch();

    const alert = useAlert();
    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(20);

    const [smShow, setSmShow] = useState(false); //small confirm modal
    const [doctorModel, setDoctorModel] = useState(null);
    const [doctorToDelete, setDoctorToDelete] = useState(null);
    const [query, setQuery] = useState("");
    const keys = ["firstname", "lastname", "email", "npinumber", "phone1"];

    // const { loading, error, isUpdated} = useSelector(state => state.admin);
    const {loading, message, error } = useSelector(state => state.common);
    const { loading : doctorLoading, doctors } = useSelector(state => state.doctor);
    const { totalDrs } = useSelector(state => state.adminStat);
        
    useEffect(() =>{
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message) {
            alert.success(message);
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
            dispatch(getDoctors(resPerPage, currentPage));
            setSmShow(false);
        }

        dispatch(getDoctors(resPerPage, currentPage));

    }, [dispatch, error, currentPage, message]);

    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    } 

    const deActivateDoctor = () => {
        dispatch(doctorDeActivate(doctorModel));
    }

    const activateDoctor = () => {
        dispatch(doctorActivate(doctorModel));
    }

    return (
        <>
                <MetaData title="Doctors"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                {doctorLoading ? <Loader /> : (
                <> 
                <br/>  
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">

                    <div className="row-display">
                            <h5 style={{color: '#00A8A8'}} className="title">
                                <i className='bx bx-plus-medical mt-2'></i> &nbsp;List of Doctors
                            </h5>

                            <div className="row-display"> 
                                <Link to="/adminDashboard" className="btn btn-secondary"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                    
                                &nbsp;&nbsp;
                                <Link to="/adddoctor" className="edit-pill">Add New Doctor</Link>
                            </div>
                        </div>
                        <hr />
                    </div>

                    {/* Patient List Card */}
                        <div className="col-md-12">
                         <>
                         <table className="styled-table">
                            <thead align="center">
                                <tr>
                                    <th>Name & Email Address</th>
                                    <th>Verification Status</th>
                                    <th>NPI Number</th>
                                    <th>Acc Status</th>
                                    <th>Organization</th>
                                    <th>No. of Patients</th>
                                    <th>Sig</th>
                                    <th width="80px">Action</th>
                                </tr> 
                            </thead>
                            <tbody>
                            {doctors && doctors.filter(item => keys.some(key => item[key]?.toLowerCase().includes(query))).map((doctor, index) => ( 
                                <tr key={index}>

                                <td className="text-center">
                                    <Link className="link" to={{ pathname: "/doctorProfile", state: {id: doctor?._id}}}> {doctor?.lastname +" , "+ doctor?.firstname} </Link> 
                                    <p>{doctor?.email}</p>
                                </td>
                                

                                <td>{doctor?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}</td>    


                                <td><b>{doctor?.npinumber ? doctor?.npinumber : '--'}</b></td>
                                
                                
                                {doctor?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>}

                                <td>
                                    <b>{doctor?.assigned_org_id?.name || '---'}</b>
                                </td>

                                <td className="text-center"><b style={{color: '#00A8A8', fontSize: '18px'}}>
                                    <i className='bx bx-user'></i> &nbsp; {doctor?.patientCount || 0} </b>
                                </td>
                                <td>{doctor?.signature ? <b><i className='bx bx-check' style={{color: 'green', fontSize: '28px'}}></i></b> : '---'}</td>
                                
                                {/* Actions Dropdown */}
                                <td>
                                <div className="row-display">    
                                
                                <Link to={{ pathname: "/Doctor/Edit", state: {id: doctor}}} className="link"><i className="bx bx-edit"></i></Link>
                                

                                {doctor?.block === false ? <Link to="/doctors" style={{color: 'red'}} className="link" onClick={() => {setSmShow(true); setDoctorModel(doctor?._id); setDoctorToDelete(doctor?.lastname)}}><i className="bx bx-block"></i></Link> : 
                                        <Link to="/doctors" className="link" style={{color: 'green'}} onClick={() => {setSmShow(true); setDoctorModel(doctor?._id); setDoctorToDelete(doctor?.lastname)}}><i className="bx bx-user-check"></i></Link> }
                                </div>
                                </td>
                            </tr> 
                             ))}    
                             </tbody>
                            </table> 
                            <small style={{color: 'gray'}}>Showing {resPerPage} records per page</small> 


                            {/* Pagination */}
                                {resPerPage <= totalDrs && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={resPerPage} 
                                    totalItemsCount = {totalDrs}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                                )}   
                        </>                      
                        </div>
                    </div>
                </>
             )}
            </section>

            <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>
                    <small style={{color: 'gray'}}>Are you sure you want to activate / block 
                        <span style={{color: '#000'}}> Dr. {doctorToDelete}'s</span> Account ?
                    </small>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-sm btn-success" onClick={activateDoctor}>Activate</button>
                    <button className="btn btn-sm btn-danger" onClick={deActivateDoctor}>Block</button>
                </Modal.Footer>
            </Modal>  
        </>
    )
}

export default DoctorsList;
