import {useState} from 'react';
import { timeSpentOnPatientByDr } from '../../actions/HRActions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {Modal, Form as BootstrapForm} from 'react-bootstrap';
import {useAlert} from 'react-alert';


const AddTimeManuallyByDr = ({totalTime, drId, patientId, patientType}) => {

    const alert = useAlert();
    let minutes;

    // Form Fields
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD')); //YYYY-MM-DD previously it is like this
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD')); //YYYY-MM-DD previously it is like this
    const [startTime, setStartTime] = useState(moment().tz("America/New_York").format("HH:mm"));
    const [endTime, setEndTime] = useState(moment().tz("America/New_York").add(4, 'minutes').format("HH:mm"));
    const [isCCM, setIsCCM] = useState(patientType === 'RPM' ? false : patientType === 'CCM' ? true : false);
    const [interactiveMinutes, setInteractiveMinutes] = useState(false);
    const [note, setNote] = useState('');
    const [fileName, setFileName] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [eType, setEType] = useState('review');


    const handleClose = () => setConfirmationModal(false);
    const handleShow = () => setConfirmationModal(true);    

    var timeStart = moment(startTime, 'HH:mm:ss a');
    var timeEnd = moment(endTime, 'HH:mm:ss a');
    var dif = moment.duration(timeEnd.diff(timeStart));
    minutes = (dif.hours() * 60) + dif.minutes();


    const dispatch = useDispatch();  

    const handleFileChange = e => {
        setFileName(e.target.files[0]);
    }

    const onOptionChange = e => {
        setEType(e.target.value)
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(startDate && endDate && startTime && endTime && note !== "" || undefined){
            handleShow(true);
        }
        // alert.error('Please fill out required fields');
        return
    }


    const submitTimeSpent = () => {
        
        if (fileName.size > 10e6) {
            alert.error("Please upload a file smaller than 10 MB");
            return;
        }
        
        
        if(minutes > 0 && minutes <= 90){
            dispatch(timeSpentOnPatientByDr(patientId, 
                drId,
                startDate, 
                endDate,
                startTime,
                endTime, 
                isCCM, 
                interactiveMinutes,
                minutes,
                note,
                fileName,
                eType
                ));
            }       
        } 

return (
    <>
        <div>   
                <div>    
                    <BootstrapForm>
                            <p style={{textAlign: 'justify-content'}}></p><span style={{color: 'red', fontWeight: 'bold'}}>Note : </span> {patientType === 'RPM' ? 'As patient is enrolled in RPM only, therefore you can only add minutes in RPM category.' : 
                            patientType === 'CCM' ? 'As patient is enrolled in CCM only, therefore you can only add minutes in CCM category.' 
                            : 'As patient is enrolled in RPM & CCM therefore you can add minutes in both categories.' }
                        


                        <div className="row">
                            <div className="col-md-6">
                            <label className="form-label">Start Date</label>
                                <input type="date" 
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    max={moment().format("YYYY-MM-DD")}
                                    required
                                />
                            </div>

                            <div className="col-md-6">
                            <label className="form-label">End Date</label>
                                <input type="date" 
                                    className="form-control"
                                    defaultValue={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    max={moment().format("YYYY-MM-DD")}
                                    required
                                />
                            </div>

                            <div className="col-md-6">
                                <label className="form-label mt-3">Start Time</label>
                                    <input type="time" 
                                        className="form-control"
                                        value={startTime}
                                        onChange={e => setStartTime(e.target.value)} 
                                        required
                                    />
                            </div>

                            <div className="col-md-6">
                                <label className="form-label mt-3">End Time</label>
                                    <input type="time" 
                                        className="form-control" 
                                        value={endTime}
                                        onChange={e => setEndTime(e.target.value)} 
                                        required
                                    />
                            </div>

                            <p className="pl-3 pt-2">
                                Calculated time duration is : <span style={{color: '#000', fontWeight: 'bold'}}>
                                    {minutes} minutes
                                    </span>
                            </p>
                            <br/><br/>
                            
                        
                            <div className="col-md-6">
                                <input  
                                    checked={isCCM}
                                    value={isCCM}
                                    onChange={(e) => setIsCCM(e.target.checked)}
                                    type="checkbox"
                                    disabled={patientType === 'RPM' || patientType === 'CCM' ? true : false}
                                    id="IsCCM"
                                />
                                <label htmlFor="IsCCM"> <b>&nbsp;&nbsp;Is CCM ? </b></label>    
                            </div> 
                            <small className="ml-3">If not checked, minutes would be added in RPM category</small>
                            <br />

                            <div className="col-md-12">
                                <label className="form-label mt-3">Encounter Type</label>
                                <div className="row-display">
                                
                                <div>
                                    <input type="radio" 
                                        id="vm" 
                                        name="encounterType" 
                                        value="vm"
                                        checked={eType === "vm"}
                                        onChange={onOptionChange}
                                    />
                                     <label for="vm" className='profile-label'>Voice mail (VM)</label>
                                  </div>

                                  &nbsp;&nbsp;
                                  <div>
                                   <input 
                                    type="radio" 
                                    id="call" 
                                    name="encounterType" 
                                    value="call"
                                    checked={eType === "call"}
                                    onChange={onOptionChange}
                                    />
                                   <label for="call" className='profile-label'>Call</label>
                                  </div>

                                &nbsp;&nbsp;
                                  <div> 
                                  <input 
                                    type="radio" 
                                    id="review" 
                                    name="encounterType" 
                                    value="review"
                                    checked={eType === "review"}
                                    onChange={onOptionChange}
                                   />
                                   <label for="review" className='profile-label'>Chart Review</label>
                                 </div>
                                </div>    
                            </div>

                        
                            
                            <div className="col-md-12">
                                <label className="form-label mt-3">Conclusion</label>   
                                <textarea 
                                    className="form-control" 
                                    rows="4" 
                                    placeholder="Enter conclusion here ..."
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                    >
                                </textarea>
                            </div>

                           <div className="col-md-12">
                            <br/>     
                            <span>
                                <input 
                                    label="File" 
                                    name="fileName" 
                                    type="file" 
                                    accept=".doc, .docx, .pdf"
                                    onChange={handleFileChange}
                                />
                            </span>
                           </div>

                            </div>

                            <br />

                        <div className="row-class" style={{justifyContent: 'space-between'}}>
                            <button className="edit-pill" onClick={handleSubmit}>Add Minutes</button>
                        </div>
                        </BootstrapForm>
                    </div>
            
                
                {/* Modal to add interactive minutes */}
                <Modal show={confirmationModal} onHide={handleClose}>
                    <Modal.Body>
                    <h5 style={{color: '#00A8A8'}}>
                        <i class='bx bx-bell'></i> <strong>&nbsp;Alert </strong>
                    </h5>

                    <div className="text-center">
                        <b> Are you sure you want to mark this patient as reviewed and adding <span style={{color: '#00A8A8', fontWeight: 'bold'}}>{minutes}</span> minutes ? </b>  
                        
                        <br /><br />
                        <BootstrapForm.Check
                            inline
                            label="Mark as Interactive Minutes "
                            type='checkbox'
                            value={interactiveMinutes}
                            onChange={e => setInteractiveMinutes(e.target.checked)}
                            id='inline-checkbox-1'
                        /> 
                    </div>
                    </Modal.Body>

                    <Modal.Footer>
                    <button className="edit-pill" onClick={submitTimeSpent}>
                        Submit minutes
                    </button>
                    </Modal.Footer>
                </Modal>
            </div>
            </>
        )
    }

    

export default AddTimeManuallyByDr