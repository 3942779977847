import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link } from 'react-router-dom';
import { getDoctors, getOrganizations } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import { getCCMBillingReportByDoctor, getCCMBillingReportByOrganization } from '../../actions/adminActions';
import moment from 'moment';
import Loader from '../../layouts/Loader';
import ExportCCMBillingReportToCSV from '../../components/ExportCCMBillingReportToCSV'
import CCMMinutesSpent from '../../components/HR/CCMMinutesSpent';
import { Modal } from 'react-bootstrap';
import orgIcon from '../../assets/Images/organizations.png';
import doctorIcon from '../../assets/Images/doctorIcon.png';



const CCMBillingReport = () => {

    const dispatch = useDispatch();
    const [month, setMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState("2024");
    const [doctorId, setDoctorId] = useState("");
    const [orgId, setOrgId] = useState("");
    const { error, doctors } = useSelector(state => state.doctor);
    const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);
    const { organizations } = useSelector(state => state.orgs);
    const [patientId, setPatientId] = useState();
    const [reportBy, setReportBy] = useState('org');


    let monthArray = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const customStyles = {
        control: (provided) => ({
          ...provided,
          height: '52px', // Adjust the height here as needed
          width: '250px',
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderWidth:'2px',
          borderColor: '#444',
        }),
    };

    useEffect(() => {
        if(error){
            alert.error(error);
        }
    
        dispatch(getDoctors(50, 1));
        dispatch(getOrganizations());
        
        dispatch({
          type: TIME_SUMMARY_REPORT_RESET
        })
    
    }, [dispatch]);

    const doctorOptions = []
    doctors && doctors.filter(item => item?.block === false).map((doctor) => (
        doctorOptions.push({ value: doctor?._id, label: ['Dr. ' + doctor?.lastname +" ,", doctor?.firstname].join(" ")})
    ))

    const getDoctorProfile = (doctor) => {
        setDoctorId(doctor.value);
    }

    // Organizations list
    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name].join(" ")})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }

    const generateBillingReport = () => {
        if(reportBy === "org"){
            dispatch(getCCMBillingReportByOrganization(orgId, month, endMonth, year, reportBy));
        } else {
            dispatch(getCCMBillingReportByDoctor(doctorId, month, endMonth, year, reportBy));
        }
    }
    
    const resetReport = () => {
        dispatch({
            type: TIME_SUMMARY_REPORT_RESET
            })
    }

    const handleViewMinutes = (patientId) => {
        // if(month && endMonth && year) {  
        //     dispatch(getTimeReportCCM(patientId, month, endMonth, year));         
        // }
        setPatientId(patientId);
        setShow(true);
    }

  return (
    <>
        <MetaData title="CCM Biling Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <br/>
        <div className="shadow-lg p-3 mb-5 mr-2 ml-4 background-white">
            <div className="home-content">

                <div className="row-display">
                    <h5 style={{color: '#00A8A8'}}>
                         <strong>&nbsp;CCM Billing Report </strong>
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;

                      <Link to="/report/billing" className="link pt-2">RPM Billing Report</Link> &nbsp;


                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>
                <hr />

                
                <div className="row cardWrapper">
                <div className={`cardButton ${reportBy === "org" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("org")}>
                        <img src={orgIcon} alt="" height="60" width="60"/>
                        <p> By Organization </p>
                    </div>


                    <div className={`cardButton ${reportBy === "doctor" ? "cardActive" : ""}`}
                    onClick={() => setReportBy("doctor")}>
                        <img src={doctorIcon} alt="" height="60" width="60"/>
                        <p> By Doctor </p>
                    </div>
                </div>


                <div className="row-display container">
               
                <div>
                {reportBy === "org"? <>
                    <label className="form-label">Select Organization  <span style={{color: 'red'}}> *</span>  </label>
                    <Select
                        options={orgOptions}
                        onChange={getOrgProfile}
                        styles={customStyles}
                    />
                </> : <>
                <label className="form-label">Select Doctor  <span style={{color: 'red'}}> *</span>  </label>
                    <Select
                        options={doctorOptions}
                        onChange={getDoctorProfile}
                        styles={customStyles}
                    />
                </>}    
                </div>

                <div>
                <label htmlFor="from" className="form-label">Start Month <span style={{color: 'red'}}> *</span> </label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            style={{width: '200px'}}
                        >
                      <option value="01">January - 26</option>
                      <option value="02">February - 26</option>
                      <option value="03">March - 26</option>
                      <option value="04">April - 26</option>
                      <option value="05">May - 26</option>
                      <option value="06">June - 26</option>
                      <option value="07">July - 26</option>
                      <option value="08">August - 26</option>
                      <option value="09">September - 26</option>
                      <option value="10">October - 26</option>
                      <option value="11">November - 26</option>
                      <option value="12">December - 26</option>
                        </select>
                    </div>

                    <div>
                    <label htmlFor="end" className="form-label">End Month <span style={{color: 'red'}}> *</span></label>
                        <select  
                            name="end" 
                            className="form-control"
                            value={endMonth}
                            onChange={(e) => setEndMonth(e.target.value)}
                            style={{width: '200px'}}
                        >
                      <option value="01">January - 25</option>
                      <option value="02">February - 25</option>
                      <option value="03">March - 25</option>
                      <option value="04">April - 25</option>
                      <option value="05">May - 25</option>
                      <option value="06">June - 25</option>
                      <option value="07">July - 25</option>
                      <option value="08">August - 25</option>
                      <option value="09">September - 25</option>
                      <option value="10">October - 25</option>
                      <option value="11">November - 25</option>
                      <option value="12">December - 25</option>
                    </select>
                    </div>

                    <div>
                        <label htmlFor="year" className="form-label">Select Year <span style={{color: 'red'}}> *</span></label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            style={{width: '200px'}}
                        >
                            <option value="SelectYear">Select Year</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label>&nbsp;</label>
                        <button className="edit-pill-2" onClick={generateBillingReport}>Generate Report</button>
                    </div>
                
                </div>
                <hr />

                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
 
                <div className="row-display">
                    <h5 className="title">Billing Report Preview</h5>
                    
                    <div className="row-display">
                        <button className="edit-pill" onClick={resetReport}>
                            <i className='bx bxs-file-pdf'></i> Reset
                        </button> &nbsp;

                        <div>
                            <ExportCCMBillingReportToCSV 
                              csvData={timeSummaryReport} 
                              fileName={'CCM Billing Report '+ moment().tz("America/New_York").format('MM/DD/YYYY')+'.csv'} 
                              month={month}
                              year={year}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                          <br />
                          <table className="styled-table">
                            <thead align="center">
                                <tr>
                                  <th width="40px">#</th>
                                  <th>Patient Name & EHR-ID</th>
                                  <th>D.O.B</th>
                                  <th width="80px">Total Minutes</th>
                                  <th>Call Minutes</th>
                                  <th>VM Minutes</th>
                                  <th>Review Minutes</th>
                                  <th width="80px">CPT</th>
                                  <th>Last Activity</th>
                                  <th>Month</th>
                                  <th>Provider</th>
                                  <th width="80px">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {timeSummaryReport && timeSummaryReport.sort((a, b) => (a.patientName > b.patientName) ? 1 : -1).filter(a => a?.totalMinutes > 0).map((summaryReport, index) => (
                                    <tr key={index} className={summaryReport?.initialSetup && summaryReport?.initialSetup === monthArray[month - 1] + "/" + year ? 'coloredRow' : null}> 
                                      <td><b>{index + 1}</b></td>

                                      <td><Link className="link"  
                                        to={{ pathname: "/patientProfile", state: {patientid: summaryReport?.patientId 
                                        }}}>
                                          {summaryReport?.patientName}
                                          </Link>
                                          <p><b>{summaryReport?.emrId || null}</b></p>
                                          </td>

                                      <td><b>{moment(summaryReport?.DOB).format('MM/DD/YYYY')} </b></td>                                     
                                      
                                      {/* Total Minutes */}
                                      {<td><b>{summaryReport?.totalMinutes}</b></td>}

                                      {/* Call Minutes */}
                                      {<td><b>{summaryReport?.callMins || 0} mins</b></td>}

                                      {/* VMs Minutes */}
                                      {<td><b>{summaryReport?.vmMins || 0} mins</b></td>}

                                      {/* Review Minutes */}
                                      {<td><b>{summaryReport?.reviewMins || 0} mins</b></td>}                            

                                      {/* CPT Codes */}
                                     <td style={{color: 'green', fontWeight: 'bold'}}>   
                                        {summaryReport?.totalMinutes >=20 && summaryReport?.totalMinutes < 40 ? '99490' : 
                                            summaryReport?.totalMinutes >= 40 && summaryReport?.totalMinutes < 60 ? '99490, 99439' : 
                                            summaryReport?.totalMinutes >= 60 && summaryReport?.totalMinutes < 90 ? '99487' : 
                                            summaryReport?.totalMinutes >= 90 && summaryReport?.totalMinutes < 120 ? '99487, 99489' : 
                                            summaryReport?.totalMinutes >= 120 && summaryReport?.totalMinutes < 150 ? '99487, 99489, 99489' : 
                                            summaryReport?.totalMinutes >= 150 ? '99487, 99489, 99489, 99489' :
                                            null  
                                        }
                                      </td>

                                      <td><b>
                                      {summaryReport?.lastActivity ? moment(summaryReport?.lastActivity).format("MM/DD/YYYY") : '--'}</b></td> 

                                      <td><b>{monthArray[month - 1]}</b></td>
                                      
                                      <td><b>{'Dr. ' + summaryReport?.doctorName}</b></td>
                                      <td>
                                        <button className="edit-pill" 
                                        onClick={() => handleViewMinutes(summaryReport?.patientId)}>View</button>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div> 
                        </> : null }
                        </> }
                </div>
            </div>


             {/* Modal to download report */}
             <Modal 
                show={show} 
                onHide={handleClose}
                size='xl'
                animation={true}
                backdrop="static"
                >

                    <Modal.Header>
                        <div className="row-display">
                            <div className="row-display-column">
                                <h5 className='title p-0 m-0'>CCM Minutes Spent</h5>
                                <small>Time duration between <b>{month +'- 26 -'+ year }</b> to <b>{endMonth +'- 25 -'+ year }</b></small>
                            </div>
                        </div>

                        <button className="edit-pill" style={{backgroundColor: '#e1e1e1', color: '#000'}} 
                            onClick={handleClose}>
                            <i className="bx bx-x"></i>
                        </button>
                    </Modal.Header>

                    <Modal.Body style={{zoom: '90%'}}>
                        <CCMMinutesSpent
                            patientId={patientId}
                            isAdmin={true}
                        />
                    </Modal.Body>
                    
            </Modal>
            </section>
            </>
  )
}

export default CCMBillingReport