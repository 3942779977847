import {useState, useEffect} from 'react';
import Sidebar from '../../components/Assistant/Sidebar';
import TopBar from '../../components/Assistant/Topbar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import {Form, InputGroup } from 'react-bootstrap';

import { 
    getCCMPatients,  
    searchCCMPatient
} from '../../actions/assistantActions';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'react-js-pagination';


const AssistantCCMPatients = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    
    const alert = useAlert();
    const { loading, error, patients} = useSelector(state => state.asstPatients);
    const { ccmPts } = useSelector(state => state.adminStat)
    const [keyword, setKeyword] = useState('');
    const [searchBy ,setSearchBy] = useState('firstname');
    const [isSearch, setIsSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(200);

    let drIds = localStorage.getItem('myArray');

    useEffect(() =>{
        if(error){
            alert.error(error);
        }

        dispatch(getCCMPatients(resPerPage, currentPage, drIds));

    }, [dispatch, error, currentPage]);


        function setCurrentPageNumber(pageNumber) {
            setCurrentPage(pageNumber);
        }

        const searchhandler = () => {
            dispatch(searchCCMPatient(searchBy, keyword, drIds));
        }  

        const refreshhandler =() => {
            dispatch(getCCMPatients(resPerPage, currentPage, drIds))
        }

    return (
        <>
                <MetaData title="CCM Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br/>

                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        <div className="row-display">
                            <div className="col-md-11">
                                <h5 className='title'>
                                    <i className="bx bx-user"></i> 
                                    <strong>&nbsp;CCM Patients </strong>
                                </h5>
                            </div>

                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i>
                            </button> &nbsp;
                                                      
                        </div>
                        <hr />

                                <div className="col-md-5">
                                    <div className="row-display">
                                    
                                        <select 
                                            className="form-control select-input-type" 
                                            value={searchBy}
                                            onChange={e => setSearchBy(e.target.value)}
                                            >
                                                <option value="undefined">Filter Patient </option>
                                                <option value="emrId">EHR-ID </option>
                                                <option value="firstname">Firstname</option>
                                                <option value="lastname">Lastname</option>
                                                <option value="email">email</option>
                                                <option value="phone1">phone1</option>
                                                <option value="initialsetup">Initial Month</option>
                                                <option value="diseases">Diseases</option>
                                                <option value="insurancecompany">Ins. Company</option>
                                                <option value="address">Address</option>
                                                <option value="state">State</option>
                                                <option value="city">City</option>
                                                <option value="zipCode">Zip Code</option>
                                        </select>    
                                            &nbsp;
                                            <InputGroup>
                                                    <Form.Control
                                                        placeholder="Search here ..."
                                                        aria-label="Search here ..."
                                                        className="form-control"
                                                        value={keyword}
                                                        onChange={e => setKeyword(e.target.value) }
                                                    />
                                                    &nbsp;<button className="edit-pill m-2" onClick={searchhandler}>
                                                        <i className='bx bx-search-alt'></i>
                                                    </button>
                                                    &nbsp;<button className="edit-pill m-2" onClick={refreshhandler}>
                                                        <i className='bx bx-refresh'></i>
                                                    </button>
                                                    </InputGroup>
                                                    </div>
                                                </div>
                                        </div>  


                    {/* Patient List Card */}
                        <div className="col-md-12">
                        {patients && <>
                            <table className="styled-table">
                            <thead align="center">
                                <tr>    
                                <th>Patient Name & EHR-ID</th>
                                <th>DOB </th>
                                <th>Acc Status</th>
                                <th>Doctor</th>
                                <th>Consent Role</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {patients && patients.length > 0 ? <> 
                                {patients && patients.filter(item => item?.block === false).map((patient, index) => (
                                <tr key={index}>
                                <td className="text-center">
                                    <Link className="link" to={{ pathname: "/clinical_staff/patient", state: {patientid: patient?._id}}}>
                                        {patient?.lastname +', '+ patient?.firstname}
                                    </Link>
                                    <p>{patient?.emrId || null}</p>
                                </td>
                                
                                <td className="text-center"> {moment(patient?.DOB).format("MM/DD/YYYY")} 
                                        <p>
                                        <Badge pill bg="info text-white" style={{fontWeight: 500, 
                                            textTransform: 'uppercase', 
                                            padding: '3px',
                                            letterSpacing: '1px'
                                        }} key={index}>{patient?.gender}</Badge>
                                        </p>
                                    
                                </td> 
                                
                                <td className="text-center">
                                   <b>{patient?.block === false ? <span style={{color: 'green'}}>
                                    <i className='bx bxs-circle'></i> Active</span> : <span style={{color: 'red'}}>Deactivated</span>}</b>
                                </td> 
                                    
                                {patient?.assigned_doctor_id ? <>
                                <td className="text-center"><b>Dr. {patient?.assigned_doctor_id?.lastname}, {patient?.assigned_doctor_id?.firstname}</b></td>
                                </> : <>
                                <td>N/A</td>
                                </>}

                                <td className="text-center">{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</td>
                                
                            </tr>    
                            ))}
                            </> : <div>
                                <small className="not-found-text">Patients Not Found</small>
                            </div> }
                            
                            </tbody>
                            
                            </table>
                            <small style={{color: 'gray'}}>Showing {resPerPage} records per page</small> 

                            {/* Pagination */}
                                {!isSearch && resPerPage <= ccmPts && (
                                    <div className="d-flex justify-content-center mt-5"> 
                                    <Pagination activePage={currentPage} 
                                    itemsCountPerPage={resPerPage} 
                                    totalItemsCount = {ccmPts}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                                )}    
                        </>}                      
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default AssistantCCMPatients
