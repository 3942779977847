import React, {Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AssistantProtectedRoute = ({isAssistant, component: Component, ...rest}) => {
    
	const { isAuthenticated, user } = useSelector(state => state.auth);
    return (
        <Fragment> 
            {user?.role === 'assistant' && isAssistant === true ? (
                <Route {...rest} render={props => {
                    
                    if(isAuthenticated === false && isAssistant !== true){
                        <Redirect to="/" />
                    }
                    
                    else if(isAssistant === true && isAuthenticated === true){
                        <Redirect to="/clinical_staff/dashboard" />
                    }
                    
                    return <Component {...props} />
                }}
                />
            ) : <Route to="/auth/signin/clinical_staff" />
}
        </Fragment>
    )
}

export default AssistantProtectedRoute;