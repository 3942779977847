import {useEffect, useState, useRef} from 'react';
import MetaData from '../../layouts/MetaData';
import HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import {Link, useHistory} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDoctorPatients } from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import moment from 'moment';
import Select from 'react-select';
import { getPatientTelemetaryReport } from '../../actions/adminActions';
import MyDocument from '../../components/MyDocument';
import { useReactToPrint } from 'react-to-print';
import { GET_DOCTOR_TELEMETARY_REPORT_RESET } from '../../constants/adminConstants';
import PatientProfileGraph from '../../components/PatientProfileGraph';
import Loader from '../../layouts/Loader';


const TelemetryReportNurse = () => {

    const { orgsAssigned, assignedDoctors } = useSelector(state => state.hrStats);
    const [orgId, setOrgId] = useState(null);
    const [drId, setDrId] = useState(null);

    const componentRef = useRef();
    let color;
    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle:`Telemetary_Report ` + moment().tz("America/New_York").format("MM/DD/YYYY"),
        content: () => componentRef.current,
    });

  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  
  const [patientId, setPatientId] = useState("");
  const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().clone().endOf('month').format('YYYY-MM-DD'));
  const {loading, telemetaryReport} = useSelector(state => state.telemetaryReport);
  const { error, doctorpatients } = useSelector(state => state.docPatients);

  
  const [filterDataByDevice, setFilterDataByDevice] = useState('all');
  

  useEffect(() => {
    if(error){
        alert.error(error);
    }

    // dispatch(getHRPatients(nurseDrs, pagination, currentPage));
    if(orgId && drId){
        dispatch(getDoctorPatients(drId));
    }

    
    }, [dispatch, filterDataByDevice, drId, orgId]);


    const options = []

    doctorpatients && doctorpatients.filter(item => item?.block === false).map((patient) => (
        options.push({ value: patient?._id, 
            label: [patient?.lastname +" , "+ patient?.firstname +"  "+ moment(patient?.DOB).format("MM-DD-YYYY")].join(" ")})
    ))

    const getPatientProfile = (patient) => {    
        setPatientId(patient.value);
    }

    const resetReport = () => {
        dispatch({
            type: GET_DOCTOR_TELEMETARY_REPORT_RESET
        })
    } 

    const generateReportByPatient = () => {
        if(!patientId) {
            alert.error('Please select patient');
            return;
        } else {
            dispatch(getPatientTelemetaryReport(patientId, startDate, endDate));
        }
    }

    let filteredReadings = calcTotalReadings();

    function calcTotalReadings() {
       return telemetaryReport && telemetaryReport.filter(healthData => healthData?.deviceId?.deviceType === "bp").reduce((sum, a) =>  
        sum + 1, 0
      )
    }

    let sysBPCategory; //variable to store category of BP

    function refreshHandler() {
        setOrgId("");
        setDrId("");
        setPatientId("");
        dispatch({
            type: GET_DOCTOR_TELEMETARY_REPORT_RESET
        })
    } 

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }

    let diaBpCategory;

    function calcDiaBpCategory(dia) {
        switch(true){
            case (dia > 120):
                diaBpCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;

            case (dia >= 110 && dia <= 120):
                diaBpCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;

            case (dia >= 100 && dia <= 109):
                diaBpCategory = "Hypertension-Stage 2"
                color = '#FE504F'
                break;

            case (dia >= 90 && dia <= 99):
                diaBpCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
                break;
                
            case (dia >= 130 && dia <= 139):
                diaBpCategory = "Pre-hypertension"
                color = '#FFFF00'
                break;

            case (dia >= 85 && dia <= 89):
                diaBpCategory = "High Normal B.P"
                color = '#CBFE33'
                break;

            case (dia >= 81 && dia <= 84):
                diaBpCategory = "High Normal Blood Pressure"
                color = '#00FF99'
                break;

            case (dia >= 65 && dia <= 80):
                diaBpCategory = "Normal Blood Pressure"
                color = '#00FF99'
                break;
            
            case (dia >= 60 && dia <= 64):
                diaBpCategory = "Low Normal B.P"
                color = '#CDFFCC'
                break;

            case (dia >= 40 && dia <= 59):
                diaBpCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (dia >= 35 && dia <= 39):
                diaBpCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (dia < 35):
                diaBpCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                diaBpCategory = "Invalid B.P"
                color = '#000'
            } 
        }

        const respondedResults = telemetaryReport && telemetaryReport.filter(item => item?.notes?.length > 0)
        const countRespondedResult = respondedResults?.length;
    
        const notRespondedResults = telemetaryReport && telemetaryReport.filter(a => !a?.notes?.length);
        const countNotRespondedResult = notRespondedResults?.length;

        const uniqueDevices = telemetaryReport && [...new Set(telemetaryReport.map(item => item.deviceId?.deviceType))];

        const customStyles = {
            control: (provided) => ({
              ...provided,
              height: '52px', // Adjust the height here as needed
              width: '200px',
              backgroundColor: 'transparent',
              borderStyle: 'solid',
              borderWidth:'2px',
              borderColor: '#444',
            }),
          };

  return (
    <>
        <MetaData title="Telemetry Report" />
                <HRSidebar />
                
                <section className="home-section">
                    {/* TopBar */}  
                    <HRTopBar />
                    <br/>
                    
                    <div className="shadow-lg m-2 background-white rounded">      
                            <div className="home-content">
                                <div className="row-display p-2 mr-2 ml-2">        
                                    <h5 className='title'>
                                        <i className='bx bx-filter'></i> Telemetry Data Report 
                                    </h5>

                                        <div className="row-display">
                                            <button className="btn btn-secondary btn-sm" 
                                                onClick={() => history.goBack()}>
                                                <i className='bx bx-arrow-back'></i>
                                            </button>
                                            &nbsp;&nbsp;
                                            <button className="btn btn-danger btn-sm" 
                                                onClick={refreshHandler}>
                                                <i className='bx bx-refresh'></i>
                                            </button>

                                            &nbsp;&nbsp;
                                            <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm">
                                                <i className='bx bxs-home'></i>
                                            </Link>
                                        </div>  
                                    </div>


                                    <div className="row-display mt-3 reportFormFields">    
                                        <div>
                                        <label className='form-label'>Select Organization</label>
                                            <select 
                                                className="form-control" 
                                                value={orgId} 
                                                onChange={e => setOrgId(e.target.value)}>
                                            <option value="null">Select Organization</option>
                                            {orgsAssigned && orgsAssigned?.length && orgsAssigned.map((org, index) => (
                                                <option key={org?._id} value={org?._id}>{org?.name}</option>
                                            ))}
                                            </select>
                                        </div>

                                        <div>
                                        <label className='form-label'>Select Doctor</label>
                                            <select className="form-control" 
                                                value={drId} 
                                                onChange={e => setDrId(e.target.value)}
                                                disabled={orgId ? false : true}
                                                >
                                                <option value="null">Select Doctor</option>
                                                {assignedDoctors && assignedDoctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                                    <option key={doctor?._id} value={doctor?._id}>
                                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className='form-label'>Select Patient</label>
                                            <Select
                                                options={options}
                                                onChange={getPatientProfile}
                                                styles={customStyles}
                                                placeholder="Select Patient"
                                                isDisabled={drId && orgId ? false : true}
                                            />
                                        </div>

                                        <div>
                                        <label className='form-label'>Start Date</label>
                                            <input 
                                                type="date" 
                                                value={startDate} onChange={e => setStartDate(e.target.value)}
                                                max={moment().format("YYYY-MM-DD")}
                                                className="form-control" 
                                                placeholder="From"
                                                style={{width: '200px'}}
                                                />
                                        </div>

                                        <div>
                                            <label className='form-label'>End Date</label>
                                            <input 
                                                type="date" 
                                                value={endDate} onChange={e => setEndDate(e.target.value)}
                                                max={moment().format("YYYY-MM-DD")} 
                                                className="form-control" 
                                                placeholder="To"
                                                style={{width: '200px'}}
                                                /> 
                                        </div>

                                        <div className="mt-4">
                                            <button 
                                             className="edit-pill-2"
                                             onClick={generateReportByPatient}
                                             disabled={orgId && drId && patientId ? false : true}
                                             >Submit</button>
                                        </div>
                                    </div>
                            

                            <br/><br/>
                            {telemetaryReport && telemetaryReport?.length > 0 ? <>
                                
                                <div className="row-display p-2">
                                    <div>
                                        <b> Generated Report Result: </b>  
                                    </div>

                                    
                                <div className='row-display'>    
                                    <button className="edit-pill" onClick={resetReport}><i className='bx bxs-file-pdf'></i> Reset</button> &nbsp;    

                                    &nbsp;&nbsp;
                                    <div>    
                                        <div style={{ display: "none" }}> 
                                        <MyDocument 
                                            filterDataByDevice={filterDataByDevice}
                                            healthData={telemetaryReport} 
                                            startDate={startDate} 
                                            endDate={endDate} 
                                            ref={componentRef}/>
                                        </div>

                                        <button onClick={handlePrint} className="edit-pill"><i className='bx bxs-file-pdf ' style={{color:'#F40F02'}}></i> Download PDF </button>
                                    </div>
                                </div>
                            </div>
                            </> : null }
                            <br />

                {loading ? <Loader /> : <>
                   {telemetaryReport && telemetaryReport?.length > 0 ? <>

                    <div className="telemetary-card background-white p-4">
                    {telemetaryReport.every(element => element.deviceId?.deviceType === "bp") ? <>
                        <PatientProfileGraph healthData={telemetaryReport} />
                        <br/>
                    </> : null }
                        
                        <div className="row-display">
                            <div>
                                <span>EHR-ID : {telemetaryReport[0]?.assigned_patient_id?.emrId}</span><br />
                                <span> Patient Name : </span> 
                                <Link className="link" 
                                    to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: telemetaryReport[0]?.assigned_patient_id?._id}}}>
                                    {telemetaryReport[0]?.assigned_patient_id?.lastname}, {telemetaryReport[0]?.assigned_patient_id?.firstname}
                                </Link>   
                                <br/><span> Gender : </span> <b>{telemetaryReport[0]?.assigned_patient_id?.gender === 'male' ? 'Male' : 'Female'}</b>      
                                <br/><span> D.O.B : </span> <b>{moment(telemetaryReport[0]?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</b>                         
                                <br/><span> Provider Name : </span> <b>{telemetaryReport[0]?.assigned_doctor_id?.lastname +' , '+ telemetaryReport[0]?.assigned_doctor_id?.firstname}</b>                         
                                <br/><span> Devices Assigned </span>
                                <div className="row pl-3">
                                {uniqueDevices.map((value, index) => (
                                    <div key={index}>
                                        <b>{value === 'bp' ? <span className='bpDeviceType'>B.P</span> : 
                                            value === 'weight' ? <span className='weightDeviceType'>Weight</span> : 
                                            value === 'sugar' ? <span className='glucoseDeviceType'>Glucose</span> :
                                            value === 'spO2' ? <span className='spo2DeviceType'>Oximeter</span> :
                                            null
                                        }</b>
                                        &nbsp;&nbsp;
                                    </div>
                                ))}
                            </div>
                            </div>

                            <div>   
                                <span> Period :  <b>{moment(startDate).format("MM/DD/YYYY")} to {moment(endDate).format("MM/DD/YYYY")} </b></span> 
                                <br/><span> Total Readings Received :  <b>{telemetaryReport?.length} </b></span>
                                <br/> <br/>
                                {uniqueDevices?.length > 1 ? <>
                                    <label className='form-label'>Filter By Device Type</label>
                                    <select className='form-control' value={filterDataByDevice} onChange={e => setFilterDataByDevice(e.target.value)}>
                                    <option value="all">All</option>
                                    {uniqueDevices.map((value, index) => (
                                        <option value={value} key={index} style={{textTransform: 'uppercase'}}>{value}</option>
                                    ))}
                                    </select>
                                </> : null }
                            </div>
                        </div>

                        {telemetaryReport.every(element => element.deviceId?.deviceType === "bp") ? <>

                        <br/>
                        <h5 className="title">Summary</h5>
                        <hr className="blue-hr" />

                        <div className="row-display">
                        <div>
                            <span>Highest Systolic Recorded:</span><br/>
                            <b><span style={{color: 'red'}}> {Math.max(...telemetaryReport.map(o => o?.telemetaryData?.sys))}</span> mmHG</b>
                            <br/><br/>

                            <span>Lowest Systolic Recorded:</span><br/>
                            <b><span style={{color: 'red'}}>{Math.min(...telemetaryReport.map(o => o?.telemetaryData?.sys))}</span>mmHG</b>
                            <br/>
                        </div>

                        <div>
                            <span>Highest Diastolic Recorded:</span><br/>
                            <b><span style={{color: 'red'}}>{Math.max(...telemetaryReport.map(o => o?.telemetaryData?.dia))} </span> mmHG</b>
                            <br/><br/>

                            <span>Lowest Diastolic Recorded:</span><br/>
                            <b><span style={{color: 'red'}}>{Math.min(...telemetaryReport.map(o => o?.telemetaryData?.dia))}</span> mmHG</b>
                            <br/>
                        </div>

                        <div>
                            <span>Average Systolic:</span><br/>
                            <h3 className="display-4">{telemetaryReport && telemetaryReport.filter(data => data?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                (Number(total) + Number(devicedata?.telemetaryData?.sys) / filteredReadings).toFixed(), 0
                                )} </h3>
                        </div>

                        <div>
                            <span>Average Diastolic:</span><br/>
                                <h3 className="display-4">{telemetaryReport && telemetaryReport.filter(data => data?.deviceId?.deviceType === "bp").reduce((total, devicedata) =>  
                                    (Number(total) + Number(devicedata?.telemetaryData?.dia) / filteredReadings).toFixed(), 0
                                    )} 
                                </h3>
                            <br/>
                        </div>
                    </div>

                    </> : null }
                        

                    <br/>
                    <div className="row-display">
                        <h5 className="title">Readings</h5>
                        <div>
                            <b>Responded :</b> {countRespondedResult} &nbsp;&nbsp; <span className="vl"></span> &nbsp;&nbsp; <b>Not Responded </b>{countNotRespondedResult}
                        </div>
                    </div>
                    <hr className="blue-hr" />

                    {telemetaryReport.filter(item => filterDataByDevice !== 'all' ? item?.deviceId?.deviceType === filterDataByDevice : item).map((healthData, index) => ( <>
                        <div key={index}>
                        <div className="card p-3 mt-4 shadow-lg background-white">
                        <div className={`ribbon ribbon-top-left`}>    
                            <p>{
                                healthData?.deviceId?.deviceType && 
                                healthData?.deviceId?.deviceType === 'bp' ? <span style={{backgroundColor: '#00A8A8'}}>B.P</span> :
                                healthData?.deviceId?.deviceType === 'sugar' ? <span style={{backgroundColor: '#5C1F4B'}}>Glucose</span> :
                                healthData?.deviceId?.deviceType === 'spO2' ? <span style={{backgroundColor: '#FF5C39'}}>Oximeter</span> :
                                healthData?.deviceId?.deviceType === 'weight' ? <span style={{backgroundColor: '#FFC508'}}>Weight</span> :
                                null }    
                            </p>
                        </div>
                        <br />

                        { healthData?.deviceId?.deviceType === 'bp' ? <>

<div className="row-display pl-4">

   {calcSysBpCategory(healthData?.telemetaryData?.sys)} 

   <div>
   <span>Systolic : {healthData?.telemetaryData?.sys >= 139 || healthData?.telemetaryData?.sys <= 110 ? 
           <span style={{color: 'red', fontWeight: 'bold', fontSize: '16px'}}>{healthData?.telemetaryData?.sys}</span> : 
           <span>{healthData?.telemetaryData?.sys}</span>} mmHG</span>
       <br/>
       <small><b>{sysBPCategory}</b> &nbsp;&nbsp;&nbsp;<i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i></small>  
   </div>

   {calcDiaBpCategory(healthData?.telemetaryData?.dia)}

   <div>
   <span>Diastolic :    {healthData?.telemetaryData?.dia >= 90 || healthData?.telemetaryData?.dia <= 70 ? 
           <span style={{color: 'red', fontWeight: 'bold'}}>{healthData?.telemetaryData?.dia}</span> : 
           <span>{healthData?.telemetaryData?.dia}</span>} mmHG</span>
       <br/>
       <small><b>{diaBpCategory}</b> &nbsp;&nbsp;&nbsp;<i className='bx bxs-circle' style={{color: color, fontSize: '22px'}}></i></small>
   </div>

   <div>
       <span>Pulse : {healthData?.telemetaryData?.pul} BPM</span>
   </div>

   <div>
       <span>Device ID: {healthData?.deviceId?._id}</span><br/>
       <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
       <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span><br/>
       
       {/* {calcDuration(moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}  */}
       {/* <span>Duration: {hours} </span> */}
       
   </div>

   </div> {/* First Row Ends here */}

</> : healthData?.deviceId?.deviceType === 'weight' ? <>
<div className="row-display pl-4"> 
   <div>
           <span>Weight :  
               <h2>
                   {healthData?.telemetaryData?.wt} <small style={{fontSize: '18px'}}> In grams</small> 
               </h2>
           </span>  
       <br/>
   </div>

   <div>
       <span>Weight :   
           <h2>{(healthData?.telemetaryData?.wt / 1000).toFixed(2)} <small style={{fontSize: '18px'}}> In kgs </small></h2>
       </span>
   </div>

   <div>
   <span>Weight :   
           <h2>{(healthData?.telemetaryData?.wt / 453.6).toFixed(2)} <small style={{fontSize: '18px'}}> In pounds </small></h2>
       </span>
   </div>

   <div>
       <span>Device ID: {healthData?.deviceId?._id}</span><br/>
       <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
       <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY')}</span><br/>
   </div>

   </div> {/* First Row Ends here */}

</> : healthData?.deviceId?.deviceType === 'sugar' ? <>
  <div className='row-display pl-4'>
       <div>
               <span>Glucose :  
                   <h2>
                   {healthData?.telemetaryData?.data} <small style={{fontSize: '18px'}}> {healthData?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}</small> 
                   </h2>
               </span>  
           <br/>
       </div>

       <div>
       <span>Reading Taken :   
           <h2> {healthData?.telemetaryData?.meal === 0 ? 'Not Selected' : healthData?.telemetaryData?.meal === 1 
               ? 'Before Meal' : healthData?.telemetaryData?.meal === 2 ? 
               'After Meal' : null
           }</h2>
       </span>
       </div>

       <div>
           <span>Device ID: {healthData?.deviceId?._id}</span><br/>
           <span>Readings Sent Date: {moment(healthData?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
           <span>Received Date: {moment(healthData?.createdAt).tz("America/New_York").format('MM/DD/YYYY')}</span><br/>
       </div>                
   </div>


</> : healthData?.deviceId?.deviceType === 'spO2' ? <>
<div className="row-display pl-4"> 
   <div>
           <span>Pulse BPM :  
               <h2>
               {healthData?.telemetaryData?.notification_payload?.pulse_bpm} <small style={{fontSize: '18px'}}> Pulse BPM</small> 
               </h2>
           </span>  
       <br/>
   </div>

   <div>
       <span>Spo2 PCT :   
           <h2>{healthData?.telemetaryData?.notification_payload?.spo2_pct} <small style={{fontSize: '18px'}}> Spo2 PCT  </small></h2>
       </span>
   </div>

   <div>
   <span>Perfusion Index PCT :   
           <h2>{healthData?.telemetaryData?.notification_payload?.perfusion_index_pct} <small style={{fontSize: '18px'}}> PI_PCT </small></h2>
       </span>
   </div>

   <div>
       <span>Device ID: {healthData?.deviceId?._id}</span><br/>
       <span>Reading Recorded: {moment(healthData?.telemetaryData?.notification_payload?.ts_recorded * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</span><br/>
       <span>Received Date: {moment(healthData?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span><br/>
   </div>

   </div> {/* First Row Ends here */}
</> : null}


                        </div>
                        </div>
                    </>))}
                    </div>
                   </> : null} 
                </>}
                </div>
            </div>
    </section>
    </>
  )
}

export default TelemetryReportNurse