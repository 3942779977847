import { useEffect, useState } from 'react';
import { staffLogout } from '../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import adminImg from '../../assets/Images/defaultUser.png';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {

    const { isAuthenticated, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [active, setActive] = useState('Dashboard');


    useEffect(() => {
		if(isAuthenticated === false) {
			props?.history?.push("/auth/signin/doctor");
		}
	}, [isAuthenticated])


    const logoutHandler = () => {
        dispatch(staffLogout());
    }


    return (
        <>
            <div className="sidebar">
            <br />

            <div>
                <div className="row" style={{paddingLeft: '50px', color: 'white'}}>
                    <img src={adminImg} width="50" height="50" className="roundedBorderImg" alt="img" />
                        &nbsp;&nbsp;<p> {user && user.firstname +" "+ user?.lastname } {user?.verified === true ? 
                        <i className='bx bxs-badge-check' style={{color: 'white'}}></i> : null}
                        &nbsp;&nbsp;
                        <Link to="/staffProfile" className="link"><small>My profile</small></Link>
                        <p className="text-white">Doctor's Dashboard</p>
                    </p>


                </div>
            </div>

            <ul>
            <li className={`${active === 'Dashboard' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="Dashboard">
                <Link to="/doctor/dashboard" onClick={() => setActive('Dashboard')} className={`${active === 'Dashboard' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>Dashboard</span>
                </Link>
            </li>

            <li className={`${active === 'RPMPts' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="RPMPts">
                <Link to="/doctor/patients" onClick={() => setActive('RPMPts')} className={`${active === 'RPMPts' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>RPM Patients</span>
                </Link>
            </li>

            <li className={`${active === 'CCMPts' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="CCMPts">
                <Link to="/CCM/doctor/patients" onClick={() => setActive('CCMPts')} className={`${active === 'CCMPts' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>CCM Patients</span>
                </Link>
            </li>

            <li className={`${active === 'finReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="finReport">
                <Link to="/doctor/report/financialReport" onClick={() => setActive('finReport')} className={`${active === 'finReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>Financial Report</span>
                </Link>
            </li>

            <li className={`${active === 'telemetryReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="telemetryReport">
                <Link to="/doctor/report/telemetry" onClick={() => setActive('telemetryReport')} className={`${active === 'telemetryReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>Telemetry Report</span>
                </Link>
            </li>

            <li className={`${active === 'timeReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="timeReport">
                <Link to="/doctor/report/timereport" onClick={() => setActive('timeReport')} className={`${active === 'timeReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <span>Time Report of RPM & CCM</span>
                </Link>
            </li>

            </ul>
        </div>    
        </>
    )
}

export default Sidebar;
