import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';


const ExportSummaryReportToCSV = ({csvData, fileName}) => {
  let CptCode = '';
  let CptCharges = '';
  let totalCharges = '';

  function calcCPT(readings, minutes) {
    switch(true){
      case (readings < 16 && minutes < 20):
        CptCode = ''
        CptCharges = ''
        totalCharges = ''
        break; 
      case (readings < 16 && minutes >=20 && minutes < 40):
          CptCode = "99457"
          CptCharges = '$42.26'
          totalCharges = '$42.26'
      break;
      case (readings < 16 && minutes >=40 && minutes < 60):
          CptCode = "99457, 99458"
          CptCharges = '$42.26, $31.94'
          totalCharges = '$74.20'
      break;
      case (readings < 16 && minutes >=60 && minutes < 80):
          CptCode = "99457, 99458, 99458"
          CptCharges = '$42.26, $31.94, $31.94'
          totalCharges = '$106.14'
      break;
      case (readings < 16 && minutes >=80 && minutes < 100):
          CptCode = "99457, 99458, 99458, 99458"
          CptCharges = '$42.26, $31.94, $31.94, $31.94'
          totalCharges = '$138.08'
      break;
      case (readings < 16 && minutes >=100 && minutes < 120):
          CptCode = "99457, 99458, 99458, 99458, 99458"
          CptCharges = '$42.26, $31.94, $31.94, $31.94, $31.94'
          totalCharges = '$170.02'
      break;
      case (readings < 16 && minutes >=120):
          CptCode = "99457, 99458, 99458, 99458, 99458, 99458"
          CptCharges = '$42.26, $31.94, $31.94, $31.94, $31.94, $31.94'
          totalCharges = '$201.96'
      break;

      case (readings >= 16 && minutes < 20):
          CptCode = '99454'
          CptCharges = '$50.83'
          totalCharges = '$50.83'
          break;
      
      case (readings >= 16 && minutes >= 20 && minutes < 40):
            CptCode = '99454, 99457'
            CptCharges = '$50.83, $42.26'
            totalCharges = '$93.09'
            break; 
      case (readings >= 16 && minutes >= 40 && minutes < 60):
          CptCode = '99454, 99457, 99458'
          CptCharges = '$50.83, $42.26, $31.94'
          totalCharges = '$125.03'
      break;
      
      case (readings >= 16 && minutes >= 60 && minutes < 80):
          CptCode = '99454, 99457, 99458, 99458'
          CptCharges = '$50.83, $42.26, $31.94, $31.94'
          totalCharges = '$156.97'
      break;
      
      case (readings >= 16 && minutes >= 80 && minutes < 100):
          CptCode = '99454, 99457, 99458, 99458, 99458'
          CptCharges = '$50.83, $42.26, $31.94, $31.94, $31.94'
          totalCharges = '$188.91'
      break;

      case (readings >= 16 && minutes >= 100 && minutes < 120):
          CptCode = '99454, 99457, 99458, 99458, 99458, 99458'
          CptCharges = '$50.83, $42.26, $31.94, $31.94, $31.94, $31.94'
          totalCharges = '$220.85'
      break;

      case (readings >= 16 && minutes >= 120):
          CptCode = '99454, 99457, 99458, 99458, 99458, 99458, 99458'
          CptCharges = '$50.83, $42.26, $31.94, $31.94, $31.94, $31.94, $31.94'
          totalCharges = '$252.79'
      break;

      default: 
      break;
      }
    }


    let result = csvData.map((report, index) => {

      calcCPT(report?.totalReadings, report?.totalMinutes);

        return {
            'Sr No':index + 1,
            'Month': report?.Month,
            'EHR-ID':report?.emrId,
            'Patient Name':report?.patientName,
            'D.O.B':moment(report?.DOB).format('MM/DD/YYYY'),
            'Total Readings':report?.totalReadings,
            'Total Minutes': report?.totalMinutes,
            'CPT Code': CptCode,
            'Payment': CptCharges, 
            'Total Payment':totalCharges,
            'Category': report?.category == true ? 'CCM' : 'RPM' 
          }
    })


  return (
    <button className="edit-pill">
        <i className='bx bxs-cloud-upload'></i> 
        <CSVLink data={result} filename={fileName}> <span style={{color: '#FFF'}}> Download</span></CSVLink> 
    </button>
  )
}

export default ExportSummaryReportToCSV