import React, { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { useSelector, useDispatch} from 'react-redux';
import {updatetelemetary} from '../../actions/adminActions';
import { useAlert } from 'react-alert';
import { Link, useHistory } from 'react-router-dom';

const EditTelemetaryData = (props) => {
  
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();

  let telemetarydata = props?.location?.state?.telemetaryData;

  const {message, error } = useSelector(state => state.common);

  const [sys, setSys] = useState(telemetarydata?.telemetaryData?.sys);
  const [dia, setDia] = useState(telemetarydata?.telemetaryData?.dia);
  const [pul, setPul] = useState(telemetarydata?.telemetaryData?.pul);
  const [dateAdded,setDateAdded] = useState(telemetarydata?.dateAdded);
  const [createdAt, setCreatedAt] = useState(telemetarydata?.createdAt);

  const [Id, setId] = useState(telemetarydata?._id);

  useEffect(() =>{
    if(error){
        return alert.error(error);
    }

    if(message){
        alert.success(message);
        props?.history?.goBack();
    }

}, [dispatch, error, message]);

  const editTelemetaryData = () => {    
    dispatch(updatetelemetary(Id, sys, dia, pul, dateAdded, createdAt));
    }

  return (
    <>
        <MetaData title="Edit Telemetary Data"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />  
                

                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 bg-white rounded">        
                        <div className="home-content">
                            <div className="container">
                                <div className="row-display">
                                    <h5 className="title">Edit Telemetary Data </h5>

                                    <div className="row-display">
                                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                            <i className='bx bx-arrow-back'></i>
                                        </button>
                                        &nbsp;&nbsp;
                                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                                            <i className='bx bxs-home'></i>
                                        </Link>
                                    </div>
                                </div>
                            <hr/>          

                                <div className="row">
                                    <div className="col-lg-4">

                                    </div>

                                    <div className="col-lg-4">
                                        <div>
                                        
                                        <label className="form-label mt-3">Data ID</label>
                                         <small> <p>
                                            {Id}
                                        </p></small>    
                                        

                                        {/* <label htmlFor="sys" className="form-label mt-3">Systolic</label>
                                            <input 
                                                type="text"
                                                name="sys"
                                                className="form-control"
                                                placeholder="Enter Systolic *"
                                                value={sys}
                                                onChange={e => setSys(e.target.value)}
                                            />

                                            <label htmlFor="dia" className="form-label mt-3">Diastolic</label>
                                            <input 
                                                type="text"
                                                name="dia"
                                                className="form-control"
                                                placeholder="Enter Diastolic *"
                                                value={dia}
                                                onChange={e => setDia(e.target.value)}
                                            /> 

                                            <label htmlFor="pul" className="form-label mt-3">Pulse</label>
                                            <input 
                                                type="text"
                                                name="pul"
                                                className="form-control"
                                                placeholder="Enter Pulse *"
                                                value={pul}
                                                onChange={e => setPul(e.target.value)}
                                            /> */}

                                            <label htmlFor="dateAdded" className="form-label mt-3">Date Added</label>
                                            <input 
                                                type="text"
                                                name="dateAdded"
                                                className="form-control"
                                                placeholder="Enter Date Added *"
                                                value={dateAdded}
                                                onChange={e => setDateAdded(e.target.value)}
                                            />

                                            <label htmlFor="dateAdded" className="form-label mt-3">Created At</label>
                                            <input 
                                                type="text"
                                                name="dateAdded"
                                                className="form-control"
                                                placeholder="Created At *"
                                                value={createdAt}
                                                onChange={e => setCreatedAt(e.target.value)}
                                            />
                                            <small>Note: Date should be in format YYYY/MM/DD. </small>

                                            <div className="mt-3" style={{ float: 'right'}}>
                                                <button className="edit-pill ml-3" onClick={editTelemetaryData}>Update</button>
                                            </div> 

                                            <br/><br/>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        
                                    </div>
                                </div>    
                            </div>
                        </div>
                </div>
                </section>
    </>
  )
}

export default EditTelemetaryData