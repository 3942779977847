import { useState, useEffect } from 'react';
import MetaData from '../../layouts/MetaData';
import { Modal } from "react-bootstrap";
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { updatePassword, 
    AddProfileImgAdmin, 
    getAdminProfile, 
    changeAdminPassword 
} from '../../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import profilePic from "../../assets/Images/defaultUser.png";
import { useAlert } from 'react-alert';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const Profile = () => {

    const dispatch = useDispatch();
    const alert = useAlert();

	const [show, setShow] = useState(false);

    const { user } = useSelector(state => state.auth);
    const {admin} = useSelector(state => state.adminsProfile);
    const {message, error } = useSelector(state => state.common);
    
    const [showEditModal, setShowEditModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [imageError, setImageError] = useState(true);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const [id, setId] = useState(user?._id);
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [image, setImage] = useState({ preview: '', data: '' })

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const handleImageModal = () => setShowImageModal(true);
    const handleCloseImageModal = () => setShowImageModal(false);
    const handleShowResetModal = () => setShowResetPasswordModal(true);
    const handleCloseResetModal = () => setShowResetPasswordModal(false);


    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            alert.success(message);
            
            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        dispatch(getAdminProfile(user?._id));
            
    }, [dispatch, error, message]);

    const handleShowEditModal = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);

    const onFormSubmit = (e) => {
        e.preventDefault();
        if(email === "" || password === "" || name === ""){
            alert.error('Please fill all fields.');
            handleCloseEditModal();
            return;
        }

         dispatch(updatePassword(id, name, email, password));
      };

      const handleFileChange = e => {
        e.preventDefault();
        
        if(e.target.files[0].length === 0){
            alert.error('No file selected');
            handleCloseImageModal(true);
        }

        if(e.target.files[0].size > 2e6){
            alert.error('Please upload image of less than 2 MB');
            handleCloseImageModal(true);
        };

        setImage(e.target.files[0]);
        setImageError(false);
      }

      const handleSubmit = () => {
        if(imageError === true) {
            alert.error('Select an Image to upload');
        }
        else {
            dispatch(AddProfileImgAdmin(image, user?._id));
            setShowImageModal(false);
        }
    }

    const handleChangePassword = () => {
        if(newPassword !== confirmPassword){
            alert.error('New Passwords not matched');
            return;
        }

        dispatch(changeAdminPassword(user?._id, oldPassword, newPassword));
        setShowResetPasswordModal(false);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');

    }

    return (
        <>
            <MetaData title="My Profile"/>
                <Sidebar />

                <section className="home-section">
                {/* TopBar */}
                <TopBar />

                
                <h4 className="title mt-4 ml-4">Admin profile</h4>

                <div className='text-center'>
                {/* <img src={profilePic} className="profile-img" alt="profileimg" /> */}
                  {admin && admin?.profileImg ? <>
                    <img src={`https://www.rpmatrix.com/v1/uploadFiles/${admin?.profileImg}`} 
                    className="profile-img" alt="profileimg" 
                  />
                  </> : <img src={profilePic} className="profile-img" alt="profileimg" />
                  }

                  <button className="edit-pill rounded-btn" onClick={handleImageModal}>
                        <i className="fas fa-camera"></i>
                  </button>

                  <div className="d-flex justify-content-center">
                      <b style={{fontSize: 20, marginTop: 10}}> {user && user.name} - ( Admin ) 
                        <button className="edit-pill ml-2"onClick={handleShowEditModal}>
                            <i className='bx bx-edit-alt'></i>
                        </button>

                        {/* <button className="edit-pill ml-2" onClick={handleShowResetModal}>
                            <i className='bx bx-key'></i>                           
                        </button> */}

                      </b>
                  </div>

                  <br />
                  <div className="d-flex justify-content-center">
                   <span className="profile-value-bottom-border"> <b>Email : </b> {admin?.email}</span>
                  </div>

                  <br />
                  <div className="d-flex justify-content-center">
                   <span className="profile-value-bottom-border"><b>Role : </b>  {admin?.role}</span>
                  </div>

                  <br />
                    <div className="d-flex justify-content-center">
                    <span className="profile-value-bottom-border"><b>Created At : </b>
                    {moment(admin?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm")}
                    </span>
                    
                  </div>

                  

                <Modal show={showEditModal}>
                   <Modal.Header>
                        <Modal.Title>Edit Profile</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="name" className='form-label'>Name: </label>
                        <input
                            type="input"
                            name="name"
                            className="form-control"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />

                    <label htmlFor="name" className='form-label'>Email Address: </label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />


                        <label htmlFor="newPassword" className='form-label'>Enter New Password</label>
                        <input
                            type={show === false ? "password" : "text"}
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {/*...*/}

                        <div style={{float: 'right'}}>
                            <input type="checkbox" 
                            name="show" 
                            id="show"
                            value={show}
                            onChange={() => setShow(!show)}
                        />
                        &nbsp;&nbsp;<label htmlFor="show" >Show Password</label>
                        </div>
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" onClick={onFormSubmit}>Save</button>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleCloseEditModal}>Cancel</button>
                    </Modal.Footer>
                </Modal>


                {/* Image Modal */}
                <Modal show={showImageModal}>
                    <Modal.Header>
                        <Modal.Title>Update Profile Image</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="name">Choose your Image: </label>
                        <input 
                            type="file" 
                            name="image" 
                            onChange={handleFileChange} 
                            accept="image/jpeg, image/png, image/jpg" 
                        />
                        <br />

                        {/*...*/}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleCloseImageModal}>Cancel</button>
                        <button className="edit-pill" onClick={handleSubmit}>Update</button>
                    </Modal.Footer>
                </Modal>


                {/* Change Password */}
                <Modal show={showResetPasswordModal}>
                    <Modal.Header>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <label htmlFor="oldPassword">Current Password: </label>
                        <input
                            type="password"
                            name="oldPassword"
                            className="form-control"
                            placeholder="Enter Email Address"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <hr />

                        <label htmlFor="newPassword">New Password: </label>
                        <input
                            type="password"
                            name="newPassword"
                            className="form-control"
                            placeholder="Enter New Address"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <br />

                        <label htmlFor="confirmPassword">Confirm New Password: </label>
                        <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <br />

                        <span><b style={{color: 'red'}}>Note: </b> <small>New Password should be alphanumeric and minimum of 8 characters. </small></span>
                        
                        {/*...*/}
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <button className="edit-pill" style={{backgroundColor: 'red', color: '#FFF'}} onClick={handleCloseResetModal}>Cancel</button>
                        <button className="edit-pill" onClick={() => handleChangePassword()}>Update</button>
                    </Modal.Footer>
                </Modal>


                </div>
                

               </section>     
        </>
    )
}

export default Profile
