import BaseModal from "./base-modal/base-modal";
import {Button} from "react-bootstrap";
import patientProfileImg from "../assets/Images/user.png";
import womanIcon from "../assets/Images/woman.png";
import moment from "moment-timezone";
export default function DuplicatePatientModal({
                                                  isBoxOpen,
                                                  setIsBoxOpen,
                                                  patientData,
                                                  setPatientData,

                                                  title,
                                                  continuable,
                                                  proceedButton
                                              }) {

    return (

        <BaseModal
            isBoxOpen={isBoxOpen}
            setIsBoxOpen={setIsBoxOpen}
            title={title}
        >
            {
                patientData && patientData.length > 0 &&
                patientData?.map((patient, index) => (
                    <div className={`text-center  col-12 my-4  ${patientData[index+1]&& "border-bottom" }`}>
                        <img src={patient?.gender === 'male' ? patientProfileImg : patient?.gender === 'female' ? womanIcon:patientProfileImg } className="patient-profile-card-img" alt="patientProfile" />
                        <h5 className="title mt-2">{patient?.lastname}, {patient?.firstname}</h5>
                        <p><b>{patient?.emrId}</b></p>

                        <div className="row">
                            <div className='col-4'>
                                <b>D.O.B</b>
                                <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                            </div>

                            <div className='col-4'>
                                <b>Age</b>
                                <p>{moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years</p>
                            </div>

                            <div className='col-4'>
                                <b>Phone1</b>
                                <p>{patient?.phone1}</p>
                            </div>
                            <br/>


                            <div className='col-12'>
                                <b>Assigned Doctor</b>
                                <p>Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname} </p>
                            </div>




                        </div>
                    </div>
                ))

            }

            <div className={"d-flex justify-content-end"}>



                {continuable &&
                    <Button onClick={proceedButton} className={"bg-danger border-danger"}>
                        Continue Anyway
                    </Button>
                }

                {!continuable &&
                    <Button onClick={()=> {
                        setIsBoxOpen(false)
                    }
                    } className={"bg-red"}>
                       Ok
                    </Button>
                }


            </div>


        </BaseModal>


    )

}
