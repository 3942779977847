import NotFoundImg from '../assets/Images/NotFoundImg.png';


const NotFound = ({title}) => {
  return (
    <div>
        <img src={NotFoundImg} width="80%" height="80%" />
        <p className="text-center text-muted" style={{letterSpacing:'1px'}}>{title}</p>
    </div>
  )
}

export default NotFound