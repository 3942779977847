import {
   FETCH_START,
   FETCH_END,
   SHOW_ALERT_MESSAGE,
   HIDE_ALERT_MESSAGE,
   VERIFIED_ACCOUNT_SUCCESS,
   VERIFIED_ACCOUNT_FAILED,
   FETCH_ERROR
  } from "../constants/Common";

const INIT_STATE = {
    error: "",
    loading: false,
    message: "",
    verified: false
  };

export const commonReducers = (state = INIT_STATE, action) => {
    switch (action.type) {
      
      case FETCH_START: 
        return {
          loading: true,
          error: "", 
          message: ""
        }
      

      case SHOW_ALERT_MESSAGE: 
        return { ...state, 
            error: "", 
            message: action.payload, 
            loading: false 
        };

      case VERIFIED_ACCOUNT_SUCCESS: 
        return { ...state, 
           verified: true
      };

      case VERIFIED_ACCOUNT_FAILED: 
        return { ...state, 
           verified: false
      };

      case FETCH_END: 
        return { 
          ...state,
          loading: false
        }


      case FETCH_ERROR: 
        return {
          ...state,
          loading: false,
          error: action.payload,
          message: ""
        };
    
      case HIDE_ALERT_MESSAGE: 
        return { ...state, 
            loading: false, 
            error: "", 
            message: "" 
        };
      
        default:
        return state;
    }
  };
  
