import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {  
    getTimeReportCCM, 
} from '../../actions/HRActions';
import Loader from '../../layouts/Loader';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Badge } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import TimeReportPDF from '../../components/TimeReportPDF';




const CCMMinutesSpent = ({patientId, isNurse, isAdmin}) => {

  const dispatch = useDispatch();
  const [filterMinutes, setFilterMinutes] = useState('all');
 
//   const [month, setMonth] = useState(moment().format('MM'));
//   const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));

  const [month, setMonth] = useState(moment().subtract(1, "month").format('MM'));
  const [endMonth, setEndMonth] = useState(moment().format('MM'));

  const [year, setYear] = useState('2024')
  const [pagination, setPagination] = useState(300);


  const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);

    useEffect(() => {
        dispatch(getTimeReportCCM(patientId, month, endMonth, year, pagination));
    }, [dispatch]);

    const refreshHandler = () => {
        dispatch(getTimeReportCCM(patientId, month, endMonth, year, pagination)); 
    }

    const downloadFile = (fileName) => {
        window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
      }

    const searchHandler = () => {
        dispatch(getTimeReportCCM(patientId, month, endMonth, year, 500)); 
    }

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        pageStyle:"A5",
        documentTitle:  "CCM Time Report",
        content: () => componentRef.current,
    });

  return (
    <div>
         {loading ? <Loader /> : <>
         <br/>

         {/* Time report download */}
         <div
            style={{ display: "none" }} // This make ComponentToPrint show   only while printing
            > 
            {targets && targets?.length > 0 ? <>
                <TimeReportPDF ref={componentRef} 
                targets={targets}
                interactiveMinutes={totalInteractiveMinutes}
                nonInteractiveMinutes={totalNonInteractiveMinutes}
                timeSpent={totalTime}
                month= {month}
                year= {year}
                category="CCM"
                endMonth={endMonth}
            /> 
            </> : null }
            </div>
         {/* Time report download */}

         <div className="coloredRow p-2">
                            <div className="row" style={{ justifyContent: 'center'}}>
                                <div className='col-md-3'>
                                <label className="form-label" htmlFor="month">Start Month </label>
                                    <select 
                                    name="month" 
                                    className="form-control"
                                    value={month} 
                                    onChange={e => setMonth(e.target.value)}
                                    >
                                    <option value="01">January - 26</option>
                                    <option value="02">February - 26</option>
                                    <option value="03">March - 26</option>
                                    <option value="04">April - 26</option>
                                    <option value="05">May - 26</option>
                                    <option value="06">June - 26</option>
                                    <option value="07">July - 26</option>
                                    <option value="08">August - 26</option>
                                    <option value="09">September - 26</option>
                                    <option value="10">October - 26</option>
                                    <option value="11">November - 26</option>
                                    <option value="12">December - 26</option>
                                    </select>
                                </div>

                                <div className='col-md-3'>
                                <label className="form-label" htmlFor="month">End Month </label>
                                    <select 
                                    name="end" 
                                    className="form-control"
                                    value={endMonth} 
                                    onChange={e => setEndMonth(e.target.value)}
                                    >
                                    <option value="01">January - 25</option>
                                    <option value="02">February - 25</option>
                                    <option value="03">March - 25</option>
                                    <option value="04">April - 25</option>
                                    <option value="05">May - 25</option>
                                    <option value="06">June - 25</option>
                                    <option value="07">July - 25</option>
                                    <option value="08">August - 25</option>
                                    <option value="09">September - 25</option>
                                    <option value="10">October - 25</option>
                                    <option value="11">November - 25</option>
                                    <option value="12">December - 25</option>
                                    </select>
                                </div>

                                <div className='col-md-3'>
                                <label className="form-label" htmlFor="year">Select Year </label>
                                    <select 
                                    name="year" 
                                    className="form-control"
                                    value={year} 
                                    onChange={e => setYear(e.target.value)}
                                    >
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    </select>
                                </div>

                                <div className='col-md-3 mt-2'>
                                <label className="form-label">Actions </label><br/>
                                    <button className="edit-pill" onClick={() => searchHandler()}>
                                        Search
                                    </button>
                                    &nbsp;&nbsp;
                                    <button className="edit-pill" onClick={() => refreshHandler()}>
                                        Refresh
                                    </button> 
                                </div>
                            </div>
                        </div>
                        <br/>

                    {targets && targets.length > 0 ? <>
                        <div className="container row-display-secondary">
                                <div className="col-md-4 card p-4 m-1 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent in CCM</b></p>
                                </div>

                                <div className="col-md-3 card p-4 m-1 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-3 card p-4 m-1 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-2 text-center">
                                    <div className="row-display-column">
                                    <label className="profile-label">Filter Only :</label>
                                    <div className="row-display">
                                        <span className={`smallFilterBtn ${filterMinutes === 'call' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('call')}>
                                                 Calls
                                        </span>
                                        &nbsp;&nbsp;

                                        <span className={`smallFilterBtn ${filterMinutes === 'vm' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('vm')}>
                                                 VMs
                                        </span>
                                        &nbsp;&nbsp;

                                        <span className={`smallFilterBtn ${filterMinutes === 'review' ? 'smallFilterBtnActive' : ''}`}
                                            onClick={() => setFilterMinutes('review')}>
                                                Reviews
                                        </span>
                                    </div>


                                     <div className='row-display-secondary'>
                                        <button 
                                            className="edit-pill" 
                                            onClick={() => setFilterMinutes('all')}
                                        ><i className='bx bx-refresh'></i>
                                        </button>
                                        
                                        {isAdmin === true || isNurse === true ? <>
                                            <button 
                                                className="edit-pill" 
                                                onClick={() => handlePrint()}
                                            >
                                                <i className='bx bx-down-arrow-alt'></i>
                                            </button>
                                        </> : null}
                                        
                                    </div>
                                    </div>
                                </div>
                            </div>    
                            <br />
                                    
                            {targets && targets.filter(item => filterMinutes !== 'all' ? item?.encounterType === filterMinutes : item).map((trgt, index) => ( 
                                    <div className="m-2" key={index}>
                                        
                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> Name: </label> 
                                                <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label"> D.O.B: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date: </label> 
                                                <label className="report-label ml-2"> {moment(trgt?.startDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label> 
                                                <label className="report-label ml-2">{moment(trgt?.endDate).format("MM/DD/YYYY") || 'N/A' }</label>
                                            </div> 
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">Start Time: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.startTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                                </div>
        
                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">End Time: </label> 
                                                    <label className="report-label ml-2">{moment(trgt?.endTime, ["HH.mm"]).format("hh:mm a") || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>    
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">
                                              
                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b> 
                                                : 
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }
                                                
                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                CCM category</span>
                                                
                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ? 
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null 
                                                    }
                                                </p>
                                            </label>
                                                
                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label> 
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>   
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>
                                        
                                        {trgt?.clinicalFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>Clinical FollowUp</h5>
                                            <label className="profile-label">Q. How have you been managing your health ?</label>
                                            <p>{trgt?.clinicalFollowUp?.healthManagement}</p> 
                                            <br />
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any new problems or conditions ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isNewProblem === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. New Problems or Conditions Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.newProblemDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isWorseningConditions === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.worseningConditionsDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 2 */}
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problems Identified ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isMedicationProblems === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problem Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationProblemsDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall in last 30 days ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentFall === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentFallDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 3 */}
                                                <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Visit ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentlyHospitalized === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Details  ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentlyHospitalizedDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Request ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequest === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequestDetails}</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <label className="profile-label">Q. Any Additional Notes ?</label>
                                            <p>{trgt?.clinicalFollowUp?.additionalNotes}</p>
                                            

                                        </div> : null}

                                        {trgt?.careplanFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>CCM Careplan FollowUp</h5>

                                            {trgt?.careplanFollowUp?.map((careplan, index) => (
                                                <div key={index}>
                                                    <label className="profile-label">Disease Name : </label> <br />
                                                    <span className="coloredRow p-2 mt-2">{careplan?.diseaseName} </span> <br />
                                                    <br />
                                                    <label className="profile-label">Short Term Goals : </label> <br />
                                                    {careplan?.shortTermGoals.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Short Term Note : </label> <br />
                                                    {careplan?.shortTermNote}

                                                    <hr />
                                                    <label className="profile-label">Long Term Goals : </label> <br />
                                                    {careplan?.longTermGoals.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Long Term Note : </label> <br />
                                                    {careplan?.longTermNote}

                                                    <hr />
                                                    <label className="profile-label">Interventions : </label> <br />
                                                    {careplan?.interventions.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Intervention Notes : </label> <br />
                                                    {careplan?.interventionNote}


                                                </div>
                                            ))}                                             


                                        </div> : null}

                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">  
                                                    
                                                    <div className='row-display'>
                                                        <b className='text-white' 
                                                            style={{letterSpacing: '1px'}}> 
                                                            {trgt?.assigned_assistant_id ? 
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname 
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname 
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                            }
                                                        </b>
                                                    </div>
                                                                        
                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/> 
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr className='blue-hr'/>
                                    </div>
                                ))}
                            </> : <div className="text-center mt-3 pb-2">
                               <b>CCM minutes not found</b><br/>
                               <button className="edit-pill" onClick={() => refreshHandler()}>Refresh Minutes</button>
                            </div>}
                            </>}
                    </div>
                )
            }

export default CCMMinutesSpent;