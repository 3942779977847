import { useState, useEffect } from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { assignAssistantToOrganization } from '../../actions/adminActions';
import {getAssistants, getAssistantProfile} from '../../actions/assistantActions';
import patientProfileImg from '../../assets/Images/network.png';
import {useDispatch, useSelector} from 'react-redux';
import { useAlert } from 'react-alert';
import moment from 'moment';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';


const AssignOrganizationToDoctor = (props) => {
    
    const history = useHistory();

    let orginfo = props?.location?.state?.orgInfo;
    
    const orgId = orginfo?._id;

    const dispatch = useDispatch();
    const alert = useAlert();    
    
    const [assistantId, setAssistantId] = useState('');

    const {error, assistants } = useSelector(state => state.assistantsList);
    const { assistant } = useSelector(state => state.assistantProfile);

    const { user } = useSelector(state => state.auth);
    const {error : commonError, message } = useSelector(state => state.common);

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(commonError) {
            alert.error(commonError);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(message){
            alert.success(message);
            history.push('/organizations');

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        dispatch(getAssistants());

        if(assistantId){
            dispatch(getAssistantProfile(assistantId));
        }
        
    }, [dispatch, alert, error, commonError, message, assistantId]);

    const assignAssistant = (assistantId) => {
        dispatch(assignAssistantToOrganization(orgId, assistantId, user?._id))
    }

    const funcSetAsstId = (dr) =>{
        setAssistantId(dr.value);
    }

    const options = []
    assistants && assistants.length > 0 && assistants.filter(item => !item?.assigned_org_id).map((asst) => (
       options.push({value:asst?._id , label:[asst?.firstname, asst?.lastname, asst?.npinumber].join(" ") })
    ))
  
    return (
    <>
    <MetaData title="Assign Clinical Staff To Organization"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="col-md-0 shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                <div className="row-display">    
                    <h5 className="title mt-2">
                        <i className='bx bx-plus-medical'></i> <strong>&nbsp;Assign Clinical Staff To {orginfo?.name} </strong>
                    </h5>

                    <div>
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                        <i className='bx bx-home' ></i></Link> &nbsp;
                    </div>
                </div>
                <hr />
                
                <div className="row">
                    <div className="col-md-4">
                        <h5 style={{color: '#00A8A8'}}>
                            <i className='bx bx-search-alt-2' ></i> <strong>&nbsp;Select Clinical Staff </strong>
                        </h5>

                        <br/>    
                        <span><b style={{color: 'red'}}> Note : </b> One clinical staff could enrolled in only one organization. </span>
                        <br/><br/>
                            <Select
                                options={options}
                                onChange={funcSetAsstId}
                            />
                        
                    </div>
                    

                    <div className="col-md-8">
                        

                        {assistantId && assistant && <>
                            <h5 style={{color: '#00A8A8'}} className="mt-4">
                                 <strong>&nbsp;Clinical Staff Details </strong>
                            </h5>
                            <br />
                                    <div className="col-md-12">
                                        <div className="row">
                                            <img src={patientProfileImg} className="patient-profile-card-img" alt="patientProfile" />
                                            <p className="profile-name pl-3 pb-2">{assistant?.lastname || '--'}, {assistant?.firstname || '--'} </p>
                                        </div>

                                        <br />

                                    <div className="row">
                                        <div className="col-4">    
                                            <span className="profile-label">Email Address: </span>
                                            <p className="profile-value-text" style={{wordWrap: 'break-word'}}>{assistant?.email ? assistant?.email : 'N/A'}</p>

                                            <span className="profile-label">License #: </span>
                                            <p className="profile-value-text">{assistant?.licensenumber || 'N/A'}</p>    
                                        </div>

                                        <div className="col-3">    
                                            <span className="profile-label">D.O.B: </span>
                                        <p className="profile-value-text">{moment(assistant?.DOB).format("MM/DD/YYYY")}</p>

                                        <br/>
                                        <span className="profile-label">Role: </span>
                                            <p className="profile-value-text">{assistant?.role}</p>                                 
                                        </div>
                                        

                                        <div className="col-3">    
                                            <span className="profile-label">Gender: </span>
                                            <p>{assistant?.gender === 'male' ? 'Male' : 'Female'}</p>

                                        <br/>
                                        <span className="profile-label">NPI: </span>
                                            <p className="profile-value-text">{assistant?.npinumber ? assistant?.npinumber : 'N/A'}</p>                                
                                        </div>

                                        {/*  */}
                                        <div className="col-2">    
                                            <span className="profile-label">Ver. Status: </span>
                                            <p>
                                                {assistant?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}
                                            </p>

                                        <br/>
                                        <span className="profile-label">Acc. Status: </span>
                                            <p>
                                                {assistant?.block === false ? <b style={{color: 'green'}}>Active</b> : <b style={{color: 'red'}}>Deactivated</b>}
                                            </p>                                
                                        </div>
                                    </div>
                            </div>
                            <button className="edit-pill mr-5" 
                                style={{ float: 'right' }} 
                                onClick={() => assignAssistant(assistant?._id)}> Assign </button>             
                        </>}
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AssignOrganizationToDoctor