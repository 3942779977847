import { useState, useEffect } from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {doctorCriticalReadings} from '../../actions/adminActions';
import { Badge } from 'react-bootstrap';
import doctorIcon from '../../assets/Images/doctorIcon.png';



const HRCriticalReadings = () => {
    const dispatch = useDispatch();
    const { assignedDoctors } = useSelector(state => state.hrStats);


    const [selectedDr, setSelectedDr] = useState("default");

    let nurseDrs = JSON.parse(localStorage.getItem('nurseSelectedDrs')) || []; 

    const {count, respondedReadings, notRespondedReadings, highSystolic, lowDiastolic,  criticalPts, weightReadings, spo2Readings, glucoseReadings} = useSelector(state => state.nurseCriticalReadings);
    
    const [filterReadings, setFilterReadings] = useState('all');
    const [pagination, setPagination] = useState(25);


    useEffect(() => {
        if(selectedDr && selectedDr !== "default"){
            const filteredDrs = nurseDrs.filter(id => id === selectedDr);
            dispatch(doctorCriticalReadings(pagination, filterReadings, JSON.stringify(filteredDrs)));
        }


    }, [dispatch, pagination, filterReadings, selectedDr]);
    
      let sysBPCategory; //variable to store category of BP
      let color
      
    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
                color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }

        const refreshCriticalReading = () => {
            setSelectedDr("default");
            dispatch(doctorCriticalReadings(pagination, filterReadings, JSON.stringify(nurseDrs)));
        }

        const updateCriticalReadingsPagination = () => {
            setPagination(pagination + 25);
            dispatch(doctorCriticalReadings(pagination, filterReadings, JSON.stringify(nurseDrs)));
        }

        var minutesDuration;
        const calcDuration = (receiveDate, sentDate) => {
            minutesDuration = moment(receiveDate).diff(moment(sentDate), 'minutes');
        }



    return (
    <>
    <MetaData title="Critical Readings" />
    <HRSidebar />
    
    <section className="home-section background-white">
        <HRTopBar />
        <br />

                <div>
                    <div className="shadow-lg recent-readings-card background-white m-2">
                    <div className="row-display">
                    
                    <div className="pr-4 rightBorderLine">    
                        <h5 className="title"> Critical Vitals</h5> 
                        <p>Showing <b>{criticalPts?.length}</b> results out of <b>{count && count}</b> </p>       
                    </div>

        
                    {/* Select Dr from dropdown */}
                    <div className="row">
                        <img src={doctorIcon} width="75px" height="75px" />
                        <div>
                            <label className='form-label'>Sort By Doctor</label>
                            <select className="form-control" value={selectedDr} onChange={e => setSelectedDr(e.target.value)}>
                                <option value="default">Select Doctor</option>
                                {assignedDoctors && assignedDoctors.map((doctor, index) => (
                                    <option key={index} value={doctor?._id}>
                                        Dr. {doctor.firstname} {doctor?.lastname}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>         
                        <button className="edit-pill" onClick={() => refreshCriticalReading()}>
                            <i className='bx bx-refresh'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/Nurse/Dashboard" className="btn btn-secondary"><i className='bx bx-arrow-back' ></i></Link> &nbsp;

                    </div>
                    </div>
                    <hr className="blue-hr"/>
                        
                        <div className="row-display">
    
                            <label className="profile-label mt-2">Search Filters </label>

                            <span className={`smallFilterBtn ${filterReadings === 'all' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('all')}
                                >All Readings 
                            </span> 
                            
                            <span className={`smallFilterBtn ${filterReadings === 'responded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('responded')}>
                                Responded <span className="notiNumber ml-2" style={{backgroundColor: 'green'}}> {respondedReadings > 10 ? respondedReadings :'0'+ respondedReadings}</span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'notResponded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('notResponded')}>
                                Not Responded <span className="notiNumber ml-2"> {notRespondedReadings > 10 ? notRespondedReadings :'0'+ notRespondedReadings}</span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'highSystolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('highSystolic')}>
                                 High Systolic {'>'} 180 <span className="notiNumber ml-2"> {highSystolic > 10 ? highSystolic :'0'+ highSystolic}</span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'lowDiastolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('lowDiastolic')}>
                                 Low Diastolic {'<'} 55 <span className="notiNumber ml-2"> {lowDiastolic > 10 ? lowDiastolic : '0'+lowDiastolic}</span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('weight')}>
                                    Weight <span className="notiNumber ml-2"> {weightReadings > 10 ? weightReadings : '0'+weightReadings}</span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'glucose' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('glucose')}>
                                    Glucose <span className="notiNumber ml-2"> {glucoseReadings > 10 ? glucoseReadings : '0'+glucoseReadings} </span>  
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('spo2')}>
                                    Spo2 <span className="notiNumber ml-2"> {spo2Readings > 10 ? spo2Readings : '0'+spo2Readings} </span> 
                            </span>
                        </div>
                        <br/>
                        
                <table className="styled-table">
                <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Reading Date</th>
                            <th>Vitals</th>
                            <th width="90px">Device</th>
                            <th width="90px">Pulse Rate</th>
                            <th>Status</th>
                            <th>Doctor</th>
                            <th width="40px"></th>
                        </tr>
                    </thead>

                    <tbody>

                {criticalPts && criticalPts.map((pts, index) => (
                        <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                            <td><span className="roundedNameIcon">{pts?.assigned_patient_id?.lastname[0]}{pts?.assigned_patient_id?.firstname[0]}</span></td>
                            
                        <td>
                            <Link to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname + " , "+  pts?.assigned_patient_id?.firstname} </Link>      
                        </td>

                        <td>{pts?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                            <b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                            </> : <>
                                <b>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                <p className="p-0 m-0">{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>

                                {/* function to calculate time duration */}
                                {pts?.createdAt && pts?.telemetaryData?.ts && calcDuration(moment(pts?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                                    moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                                    {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }
                                    {/* function to calculate time duration ends */}
                            </>}
                        </td>
                            
                        {calcSysBpCategory(pts?.telemetaryData?.sys)}

                        {pts?.deviceId?.deviceType === "bp" ? <td> 
                            <b>{pts?.telemetaryData?.sys} / {pts?.telemetaryData?.dia} mmHG </b><p>
                                {pts?.telemetaryData?.sys >= 139 ? 
                                <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                pts?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                pts?.telemetaryData?.dia >= 90 ? 
                                <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                pts?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                </p>
                                </td> : pts?.deviceId?.deviceType === "weight" ? <td>
                                <b>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                <p>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                            </b>
                        </td> : pts?.deviceId?.deviceType === "sugar" ? <td>
                            <b> {pts?.telemetaryData?.data} &nbsp;
                                {pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                            </b>
                        </td> : pts?.deviceId?.deviceType === "spO2" ? <td>
                            <b>{pts?.telemetaryData?.notification_payload?.spo2_pct}
                            <p>Perfusion: {pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                        </td> : <td>---</td> }

                             {/* Device type */}
                             <td><b>{pts?.deviceId?.deviceType === "bp" ? 
                                    <span className='bpDeviceType'>B.P</span> : 
                                    pts?.deviceId?.deviceType === "weight" ? 
                                    <span className='weightDeviceType'>Weight</span> : 
                                    pts?.deviceId?.deviceType === "sugar" ? 
                                    <span className="glucoseDeviceType">Glucose</span> : 
                                    <span className="spo2DeviceType">Spo2</span>}</b>
                            </td>

                            {/* B.P Pulse rate  */}
                            <td>
                                <b>{pts?.telemetaryData?.pul ? pts?.telemetaryData?.pul +' bpm' : pts?.telemetaryData?.notification_payload?.pulse_bpm ? pts?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} </b>
                            </td>
                            
                            {/* B.P Category */}
                            <td> {pts?.telemetaryData?.sys ? <><i className='bx bxs-circle' style={{color: color, fontSize: '24px'}}></i>&nbsp;&nbsp;<b>{sysBPCategory}</b></> : '---'}</td> 
                                            

                            <td><b>{pts?.assigned_doctor_id ? 'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname : null} </b></td> 
                            
                            <td className="text-center">{pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                <i className="fas fa-times" style={{color: 'red'}}> </i> }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <br/>
                    {criticalPts?.length < count && <>
                        <div className="text-center">
                            <button className="edit-pill" onClick={() => updateCriticalReadingsPagination()}>Show more</button>
                        </div>
                        </> 
                    }
                
                    
                </div>
            </div>
        

    </section>
    </>
  )
}

export default HRCriticalReadings