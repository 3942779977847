import {useState} from 'react';
import { hrLogout } from '../../actions/authActions';
import { useDispatch } from 'react-redux';
import SessionTimeout from '../../components/SessionTimeout';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';


const TopBar = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();
    
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);

    let nurseDrs = localStorage.getItem('nurseSelectedDrs'); 
    let countLength = JSON.parse(nurseDrs);

    const logoutHandler = () => {
     var confirmation = window.confirm(`Are you sure you want to logout ?`);
        if(confirmation){
            window.location.replace("/");
            dispatch(hrLogout());
        }

        localStorage.removeItem('token');
        localStorage.removeItem('hr');
    }

    const fetchData = (value) => {
        axios.post(`${Prod01}/hr/patient/searchAll`, {
                value: value,
                nurseDrs
            },{
                headers: {
                'Content-Type': 'application/json',
                }
                }, {
                timeout: 10000 // 10 seconds
            })
        .then((result) => {
            value && setResults(result?.data);
        }
        )
      }
    
      const handleChange = (value) => {
        setInput(value);
        fetchData(value)
    }

    const redirectHandler = (patientId) => {
        history.push({
          pathname: `/Nurse/Patient/Profile`,
          state: {patientid: patientId} // Pass the state object along with the URL
        });
        setInput('');
     }

    return (
        <>
            <SessionTimeout />

            <div className="row-display" id='topbar' style={{backgroundColor: '#006464'}}>

            <div className="searchBox" >
                <input 
                    type="text" 
                    placeholder="Search patient . . ." 
                    className="searchInput" 
                    value={input}
                    onChange={e => handleChange(e.target.value)}
                    maxLength={15}
                    disabled={countLength?.length > 0 ? false : true}
                />
                <i className='bx bx-search searchbar-Icon'></i>
            </div>

            <div className='row'>
                <Link to="/Nurse/settings" className='link'>
                <i className='bx bx-cog mr-4 mt-2' style={{fontSize: '28px', 
                        color: 'white', 
                        marginLeft: '5px', 
                        cursor: 'pointer'
                    }}></i>
                </Link>

                <i className='bx bx-log-out mr-4 mt-2' style={{
                    fontSize: '28px',  
                    color: 'white', 
                    marginLeft: '20px', 
                    cursor: 'pointer'
                    }} onClick={logoutHandler}></i> 
                </div>
            </div>


            {input && results && results.length > 0 &&                             
            <div className="searchResults">
                {results.map((result, id) => (
                    <div key={id} className='row-display-secondary searchResult'>
                        <span onClick={() => redirectHandler(result?._id)}>
                           <b><i className="bx bx-search"></i> &nbsp;&nbsp;&nbsp;&nbsp;{result?.emrId} &nbsp;&nbsp;&nbsp;&nbsp; {result?.lastname +" "+ result?.firstname } </b> 
                           {result?.assigned_doctor_id && <><span> of Organization </span>  <b>{result?.assigned_doctor_id?.lastname +" "+ result?.assigned_doctor_id?.firstname} </b></>}  
                        </span>

                    </div>
                ))}
            </div>
        }
        </>
    )
}

export default TopBar;
