import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';


const ExportBillingReportToCSV = ({csvData, fileName}) => {
  let CptCode = '';

//   console.log('Month is '+ month);  

function calcCPT(minutes) {
    switch(true){
     case (minutes >= 20 && minutes < 40):
        CptCode = '99490'
        break; 
      case (minutes >= 40 && minutes < 60):
          CptCode = '99490, 99439'
          break;
      case (minutes >= 60 && minutes < 90):
          CptCode = "99487"
          break;
      case (minutes >= 90 && minutes < 120):
            CptCode = '99487, 99489'
            break;
      case (minutes >= 120 && minutes < 150):
            CptCode = '99487, 99489,99489'
            break; 
      case (minutes >= 150):
            CptCode = '99487, 99489, 99489,99489'
            break;

      default: 
          CptCode = ''
      break;
      }
    }



    let result = csvData.filter(item => item.totalMinutes > 0).map((report, index) => {

        calcCPT(report?.totalMinutes);


        return {
            'Sr No':index + 1,
            'EHR-ID':report?.emrId,
            'Patient Name':report?.patientName,
            'D.O.B':moment(report?.DOB).format('MM/DD/YYYY'),
            'Total Minutes': report?.totalMinutes,
            'Call Minutes':report?.callMins,
            'VM Minutes':report?.vmMins,
            'Review Minutes': report?.reviewMins,
            'CPT Code': CptCode,
            'Last Activity': report?.lastActivity ? moment(report?.lastActivity).tz("America/New_York").format("MM/DD/YYYY hh:mm A") : null,
            'Category': 'CCM', 
            'Month': report?.Month,
            'Doctor':report?.doctorName
          }
    })


  return (
    <button className="edit-pill">
        <i className='bx bxs-cloud-upload'></i> 
        <CSVLink data={result} filename={fileName}> <span className='text-white'>Download .csv</span></CSVLink> 
    </button>
  )
}

export default ExportBillingReportToCSV;