import {useState, useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import {ProgressBar } from 'react-bootstrap';

import { 
    getPatients, 
    patientDeActivate,
    getDoctors,
    getOrganizations,
    filterPatientByAccStatus,
} from '../../actions/adminActions';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {Badge, Modal } from 'react-bootstrap';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import {UPDATE_PATIENT_RESET} from '../../constants/adminConstants';
import { 
    ALL_PATIENTS_FAIL, 
    ALL_PATIENTS_REQUEST, 
    ALL_PATIENTS_SUCCESS
} from '../../constants/adminConstants';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';

const PatientsList = () => {

    const dispatch = useDispatch();
    let compliantStatus; // variable to store compliant status of patient

    const [smShow, setSmShow] = useState(false); //small confirm modal
    const [patientModel, setPatientModel] = useState(null);
    const [patientToDelete, setPatientToDelete] = useState(null);
    const [doctorId, setDoctorId] = useState('');
    const [sortByDeviceType, setSortByDeviceType] = useState('undefined');
    const [orgId, setOrgId] = useState("");

    const alert = useAlert();
    const { loading, error, patients, count, isUpdated} = useSelector(state => state.admin);
    const { doctors } = useSelector(state => state.doctor);
    const { organizations } = useSelector(state => state.orgs);
    const [input, setInput] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(15);

    
    useEffect(() =>{
        if(error){
            alert.error(error);
        }

        if(isUpdated){
            alert.success('Status Changed');
            dispatch({type: UPDATE_PATIENT_RESET});
            dispatch(getPatients(resPerPage, currentPage));
            setSmShow(false);
        }
        
        if(doctorId && !orgId && sortByDeviceType === "undefined"){
            dispatch(getPatients(resPerPage, currentPage, doctorId));
        } else if(orgId && !doctorId && sortByDeviceType === "undefined"){
            dispatch(getPatients(resPerPage, currentPage, null, orgId));
        } else if (orgId && doctorId && sortByDeviceType === "undefined"){
            dispatch(getPatients(resPerPage, currentPage, doctorId));
        } else if(!orgId && !doctorId && sortByDeviceType === "undefined") {
            dispatch(getPatients(resPerPage, currentPage));
        } 

        if(sortByDeviceType !== "undefined"){
            dispatch(filterPatientByAccStatus(sortByDeviceType));
        }

        dispatch(getOrganizations());
        dispatch(getDoctors(50, 1));

    }, [resPerPage, currentPage, isUpdated, doctorId, orgId, sortByDeviceType]);
    

    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    } 

    const deActivatePatient = () => {
        dispatch(patientDeActivate(patientModel));
    }

    const refreshHandler = () => {
        dispatch(getPatients(resPerPage, currentPage));
        setOrgId("");
        setDoctorId("");
        setSortByDeviceType("undefined");
    }

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }

    const searchHandler = async() => {
        try {
            dispatch({
                type: ALL_PATIENTS_REQUEST,
            })
    
            const { data } = await axios.post(`${Prod01}/patient/search/${resPerPage}/${currentPage}`,{
                value:input
            }, {
                timeout: 10000 // timeout of API is 10 secs
            });
            
            dispatch({
                type: ALL_PATIENTS_SUCCESS,
                payload: data
            })    
        } 
        catch (error) {
            if (error.code === 'ECONNABORTED') {
                dispatch({
                    type: ALL_PATIENTS_FAIL,
                    payload: 'request timed out'
                });
            } else {
                dispatch({
                    type: ALL_PATIENTS_FAIL,
                    payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                });
            }
        }
      }


    return (
        <>
                <MetaData title="RPM Patients"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
    
                {loading ? <Loader /> : (
                <>   
                {/*  patients List Filter Section */}
                <br/>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        
                        <div className="row-display">
                            <h5 className='title'>
                                <i className="bx bx-user"></i> 
                                <strong>&nbsp;{count && count} - RPM Patients </strong>
                            </h5>
                            
                            <div>
                                <Link to="/adminDashboard" className="btn btn-secondary"><i className='bx bx-arrow-back' ></i></Link> 
                                &nbsp;&nbsp;
                                <button className="edit-pill" onClick={refreshHandler}>
                                    <i className='bx bx-refresh'></i></button> 
                                &nbsp;&nbsp;
                                <Link to ="/Patients/Inactive" className="edit-pill" style={{backgroundColor: 'red'}}>
                                    <i className='bx bxs-user'></i> &nbsp;Archieved Patients
                                </Link>
                                &nbsp;&nbsp;
                                <Link to="/addpatient" className="edit-pill">
                                    <i className='bx bxs-user'></i> &nbsp;Add New Patient
                                </Link>
                            </div>                           
                        </div>
                        <hr />

                        <div className="ml-3 mr-3">
                            <div className="row-display">
                            <div className="row colored-row">
                            <div className="mini-searchBox" >
                                <input 
                                    type="text" 
                                    placeholder="Search by EHR-ID, Fname, Lname and DOB . . . " 
                                    className="mini-searchInput" 
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    autoFocus
                                    maxLength="15"
                                />
                                <i className='bx bx-user mini-searchbar-Icon'></i>
                            </div>
                            &nbsp;&nbsp;
                            <button className='edit-pill m-2' onClick={() => searchHandler()}>Search</button>
                            </div>
 
                                <div className="row-display">
                                    <div>
                                        <select 
                                        value={sortByDeviceType}
                                        onChange={e => setSortByDeviceType(e.target.value)}
                                        className="form-control mt-2">
                                            <option value="undefined"> Device Status</option>
                                            <option value="nonCompliant">No Device Pts</option> 
                                        </select>    
                                    </div>
                                    &nbsp;&nbsp;

                                    {/* Sort by Doctor */}
                                    <div className="row colored-row">                       
                                    <select className='form-control'
                                            value={orgId}
                                            onChange={e => setOrgId(e.target.value)}
                                            style={{width: '200px'}}
                                            >
                                            <option key="151546">Select Organization</option>
                                                {organizations && organizations.map((org) =>  
                                                    <option key={org?._id} value={org?._id}>{org?.name}</option>
                                                )}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;

                                    {orgId && <>
                                        <select className='form-control'
                                            value={doctorId}  
                                            onChange={e => setDoctorId(e.target.value)}
                                            style={{width: '200px'}}
                                            >
                                                <option value="undefined" key="15151">Select Doctor</option>   
                                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) =>  
                                                    <option key={doctor?._id} value={doctor?._id}>Dr. {doctor?.lastname}, {doctor?.firstname}</option>
                                                )}
                                        </select>
                                    </> }
                                                        
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>  
                    <br />

                    {/* Patient List Card */}
                        <div className="col-md-12">
                        
                            <table className="styled-table">
                            <thead align="center">
                                <tr>
                                <th>Name & EHR-ID</th>
                                <th>DOB </th>
                                <th>Device Status</th>
                                <th>Devices Assigned</th>
                                <th>Clinician</th>
                                <th>Last Reading & Compl. Status</th>
                                <th>Program Enrolled</th>
                                <th>Action</th> 
                                </tr>
                            </thead>
                            <tbody>
                            
                            {patients && patients.length > 0 ? <> 
                                {patients && patients.map((patient, index) => (
                                <tr key={patient?._id}>

                                <td className="text-center">
                                    <Link className="link" 
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        {patient?.lastname +" , "+ patient?.firstname}
                                    </Link>
                                    <p><b>{patient.emrId || null}</b></p>
                                </td>
                                
                                <td> <b>{moment(patient?.DOB).format("MM-DD-YYYY")}</b> <p>
                                <Badge bg="info text-white" style={{
                                        fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '3px',
                                        letterSpacing: '2px',
                                    }} key={index}>{patient?.gender}</Badge>
                                    </p></td> 
                                
                                <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                        <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                        <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                    {patient?.batterySignals?.sig ? <ProgressBar animated variant="success" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                </td>

                                <td className="text-center">
                                <p>{patient?.assigned_devices.map((device, index) => (
                                    <Badge bg="info text-white" style={{
                                        fontWeight: 500, 
                                        textTransform: 'uppercase', 
                                        padding: '5px',
                                        letterSpacing: '2px',
                                        margin: '2px'
                                    }} key={index}>{device?.deviceObjectId?.deviceType}</Badge>
                                ))}</p> 
                                </td>
                                
                                  
                                    
                                {patient?.assigned_doctor_id ? <>
                                <td>
                                    <Link to={{ pathname: "/doctorProfile", state: {id: patient?.assigned_doctor_id?._id}}} className="link">
                                        Dr. {patient?.assigned_doctor_id?.lastname +" , "+ patient?.assigned_doctor_id?.firstname}
                                    </Link>
                                </td>
                                </> :
                                <td>---</td>
                                }

                                {calculateCompliancyStatus(moment(patient?.lastReading))}    
                        
                                <td>
                                <span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                    <p>{patient?.lastReading ? 
                                        compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                            Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                    Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                    Non Compliant</p> : null : '--'}</p>
                                </td>

                                <td><b>{patient?.patientType === 'Both' ? 'RPM & CCM' : patient?.patientType}</b></td>
                                
                                {/* Actions */}
                                <td>
                                <div className="row-display">    
                                    <Link className="link" 
                                        style={{fontSize: '20px'}}
                                        to={{ pathname: "/patientProfile", state: {patientid: patient?._id, batterySignalStatus: patient?.batterySignals?.sig}}}>
                                        <i className="bx bx-user"></i>
                                    </Link>
                                
                                    <Link className="link" 
                                    style={{fontSize: '20px'}}
                                    to={{pathname: '/Patients/Edit', state: {patientDetails: patient}}}>
                                        <i className="bx bx-edit"></i>
                                    </Link>
                                
                                    <Link className="link" 
                                    style={{color: 'red', fontSize: '20px'}} to="/patients" onClick={() => {setSmShow(true); setPatientModel(patient?._id); setPatientToDelete(patient?.lastname)}}><i className="bx bx-block"></i></Link>     
                                </div>
                                </td>
                            </tr>    
                            ))}
                            </> : <tr key="no-patients">
                                <td colSpan="7" style={{color: 'red', fontWeight: 'bold'}}>Patients Not Found</td>
                            </tr> 
                            }
                            
                            </tbody>
                            </table>

                            
                            <small style={{color: 'gray'}}>Showing {resPerPage} records per page</small> 

                            {/* Pagination */}
                            {patients?.length < count && 
                            <div className="d-flex justify-content-center mt-5"> 
                                <Pagination 
                                    activePage={currentPage} 
                                    itemsCountPerPage={resPerPage} 
                                    totalItemsCount = {count}
                                    onChange={setCurrentPageNumber} 
                                    nextPageText = {'⟩'}
                                    prevPageText = {'⟨'}
                                    firstPageText = {'«'}
                                    lastPageText = {'»'}
                                    itemClass='page-item'
                                    linkClass="page-link"
                                    />           
                            </div>
                        }     

                                <Modal
                                    size="sm"
                                    show={smShow}
                                    onHide={() => setSmShow(false)}
                                    aria-labelledby="example-modal-sizes-title-sm"
                                >
                                    <Modal.Body>
                                        <span>Are you sure you want to Inactivate 
                                            <b> {patientToDelete}</b> ?
                                        </span>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-danger" onClick={deActivatePatient}>Inactive</button>
                                    </Modal.Footer>
                                </Modal>  
                                              
                        </div>
                    </div>
                </>
            )}
            </section>
        </>
    )
}

export default PatientsList
