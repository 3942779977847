import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import MetaData from '../../layouts/MetaData';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import patientProfileImg from "../../assets/Images/patientProfile.png";
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { getHRStats } from '../../actions/HRActions';


const HR = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { message, error } = useSelector(state => state.common);
    const { orgsAssigned, assignedDoctors } = useSelector(state => state.hrStats);
    const [reset, setReset] = useState(false);
    const nurseAssignedDr = localStorage.getItem('nurseSelectedDrs');

    const history = useHistory();
    const alert = useAlert();

    let id = user?._id;
    let nurseDrs = localStorage.getItem('nurseSelectedDrs'); 
   

    // Initialize state with localStorage value or empty array
    const [checkedDrs, setCheckedDrs] = useState(JSON.parse(nurseAssignedDr) || []);

    // Update localStorage and state when checkbox is changed
    const handleCheckboxChange = (e) => {
        const doctorId = e.target.value;
        if (e.target.checked) {
            setCheckedDrs(prevCheckedDrs => [...prevCheckedDrs, doctorId]);
        } else {
            setCheckedDrs(prevCheckedDrs => prevCheckedDrs.filter(id => id !== doctorId));
        }
    };

    // Effect to handle localStorage updates and alert messages
    useEffect(() => {
        // Update localStorage with checkedDrs array
        if(checkedDrs.length > 0){
         localStorage.setItem('nurseSelectedDrs', JSON.stringify(checkedDrs));
        } else {
         localStorage.removeItem('nurseSelectedDrs');   
        }
        
        dispatch(getHRStats(id, nurseDrs, checkedDrs, reset));

        // Display alert messages for error or success
        if (error) {
            alert.error(error);
        }
        if (message) {
            alert.success(message);
        }


    }, [checkedDrs, error, message, alert]);

    
    const resetHandler = () => {
        var confirmation = window.confirm(`Are you sure to reset all selected Doctors ?`);
        if(confirmation){
            localStorage.removeItem('nurseSelectedDrs');   
            setReset(true);   
            setCheckedDrs([]);
            alert.success('Doctors have been reset successfully')
        }
    }

    return (
        <>
            <MetaData title="My Profile" />
            <HRSidebar />

            <section className="home-section">
                <HRTopBar />
                <br />
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        <div>
                            <div className="row-display">

                                <h5 className="pt-2 mt-2 title"> Settings</h5>

                                <div className="row-display">
                                    <button onClick={() => history.goBack()} className="btn btn-secondary btn-sm mt-3">
                                        <i className='bx bx-arrow-back'></i>
                                    </button>
                                </div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="col-md-3">
                                    <div className='text-center'>
                                        <img src={patientProfileImg} className="profile-img" alt="Profile" />
                                        <p className="profile-name">{user?.lastname}, {user?.firstname} </p>
                                        <b className=" m-0 p-0">Role:</b> Nurse
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    <div>
                                        <div className="card-inner-margin">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <span className="profile-label">Email: </span>
                                                    <p className="profile-value-text" style={{ wordWrap: 'break-word' }}>{user?.email}</p>

                                                    <span className="profile-label">Gender: </span>
                                                    <p className="profile-value-text">{user?.gender === 'male' ? 'Male' : 'Female'}</p>

                                                    <span className="profile-label">Program Enrolled For: </span>
                                                    <p className="profile-value-text">{user?.consentRole === 'Both' ? 'RPM & CCM' : user?.consentRole}</p>
                                                </div>

                                                <div className="col-md-4">
                                                    <span className="profile-label">DOB : </span>
                                                    <p className="profile-value-text">{moment(user.DOB).format("MM/DD/YYYY")}</p>

                                                    <span className="profile-label">Phone 1: </span>
                                                    <p className="profile-value-text">{user?.phone1 || 'N/A'}</p>
                                                </div>

                                                <div className="col-md-4">
                                                    <span className="profile-label">Mobile No: </span>
                                                    <p className="profile-value-text">{user?.mobileNo || 'N/A'}</p>

                                                    <span className="profile-label">Account Created Date: </span>
                                                    <p className="profile-value-text">{moment(user?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="container">
                                <h5 className="title"> {'0' + orgsAssigned?.length} Organizations Assigned. </h5>
                                <br />
                                <h5>
                                    <div className="row ml-1">
                                        {orgsAssigned && orgsAssigned?.length && orgsAssigned.map((org, index) => (
                                            <li key={index}><b>{org?.name} &nbsp;&nbsp;&nbsp;</b></li>
                                        ))}
                                    </div>
                                </h5>
                                <hr />

                                <div className="row-display">            
                                    <div>
                                        <h5 className="title"> Select Your working Doctor </h5>
                                        <p><b style={{ color: 'red' }}>Note :</b> You can select multiple doctors to work with. The patients assigned to each doctor would be displayed to you accordingly. </p>
                                    </div>

                                    <button className='edit-pill m-3' 
                                    style={{backgroundColor: 'red'}}
                                    onClick={resetHandler}
                                    >
                                    
                                    Reset All</button>
                                </div>
                                {assignedDoctors && assignedDoctors.map((doctor) => (
                                    <div key={doctor?._id} className="checkbox-container">
                                        <label className="checkbox-wrapper" htmlFor={doctor?._id}>
                                            <input
                                                id={doctor?._id}
                                                type="checkbox"
                                                value={doctor?._id}
                                                checked={checkedDrs.includes(doctor?._id)}
                                                onChange={handleCheckboxChange}
                                            />
                                            <div className="checkbox-custom"></div>
                                            <span className="checkbox-label">
                                                Dr. {doctor?.lastname + ', ' + doctor?.firstname}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HR;
