import {useState, useEffect} from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { getInitialMonthReport } from '../../actions/HRActions';
import {  
    getDoctors
} from '../../actions/adminActions';
import { RESET_INITIAL_MONTH_DATA } from '../../constants/HRConstants';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ExportReactCSV from '../../components/ExportReactCSV';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import Loader from '../../layouts/Loader';

const InitialMonthReport = () => {
    const alert = useAlert();

    const [month, setMonth] = useState('October');
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [resPerPage, setResPerPage] = useState(20);
    const [year, setYear] = useState("2024");

    const {loading, error, initialMonthPatients} = useSelector(state => state.initialMonthReport);
    const { doctors } = useSelector(state => state.doctor);

    const [doctorId, setDoctorId] = useState('');

    useEffect(() => {
        if(error){
            alert.error(error);
            return 
        }
        dispatch(getDoctors(50, currentPage));
    }, [error]);

    const resetHandler = () => {
        dispatch({
            type: RESET_INITIAL_MONTH_DATA
        })
    }

    const generateInitialReport = () => {
        dispatch(getInitialMonthReport(doctorId, month))
    }

  return (
    <>
        <MetaData title="Initial Month Report"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        
        <br/>
        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

                <div className="row-display">
                    <h5 className='title'>
                        <i className="bx bx-edit"></i> &nbsp;Initial Month Report
                    </h5>
                
                    <div className="row-display">
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                                <i className='bx bx-arrow-back'></i>
                            </button>
                        </Link>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard">
                            <button className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                            </button>
                        </Link>
                    </div>   
                </div>
                <hr />
                
                <div className="row-display">
                <div className="col-md-3">
                <label htmlFor="from" className="profile-label">Select Month </label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <option value="SelectMonth">Select Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="year" className="profile-label">Select Year</label>
                        <select 
                            label="From" 
                            name="from" 
                            className="form-control"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            disabled
                        >
                            <option value="SelectYear">Select Year</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="doctor" className="profile-label">Select Provider </label>
                            <select 
                            label="doctor" 
                            name="doctor" 
                            className="form-control"
                            value={doctorId}
                            onChange={(e) => setDoctorId(e.target.value)}
                            >
                            <option value="undefined">Select Provider</option>
                            {doctors && doctors.filter(item => item?.block === false).map((doc, index) => (
                                <option value={doc?._id} key={index}> 
                                    {doc?.lastname +" , "+ doc?.firstname}
                                </option>
                            ))}    
                    </select>
                    </div>


                    <div>
                        <label>&nbsp;</label>
                        <button className="edit-pill mt-4" onClick={generateInitialReport}>Search</button>
                    </div>
                
                </div>

                 {/* Heading */}
                     {loading ? <Loader /> : <>
                     {initialMonthPatients && initialMonthPatients?.data?.length > 0 && (<>
                    <hr />
                    <div className="row-display">
                     <div>
                         <p style={{color: 'gray', fontWeight: 'bold'}}>Results Found: <span style={{color: '#8FBB2C'}}>{initialMonthPatients && initialMonthPatients.length} </span></p>
                     </div>

                    <div>
                        <ExportReactCSV csvData={initialMonthPatients?.data} fileName="InitialMonthPatients.csv" />
                    &nbsp;&nbsp;
                    <button className="reset-btn" onClick={resetHandler}>Reset</button>
                    </div>

                    </div>
                    <br/>
                    </>)}                     
                
                {/* Heading */}


                {initialMonthPatients && initialMonthPatients?.data?.map((initialreport, index) => (
                    <>
                        <div className="row" key={index}>
                           <div className="col-md-1">
                               <span>{index + 1}</span>
                           </div>
                            <div className="col-md-3">
                                <b>Patient Details</b>
                                <br/><br/>
                                <span>EHR-ID: {initialreport?.emrId}</span><br/>
                                <Link style={{color: 'dodgerblue', fontWeight: 'bold'}} 
                                    to={{ pathname: "/patientProfile", state: {patientid: initialreport?._id}}}>
                                    {initialreport?.lastname +" , "+ initialreport?.firstname}
                                </Link><br/>
                                <span>DOB: {moment(initialreport?.DOB).format("MM/DD/YYYY")}</span><br/>
                                <span>Gender: {initialreport?.gender}</span><br/>
                                <span>Email: {initialreport?.email}</span><br/>
                                <span>Phone 1: {initialreport?.phone1}</span> <br/>
                                <strong style={{color: '#004aad'}}>Initial Month: {month} {year}</strong>
                            </div>

                            <div className="col-md-3">
                                <b>Organization Details</b>
                                <br/><br/>
                                <span>Name: {initialreport?.assigned_doctor_id?.lastname +" , "+ initialreport?.assigned_doctor_id?.firstname}</span><br/>
                            </div>

                            <div className="col-md-2">
                                <b>Nurse Details</b>
                                <br/><br/>
                                <span>Name: {initialreport?.assigned_hr_id?.lastname +" , "+ initialreport?.assigned_hr_id?.firstname}</span><br/>
                                <span>Gender: {initialreport?.assigned_hr_id?.gender}</span><br/>
                            </div>

                            <div className="col-md-3">
                                <b>Assigned Device( s ) </b>
                                <br/><br/>
                                {initialreport?.assigned_devices && initialreport?.assigned_devices.map((device, index) => (
                                    <div key={index}>
                                        <span>Device ID: {device?.deviceObjectId?._id}</span><br/>
                                        <span>{device?.deviceObjectId?.deviceType === 'bp' ? 'Cuff Telemetry Device' : device?.deviceObjectId?.deviceType}</span><br/>
                                        <span>IMEI: {device?.deviceObjectId?.imei}</span><br/>
                                        <strong style={{color: '#004aad'}}>Assign Date: {device?.deviceObjectId?.assignedTime || '---'}</strong>
                                        <p><small>( DD / MM / YYYY )</small></p> 
                                        <br/>
                                    </div>
                                    
                                ))}
                                
                            </div>
                        </div>
                        <hr />
                    </>
                ))}
                </>}
            </div>
        </div>


        </section>
    </>
  )
}

export default InitialMonthReport