import { useState, useEffect } from 'react';
import patientProfileImg from '../../assets/Images/user.png';
import womanIcon from '../../assets/Images/woman.png';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import AddTimeManually from '../../components/HR/AddTimeManually';
import { useDispatch, useSelector } from 'react-redux';
import { ADDING_TIME_SPENT_RESET } from '../../constants/HRConstants';
import {useAlert} from 'react-alert';
import AddClinicalFollowUp from './AddClinicalFollowUp';
import PatientSickness from '../Patient/PatientSickness';



const HRPatientOverview = ({patient, totalTime, hrId}) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const history = useHistory();
    const [noteModalShow, setNoteModalShow] = useState(false);
    const [addTimeShow, setAddTimeShow] = useState(false);

    const { isSuccessful } = useSelector(state => state.timeSpent);


    const handleClose = () => setAddTimeShow(false);
    const handleShow = () => setAddTimeShow(true);
    const handleNoteModalShow = () => setNoteModalShow(true);
    const handleNoteModalClose = () => setNoteModalShow(false);
    

    useEffect(() => {
        if(isSuccessful) {
            setAddTimeShow(false);
            // alert.success('Minutes added successfully');       
            dispatch({ type: ADDING_TIME_SPENT_RESET})
        }
    }, [isSuccessful]);


    return (
    <div>
        <div className="row-display">
            <h5 className="title">Patient Overview</h5>

            <div>
                

            <AddClinicalFollowUp patient={patient} />

            </div>

            <div className="row-display">
            &nbsp;&nbsp;
            <button className="btn btn-secondary btn-sm" 
                    onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                </button> &nbsp;
                <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                &nbsp;&nbsp;

                {patient?.assigned_doctor_id?._id === '65b977be420e3eaf115e4317' && <button className='edit-pill' onClick={handleNoteModalShow}>Blanchard B.P Criteria</button> }
                &nbsp;&nbsp;
                <button className="edit-pill" onClick={handleShow}>
                 <i className='bx bx-time pr-2'> </i>
                    Add Time Manually
                </button>
            </div>
        </div>
        <hr />

        <div className="row-display">
            <div className="col-md-6">
                <div className="row">
                    <div className="text-center  col-6" style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
                        <img src={patient?.gender === 'male' ? patientProfileImg : womanIcon} className="patient-profile-card-img" alt="patientProfile" />
                        <h5 className="title mt-2">{patient?.lastname}, {patient?.firstname}</h5>
                        <p><b>{patient?.emrId}</b></p>    

                        <div className="row">
                            <div className='col-6'>
                                <b>D.O.B</b>
                                <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                            </div>

                            <div className='col-6'>
                                <b>Age</b>
                                <p>{moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years</p>
                            </div>
                            <br/>

                            <div className='col-6'>
                                <b>Gender</b>
                                <p>{patient?.gender}</p>
                            </div>

                            <div className='col-6'>
                                <b>Role</b>
                                <p>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</p>
                            </div>

                            <div className='col-12'>
                            {patient?.assigned_devices?.length > 0 ? <>
                                    <b>Device(s) Assigned</b>
                                    {patient?.assigned_devices?.length > 0 && patient?.assigned_devices.map((deviceass, index) => (
                                    <div className="col-12" key={index}>
                                        <b>{deviceass?.deviceObjectId?.deviceType === 'bp' ? <span className='bpDeviceType'>B.P</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'weight' ? <span className='weightDeviceType'>Weight</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'sugar' ? <span className='glucoseDeviceType'>Glucose</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'spO2' ? <span className='spo2DeviceType'>Oximeter</span> :
                                            null
                                        }</b>
                                        <br /><br />
                                    </div>
                                ))}
                                </> : 
                                <div className='col-12'>
                                        <p>No Device Assigned</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <b>Home Address</b>
                        <p className="shrink-text">
                            {patient?.address || null}, {patient?.line2 || null} - {patient?.city || null} - {patient?.state || null} - {patient?.zipCode || null}
                        </p>

                        <b>Primary Phone #</b>
                        <p className="shrink-text">
                            {patient?.phone1 || '--'}
                        </p>

                        <b>Email Address</b>
                        <p className="shrink-text link">
                            {patient?.email || '--'}
                        </p>

                        <b>Insurance Company</b>
                        <p className="shrink-text">
                            {patient?.insurancecompany || '--'}
                        </p>

                        <b>Account Created Date</b>
                        <p className="shrink-text">
                            {moment(patient?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </div>
                </div>
            </div>



            <div className="col-md-3">
                    <div>
                        <PatientSickness 
                            isAuthenticated={true}
                            patient={patient}
                        />
                    </div>
            <br/>
            </div>

            <div className="text-center col-md-3">
            <br/><br/>
                <div className="diseaseSection">
                    <b>Disease(s)</b>
                    <p>{patient?.diseases || '--'}</p>
                </div>

                <p className='text-muted m-0 p-0'>Assigned Doctor</p>
                
                {patient?.assigned_doctor_id ? <>
                    <p>
                    <b>Dr. {patient?.assigned_doctor_id?.lastname || null}, {patient?.assigned_doctor_id?.firstname || null}</b>
                    </p>
                </> : null}
            </div>
        </div>

        {/* Blanchard Modal */}
                {/* Add Special Instruction Modal */}
                <Modal show={noteModalShow} onHide={handleNoteModalClose} size="lg">
            <Modal.Header >
                <h5 className="title">Blanchard B.P Criteria</h5> 
                <button className='edit-pill'onClick={handleNoteModalClose}>
                    <i className='bx bx-x'></i></button>
            </Modal.Header>

                <Modal.Body>
                <table className="styled-table" style={{backgroundColor: '#EDEADE'}}>
                    <thead align="center">
                        <tr>
                            <th width="80px">Blanchard</th>
                            <th width="150px">Systolic</th>
                            <th width="150px">Diastolic</th>
                            <th></th>
                        </tr> 
                    </thead>

                    <tbody>
                       <tr>
                            <td><b>Above</b></td>
                            <td>140</td>
                            <td>90</td>
                            <td>Create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>110</td>
                            <td>60</td>
                            <td>Create ECW Encounter</td>
                        </tr>   

                        <tr>
                            <td><b>Above</b></td>
                            <td>150</td>
                            <td>90</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>170</td>
                            <td>100</td>
                            <td>Call or text provider</td>
                        </tr>    

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call or text provider</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>180</td>
                            <td>110</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>90</td>
                            <td>50</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr> 
                    </tbody>
                </table>      
                </Modal.Body>
            </Modal>
            {/* Blanchard Modal ends */}

            {/* Add Time Manually Modal */}
             {/* Time spent Modal */}
             <Modal size="md" show={addTimeShow} onHide={handleClose}>
                <div className="row-display">    
                    <h5 className="title m-2 p-2">Add Time Manually</h5> 
                    <button className='edit-pill p-2 m-2' onClick={handleClose}>
                        <i className='bx bx-x'></i></button>
                </div>   

                <Modal.Body>
                    <AddTimeManually 
                        totalTime={totalTime} 
                        hrId={hrId} 
                        patientId={patient?._id} 
                        patientType={patient?.patientType} 
                        />   
                </Modal.Body>
            </Modal>
    </div>
  )
}

export default HRPatientOverview