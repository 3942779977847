import { Helmet } from 'react-helmet';


const MetaData = ({title}) => {
    
    title = title !== undefined ? title : 'Home';

    return (
        <Helmet>
            <title>{`${title} - RP Matrix` }</title>
        </Helmet>
    )
}

export default MetaData
