import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import { updateOrganization } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';


const EditOrganization = (props) => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    let orgInfo = props?.location?.state?.orgsDetails;

    const {_id, name, city, country, signature} = orgInfo;

    const [orgName, setOrgName] = useState(name);
    const [orgCity, setOrgCity] = useState(city);
    const [orgCountry, setOrgCountry] = useState(country); 
    const [orgSig, setOrgSig] = useState({});
    
    
    const {message, error } = useSelector(state => state.common);
    

    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
            
        }

        if(message){
            alert.success(message);
            props.history.push('/organizations');

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
            
    }, [dispatch, error, message]);

    const handleFileChange = e => {
        setOrgSig(e.target.files[0]);
    }


    const updateHandler = () => {
        dispatch(updateOrganization(_id, orgName, orgCity, orgCountry, orgSig));
    }

    return (
        <>
            <MetaData title="Edit Organization"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />
              <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                    <div className="row-display">
                        <h5 className='title'>
                            <i className="bx bx-edit"></i> <strong>&nbsp;Edit Organization </strong>
                        </h5>

                        <div>
                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                                <i className='bx bx-home' ></i></Link> &nbsp;

                        </div>
                    </div>
                    <hr />

                        <Formik initialValues={{ }}
                            enableReinitialize={true}
                            onSubmit={updateHandler}
                        >
                        <div>
                            <Form>

                            {/* First Name */}
                            <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label htmlFor="name" className="form-label mt-3">Organization Name</label>
                                    <input
                                        type="text" 
                                        name="name"
                                        className='form-control shadow-none'
                                        placeholder="Organzation Name"
                                        value={orgName}
                                        onChange={(e) => setOrgName(e.target.value)} 
                                    />
                            </div>

                            {/* City */}
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label htmlFor="city" className="form-label mt-3">Organization City</label>
                                    <input
                                        type="text" 
                                        name="city"
                                        className='form-control shadow-none'
                                        placeholder="Organization City"
                                        value={orgCity}
                                        onChange={(e) => setOrgCity(e.target.value)} 
                                    />
                            </div>

                            {/* Country */}
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 rightBorderLine">
                                <label htmlFor="country" className="form-label mt-3">Country</label>
                                    <input
                                        type="text" 
                                        name="country"
                                        className='form-control shadow-none'
                                        placeholder="Organization Country"
                                        value={orgCountry}
                                        onChange={(e) => setOrgCountry(e.target.value)} 
                                    />
                            </div>

                            {/* Signature */}
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            {signature ? <>
                                <label htmlFor="signature" className="form-label">Signature</label>                                       
                                    <img src={`https://www.rpmatrix.com/v1/uploadFiles/${signature}`} 
                                    width='100%' height="80" alt="img" 
                                /> </> : null 
                            }

                                    <br />        
                                    <label htmlFor="signature" className="form-label">Upload Signature</label>
                                    <input  
                                        name="orgSig" 
                                        type="file" 
                                        accept=".png"
                                        onChange={handleFileChange}
                                    />
                            </div>
                        </div> 



                        <br/>
                        {/* Buttons */}
                        <div className="row mr-3" style={{ float: 'right'}}>
                            <button className="edit-pill ml-3" type="submit" >Update</button>
                        </div>

                        <br/><br/><br/>
                    </Form>
                </div>
                </Formik>

                    
                    </div>
                </div>
                </> 
                </section>
        </>
    )
}

export default EditOrganization
