export const ALL_PATIENTS_REQUEST = 'ALL_PATIENTS_REQUEST';
export const ALL_PATIENTS_SUCCESS = 'ALL_PATIENTS_SUCCESS';
export const ALL_PATIENTS_FAIL = 'ALL_PATIENTS_FAIL';

export const ALL_DOCTORS_REQUEST = 'ALL_DOCTORS_REQUEST';
export const ALL_DOCTORS_SUCCESS = 'ALL_DOCTORS_SUCCESS';
export const ALL_DOCTORS_FAIL = 'ALL_DOCTORS_FAIL';

export const DOCTOR_PROFILE_REQUEST = 'DOCTOR_PROFILE_REQUEST';
export const DOCTOR_PROFILE_SUCCESS = 'DOCTOR_PROFILE_SUCCESS'; 
export const DOCTOR_PROFILE_FAIL = 'DOCTOR_PROFILE_FAIl';

export const DOCTOR_PATIENTS_REQUEST = 'DOCTOR_PATIENTS_REQUEST';
export const DOCTOR_PATIENTS_SUCCESS = 'DOCTOR_PATIENTS_SUCCESS'; 
export const DOCTOR_PATIENTS_FAIL = 'DOCTOR_PATIENTS_FAIL';

export const PATIENT_PROFILE_REQUEST = 'PATIENT_PROFILE_REQUEST';
export const PATIENT_PROFILE_SUCCESS = 'PATIENT_PROFILE_SUCCESS'; 
export const PATIENT_PROFILE_FAIL = 'PATIENT_PROFILE_FAIl';

export const RESET_ASSIGN_PATIENT_TO_HR = 'RESET_ASSIGN_PATIENT_TO_HR';

export const ASSIGN_DEVICE_TO_PATIENT_REQUEST = 'ASSIGN_DEVICE_TO_PATIENT_REQUEST';
export const ASSIGN_DEVICE_TO_PATIENT_SUCCESS = 'ASSIGN_DEVICE_TO_PATIENT_SUCCESS';
export const ASSIGN_DEVICE_TO_PATIENT_FAIL = 'ASSIGN_DEVICE_TO_PATIENT_FAIL';

export const GET_PATIENT_DEVICE_DATA_REQUEST = 'GET_PATIENT_DEVICE_DATA_REQUEST';
export const GET_PATIENT_DEVICE_DATA_SUCCESS = 'GET_PATIENT_DEVICE_DATA_SUCCESS';
export const GET_PATIENT_DEVICE_DATA_FAIL = 'GET_PATIENT_DEVICE_DATA_FAIL';

export const GET_DEVICES_LIST_REQUEST = 'GET_DEVICES_LIST_REQUEST';
export const GET_DEVICES_LIST_SUCCESS = 'GET_DEVICES_LIST_SUCCESS';
export const GET_DEVICES_LIST_FAIL = 'GET_DEVICES_LIST_FAIL';

export const GET_DEVICE_DETAILS_REQUEST = 'GET_DEVICE_DETAILS_REQUEST';
export const GET_DEVICE_DETAILS_SUCCESS = 'GET_DEVICE_DETAILS_SUCCESS';
export const GET_DEVICE_DETAILS_FAIL = 'GET_DEVICE_DETAILS_FAIL';

export const ADD_RPM_DEVICE_RESET = 'ADD_RPM_DEVICE_RESET';

export const SORT_DEVICES_REQUEST = 'SORT_DEVICES_REQUEST';
export const SORT_DEVICES_SUCCESS = 'SORT_DEVICES_SUCCESS';
export const SORT_DEVICES_FAIL = 'SORT_DEVICES_FAIL';


export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL';
export const UPDATE_PATIENT_RESET = 'UPDATE_PATIENT_REQUEST';

export const ALL_HRS_REQUEST = 'ALL_HRS_REQUEST';
export const ALL_HRS_SUCCESS = 'ALL_HRS_SUCCESS';
export const ALL_HRS_FAIL = 'ALL_HRS_FAIL';

export const ASSIGN_DOCTOR_TO_HR_REQUEST = 'ASSIGN_DOCTOR_TO_HR_REQUEST';
export const ASSIGN_DOCTOR_TO_HR_SUCCESS = 'ASSIGN_DOCTOR_TO_HR_SUCCESS';
export const ASSIGN_DOCTOR_TO_HR_FAIL = 'ASSIGN_DOCTOR_TO_HR_FAIL';

export const ADMIN_STATS_SUCCESS = 'ADMIN_STATS_SUCCESS';
export const ADMIN_STATS_FAIL = 'ADMIN_STATS_FAIL';

export const INVENTORY_STATS_SUCCESS = 'INVENTORY_STATS_SUCCESS';
export const INVENTORY_STATS_FAIL = 'INVENTORY_STATS_FAIL'

export const GET_ADMIN_NOTIFICATIONS_REQUEST = 'GET_ADMIN_NOTIFICATIONS_REQUEST';
export const GET_ADMIN_NOTIFICATIONS_SUCCESS = 'GET_ADMIN_NOTIFICATIONS_SUCCESS';
export const GET_ADMIN_NOTIFICATIONS_FAIL = 'GET_ADMIN_NOTIFICATIONS_FAIL';

export const GET_LOGS_REQUEST = "GET_LOGS_REQUEST";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_FAIL = "GET_LOGS_FAIL"

export const GET_PATIENT_REMAINING_READINGS = 'GET_PATIENT_REMAINING_READINGS';
export const GET_PATIENT_REMAINING_READINGS_FAIL = 'GET_PATIENT_REMAINING_READINGS_FAIL';

export const HR_PROFILE_SUCCESS = "HR_PROFILE_SUCCESS";
export const HR_PROFILE_FAIL = "HR_PROFILE_FAIL";

export const GET_CAREPLAN_LIST_REQUEST = 'GET_CAREPLAN_LIST_REQUEST';
export const GET_CAREPLAN_LIST_SUCCESS = 'GET_CAREPLAN_LIST_SUCCESS';
export const GET_CAREPLAN_LIST_FAIL = 'GET_CAREPLAN_LIST_FAIL'; 

export const GET_DOCTOR_TELEMETARY_REPORT_REQUEST = "GET_DOCTOR_TELEMETARY_REPORT_REQUEST";
export const GET_DOCTOR_TELEMETARY_REPORT_SUCCESS = "GET_DOCTOR_TELEMETARY_REPORT_SUCCESS";
export const GET_DOCTOR_TELEMETARY_REPORT_FAIL = "GET_DOCTOR_TELEMETARY_REPORT_FAIL";
export const GET_DOCTOR_TELEMETARY_REPORT_RESET = "GET_DOCTOR_TELEMETARY_REPORT_RESET";

export const TIME_SUMMARY_REPORT_REQUEST = 'TIME_SUMMARY_REPORT_REQUEST';
export const TIME_SUMMARY_REPORT_SUCCESS = 'TIME_SUMMARY_REPORT_SUCCESS';
export const TIME_SUMMARY_REPORT_FAIL = 'TIME_SUMMARY_REPORT_FAIL';
export const TIME_SUMMARY_REPORT_RESET = 'TIME_SUMMARY_REPORT_RESET';

export const SEARCH_LOG_REQUEST = 'SEARCH_LOG_REQUEST';
export const SEARCH_LOG_SUCCESS = 'SEARCH_LOG_SUCCESS';
export const SEARCH_LOG_FAIL = 'SEARCH_LOG_FAIL';
export const SEARCH_LOG_RESET = 'SEARCH_LOG_RESET';

export const ALL_ADMINS_REQUEST = 'ALL_ADMINS_REQUEST';
export const ALL_ADMINS_SUCCESS = 'ALL_ADMINS_SUCCESS';
export const ALL_ADMINS_FAIL = 'ALL_ADMINS_FAIL';

export const DEVICE_SIGNAL_HISTORY_FETCH_SUCCESS = 'DEVICE_SIGNAL_HISTORY_FETCH_SUCCESS';
export const DEVICE_SIGNAL_HISTORY_FETCH_FAIL = 'DEVICE_SIGNAL_HISTORY_FETCH_FAIL';

export const FINANCIAL_REPORT_HISTORY_REQUEST = 'FINANCIAL_REPORT_HISTORY_REQUEST';
export const FINANCIAL_REPORT_HISTORY_SUCCESS = 'FINANCIAL_REPORT_HISTORY_SUCCESS';
export const FINANCIAL_REPORT_HISTORY_FAIL = 'FINANCIAL_REPORT_HISTORY_FAIL';
export const FINANCIAL_REPORT_HISTORY_RESET = 'FINANCIAL_REPORT_HISTORY_RESET';

export const ALL_INACTIVE_PATIENTS_REQUEST = 'ALL_INACTIVE_PATIENTS_REQUEST';
export const ALL_INACTIVE_PATIENTS_SUCCESS = 'ALL_INACTIVE_PATIENTS_SUCCESS';
export const ALL_INACTIVE_PATIENTS_FAIL = 'ALL_INACTIVE_PATIENTS_FAIL';

export const DEVICE_HISTORY_REQUEST = 'DEVICE_HISTORY_REQUEST';
export const DEVICE_HISTORY_SUCCESS = 'DEVICE_HISTORY_SUCCESS';
export const DEVICE_HISTORY_FAIL = 'DEVICE_HISTORY_FAIL';

export const RPM_CRITICAL_DATA_SUCCESS = 'RPM_CRITICAL_DATA_SUCCESS';

export const RECENT_READINGS_SUCCESS = 'RECENT_READINGS_SUCCESS';

export const DOCTOR_STATS_SUCCESS = 'DOCTOR_STATS_SUCCESS';
export const DOCTOR_STATS_FAIL = 'DOCTOR_STATS_FAIL';

export const ALL_TARGETS_REQUEST = 'ALL_TARGETS_REQUEST';
export const ALL_TARGETS_SUCCESS = 'ALL_TARGETS_SUCCESS';
export const ALL_TARGETS_FAIL = 'ALL_TARGETS_FAIL';

export const MONTHLY_WISE_STATS_SUCCESS = 'MONTHLY_WISE_STATS_SUCCESS';
export const MONTHLY_WISE_STATS_FAIL = 'MONTHLY_WISE_STATS_FAIL';

export const NURSE_PROGRESS_STATS_SUCCESS = 'NURSE_PROGRESS_STATS_SUCCESS';
export const NURSE_PROGRESS_STATS_FAIL = 'NURSE_PROGRESS_STATS_FAIL';

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const MONTHLY_TELEMETRY_DATA_REQUEST = 'MONTHLY_TELEMETRY_DATA_REQUEST';
export const MONTHLY_TELEMETRY_DATA_SUCCESS = 'MONTHLY_TELEMETRY_DATA_SUCCESS';
export const MONTHLY_TELEMETRY_DATA_FAIL = 'MONTHLY_TELEMETRY_DATA_FAIL';
export const MONTHLY_TELEMETRY_DATA_RESET = 'MONTHLY_TELEMETRY_DATA_RESET';

export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';

export const CALL_REPORT_REQUEST = 'CALL_REPORT_REQUEST';
export const CALL_REPORT_SUCCESS = 'CALL_REPORT_SUCCESS';
export const CALL_REPORT_FAIL = 'CALL_REPORT_FAIL';
export const CALL_REPORT_RESET = 'CALL_REPORT_RESET';

export const PATIENT_ENCOUNTER_SUCCESS = 'PATIENT_ENCOUNTER_SUCCESS';
export const PATIENT_ENCOUNTER_FAIL = 'PATIENT_ENCOUNTER_FAIL';

export const CALC_AVG_START = 'CALC_AVG_START';
export const CALC_AVG_END = 'CALC_AVG_END';
export const CALC_AVG_ERROR = 'CALC_AVG_ERROR'

export const GET_PATIENT_CHAT_REQUEST = 'GET_PATIENT_CHAT_REQUEST';
export const GET_PATIENT_CHAT_SUCCESS = 'GET_PATIENT_CHAT_SUCCESS';
export const GET_PATIENT_CHAT_FAIL = 'GET_PATIENT_CHAT_FAIL';

export const GET_ORGANIZATIONS_PROFILE_REQUEST = 'GET_ORGANIZATIONS_PROFILE_REQUEST';
export const GET_ORGANIZATIONS_PROFILE_SUCCESS = 'GET_ORGANIZATIONS_PROFILE_SUCCESS';
export const GET_ORGANIZATIONS_PROFILE_FAIL = 'GET_ORGANIZATIONS_PROFILE_FAIL';

export const AVG_BP_REQUEST = 'AVG_BP_REQUEST';
export const AVG_BP_SUCCESS = 'AVG_BP_SUCCESS';
export const AVG_BP_FAIL = 'AVG_BP_FAIL';

export const CCM_CAREPLAN_MANUAL_REQUEST = "CCM_CAREPLAN_MANUAL_REQUEST";
export const CCM_CAREPLAN_MANUAL_SUCCESS = "CCM_CAREPLAN_MANUAL_SUCCESS";
export const CCM_CAREPLAN_MANUAL_FAIL = "CCM_CAREPLAN_MANUAL_FAIL";

export const CLEAR_ERRORS = 'CLEAR_ERRORS';


