import { useEffect } from 'react';
import Sidebar  from '../../components/AdminDashboard/Sidebar';
import TopBar  from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { Link, useHistory } from 'react-router-dom';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../components/Form/TextField';
import { addOrganization } from '../../actions/adminActions';
import { useAlert } from 'react-alert';

const AddNewOrganizations = () => {
  
    const history = useHistory();
    const dispatch = useDispatch(); 
    const alert = useAlert();

    const {message, error } = useSelector(state => state.common);


    const validate = Yup.object().shape({
		name: Yup.string()
        .required('Name is required')
        .min(2, 'Should be atleast 2 characters')
        .max(40, 'Should be less than 40 characters'),
		city: Yup.string() 
		  .min(2, 'Should be atleast 2 characters')
		  .max(25, 'Should be less than 25 characters')
		  .required('City is Required'),
        country: Yup.string() 
        .min(2, 'Should be atleast 2 characters')
        .max(25, 'Should be less than 25 characters')
        .required('Country is Required'),

	  });

      useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            alert.success(message);
            history.push('/organizations');

            dispatch({
              type: HIDE_ALERT_MESSAGE
            })
        }

}, [dispatch, alert, error, message]);

    const submitHandler = (values) => {
        dispatch(addOrganization(values));
    }
  
    return (
    <>
        <MetaData title="Add New organization "/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            <br/>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                        <div className="row-display">
                            
                        <h5 style={{color: '#00A8A8'}}>
                            <i className="bx bx-user"></i> <strong>&nbsp;Add New Organization </strong>
                        </h5>

                            <div className="row-display">
                                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                        <i className='bx bx-arrow-back'></i>
                                    </button>
                                &nbsp;&nbsp;
                                <Link to="/adminDashboard">
                                    <button className="btn btn-secondary btn-sm">
                                        <i className='bx bxs-home'></i>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <hr />

                        <Formik initialValues={{ 
                            name: '',
                            city: '',
                            country: '',
                        }}
                         validationSchema={validate}
                         onSubmit={values => {
                            submitHandler(values)
                        }}
                         >   
                             <div>
                             <Form>
                                 <div className="row">
                                     {/* First Name */}
                                     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                         <TextField 
                                             label="Name" 
                                             name="name" 
                                             type="text" 
                                             placeholder="Organization Name"
                                             compulsory={true}
                                         />
                                     </div>

                                     {/* Last Name */}
                                     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                         <TextField 
                                             label="City" 
                                             name="city" 
                                             type="text" 
                                             placeholder="Organization City"
                                             compulsory={true}
                                         />
                                     </div>

                                     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                         <TextField 
                                             label="Country" 
                                             name="country" 
                                             type="text" 
                                             placeholder="Organization Country"
                                             compulsory={true}
                                         />
                                     </div>
                                    </div> 

                                    <br />
                                    {/* Buttons */}
                                    <div className="row mr-3" style={{ float: 'right'}}>
                                        <button className="edit-pill" style={{backgroundColor: 'red', color: 'white'}} type="reset">Reset</button> &nbsp;&nbsp;
                                        <button className="edit-pill" type="submit">Add Organization</button>
                                    </div>

                                    <br/><br/>
                                </Form>
                            </div>   
                            </Formik>
                    </div>
                </div>
            </section>
        </>
  )
}

export default AddNewOrganizations