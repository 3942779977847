import { useState, useEffect } from "react";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import {useAlert } from 'react-alert';
import { getPtCCMCareplanManual } from '../../actions/adminActions';
import Loader from '../../layouts/Loader';
import {addClinicalFollowUp, addCCMCareplanFollowUp} from '../../actions/HRActions';



const AddClinicalFollowUp = ({patient}) => {

    const dispatch = useDispatch();
    const alert = useAlert();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [category, setCategory] = useState("clinicalFollowUp");

    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState('2024');

    const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));

    const {loading, error, ccmCareplan} = useSelector(state => state.ccmCareplanManual);
    const {user} = useSelector(state => state.auth);

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;

    const matchingClinicalFollowUp = patient?.clinicalFollowUp?.some(({ day, month, year, completed }) => {
        return (
            completed === true && (
                (month === parseInt(startMonth, 10) && year === year && day >= 26 && day <= 31) ||
                (month === parseInt(endMonth, 10) && year === year && day >= 1 && day <= 25)
            )
        );
    });
    

    const matchingCareplanFollowUp = patient?.ccmCareplanFollowUp?.some(({ day, month, year, completed }) => {
        return (
            completed === true && (
                (month === parseInt(startMonth, 10) && year === year && day >= 26 && day <= 31) ||
                (month === parseInt(endMonth, 10) && year === year && day >= 1 && day <= 25)
            )
        );
    });

    // const matchingClinicalFollowUp = patient?.clinicalFollowUp?.some(({ day, month, year, completed }) => {
    //     if (currentDay >= 26 && currentDay <= 31) {
    //         return (month === currentMonth && year === currentYear && completed === true && day >= 26) || 
    //        (month === currentMonth + 1 && year === currentYear && completed === true && day <= 26);
    //     } else {
    //         return (month === currentMonth && year === currentYear && completed === true && day <= 26) || 
    //        (month === currentMonth - 1 && year === currentYear && completed === true && day >= 1);
    //     }
    // });



    // const matchingCareplanFollowUp = patient?.ccmCareplanFollowUp?.some(({ day, month, year, completed }) => {
    //     if (currentDay >= 26 && currentDay <= 31) {
    //         return (month === currentMonth && year === currentYear && completed === true && day >= 26) || 
    //        (month === currentMonth + 1 && year === currentYear && completed === true && day <= 26);
    //     } else {
    //         return (month === currentMonth && year === currentYear && completed === true && day <= 26) || 
    //        (month === currentMonth - 1 && year === currentYear && completed === true && day >= 1);
    //     }
    // });


    // Form States
    const [healthManagement, setHealthManagement] = useState('');
    const [isNewProblem, setIsNewProblem] = useState("no");
    const [newProblemDetails, setNewProblemDetails] = useState("");
    const [isWorseningConditions ,setIsWorseningConditions] = useState("no");
    const [worseningConditionsDetails ,setWorseningConditionsDetails] = useState("");
    const [isMedicationProblems, setIsMedicationProblems] = useState("no");
    const [medicationProblemsDetails, setMedicationProblemsDetails] = useState("");
    const [additionalNotes, setAdditionalNotes] = useState("");
    const [isRecentFall, setIsRecentFall] = useState("no");
    const [recentFallDetails, setRecentFallDetails] = useState("");
    const [isRecentlyHospitalized, setIsRecentlyHospitalized] = useState("no");
    const [recentlyHospitalizedDetails, setRecentlyHospitalizedDetails] = useState("");
    const [medicationRefillRequest, setMedicationRefillRequest] = useState("no");
    const [medicationRefillRequestDetails, setMedicationRefillRequestDetails] = useState("");


    useEffect(() => {
      if(error){
        alert.error(error)
      }

      {patient?.ccmCareplanManualAdded === true &&
        dispatch(getPtCCMCareplanManual(patient?._id));
      }
    }, [error, category])

    
    const handleClinicalFollowUpSubmission = () => {
        const formData = {
            healthManagement,
            isNewProblem,
            newProblemDetails,
            isWorseningConditions,
            worseningConditionsDetails,
            isMedicationProblems,
            medicationProblemsDetails,
            additionalNotes,
            isRecentFall,
            recentFallDetails,
            isRecentlyHospitalized,
            recentlyHospitalizedDetails,
            medicationRefillRequest,
            medicationRefillRequestDetails
        }

        dispatch(addClinicalFollowUp(patient?._id, user?._id, formData, month, year, currentDay));
    } 

    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (ccmCareplan && patient?.ccmCareplanManualAdded === true && ccmCareplan.length > 0) {
            const initialNotes = ccmCareplan[0]?.diseases.map(disease => ({
                diseaseName: disease.name,
                shortTermGoals: disease?.shortTermGoals.map(goal => goal.goalname),
                shortTermNote: "",  // Initialize as empty string
                longTermGoals: disease?.longTermGoals.map(goal => goal.goalname),
                longTermNote: "",   // Initialize as empty string
                interventions: disease?.interventions.map(goal => goal.goalname),
                interventionNote: "" // Initialize as empty string
            }));
            setNotes(initialNotes);
        }        
        
    }, [ccmCareplan]);


    const handleNoteChange = (value, index, noteType) => {
        setNotes(prevNotes => {
            const updatedNotes = [...prevNotes];
            updatedNotes[index][noteType] = value;
            return updatedNotes;
        });
    };

    const handleSubmit = () =>  {
        dispatch(addCCMCareplanFollowUp(patient?._id, user?._id, notes, month, year, currentDay));
    }

    return (
    <div>
        <button className="edit-pill" onClick={handleShow}>
            <i className='bx bx-time pr-2'> </i>
            Add Complete Call FollowUp
        </button>

        <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
        <div>
          <h5 className="title mt-2">Complete Call FollowUp</h5>
          {currentDay >= 26 && currentDay <= 31 ? <>
                <p className="text-muted"><b>Span of :</b>  {'26-' + currentMonth + '-' + year + ' to 25-' + (currentMonth + 1) + '-' + year}</p>
            </> : <>
                <p className="text-muted">Span of {'26-' + (currentMonth - 1) + '-' + year + ' to 25-' + (currentMonth) + '-' + year}</p>
            </>}
          </div>
          <button className="edit-pill" onClick={handleClose}><i className='bx bx-x'></i></button>
        </Modal.Header>
        <Modal.Body>

        
        <div className="row-display">
                <div>
                    <h5 className="title">{patient?.lastname}, {patient?.firstname}</h5>
                    <p><b>{patient?.emrId}</b></p>
                </div>

                <div>
                    <p className="form-label m-0 p-0">Date of Birth (D.O.B): </p>
                    <b>{moment(patient?.DOB).format('MM/DD/YYYY')}</b>

                    <br /><br />
                    <p className="form-label m-0 p-0">Program Enrolled: </p>
                    <b>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</b>
                </div>


                {patient?.ccmCareplanManualAdded === true ? <>
                {ccmCareplan && ccmCareplan.map((plan, index) => (
                <div key={index}>
                    <p className="form-label m-0 p-0">Careplan Created By: </p>
                    <b> {plan?.addedByAdmin ? plan?.addedByAdmin?.name : plan?.addedByNurse?.lastname +' , '+ plan?.addedByNurse?.firstname} </b>

                       
                    
                    <br /><br />
                    <p className="form-label m-0 p-0">Careplan Created Date: </p>
                       <b> {plan?.addedDateTime} </b>    
                </div>
                ))}

                </> : null}


            </div>
            
            <br /><br />
            <div className='container row-display'>
                <button className={`${category === 'clinicalFollowUp' ? 'tabsBtnActive' : 'tabsBtn'}`}
                    onClick={() => setCategory('clinicalFollowUp')}>Clinical FollowUp
                </button>
                
                <button className={`${category === 'ccmCareplanFollowUp' ? 'tabsBtnActive' : 'tabsBtn'}`}
                    onClick={() => setCategory('ccmCareplanFollowUp')}> CCM Careplan FollowUp
                </button>
               
            </div>

        {category !== "ccmCareplanFollowUp" && !matchingClinicalFollowUp ? <>
            <hr className="blue-hr" />
            <h5 className="title">Clinical FollowUp</h5>

            <div>    
            <label className="form-label">How Have You Been Managing Your Health ? </label>
            <input 
            value={healthManagement}
            onChange={e => setHealthManagement(e.target.value)}
            type="text" className="form-control" placeholder=". . . " />
        </div>

        <br />
        <div className="row-display">
            <div>
            <label className="form-label">Any New Problems or Conditions ? </label>
            <select 
                value={isNewProblem}
                onChange={e => setIsNewProblem(e.target.value)}
                className="form-control" placeholder=". . . " style={{width:'270px'}}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>

            <div>    
                <label className="form-label">New Problem or Condition Details ? </label>
                <input 
                value={newProblemDetails}
                onChange={e => setNewProblemDetails(e.target.value)}
                type="text" className="form-control" placeholder=". . . " style={{width: '500px'}} />
            </div>
            &nbsp;
        </div>

        <br />
        <div className="row-display">
            <div>
            <label className="form-label">Any Worsening of Conditions / Problems ? </label>
            <select className="form-control"
            value={isWorseningConditions}
            onChange={e => setIsWorseningConditions(e.target.value)}
             style={{width:'270px'}}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>

            <div>    
                <label className="form-label">Worsening of Conditions / Problems Details ? </label>
                <input type="text" className="form-control"
                 value={worseningConditionsDetails}
                 onChange={e => setWorseningConditionsDetails(e.target.value)}
                 placeholder=". . . " 
                 style={{width: '500px'}} />
            </div>
        </div>
        <br />

        &nbsp;
        <div className="row-display">
            <div>
            <label className="form-label">Medication Problems Identified ? </label>
            <select className="form-control" 
            value={isMedicationProblems}
            onChange={e => setIsMedicationProblems(e.target.value)}
            style={{width:'270px'}}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>

            <div>    
                <label className="form-label">Medication Problems Details ? </label>
                <input type="text" className="form-control"
                value={medicationProblemsDetails}
                onChange={e => setMedicationProblemsDetails(e.target.value)}
                placeholder=". . . " style={{width: '500px'}} />
            </div>
        </div>
        <br />

        <div>
            <label className="form-label">Any Additional Notes ? </label>
            <input type="text" className="form-control"
            value={additionalNotes}
            onChange={e => setAdditionalNotes(e.target.value)}
            placeholder=". . ." />
        </div>

        <br />
        <div className="row-display">
            <div>
            <label className="form-label">Recent Fall in last 30 days ? </label>
            <select className="form-control" 
            value={isRecentFall}
            onChange={e => setIsRecentFall(e.target.value)}
            >
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>
            &nbsp;
            <div>    
                <label className="form-label">Recent Fall Details ? </label>
                <input type="text" className="form-control" 
                value={recentFallDetails}
                onChange={e => setRecentFallDetails(e.target.value)}
                placeholder=". . . " style={{width: '500px'}} />
            </div>
        </div>

        <br />
        <div className="row-display">
            <div>
            <label className="form-label">Recent Hospitalized / ED Visit ? </label>
            <select className="form-control" 
            value={isRecentlyHospitalized}
            onChange={e => setIsRecentlyHospitalized(e.target.value)}
            >
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>
            &nbsp;

            <div>    
                <label className="form-label">Recent Hospitalized / ED Details ? </label>
                <input type="text" className="form-control" 
                value={recentlyHospitalizedDetails}
                onChange={e => setRecentlyHospitalizedDetails(e.target.value)}
                placeholder=". . . " style={{width: '500px'}} />
            </div>
        </div>

        <br />
        <div className="row-display">
            <div>
            <label className="form-label">Medication Refill Request ? </label>
            <select className="form-control" 
                value={medicationRefillRequest} 
                onChange={e => setMedicationRefillRequest(e.target.value)}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
            </div>
            &nbsp;
            <div>    
                <label className="form-label">Medication Refill Details ? </label>
                <input type="text" className="form-control" 
                placeholder=". . . " 
                value={medicationRefillRequestDetails}
                onChange={e => setMedicationRefillRequestDetails(e.target.value)}
                style={{width: '500px'}} />
            </div>
        </div>
        
        <br />    
        <div className="ml-2" style={{float: 'right'}}>
                <label className="form-label">{" "}</label>
                <button style={{backgroundColor: 'green'}} className="edit-pill" onClick={handleClinicalFollowUpSubmission}>
                    + Submit Clinical FollowUp Only
                </button>
        </div>
        <br /><br />
        </> : null
        }


        
        {category !== "clinicalFollowUp" && patient?.ccmCareplanManualAdded === true && !matchingCareplanFollowUp ? <>
            <hr className="blue-hr" />
        <h5 className="title">CCM Careplan FollowUp</h5>

        {loading ? <Loader /> : <> 
        {ccmCareplan && ccmCareplan.map((plan, planIndex) => (
            <div key={planIndex}>
                {plan?.diseases && plan?.diseases.map((disease, diseaseIndex) => (
                <div key={diseaseIndex}>
                    <br />
                        <b className="coloredRow p-2 mt-2">Disease {diseaseIndex + 1} : {disease.name} </b> <br /><br />
                    

                    <h5 className="coloredUnderline"><b>Short Term Goals</b></h5>
                    {disease.shortTermGoals.map((goal, goalIndex) => (
                    <div key={goalIndex}>
                        <b>{goalIndex + 1 + ' ) ' + goal?.goalname}</b>
                        <span className="row ml-1">
                        <p>{goal?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                        </span>
                    </div>
                    ))}

                        <div>
                            <label className="form-label">Short Term Goals Note</label>
                            <textarea
                                placeholder="Enter note for short-term goals"
                                className="form-control"
                                value={notes.shortTermNote}
                                rows={4}
                                cols={4}
                                onChange={(e) => handleNoteChange(e.target.value, diseaseIndex, 'shortTermNote')}
                            ></textarea>
                        </div>
                    
                    <br />
                    <h5 className="coloredUnderline"><b>Long Term Goals</b></h5>
                    {disease.longTermGoals.map((goal, goalIndex) => (
                    <div key={goalIndex}>
                        <b>{goalIndex + 1 + ' ) ' + goal?.goalname}</b>
                        <span className="row ml-1">
                        <p>{goal?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                        </span>
                    </div>
                    ))}

                    <label className="form-label">Notes: </label>
                    <textarea
                    className="form-control"
                    rows={4}
                    cols={4}
                    placeholder="Add notes ..."
                    value={notes.longTermNote}
                    onChange={(e) => handleNoteChange(e.target.value, diseaseIndex, 'longTermNote')}
                    />

                    <br /><br />

                    <h5 className="coloredUnderline"><b>Interventions</b></h5>
                    {disease.interventions.map((goal, goalIndex) => (
                    <div key={goalIndex}>
                        <b>{goalIndex + 1 + ' ) ' + goal?.goalname}</b>
                        <span className="row ml-1">
                        <p>{goal?.addedDate}</p> &nbsp;&nbsp;&nbsp;
                        </span>
                    </div>
                    ))}
                    <label className="form-label">Notes: </label>
                    <textarea
                        className="form-control"
                        rows={4}
                        cols={4}
                        placeholder="Add notes ..."
                        value={notes.interventionNote}
                        onChange={(e) => handleNoteChange(e.target.value, diseaseIndex, 'interventionNote')}
                    />
                </div>
                ))}
            </div>
            ))}
            <br />
            </>}    
            
            <br />    
            <div className="ml-2" style={{float: 'right'}}>
                    <label className="form-label">{" "}</label>
                    <button style={{backgroundColor: 'green'}} className="edit-pill" onClick={handleSubmit}>
                        + Submit Careplan FollowUp Only
                    </button>
            </div>
            <br /><br /><br />
        </> : null}
        

        {/* <Modal.Footer>
            <button className="edit-pill" onClick={handleClinicalFollowUpSubmission}>
                Submit Complete Form
            </button>
        </Modal.Footer> */}



        </Modal.Body>
        </Modal>
    </div>
  )
}

export default AddClinicalFollowUp