import { useEffect, useState } from 'react';
import MetaData from '../../layouts/MetaData';
import  HRSidebar from '../../components/HR/HRSidebar';
import HRTopBar from '../../components/HR/HRTopbar';
import { getHRPatients } from '../../actions/HRActions';
import { useSelector, useDispatch } from 'react-redux';
import folderImg from '../../assets/Images/folder.png';
import Loader from '../../layouts/Loader';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import { useAlert} from 'react-alert';
import { Badge } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { 
    HR_PATIENTS_REQUEST,
    HR_PATIENTS_SUCCESS,
    HR_PATIENTS_FAIL,
} from '../../constants/HRConstants';
import { Prod01 } from '../../constants/ActionType';
import axios from 'axios';


const HRPatientsList = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    let compliantStatus; // variable to store compliant status of patient

    const { loading, error, hrpatients, count} = useSelector(state => state.hrPatients);
    const [pagination, setPagination] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [input, setInput] = useState("");

    let nurseDrs = localStorage.getItem('nurseSelectedDrs');   
    let countLength = JSON.parse(nurseDrs);

    useEffect(() => {
		if(error) {
			alert.error(error);
		}

        if(nurseDrs && countLength.length > 0){
            dispatch(getHRPatients(nurseDrs, pagination, currentPage));
        }
        
	}, [pagination, currentPage])

    console.log(countLength.length);

    function setCurrentPageNumber(pageNumber) {
        setCurrentPage(pageNumber);
    }

    function calculateCompliancyStatus(date) {  
        var m = moment(date);
        var today = moment().startOf('day');
        var days = moment.duration(today - m).asDays();

        switch(true){
            case (days >= 0 && days < 3):
                compliantStatus = 'compliant'
                break;

            case (days >= 3 && days <= 7):
                compliantStatus = "mostLikelyCompliant"
                break;

            case (days >= 8 && days <= 20):
                compliantStatus = "notLikelyCompliant"
                break;

            case (days >= 8 && days <= 31):
                compliantStatus = "nonCompliant"
                break;
            
            default:
                compliantStatus = 'nonCompliant'
                break;
        }
    }

    const refreshhandler =() => {
        if(nurseDrs && countLength?.length > 0){
            dispatch(getHRPatients(nurseDrs, pagination, currentPage));
            setInput("")
        }
    }


    const searchHandler = async() => {
        if(input?.length > 0){
            try {
                dispatch({
                    type: HR_PATIENTS_REQUEST,
                })
        
                await axios.post(`${Prod01}/patient/rpm/search/${pagination}/${currentPage}`, {
                        value: input,
                        nurseDrs
                    })
                .then((result) => {
                    dispatch({
                        type: HR_PATIENTS_SUCCESS,
                        payload: result.data
                    })
                })    
            } 
            catch (error) {
                if (error.code === 'ECONNABORTED') {
                    dispatch({
                        type: HR_PATIENTS_FAIL,
                        payload: 'request timed out'
                    });
                } else {
                    dispatch({
                        type: HR_PATIENTS_FAIL,
                        payload: error.response && error.response.data ? error.response.data.message : 'An error occurred'
                    });
                }
            }
        } else {
            alert.error('Please type something to search');
        }
      }

  return <>
      <MetaData title="RPM Patients" />
            <HRSidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <HRTopBar />

                <br />
                {loading ? <Loader /> : <>
                    <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                        <div className="home-content">
                            
                            {/* <div> */}
                                <div className="row-display">
                                    <div>
                                        <h5 className="title">
                                            {count} - RPM Patients
                                        </h5>
                                        <p>Showing <b>{pagination}</b> results per page. </p>
                                        
                                    </div>

                                <div className="row-display m-3"> 
                                        <Link to="/Nurse/Dashboard" className="btn btn-secondary btn-sm"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                        &nbsp;&nbsp;
                                        <button className="edit-pill" onClick={refreshhandler}>
                                            <i className='bx bx-refresh'></i>
                                        </button>
                                        &nbsp;&nbsp;
                                        <Link to="/Nurse/Add_New_Patient" className="edit-pill">+ Add New Patient</Link> 
                                        
                                </div>
                            </div>
                            <hr />       

                            <div className="row colored-row">
                                <div className="mini-searchBox" >
                                    <input 
                                        type="text" 
                                        placeholder="Search RPM Pts. by ID, Name and DOB" 
                                        className="mini-searchInput" 
                                        value={input}
                                        onChange={e => setInput(e.target.value)}
                                        autoFocus
                                        maxLength="15"
                                        disabled={nurseDrs && countLength?.length > 0 ? false : true}
                                    />
                                    <i className='bx bx-search mini-searchbar-Icon'></i>
                                </div>

                                <button className='edit-pill m-2' onClick={e => searchHandler(e.target.value)}>
                                    Search
                                </button>
                            </div>
                            <br />
                                
                                {hrpatients?.length > 0 ? (<>
                                    <>
                                        <table className="styled-table">
                                        <thead align="center">
                                            <tr>
                                                <th>Name & EHR-ID</th>
                                                <th>DOB </th>
                                                <th>Device Status</th>
                                                <th>Devices Assigned</th>
                                                <th>Acc Status</th>
                                                <th>Doctor</th>
                                                <th>Compliancy Status</th>
                                                <th>Last Reading </th>
                                                <th>Consent Program</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                        {hrpatients && hrpatients.map((patient, index) => (  
                                        <tr key={index}>    
                                             <td className='text-center'>
                                             {patient?.block === false ? <>
                                                <Link style={{textDecoration: 'none', color: '#00A8A8', fontWeight: 'bold'}} to={{ pathname: "/Nurse/Patient/Profile", state: {patientid: patient?._id }}}>
                                                        {patient?.lastname +" , "+ patient?.firstname}
                                                </Link>
                                                <p><b>{patient?.emrId || '---'}</b></p>
                                                </> : 
                                                <b style={{color: 'red'}}> {patient?.lastname},  {patient?.firstname} </b>
                                             }
                                             </td>
                                        
                                            <td> <b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b> <p>
                                                {moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years old</p>
                                            </td>
                                                        
                                            <td>{patient?.batterySignals?.sig >= 0 && patient?.batterySignals?.sig <= 10 ? 
                                                    <text style={{color: 'red', fontWeight: 'bold'}}>Weak</text> : patient?.batterySignals?.sig > 10 && patient?.batterySignals?.sig <= 20 ? 
                                                    <text style={{color: 'orange', fontWeight: 'bold'}}>Medium</text> : patient?.batterySignals?.sig > 20 ? <text style={{color: 'green', fontWeight: 'bold'}}>Strong</text> : '--'} 

                                                {patient?.batterySignals?.sig ? <ProgressBar striped variant="info" now={patient?.batterySignals?.bat} label={`${patient?.batterySignals?.bat}%`} /> : '--'}
                                            </td>

                                            <td className="text-center">
                                            <p>{patient?.assigned_devices.map((device, index) => (
                                                <Badge bg="info text-white" style={{
                                                    fontWeight: 500, 
                                                    textTransform: 'uppercase', 
                                                    padding: '5px',
                                                    letterSpacing: '2px',
                                                    margin: '2px'
                                                }} key={index}>{device?.deviceObjectId?.deviceType}</Badge>
                                            ))}</p> 
                                            </td>

                                            {patient?.block === false ? <td>
                                                <p style={{color: 'green', fontWeight: 'bold'}}>Activated</p>
                                                </td> : <td><p style={{color: 'red', fontWeight: 'bold'}}>Inactive</p>
                                            </td>}

                                        {patient?.assigned_doctor_id ? <>
                                            <td><b>Dr .{patient?.assigned_doctor_id?.lastname +" , "+ patient?.assigned_doctor_id?.firstname} </b></td>
                                            </> : <>
                                            <td>Not Assigned</td>
                                        </>}
 
                                        {calculateCompliancyStatus(moment(patient?.lastReading))}    
                                        
                                        <td>
                                            {patient?.lastReading ? 
                                                compliantStatus === 'compliant' ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                                    Compliant</p> : compliantStatus === 'mostLikelyCompliant' ? <p style={{color: '#F95800', fontWeight: 'bold'}}>
                                                        Most Likely Compliant</p> : compliantStatus === 'notLikelyCompliant' ? <p style={{color: 'dodgerblue', fontWeight: 'bold'}}>
                                                            Not Likely Compliant</p> : compliantStatus === 'nonCompliant' ? <p style={{color: 'red', fontWeight: 'bold'}}>
                                                            Non Compliant</p> : null : '--'}
                                        </td>

                                        <td><span style={{fontWeight: 'bold'}}>{patient?.lastReading ? moment(patient?.lastReading).format("MM/DD/YYYY") : '--'}</span>
                                        {/* <p>{patient?.lastReading ?  moment(patient?.lastReading).fromNow() : null}</p> */}
                                        </td>

                                        <td style={{fontWeight: 'bold'}}> {patient?.patientType}</td> 

                                        </tr> 
                                        ))}
                               
                                        </tbody>
                                    </table>
                                   {hrpatients?.length < count && 
                                    <div className="d-flex justify-content-center mt-5"> 
                                        <Pagination 
                                            activePage={currentPage} 
                                            itemsCountPerPage={pagination} 
                                            totalItemsCount = {count}
                                            onChange={setCurrentPageNumber} 
                                            nextPageText = {'⟩'}
                                            prevPageText = {'⟨'}
                                            firstPageText = {'«'}
                                            lastPageText = {'»'}
                                            itemClass='page-item'
                                            linkClass="page-link"
                                            />           
                                    </div>
                                   }    
                                </>                      
                        </> ) : <>
                        <div>   

                                           
                        <img src={folderImg} className="no-record-found-img"/>
                        <p className="doctor-specilizations">No Patient Assigned Yet...</p>                            
                                    </div>
                                </> }
                            {/* </div> */}
                        </div>
                    </div>
                </>
                    }
            </section>
        </>
    };

export default HRPatientsList;
