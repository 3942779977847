import  {useState, useEffect, useRef} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { getDoctorPatients, getDoctors, deleteHRMinutes, getOrganizations } from '../../actions/adminActions';
import { getTimeReportByDate, getTimeReportByDateRange } from '../../actions/HRActions';
import Select from 'react-select';
import { useAlert } from 'react-alert';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../layouts/Loader';
import moment from 'moment';
import {Link, useHistory } from 'react-router-dom';
import patientProfileImg from '../../assets/Images/doctorIcon.png';
import { Badge } from 'react-bootstrap';
import { RESET_TIME_REPORT_DATA } from '../../constants/HRConstants';
import { useReactToPrint } from 'react-to-print';
import TimeReportPDF from '../../components/TimeReportPDF';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';



const TimeReportByDate = () => {
    const history = useHistory();
    const [doctorId, setDoctorId] = useState("");
    const alert = useAlert();

    const [patientId, setPatientId] = useState("");
    const [startDate, setStartDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState();
    const [reportCategory, setReportCategory] = useState('RPM');
    const [orgId, setOrgId] = useState('');
    // const { error, patients} = useSelector(state => state.admin);
    const { doctorpatients } = useSelector(state => state.docPatients);
    const { error, doctors } = useSelector(state => state.doctor);
    const { loading, targets, totalTime, totalInteractiveMinutes, totalNonInteractiveMinutes} = useSelector(state => state.target);

    const { organizations } = useSelector(state => state.orgs);
    const {error: commonError, message} = useSelector(state => state.common);
    const dispatch = useDispatch();



    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(commonError){
            alert.error(commonError);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        if(message){
            alert.success(message);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

        dispatch(getDoctors(50, 1));
        dispatch(getOrganizations());

        if(doctorId){
            dispatch(getDoctorPatients(doctorId));
        }
    }, [dispatch, doctorId, error, commonError, message]);

    const options = []
    if(doctorId){
        doctorpatients && doctorpatients.map((patient) => (
            options.push({
                value: patient?._id,
                label: [patient?.lastname, patient?.firstname, moment(patient?.DOB).format("MM-DD-YYYY")].join(" "),
                isBlocked: patient?.block
            })
        ))
    }

    const getPatientProfile = (patient) => {
        setPatientId(patient.value);
    }

    const downloadFile = (fileName) => {
        window.open(`https://www.rpmatrix.com/v1/uploadFiles/${fileName}`);
    }

    const orgOptions = []
    organizations && organizations.map((org) => (
        orgOptions.push({ value: org?._id, label: [org?.name]})
    ))

    const getOrgProfile = (org) => {
        setOrgId(org.value);
    }

        const customStyles = {
            control: (provided) => ({
              ...provided,
              height: '52px', // Adjust the height here as needed
              width: '250px',
              backgroundColor: 'transparent',
              borderStyle: 'solid',
              borderWidth:'2px',
              borderColor: '#444',
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.data.isBlocked ? 'red' : 'black', // Color based on isBlocked property
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: state.data.isBlocked ? 'red' : 'black', // Color for single value display
            }),
          };

          const submitHandler = () => {
            if(patientId && startDate && reportCategory && !endDate){
                dispatch(getTimeReportByDate(patientId, startDate, reportCategory));
            } else if (patientId && startDate && endDate && reportCategory){
                dispatch(getTimeReportByDateRange(patientId, startDate, endDate, reportCategory));
            } else {
                alert.error('Please fill out all compulsory fields');
            }
          }

          const deleteHandler = (Id) => {
            var confirmation = window.confirm(`Are you sure you want to delete minutes ?`);
                if(confirmation){
                    dispatch(deleteHRMinutes(Id));
                }
            }

          const resetData = () => {
            dispatch({ type: RESET_TIME_REPORT_DATA });
          }

          const componentRef = useRef();

          const handlePrint = useReactToPrint({
            pageStyle:"A5",
            documentTitle:  "Time Report",
            content: () => componentRef.current,
        });

  return (
    <>
        <MetaData title="Time Report By Date"/>
        <Sidebar />

        <section className="home-section">
        {/* TopBar */}
        <TopBar />

        <br/>
        <div className="shadow-lg m-2 background-white">
            <div className="home-content">
                <div className="row-display p-2 mr-2 ml-2">
                    <h5 className="title mt-2">
                        <i className="bx bx-edit"></i> &nbsp;Time Report By Date 
                    </h5>
                    <div>
                        <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                            <i className='bx bx-arrow-back'></i>
                        </button>
                        &nbsp;&nbsp;
                        <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                            <i className='bx bxs-home'></i>
                        </Link>
                    </div>
                </div>

                <div>

                <div className="row-display reportFormFields">
                <div>
                        <label className="form-label">
                            Select Organization  <span style={{color: 'red'}}> *</span>  
                        </label>
                        <Select
                            options={orgOptions}
                            onChange={getOrgProfile}
                            styles={customStyles}
                        />    
                    </div>&nbsp;&nbsp;&nbsp;
                    
                    <div>
                        <label className='form-label'>Select Doctor</label>
                            <select className="form-control" 
                                value={doctorId} 
                                onChange={e => setDoctorId(e.target.value)}
                                disabled={orgId ? false : true}
                                >
                                <option value="null">Select Doctor</option>
                                {doctors && doctors.filter(item => item?.assigned_org_id?._id === orgId).map((doctor) => (
                                    <option key={doctor?._id} value={doctor?._id}>
                                        {'Dr. ' +doctor?.lastname +' , '+ doctor?.firstname}
                                    </option>
                                ))}
                            </select>
                        </div>


                    <div>
                        <label className="form-label">Select Patient <span style={{color: 'red'}}> *</span> </label>
                        <Select
                            options={options}
                            onChange={getPatientProfile}
                            styles={customStyles}
                        />
                    </div>


                    <div>
                        <label className="form-label">Start Date <span style={{color: 'red'}}> *</span> </label>
                        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control" placeholder="From"/>
                    </div>

                    <div>
                    <label className="form-label">End Date </label>
                        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                            className="form-control" placeholder="To"/>
                    </div>

                    <div>
                    <label className="form-label">Category <span style={{color: 'red'}}> *</span> </label>
                        <select className="form-control" name="cateogory" value={reportCategory} onChange={e => setReportCategory(e.target.value)}>
                            <option value="RPM">RPM</option>
                            <option value="CCM">CCM</option>
                        </select>
                    </div>

                    <button className="edit-pill-2 mt-4" 
                        onClick={() => submitHandler()}
                        disabled={doctorId && patientId ? false : true}
                        > 
                        Generate Report
                    </button>
                    
                </div>

                </div>
                <hr/>
            </div>

            {loading ? <Loader /> : <>
                    {targets && targets.length > 0 ? <>

                        <br />
                        <div className="row-display">
                            <div className='col-md-11'>
                            <div className="row-display">
                                <h5 style={{color: '#00A8A8'}}>
                                    <i className='bx bxs-watch'></i> <strong> Targeted Minutes Added </strong>
                                </h5>

                                <div>
                                    <div className="row">
                                    <div
                                        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
                                    >
                                    <TimeReportPDF ref={componentRef}
                                        targets={targets}
                                        interactiveMinutes={totalInteractiveMinutes}
                                        nonInteractiveMinutes={totalNonInteractiveMinutes}
                                        timeSpent={totalTime}
                                        category={reportCategory}
                                    />
                                    </div>

                                    <button onClick={handlePrint}
                                        className="edit-pill">
                                        <i className='bx bxs-file-pdf' style={{color:'#F40F02'}}></i> Download Report .PDF
                                    </button>

                                    <button className="edit-pill ml-2"
                                    style={{backgroundColor:'red'}}
                                    onClick={resetData}>Reset</button>
                                    </div>
                                    </div>
                            </div>

                            <div className="row-display-secondary">
                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalTime} mins</h2>
                                    <p><b>Total Time Spent</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalInteractiveMinutes} mins</h2>
                                    <p style={{color: 'green'}}><b>Interactive Minutes</b></p>
                                </div>

                                <div className="col-md-4 card p-4 m-2 text-center">
                                    <h2>{totalNonInteractiveMinutes} mins</h2>
                                    <p style={{color: 'red'}}><b>Non-Interactive Minutes</b></p>
                                </div>
                            </div>
                              </div>
                            </div>
                            <br />

                            {targets && targets.map((trgt, index) => (
                                    <div className="m-2" key={index}>

                                        <div className="row-display">
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">EHR-ID: </label>
                                                <label className="report-label ml-2">{trgt?.assigned_patient_id?.emrId}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient Name: </label>
                                                <Link to={{ pathname: "/patientProfile", state: {patientid: trgt?.assigned_patient_id?._id}}} className="link">
                                                    {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}
                                                </Link>
                                                {/* <label className="report-label ml-2"> {trgt?.assigned_patient_id?.lastname +" "+ trgt?.assigned_patient_id?.firstname}</label> */}
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Patient DOB: </label>
                                                <label className="report-label ml-2">{trgt?.assigned_patient_id?.DOB && moment(trgt?.assigned_patient_id?.DOB).format("MM/DD/YYYY")}</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Account Status: </label>
                                                <label style={{color: trgt?.assigned_patient_id?.block === true ? 'red' : 'green', fontWeight: 'bold'}}>
                                                    {trgt?.assigned_patient_id?.block === true ? 'Deactivated' : 'Active'}
                                                </label>
                                            </div>
                                        </div>

                                        {/* Time added details */}
                                        <div className="col-lg-3">
                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">Start Date: </label>
                                                <label className="report-label ml-2"
                                                style={{backgroundColor: 'yellow'}}> {moment(trgt?.startDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div>

                                            <div className="row-display pl-4 pr-4">
                                                <label className="profile-label">End Date: </label>
                                                <label className="report-label ml-2"
                                                style={{backgroundColor: 'yellow'}}>{moment(trgt?.endDate).format("MM-DD-YYYY") || 'N/A' }</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-2'>
                                            <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">S - Time: </label>
                                                    <label className="report-label ml-2">{trgt?.startTime || 'N/A'}</label>
                                                </div>

                                                <div className="row-display pl-4 pr-4">
                                                    <label className="profile-label">E - Time: </label>
                                                    <label className="report-label ml-2">{trgt?.endTime || 'N/A'}</label>
                                            </div>
                                        </div>

                                        <div className='col-lg-1'>
                                            <h5 className="display-4"> {trgt?.timeSpentInMinutes < 10 ? '0'+trgt?.timeSpentInMinutes : trgt?.timeSpentInMinutes}</h5>
                                        </div>

                                        {/* Notes added */}
                                        <div className="col-lg-3">
                                            <div className="pl-4 pr-4">

                                            {trgt?.interactiveMinutes === true ?
                                                <b style={{color: 'green'}}>Interactive Minutes</b>
                                                :
                                                <b style={{color: 'red'}}>Non-Interactive Minutes</b>
                                            }

                                            <label className="report-label">added in <span style={{fontWeight: 'bold'}}>
                                                {reportCategory} category</span>
                                                <p>
                                                    {trgt?.encounterType && trgt?.encounterType === 'vm' ? <p>
                                                    <Badge pill bg="primary" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bxs-microphone'></i> &nbsp;&nbsp;Voicemail</Badge>
                                                    </p> : trgt?.encounterType === 'call' ? <Badge pill bg="success" style={{color: 'white', width: '100px', fontSize: '13px'}}> <i className='bx bxs-phone-call'></i> &nbsp;&nbsp; Call</Badge> : trgt?.encounterType === 'review' ?
                                                    <Badge pill bg="danger" style={{color: 'white', width: '100px', fontSize: '13px'}}><i className='bx bx-line-chart'></i> &nbsp;&nbsp; Review</Badge>
                                                    : null
                                                    }
                                                </p>
                                            </label>

                                            {trgt?.fileName ? <>
                                            <label className="profile-label">Attachment: </label>
                                                <p><button className="btn btn-link" onClick={() => downloadFile(trgt?.fileName)}>{trgt?.fileName} </button>
                                                </p></> : null}
                                            </div>
                                        </div>
                                        </div>

                                        {trgt?.clinicalFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>Clinical FollowUp</h5>
                                            <label className="profile-label">Q. How have you been managing your health ?</label>
                                            <p>{trgt?.clinicalFollowUp?.healthManagement}</p> 
                                            <br />
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any new problems or conditions ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isNewProblem === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. New Problems or Conditions Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.newProblemDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isWorseningConditions === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Any Worsening of Conditions / Problems Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.worseningConditionsDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 2 */}
                                            <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problems Identified ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isMedicationProblems === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Problem Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationProblemsDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall in last 30 days ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentFall === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Fall Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentFallDetails}</p>
                                                </div>
                                            </div>
                                            <br />
                                            {/* row 3 */}
                                                <div className="row-display">
                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Visit ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.isRecentlyHospitalized === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Recent Hospitalization / ED Details  ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.recentlyHospitalizedDetails}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Request ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequest === "yes" ? 'Yes' : "No"}</p>
                                                </div>

                                                <div className="col-md-3">
                                                    <label className="profile-label">Q. Medication Refill Details ?</label>
                                                    <p>{trgt?.clinicalFollowUp?.medicationRefillRequestDetails}</p>
                                                </div>
                                            </div>
                                            
                                            <br />
                                            <label className="profile-label">Q. Any Additional Notes ?</label>
                                            <p>{trgt?.clinicalFollowUp?.additionalNotes}</p>
                                            

                                        </div> : null}

                                        {trgt?.careplanFollowUp ? <div className="container-fluid">
                                            <h5 className='title'>CCM Careplan FollowUp</h5>

                                            {trgt?.careplanFollowUp?.map((careplan, index) => (
                                                <div key={index}>
                                                    <label className="profile-label">Disease Name : </label> <br />
                                                    <span className="coloredRow p-2 mt-2">{careplan?.diseaseName} </span> <br />
                                                    <br />
                                                    <label className="profile-label">Short Term Goals : </label> <br />
                                                    {careplan?.shortTermGoals.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Short Term Note : </label> <br />
                                                    {careplan?.shortTermNote}

                                                    <hr />
                                                    <label className="profile-label">Long Term Goals : </label> <br />
                                                    {careplan?.longTermGoals.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Long Term Note : </label> <br />
                                                    {careplan?.longTermNote}

                                                    <hr />
                                                    <label className="profile-label">Interventions : </label> <br />
                                                    {careplan?.interventions.map((stg, index) => (
                                                        <div key={index}>
                                                            {stg}
                                                        </div>
                                                    )) }
                                                    <br />

                                                    <label className="profile-label">Intervention Notes : </label> <br />
                                                    {careplan?.interventionNote}
                                                </div>
                                            ))}                                             
                                        </div> : null}

                                        <div className="row-display-secondary">
                                                <div className="mt-3 mr-3">
                                                    <img src={patientProfileImg} alt="hr/drImg" style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                                                </div>
                                                <div className="bubble bubble-alt">

                                                    <div className='row-display'>
                                                        <b className='text-white'
                                                            style={{letterSpacing: '1px'}}>
                                                            {trgt?.assigned_assistant_id ?
                                                            trgt?.assigned_assistant_id?.lastname +', '+ trgt?.assigned_assistant_id?.firstname
                                                            : trgt?.assigned_doctor_id ? 'Dr. '+ trgt?.assigned_doctor_id?.lastname +", "+ trgt?.assigned_doctor_id?.firstname
                                                            : trgt?.assigned_hr_id?.lastname +", "+ trgt?.assigned_hr_id?.firstname
                                                            }
                                                        </b>

                                                        <button className='btn btn-danger btn-sm'
                                                            onClick={() => deleteHandler(trgt?._id)}>
                                                            Delete
                                                        </button>

                                                    </div>

                                                    <p className="mt-1 mr-3">{trgt?.conclusion} <br/>
                                                    <p className="mt-1"><b>{moment(trgt?.createdAt).tz("America/New_York").format("MM-DD-YYYY hh:mm A")}</b></p>
                                                    </p>
                                                </div>
                                            </div>
                                        <hr />
                                    </div>
                                ))}
                        </> : ''}
                        </>}


        </div>
        </section>
    </>
  )
}

export default TimeReportByDate
