import { useEffect} from 'react';
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import defaultImg from '../../assets/Images/doctorIcon.png';
import assistantImg from '../../assets/Images/assistant.png';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { getMessages, changeMessageStatus } from '../../actions/adminActions';
import Loader from '../../layouts/Loader';
import {HIDE_ALERT_MESSAGE} from '../../constants/Common';

const Messages = () => {
 const dispatch = useDispatch();
 const alert = useAlert();
 const history = useHistory();
    
 const {error, message} = useSelector(state => state.common);
 const {loading, messages} = useSelector(state => state.messages);

    useEffect(() => {
        if(message){
            alert.success(message);

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
    
        if(error){
            alert.error(error)

            dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }
        
        dispatch(getMessages({getFor:"admin"}));
    
      },[dispatch, alert, error, message])
      
      const approveMessage = (replyId, patientId, hrId, answer) => {
        var confirmation = window.confirm('Are you sure you want to approve this reply ?');

        if(confirmation){
            dispatch(changeMessageStatus(replyId, patientId, hrId, answer));
        }
      }


  return (
    <>
    <MetaData title="Messages"/>
        <Sidebar />    

        <section className="home-section">
        {/* TopBar */}
        <TopBar />
        <br/>

        <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
            <div className="home-content">

            <div className="row-display">
                <div className="col-md-6">   
                    <h5 className='title'> <strong>&nbsp;Chat & Messages </strong>
                    </h5>
                </div>
                
                <div className="row-display">
                    <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                        <i className='bx bx-arrow-back' ></i>
                    </button>
                </div>                          
            </div>
            <hr />

            {loading ? <Loader /> : <>
            {messages && messages?.length > 0 ? <>
            {messages.map((message, index) => (
                <div key={index} className="ml-5">
                <div className="row-display-secondary">
                    <div className="mt-3 mr-3">
                        <img src={assistantImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                    </div>
                    <div>
                        <small>Asked : {moment(message?.createdAt).tz("America/New_York").format("MM/DD/YYYY hh:mm A")}
                        <b className="ml-3">By : <span style={{color: 'dodgerblue'}}>{message?.assigned_assistant_id?.lastname}  {message?.assigned_assistant_id?.firstname} </span></b> 
                        <b className="ml-3">
                            To: <span style={{color: 'dodgerblue'}}>{message?.assigned_hr_id?.lastname} {message?.assigned_hr_id?.firstname}</span>
                        </b>
                        <b className="ml-3">
                            Related To Patient: <span style={{color: 'dodgerblue'}}>{message?.assigned_patient_id?.lastname} {message?.assigned_patient_id?.firstname}</span>
                        </b> 
                        </small>
                        <h5><b>{message?.question} ?</b></h5>
                    </div>
                </div>
                
                {message?.answers?.length > 0 ? <>
                    {message?.answers?.map((answers, index) => (
                    <div key={index} className="mt-3 ml-4">
                    <div className="row-display-secondary">
                        <div className="mr-3">
                            <img src={defaultImg} alt="img" className='roundedBorderImg' width="70px" height="70px" />
                        </div>
                        <div>  
                            
                            <b>Replied By : {answers?.repliedBy}</b><br/>
                                                
                            <p className="mt-1 mr-3">{answers?.answer} <br/> 
                                <div className="row-display-secondary">
                                    
                                    {answers?.approvalStatus === true ? 
                                    <div className="row">
                                    <p className="mt-1 ml-3" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                        <small className="approvedBadge ml-4">Approved by admin</small>
                                        <small style={{color: 'green'}} className="ml-4">+1 minute added</small> 
                                    </div> : 
                                    <div className="row">
                                    <p className="mt-1 ml-3" style={{letterSpacing: '1px'}}>{moment(answers?.dateTime).tz("America/New_York").format("MM/DD/YYYY hh:mm A")} </p>
                                        <small className="declinedMessageBadge ml-4">Not Approved</small>
                                        <span className="changeStatusBtn ml-4" onClick={() => approveMessage(answers?._id, message?.assigned_patient_id?._id, message?.assigned_hr_id?._id, answers?.answer )}>Click to approve</span>
                                    </div>}
                                </div>
                            </p>
                        </div>
                    </div>
                    </div>
                ))}
                
                </> : <div className="text-center">
                        <p>No reply yet</p>
                    </div>
                }
                <hr className='blue-hr'/>
                </div>
            ))}
            
            </> : <></>}
            
            
            </>}

            </div>
        </div>

        </section>
    </>
  )
}

export default Messages;