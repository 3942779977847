import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {getPatientsWithCriticalDataAssistant} from '../../actions/assistantActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {Badge} from 'react-bootstrap';

const AssistantCCriticalReadingsComponent = () => {
  
  const dispatch = useDispatch();
  const {count, allReadings, respondedReadings, notRespondedReadings, highSystolic, lowDiastolic,  criticalPts, weightReadings, glucoseReadings, spo2Readings} = useSelector(state => state.criticalPatients);
  let drIds = localStorage.getItem('myArray');
  
  const [filterReadings, setFilterReadings] = useState('all');
  const [pagination, setPagination] = useState(25);  


  useEffect(() => {
    dispatch(getPatientsWithCriticalDataAssistant(pagination, filterReadings, drIds));
}, [dispatch, filterReadings, pagination]);

    const refreshCriticalReadings = () => {
        dispatch(getPatientsWithCriticalDataAssistant(pagination, filterReadings, drIds));
    }

    const updateCriticalReadingsPagination = () => {
        setPagination(pagination + 25);
        dispatch(getPatientsWithCriticalDataAssistant(pagination, filterReadings, drIds));
    }

    let sysBPCategory; //variable to store category of BP
    let color

    function calcSysBpCategory(sys) {
        switch(true){
            case (sys > 210):
                sysBPCategory = "Hypertension- stage 4"
                color = '#FE0004'
                break;
            case (sys >= 180 && sys <= 210):
                sysBPCategory = "Hypertension-Stage 3"
                color = '#FE504F'
                break;
            case (sys >= 160 && sys <= 179):
                sysBPCategory = "Hypertension-Stage 2"
               color = '#FE504F'
            break;
            case (sys >= 140 && sys <= 159):
                sysBPCategory = "Hypertension-Stage 1"
                color = '#FF32CB'
            break;
            case (sys >= 130 && sys <= 139):
                sysBPCategory = "Pre-hypertension"
                color = '#FFFF00'
            break;
            case (sys >= 121 && sys <= 129):
                sysBPCategory = "High Normal B.P"
                color = '#CBFE33'
            break;
            case (sys >= 100 && sys <= 120):
                sysBPCategory = "Normal Blood Pressure"
                color = '#00FF99'
            break;
            case (sys >= 90 && sys <= 99):
                sysBPCategory = "Low Normal B.P"
                color = '#CDFFCC'
            break;
            case (sys >= 70 && sys <= 89):
                sysBPCategory = "Low Blood Pressure"
                color = '#DEEAF6'
            break;
            case (sys >= 50 && sys <= 69):
                sysBPCategory = "Too Low Blood Pressure"
                color = '#9CC3E4'
            break;
            case (sys < 50):
                sysBPCategory = "Extremely Low B.P"
                color = '#2E73B4'
            break;
            default:
                sysBPCategory = "Invalid B.P"
                color = '000'
            } 
        }   

        var minutesDuration;
        const calcDuration = (receiveDate, sentDate) => {
            minutesDuration = moment(receiveDate).diff(moment(sentDate), 'minutes');
        }
  
  return (
    <div className="container-fluid mt-3 mb-5">

        <div className="col-md-12">
            <div className="row">
                <div className="col-md-12">
                  <div className="shadow-lg p-3 recent-readings-card background-white">
                    <div className="home-content">
                            <div className='row-display'>
                                <div className="pr-4 rightBorderLine"> 
                                    <h5 className="title"> Critical Vitals Received</h5> 
                                    <p>Showing <b>{criticalPts?.length}</b> results out of <b>{count && count}</b> </p>
                                </div>


                                <div className="ml-4">
                                    <small><b>Blood Pressure Readings</b></small> <br/>
                                    <h5>{allReadings} </h5>                                
                                </div>

                                <div className="ml-4">
                                <small><b>Weight Scale Readings</b></small> <br/>
                                    <h5>{'0'+weightReadings} &nbsp;&nbsp;</h5> 
                                </div>

                                <div className="ml-4">
                                <small><b>Glucose Readings</b></small> <br/>
                                    <h5>{'0'+glucoseReadings} &nbsp;&nbsp;</h5> 
                                </div>

                                
                                <div className="ml-4">
                                <small><b>Spo2 Readings</b></small> <br/>
                                    <h5>{'0'+spo2Readings} &nbsp;&nbsp;</h5> 
                                </div>

                                <button className="btn btn-secondary btn-sm" onClick={() => refreshCriticalReadings()}>
                                    Refresh
                                </button>

                                
                            </div>
                        <hr className="blue-hr"/>

                        <div className="row-display">
                            <label className="profile-label mt-2">Search Filters </label>
                            <span className={`smallFilterBtn ${filterReadings === 'all' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('all')}>
                                    All Readings
                            </span> 
                            
                            <span className={`smallFilterBtn ${filterReadings === 'responded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('responded')}>
                                    Responded <span className="notiNumber ml-2" style={{backgroundColor: 'green'}}> {respondedReadings > 10 ? respondedReadings :'0'+ respondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'notResponded' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('notResponded')}>
                                    Not Responded <span className="notiNumber ml-2"> {notRespondedReadings > 10 ? notRespondedReadings :'0'+ notRespondedReadings} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'highSystolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('highSystolic')}>
                                    High Systolic {'>'} 180 <span className="notiNumber ml-2"> {highSystolic > 10 ? highSystolic :'0'+ highSystolic} </span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'lowDiastolic' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('lowDiastolic')}>
                                    Low Diastolic {'<'} 55 <span className="notiNumber ml-2"> {lowDiastolic > 10 ? lowDiastolic :'0'+ lowDiastolic} </span>
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'weight' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('weight')}>
                                    Weight <span className="notiNumber ml-2"> {weightReadings > 10 ? weightReadings :'0'+ weightReadings}</span> 
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'glucose' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('glucose')}>
                                    Glucose <span className="notiNumber ml-2"> {glucoseReadings > 10 ? glucoseReadings :'0'+ glucoseReadings} </span>  
                            </span>

                            <span className={`smallFilterBtn ${filterReadings === 'spo2' ? 'smallFilterBtnActive' : ''}`}
                                onClick={() => setFilterReadings('spo2')}>
                                    Spo2 <span className="notiNumber ml-2"> {spo2Readings > 10 ? spo2Readings :'0'+ spo2Readings} </span> 
                            </span>
                        </div>
                        <br/>

                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Vitals</th>
                                    <th width="90px">Device</th>
                                    <th width="90px">Pulse</th>
                                    <th>Status</th>
                                    <th>Doctor</th>
                                    <th width="40px"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {criticalPts && criticalPts.map((pts, index) => (
                                <tr key={index} className={pts?.notes && pts?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                                    <td>
                                        <div className="row-display">
                                        <span className="roundedNameIcon">{pts?.assigned_patient_id?.lastname[0]}{pts?.assigned_patient_id?.firstname[0]}</span>
                                        
                                        <Link to={{ pathname: "/clinical_staff/patient", state: {patientid: pts?.assigned_patient_id?._id}}} className="link">{pts?.assigned_patient_id?.lastname + " , "+ pts?.assigned_patient_id?.firstname } </Link>
                                        </div>
                                    </td>

                                    <td>{pts?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(pts?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p className="p-0 m-0">{moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                    
                                        {/* function to calculate time duration */}
                                        {pts?.createdAt && pts?.telemetaryData?.ts && calcDuration(moment(pts?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'), 
                                            moment(pts?.telemetaryData?.ts * 1000).tz("America/New_York").format('MM/DD/YYYY hh:mm z A'))}

                                            {minutesDuration && minutesDuration >= 10 ? <Badge pill bg="danger" text="light">Reading Received Late</Badge> : null }
                                            {/* function to calculate time duration ends */}
                                    </>}
                                    </td>

                                    {calcSysBpCategory(pts?.telemetaryData?.sys)}
                                    
                                    {pts?.telemetaryData?.data_type === "bpm_gen2_measure" ? <td> 
                                            <b>{pts?.telemetaryData?.sys} / {pts?.telemetaryData?.dia} mmHG </b><p>
                                                {pts?.telemetaryData?.sys >= 139 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                                pts?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                                pts?.telemetaryData?.dia >= 90 ? 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                                pts?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                                </p>
                                                </td> : pts?.telemetaryData?.data_type === "scale_gen2_measure" ? <td>
                                                <b>{(pts?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                                <p>{(pts?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                            </b>
                                        </td> : pts?.telemetaryData?.data_type === "bgm_gen1_measure" ? <td>
                                            <b> {pts?.telemetaryData?.data} &nbsp;
                                                {pts?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                            </b>
                                        </td> : pts?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <td>
                                            <b>{pts?.telemetaryData?.notification_payload?.spo2_pct}
                                            <p>Perfusion: {pts?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                        </td> : <td>---</td> }

                                    {/* Device type */}
                                    <td><b>{pts?.telemetaryData?.data_type === "bpm_gen2_measure" ? 
                                    <span className='bpDeviceType'>B.P</span> : 
                                    pts?.telemetaryData?.data_type === "scale_gen2_measure" ? 
                                    <span className='weightDeviceType'>Weight</span> : 
                                    pts?.telemetaryData?.data_type === "bgm_gen1_measure" ? 
                                    <span className="glucoseDeviceType">Glucose</span> : 
                                    <span className="spo2DeviceType">Spo2</span>}</b></td>        

                                     {/* B.P Pulse rate  */}
                                     <td>
                                        <b>{pts?.telemetaryData?.pul ? pts?.telemetaryData?.pul +' bpm' : pts?.telemetaryData?.notification_payload?.pulse_bpm ? pts?.telemetaryData?.notification_payload?.pulse_bpm + ' bpm' : '---'} </b>
                                    </td>
                                    
                                    {/* B.P Category */}
                                    <td> {pts?.telemetaryData?.sys ? <><i className='bx bxs-circle' style={{color: color, fontSize: '24px'}}></i>&nbsp;&nbsp;<b>{sysBPCategory}</b></> : '---'}</td> 
                                    
                                    {/* Doctor Name */}
                                    <td><b>{pts?.assigned_doctor_id ? 'Dr. ' + pts?.assigned_doctor_id?.lastname +" "+ pts?.assigned_doctor_id?.firstname : null}</b></td>
                                    
                                    {/* Responded or Not */}
                                    <td>{pts?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                        <i className="fas fa-times" style={{color: 'red'}}></i>}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        
                        <br/>
                        {criticalPts?.length < count && <>
                            <div className="text-center">
                                <button className="edit-pill" onClick={() => updateCriticalReadingsPagination()}>Show more</button>
                            </div>
                        </> }
                    </div>
              </div>
            </div>
            </div>
        </div>

        </div>
    )
}

export default AssistantCCriticalReadingsComponent;