import { useEffect, useState } from 'react'
import Sidebar from '../../components/AdminDashboard/Sidebar';
import TopBar from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { getAssistants } from '../../actions/assistantActions';
import { Badge, Modal } from 'react-bootstrap';
import { assistantActivate, assistantDeActivate } from '../../actions/adminActions';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';


const AssistantList = () => {

const dispatch = useDispatch();
const alert = useAlert();
const {loading, error, assistants } = useSelector(state => state.assistantsList);

const [smShow, setSmShow] = useState(false); //small confirm modal
const [doctorModel, setDoctorModel] = useState(null);
const [doctorToDelete, setDoctorToDelete] = useState(null);

const {error : commonError, message } = useSelector(state => state.common);

useEffect(() =>{
    if(error){
        alert.error(error);

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
        setSmShow(false);
    }

    if(commonError) {
        alert.error(commonError);

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
        setSmShow(false);
    }

    if(message){
        alert.success(message);

        dispatch({
            type: HIDE_ALERT_MESSAGE
        })
        setSmShow(false);
    }

    dispatch(getAssistants());

}, [dispatch, error, commonError, message]);

    const deActivateAssistant = () => {
        dispatch(assistantDeActivate(doctorModel));
    }

    const activateAssistant = () => {
        dispatch(assistantActivate(doctorModel));
    }

  return (
    <div>
         <>
            <MetaData title="Assistants"/>
            <Sidebar />    

            <section className="home-section">
            {/* TopBar */}
            <TopBar />

            {loading ? <Loader /> : (
            <>   
             <br/>
              <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                  <div className="home-content">

                  <div className="row-display">
                          <h5 className='title'>
                              <i className='bx bx-plus-medical'></i> <strong>&nbsp;List of Assistants </strong>
                          </h5>

                          <div className="row-display"> 
                              <Link to="/adminDashboard" className="btn btn-secondary btn-sm"><i className='bx bx-arrow-back' ></i></Link> &nbsp;
                                  
                              &nbsp;&nbsp;
                              <Link to="/Add/Assistant" className="edit-pill">Add New Assistant</Link>
                          </div>
                      </div>
                      <hr />

                      <div className="col-md-12">
                         <>
                         <table className="styled-table">
                            <thead align="center">
                                <tr>
                                    <th>Sr #</th>
                                    <th>Name</th>
                                    <th>Verification Status</th>
                                    <th>Email</th>
                                    <th>Gender </th>    
                                    <th>NPI Number</th>
                                    <th>Primary Ph</th>
                                    <th>Assigned Organization</th>
                                    <th>Acc Status</th>
                                    <th width="70px">Action</th>
                                </tr> 
                            </thead>
                            <tbody>

                            {assistants && assistants.length > 0 && assistants.map((assistant, index) => ( 
                                <tr key={index}>
                                <td><b>{index + 1}</b></td>
                                <td><b>{assistant?.lastname +" , " + assistant?.firstname}</b></td>
                                
                                <td>{assistant?.verified === true ? <b style={{color: 'green'}}>Verified</b> : 
                                    <b style={{color: 'red'}}>Not Verified</b>}
                                </td>    

                                <td style={{wordWrap: 'break-word'}}><b>{assistant?.email}</b></td>
                                
                                {assistant?.gender === 'male' ? <td>
                                    <b>Male</b> </td> : <td><b>Female</b>
                                </td>}

                                <td><b>{assistant?.npinumber ? assistant?.npinumber : '--'}</b></td>
                                
                                <td><b>{assistant?.phone1 ? assistant?.phone1 : '--'}</b></td>

                                <td><b>{assistant?.assigned_org_id ? assistant?.assigned_org_id?.name : '---'}</b></td>

                                {/* <td style={{color:"#8FBB2C"}}>{assistant?.assigned_hr_id ? Asst. {assistant?.assigned_hr_id?.firstname}  {doctor?.assigned_hr_id?.lastname}</> : '--'} </td> */}
                                
                                {assistant?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>}

                                <td>        
                                {assistant?.block === false ? <Link to="/Assistants" style={{color: 'red'}} className="link" onClick={() => {setSmShow(true); setDoctorModel(assistant?._id); setDoctorToDelete(assistant?.lastname)}}><i className="bx bx-block"></i></Link> : 
                                        <Link to="/Assistants" className="link" style={{color: 'green'}} onClick={() => {setSmShow(true); setDoctorModel(assistant?._id); setDoctorToDelete(assistant?.lastname)}}><i className="bx bx-user-check"></i></Link> }
                                </td>
                            </tr> 
                             ))}
                            </tbody>
                          </table>
                        </>
                        </div>
                    </div>
                  </div>
            </>
            )}

            <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>
                    <p>Are you sure you want to activate / block 
                        <b> Asst. {doctorToDelete}'s</b> Account ?
                    </p>
                </Modal.Body>

                <Modal.Footer>

                    <button className="btn btn-sm btn-success" onClick={activateAssistant}> Activate</button>
                    <button className="btn btn-sm btn-danger" onClick={deActivateAssistant}>De-Activate</button>
                </Modal.Footer>
            </Modal>
            </section>
        </>   
    </div>
  )
}

export default AssistantList