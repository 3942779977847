import {useState, useEffect} from 'react';
import nurseIcon from '../../assets/Images/woman.png';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getNursesProgressOnDashboard } from '../../actions/adminActions';


const NurseProgressComponent = () => {

    const dispatch = useDispatch();

    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState('2024');

    // for CCM 
    const [startMonth, setStartMonth] = useState(moment().subtract(1, "month").format('MM'));
    const [endMonth, setEndMonth] = useState(moment().format('MM'));

    // const [startMonth, setStartMonth] = useState(moment().format('MM'));
    // const [endMonth, setEndMonth] = useState(moment().add(1, "month").format('MM'));

    const [consentCategory, setConsentCategory] = useState('RPM');

    const { nurseProgress } = useSelector(state => state.progress);

    useEffect(() => {
        if(consentCategory === 'RPM'){
            dispatch(getNursesProgressOnDashboard(month, year, consentCategory));
        } else {
            dispatch(getNursesProgressOnDashboard(startMonth, year, consentCategory, endMonth)); 
        }
    }, [dispatch, consentCategory]);


    const searchHandler = () => {
        if(consentCategory === 'RPM'){
            dispatch(getNursesProgressOnDashboard(month, year, consentCategory));
        } else {
            dispatch(getNursesProgressOnDashboard(startMonth, year, consentCategory, endMonth)); 
        }
    }


    const calculateTotalMarks = () => {
        return nurseProgress.map(datum => datum.sum).reduce((a, b) => a + b)
      };
    
    const totalMinutes = nurseProgress && nurseProgress.length > 0 ? calculateTotalMarks() : null;


  return (
    <div>
        <div className='row-display'>
            <h5 className='title m-0 p-0'> {consentCategory} Nurses Progress</h5>
            <h5 className='title'>Total Minutes Added: {totalMinutes && totalMinutes}</h5>
        </div>
        <hr />

         <div className="row-display">
         {consentCategory === 'RPM' ? <>
                <div>
                <label className="form-label" htmlFor="month">Select Month </label>
                <select 
                    name="month" 
                    className="form-control"
                    value={month} 
                    onChange={e => setMonth(e.target.value)}
                    style={{width: '200px'}}
                    >
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    </select>
                </div>

                <div>
                <label className="form-label" htmlFor="month">Year </label>
                    <select 
                    name="month" 
                    className="form-control"
                    value={year} 
                    onChange={e => setYear(e.target.value)}
                    style={{width: '200px'}}
                    >
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    </select>
                </div>
                </>
                : 
            <>
            <div>
            <label className="form-label" htmlFor="month">Start Month </label>
            <select 
                name="month" 
                className="form-control"
                value={startMonth} 
                onChange={e => setStartMonth(e.target.value)}
                style={{width: '200px'}}
                >
                <option value="01">January - 26</option>
                <option value="02">February - 26</option>
                <option value="03">March - 26</option>
                <option value="04">April - 26</option>
                <option value="05">May - 26</option>
                <option value="06">June - 26</option>
                <option value="07">July - 26</option>
                <option value="08">August - 26</option>
                <option value="09">September - 26</option>
                <option value="10">October - 26</option>
                <option value="11">November - 26</option>
                <option value="12">December - 26</option>
                </select>
            </div>


            <div>
            <label className="form-label" htmlFor="month">End Month </label>
            <select 
                name="month" 
                className="form-control"
                value={endMonth} 
                onChange={e => setEndMonth(e.target.value)}
                style={{width: '200px'}}
                >
                <option value="01">January - 25</option>
                <option value="02">February - 25</option>
                <option value="03">March - 25</option>
                <option value="04">April - 25</option>
                <option value="05">May - 25</option>
                <option value="06">June - 25</option>
                <option value="07">July - 25</option>
                <option value="08">August - 25</option>
                <option value="09">September - 25</option>
                <option value="10">October - 25</option>
                <option value="11">November - 25</option>
                <option value="12">December - 25</option>
                </select>
            </div>
            </>
            }
                
                <div className="form-label">
                    <label className='form-label'>Consent Type: </label>
                    <select 
                            className="form-control" 
                            name="category" 
                            value={consentCategory} 
                            onChange={e => setConsentCategory(e.target.value)}
                            // style={{width: '250px'}}
                            placeholder='Select Stats'
                        >
                            <option value="RPM">RPM</option>
                            <option value="CCM">CCM</option>
                    </select>
                </div>

                <div>
                    <label className='form-label'>Action </label><br />
                    <button className="edit-pill" onClick={() => searchHandler()} >Search</button>
                </div>
                </div>
                <br />

                <div className="row mt-2">
                {nurseProgress && nurseProgress.sort((a, b) => b?.sum - a?.sum).map((nursesProgress, index) => (
                    <>
                     <div className="col-md-4 text-center" key={index}>
                        <div className={`card p-3 performanceCard ${index === 0 ? "activePerformanceCard" : ""}`}>
                            <div className="row">
                            <img src={nurseIcon} 
                                alt="nurseImg" 
                                width="70" 
                                height="70" 
                                className="roundedBorderImg centeredImg"
                            />
                            </div>
                            
                            <br />
                            <h5>{nursesProgress?._id?.firstname} {nursesProgress?._id?.lastname}</h5>

                            <span>Tracked Time</span>                   
                            <h3>{nursesProgress?.sum}</h3>
                            {index === 0 ? 
                            <span style={{color: 'gold'}}> 
                                <i className='bx bxs-star'></i> Best Performer
                            </span> : null        
                            }           
                        </div>
                    </div> 
                    </>
                ))}  
                </div>
    </div>
  )
}

export default NurseProgressComponent