import {useState, useEffect} from 'react';
import  Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import MetaData from '../../layouts/MetaData';
import Loader from '../../layouts/Loader';
import { useSelector, useDispatch } from 'react-redux';
import folderImg from '../../assets/Images/folder.png';
import { Link, useHistory } from 'react-router-dom';
import { getDoctorCCMPatients, searchDoctorCCMPatient } from '../../actions/adminActions';
import { Form, InputGroup } from 'react-bootstrap';
import moment from 'moment';


const StaffCCMPatients = () => {

    const { user, isAuthenticated} = useSelector(state => state.auth);
    const { loading, doctorpatients } = useSelector(state => state.docPatients);

    const [keyword, setKeyword] = useState('');
    const [searchBy, setSearchBy] = useState('emrId');

    const history = useHistory();
    const dispatch = useDispatch();
    
    let id = user._id;

    useEffect(() => {
        dispatch(getDoctorCCMPatients(id));
	}, [dispatch])

    const searchhandler = () => {
        dispatch(searchDoctorCCMPatient(id, searchBy, keyword));
    }

    return (
    <>
    <MetaData title="CCM Patients"/>
                <Sidebar />    

            <section className="home-section">
                {/* TopBar */}
                <TopBar />
                <br />

                {loading ? <Loader /> : <>
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                    <div className="home-content">
                        <div>
                            <div className="row-display">
                                <h5 className="title">CCM Patients </h5>

                                <div>
                                <div className="row">
                                    <div className="row-display">
                                    
                                        <select 
                                            className="form-control" 
                                            value={searchBy}
                                            onChange={e => setSearchBy(e.target.value)}
                                            >
                                                <option value="emrId">EHR-ID</option>
                                                <option value="firstname">Firstname</option>
                                                <option value="lastname">Lastname</option>
                                                <option value="email">email</option>
                                                <option value="phone1">phone1</option>
                                                <option value="address">Address</option>
                                                <option value="state">State</option>
                                                <option value="city">City</option>
                                                <option value="zipCode">Zip Code</option>
                                        </select>    
                                            &nbsp;
                                            <InputGroup>
                                                    <Form.Control
                                                        placeholder="Search here ..."
                                                        aria-label="Search here ..."
                                                        className="form-control"
                                                        style={{width: '300px'}}
                                                        value={keyword}
                                                        onChange={e => setKeyword(e.target.value) }
                                                    />
                                                    &nbsp;<button className="edit-pill m-2" 
                                                        id="button-addon2" onClick={searchhandler}>
                                                        <i className='bx bx-search-alt'></i>
                                                    </button>
                                            </InputGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />


                            {doctorpatients?.length > 0 ? <>
                                <div className="col-md-12">
                                    <>
                                    <table className="styled-table">
                                    <thead align="center">
                                        <tr>
                                            <th>Name & EHR-ID</th>
                                            <th>DOB </th>
                                            <th>Acc Status</th>
                                            <th>Assigned Doctor</th>
                                            <th>Assigned Nurse</th>
                                            <th>Consent Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {doctorpatients && doctorpatients.map((patient, index) => ( 
                                            <tr align="center" key={index}>

                                            <td>
                                                <Link className="link" to={{ pathname: "/doctor/patient/profile", state: {patientid: patient?._id, deviceid: patient?.deviceassigned?.deviceid}}}>{patient?.firstname} {patient?.lastname}</Link>
                                                <p>{patient?.emrId}</p>    
                                            </td>
                                            
                                            <td> 
                                                <b>{moment(patient?.DOB).format("MM/DD/YYYY")}</b> <p>
                                                    {moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years old
                                                </p>
                                            </td>
                                            
                                            {patient?.block === false ? <td>
                                                <p style={{color: 'green', fontWeight: 'bold'}}>Activated</p>
                                                </td> : <td><p style={{color: 'red', fontWeight: 'bold'}}>Inactive</p>
                                            </td>}

                                            {patient?.assigned_doctor_id ? <>
                                                <td> <b>Dr. {patient?.assigned_doctor_id?.firstname} {patient?.assigned_doctor_id?.lastname}</b></td>
                                                </> : <>
                                                <td>---</td>
                                            </>}
                                            
                                            {patient?.assigned_hr_id ? <>
                                                <td> <b>{patient?.assigned_hr_id?.firstname} {patient?.assigned_hr_id?.lastname}</b></td>
                                                </> : <>
                                                <td>---</td>
                                            </>}

                                            <td><b>{patient?.patientType}</b></td>
                                        </tr> 
                                        ))}
                                        
                                        </tbody>
                                        </table>    
                                    </>                      
                            </div>
                        </>  : <>

                        <div>   
                            <img src={folderImg} className="no-record-found-img"/>
                            <p className="doctor-specilizations">Not Found Any Patient...</p>         
                        </div>
                        </> }
                        </div>
                    </div>
                </div>
                </>
            }

            </section>
    </>
  )
}

export default StaffCCMPatients