import {useEffect} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { getTotalMinutesReportMonthwise } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';

const colors = ['#02C78A', '#009aa8', '#00A8A8'];

const RPMTotalMonthlyMinutesGraph = ({category}) => {

const dispatch = useDispatch();

const { monthlyTargets } = useSelector(state => state.monthlyTargetMinutes);


useEffect(() => {
  dispatch(getTotalMinutesReportMonthwise(category));
  
}, [dispatch, category]);


let data = monthlyTargets && monthlyTargets.slice(0,3).map(targets => {
     
  return {
      'date': targets?._id?.month,
      'Total Minutes': targets?.total
  } 
});



const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};


  return ( <>
  <div>
  <ResponsiveContainer aspect={2 / 1} style={{ width: '100%', overflowY: 'hidden' }}>
  <BarChart
    width={500}
    height={300}
    data={data}
    margin={{
      top: 10,
      right: 10,
      left: 10,
      bottom: 5,
    }}
  >
    <XAxis dataKey="date" />
    <YAxis />

    <CartesianGrid strokeDasharray="3 3" />

    <Bar dataKey="Total Minutes" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
      ))}
    </Bar>
  </BarChart>
</ResponsiveContainer>
    </div>
    </>
  )
}

export default RPMTotalMonthlyMinutesGraph	
