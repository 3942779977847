import React, {
    useState,
    useEffect,
    Fragment,
  } from 'react';
  import { logout } from '../actions/authActions';
  import { useDispatch } from 'react-redux';


function SessionTimeout() {
  
  const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector(state => state.auth);

  const [loggedIn, setLoggedIn] = useState(true);

    //   function to check for inactivity and logged out
    const checkForInactivity = () => {
    
    // Get expire time from localstorage
    const expireTime = localStorage.getItem("expireTime"); 
    
    // if expire time is earlier than now, log out
    if(expireTime < Date.now()) {
      window.location.replace("/");
        dispatch(logout());
        localStorage.removeItem('token');
        setLoggedIn(false);
    } 
    }

    // function to update expire time
    const updateExpireTime = () => {
     
    // Set Expire time to fifteen minutes (30)
     const expireTime = Date.now() + 1800000; //900000 missiseconds in 30 minutes. 
     
     localStorage.setItem("expireTime", expireTime);
    }

    // useEffect to set interval to check for inactivity
    useEffect(() => {
    
        // Check for inactivity every 5 seconds
        const interval = setInterval(() => {
        checkForInactivity();
        }, 5000)

        // clear interval on unmount
        return () => clearInterval(interval);

    }, []);

    // update expire time on any user activity
    useEffect(() => {
      updateExpireTime();
      
      window.addEventListener('click', updateExpireTime);
      window.addEventListener('keypress', updateExpireTime);
      window.addEventListener('scroll', updateExpireTime);

    // clean up
    return () => {
        window.removeEventListener("click", updateExpireTime);
        window.removeEventListener('keypress', updateExpireTime);
        window.removeEventListener('scroll', updateExpireTime);
    } 

    }, []);


  return <Fragment />

}

export default SessionTimeout