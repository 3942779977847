import { useEffect } from 'react'
import { Link, useLocation, useHistory  } from 'react-router-dom';
import TextField from '../components/Form/TextField';
import MetaData from '../layouts/MetaData';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Spinner} from 'react-bootstrap';
import { verifyOTPCode } from '../actions/authActions';
import {HIDE_ALERT_MESSAGE} from '../constants/Common';
import loginLogo from '../assets/Images/loginIcon.png';

const VerifyAccount = () => {

	const location = useLocation();
	const alert = useAlert();
	const dispatch = useDispatch();
	const history = useHistory();

	const {error, message, verified, loading} = useSelector(state => state.common);

	const _id = location.state?._id;
	const email = location.state?.email;
	const forAccount = location.state?.forAccount;

	const validate = Yup.object().shape({
		otp: Yup.number().required().min(100000, "Must be 6 digits").max(1000000),
    })

	useEffect(() => {
        if(error){
        alert.error(error);

        dispatch({
            type: HIDE_ALERT_MESSAGE
          })
        }

        if(message) {
            alert.success(message);

			dispatch({
                type: HIDE_ALERT_MESSAGE
            })
        }

		if(forAccount === 'hr' && verified === true){
			history.push('/Nurse/Dashboard');
		} else if(forAccount === 'admin' && verified === true){
			history.push('/adminDashboard');
		} else if(forAccount === 'assistant' && verified === true){
			history.push('/clinical_staff/dashboard');
		} else if(forAccount === 'doctor' && verified === true){
			history.push('/doctor/dashboard');
		}

    }, [dispatch, alert, error, message])


	const submitHandler = (values) => {
		dispatch(verifyOTPCode(_id, values?.otp, forAccount,email))
	}

    return (
    	<>
		<MetaData title="Verify Account" />
		 <>
				<div className="login-section">
					<div className="text-center text-white mt-4">
						<h1 className="display-4">2-factor authentication</h1>
						<p>We have sent 6-digit verification code to your registered email address. Enter your OTP code here. </p>

					</div>
					<div className="container bg-white" style={{marginTop: '3%', width: '45%', borderRadius: '10px'}}>
						<div className="col-md-12" >
							<br />

							<div className="text-center">
								<img src={loginLogo} className='img-responsive loginIcon'/>
							</div>

							<Formik initialValues={{
								opt: ''
							}}
								validationSchema={validate}
								onSubmit={values => {
								submitHandler(values)
							}}
							>
								<div style={{margin: '0px 80px'}}>
									<Form>
										<TextField
											label="OTP Code"
											name="otp"
											placeholder="Enter 6-digit OTP code"
                                            maxLength= "6"
										/>

                                        <br/>
										{/* <button className="reset-btn" type="reset"><i className='bx bx-reset' ></i></button> */}
										<div className="text-center">
										{loading === true ? <>
											<Spinner animation="border" style={{height: '20px', width: '20px'}}/> Sending Code ... </> :<button className="loginScreenBtn" type="submit">
											Verify
										</button> }

										<br/><br/>

											{/* <p><Link to="/" className="link">Resend Code</Link></p> */}
											<p><Link to="/" className="link">www.rpmatrix.com</Link></p>

											<br/>
											</div>

									</Form>
								</div>
							</Formik>
						</div>
					</div>
				</div>
		</>
    </>
    )
}

export default VerifyAccount
