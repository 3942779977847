
const Loader = () => {
    return (
        // <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        
        <span className="loader"></span>
        
        // <div className="loader-area">
        // <div className="loader"> 
        //     Loading...                 
        // </div>
        // </div>
    )
}

export default Loader
