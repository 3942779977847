import {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import womanIcon from '../../assets/Images/woman.png';
import patientProfileImg from '../../assets/Images/user.png';
import PatientEncounters from '../../components/Patient/PatientEncounters';
import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';
import CCMMinutesProgress from '../../components/HR/CCMMinutesProgress';
import PatientCCMConsent from '../../components/Patient/PatientCCMConsent';
import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
import NotFound from '../../components/NotFound';
import PatientCareplans from '../../components/Patient/PatientCareplans';
import { calcTelemetryAvg } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import AddTimeManuallyByDr from '../../components/Staff/AddTimeManuallyByDr';
import { ADDING_TIME_SPENT_RESET } from '../../constants/HRConstants';
import { useAlert } from 'react-alert';
import PatientSickness from '../Patient/PatientSickness';
import PatientCCMCareplanManualView from '../Patient/PatientCCMCareplanManualView';



const StaffPatientInfo = ({patient, telemetaryReadings, totalTime}) => {
    const alert = useAlert();
    const dispatch = useDispatch();    
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('vitals');
    const [minutesCategory, setMinutesCategory] = useState('RPM');

    const handleShow = () => setAddTimeShow(true);
    const handleClose = () => setAddTimeShow(false);
    const [addTimeShow, setAddTimeShow] = useState(false);

    const { isSuccessful } = useSelector(state => state.timeSpent);

    const { average } = useSelector(state => state.avgCalc);
    const { user } = useSelector(state => state.auth);


    // avgReadings 
    const [deviceId, setDeviceId] = useState(patient?.assigned_devices?.length > 0 ? patient?.assigned_devices[0]?.deviceObjectId?._id : null);
    const [records, setRecords] = useState(10);

    let patientid = patient?._id;

    useEffect(() => {
        if(isSuccessful) {
            setAddTimeShow(false);    
            alert.success('Time Added Manually');   
            dispatch({ type: ADDING_TIME_SPENT_RESET})
        }
    }, [isSuccessful]);

    useEffect(() => {
        if(deviceId !== null){
             handleAverageCalc();
        }
    }, [deviceId])

    const handleAverageCalc = () => {
        dispatch(calcTelemetryAvg(patientid, deviceId, records));
    };

    return (
    <>
     <div>
         <div className="row-display">
            <h5 className="title">Patient Overview</h5>

            <div>
                <button className="btn btn-secondary btn-sm" 
                    onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                </button> &nbsp;
                <Link to="/doctor/dashboard" className="btn btn-secondary btn-sm"><i className='bx bx-home'></i></Link> &nbsp;
                &nbsp;&nbsp;

                <button className="edit-pill" onClick={handleShow}>
                 <i className='bx bx-time pr-2'> </i>
                    Add Time Manually
                </button>
            </div>
        </div>
        <hr />

        <div className="row-display">
            <div className="col-md-6">
                <div className="row">
                    <div className="text-center  col-6" style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
                        <img src={patient?.gender === 'male' ? patientProfileImg : womanIcon} className="patient-profile-card-img" alt="patientProfile" />
                        <h5 className="title mt-2">{patient?.lastname}, {patient?.firstname}</h5>
                        <p><b>{patient?.emrId}</b></p>    

                        <div className="row">
                            <div className='col-6'>
                                <b>D.O.B</b>
                                <p>{moment(patient?.DOB).format('MM/DD/YYYY')}</p>
                            </div>

                            <div className='col-6'>
                                <b>Age</b>
                                <p>{moment().diff(moment(patient?.DOB).format("MM/DD/YYYY"), 'years')} years</p>
                            </div>
                            <br/>

                            <div className='col-6'>
                                <b>Gender</b>
                                <p>{patient?.gender}</p>
                            </div>

                            <div className='col-6'>
                                <b>Role</b>
                                <p>{patient?.patientType === "Both" ? "RPM & CCM" : patient?.patientType}</p>
                            </div>

                            <div className='col-12'>
                            {patient?.assigned_devices?.length > 0 ? <>
                                    <b>Device(s) Assigned</b>
                                    {patient?.assigned_devices?.length > 0 && patient?.assigned_devices.map((deviceass, index) => (
                                    <div className="col-12" key={index}>
                                        <b>{deviceass?.deviceObjectId?.deviceType === 'bp' ? <span className='bpDeviceType'>B.P</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'weight' ? <span className='weightDeviceType'>Weight</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'sugar' ? <span className='glucoseDeviceType'>Glucose</span> :
                                            deviceass?.deviceObjectId?.deviceType === 'spO2' ? <span className='spo2DeviceType'>Oximeter</span> :
                                            null
                                        }</b>
                                        <br /><br />
                                    </div>
                                ))}
                                </> : 
                                <div className='col-12'>
                                        <p>No Device Assigned</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <b>Home Address</b>
                        <p className="shrink-text">
                            {patient?.address || null}, {patient?.line2 || null} - {patient?.city || null} - {patient?.state || null} - {patient?.zipCode || null}
                        </p>

                        <b>Primary Phone #</b>
                        <p className="shrink-text">
                            {patient?.phone1 || '--'}
                        </p>

                        <b>Email Address</b>
                        <p className="shrink-text link">
                            {patient?.email || '--'}
                        </p>

                        <b>Insurance Company</b>
                        <p className="shrink-text">
                            {patient?.insurancecompany || '--'}
                        </p>

                        <b>Account Created Date</b>
                        <p className="shrink-text">
                            {moment(patient?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-md-3">
                <div>
                    <PatientSickness
                        isAuthenticated={false}
                        patient={patient}
                    />
                </div>
            </div>

            <div className="text-center col-md-3">
            <br/><br/>

                <div className="diseaseSection">
                    <b>Disease(s)</b>
                    <p>{patient?.diseases || '--'}</p>
                </div>

                <p className='text-muted m-0 p-0'>Assigned Doctor</p>
                
                
                {patient?.assigned_doctor_id ? <>
                    <p>
                    <b>Dr. {patient?.assigned_doctor_id?.lastname || null}, {patient?.assigned_doctor_id?.firstname || null}
                    </b></p>
                </> : null}

                {patient?.ccmCareplanManualAdded === false && patient?.sickness?.length > 0  ? 
                    <b style={{color: 'red'}}>
                        <i className='bx bx-x'></i> CCM Careplan Not Added
                      </b>
                    : patient?.sickness?.length > 0 && <b style={{color: 'green'}}>
                        <i className='bx bx-check'></i> CCM Careplan Added
                      </b>
                }
                    
                {patient?.ccmCareplanManualAdded === true ? <PatientCCMCareplanManualView
                    patient={patient} 
                /> : null}
            </div>
        </div>

        <div> 
            <PatientEncounters 
                patientId={patient?._id} 
                extraNotes={patient?.extraNotes}
                user={user}
                isEligible={false} 
            />
        </div>
        <br/>

        <div className='mt-3 mb-3' style={{width: '40%'}}>
        <div className='row-display'>
            <button className={`${activeTab === 'vitals' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('vitals')}> Vitals</button>

            <button className={`${activeTab === 'documents' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('documents')}>Documents</button>
        </div>
        </div>
       <br/>

       <div className="row">           
         <div className='col-md-9' style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
         {activeTab === 'vitals' ? <>
            <h5 className='title'>Recent Vitals</h5>
            
            <div className='row'>
                <div className='col-8'>
                {telemetaryReadings && telemetaryReadings.length > 0 ? <>
                    <table className="styled-table" >
                            <thead>
                                <tr>
                                    <th width="40px">#</th>
                                    <th>Vitals</th>
                                    <th>Pulse</th>
                                    <th>Device</th>
                                    <th>Date & Time</th>
                                    <th width="40px"></th>
                                </tr>
                            </thead>
                            <tbody>

                            {telemetaryReadings && telemetaryReadings.slice(0,10).map((devicedata, index) => (
                            
                            <tr align="center" key={index} className={devicedata?.notes && devicedata?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                            
                            <td><b>{index + 1}</b></td>
                            
                              {/* vitals */}
                            {devicedata?.deviceId?.deviceType === "bp" ? <td> 
                                    <b>{devicedata?.telemetaryData?.sys} / {devicedata?.telemetaryData?.dia} mmHG </b><p>
                                        {devicedata?.telemetaryData?.sys >= 139 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                        devicedata?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                        devicedata?.telemetaryData?.dia >= 90 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                        devicedata?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                        </p>
                                        </td> : devicedata?.deviceId?.deviceType === "weight" ? <td>
                                        <b>{(devicedata?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                        <p>{(devicedata?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "sugar" ? <td>
                                    <b> {devicedata?.telemetaryData?.data} &nbsp;
                                        {devicedata?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "spO2" ? <td>
                                    <b>{devicedata?.telemetaryData?.notification_payload?.spo2_pct}
                                    <p>Perfusion: {devicedata?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                </td> : <td>---</td> 
                            }

                            {/* Pulse rate */}
                            <td>
                                <b>{devicedata?.telemetaryData?.pul ? devicedata?.telemetaryData?.pul +'bpm' : '---'} </b>
                            </td>

                            {/* Device Type */}
                            <td><b>{devicedata?.deviceId?.deviceType === "bp" ? 
                                <span className='bpDeviceType'>B.P</span> : 
                                devicedata?.deviceId?.deviceType === "weight" ? 
                                <span className='weightDeviceType'>Weight</span> : 
                                devicedata?.deviceId?.deviceType === "sugar" ? 
                                <span className="glucoseDeviceType">Glucose</span> :
                                devicedata?.deviceId?.deviceType === "spO2" ? 
                                <span className="spo2DeviceType">Spo2</span> : null }</b>    
                            </td>

                            {/* date & time */}
                            <td>{devicedata?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(devicedata?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                    </>}
                            </td>
                            
                            {/* responded or not */}
                            <td>{devicedata?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                <i className="fas fa-times" style={{color: 'red'}}></i>}
                            </td>

                            </tr>
                            ))}
                            </tbody>
                    </table>
                    </>
                    : <NotFound title="No Record Found."/> 
                }
                </div>


                <div className='col-4'>
                {patient?.assigned_devices?.length > 0 ? <>
                    <h5 className='title text-center'>Average Calculation</h5>
                
                    <div className='row-display'>
                        <select className='form-control' value={deviceId} onChange={e => setDeviceId(e.target.value)}>
                            {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                            <option value={deviceass?.deviceObjectId?._id} key={index}>
                                {deviceass?.deviceObjectId?.deviceType}
                            </option>
                            ))}
                        </select>
                        &nbsp;&nbsp;

                        <select className='form-control' name="records" 
                            value={records} 
                            onChange={e => setRecords(e.target.value)}
                        >
                            <option value="10">Last 10 records</option>
                            <option value="20">Last 20 records</option>
                            <option value="30">Last 30 records</option>
                            <option value="40">Last 40 records</option>
                        </select>
                        &nbsp;&nbsp;
                        <button className='edit-pill' onClick={handleAverageCalc}>
                            <i className="bx bx-search"></i>
                        </button>
                    </div>

                    

                <div className='text-center mt-4'>
                    {average?.deviceType === 'bp' ? <>
                    <div className='text-center mt-4'>
                    <i className='bx bx-heart' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Blood Pressure Readings</h5>    
                    <br/>
                    <h2><b>
                        {average?.averageSys} / {average?.averageDia}
                        </b> <small>mmHG</small></h2>
                        </div>
                        <br/>

                        <div className='text-center mt-4'>
                            <i className='bx bx-pulse' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                            <h5 className='text-muted'>Pulse</h5>    
                                <h2><b>
                                {average?.averagePulse}
                                </b> <small>BPM</small></h2>
                        </div>
                    </> 
                    : average?.deviceType === 'spO2' ? <>
                    <i className='bx bxs-droplet' style={{color: '#C20A4D', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Spo2 Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Pulse BPM</h5>
                        <h3>{average?.averagePulseBPM}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>Average Spo2 PCT</h5>
                        <h3>{average?.averageSpo2PCT}</h3>
                    </div> 

                    </> : average?.deviceType === 'weight' ? <>
                    <i className='bx bxs-hourglass-top' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Weight Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Weight</h5>
                        <h3>{average?.averageWeight}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>In KGs</h5>
                        <h3>{(average?.averageWeight / 1000).toFixed(2)}</h3>
                    </div>

                    <br />
                    <div className='row-display'>
                        <h5>In Pounds</h5>
                        <h3>{(average?.averageWeight / 453.6).toFixed(2)}</h3>
                    </div>
                    </> : average?.deviceType === 'sugar' ? <>
                    <i className='bx bxs-droplet' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Sugar Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Sugar</h5>
                        <h3>{average?.averageSugar}</h3>
                    </div>
                    </> : null 
                    }        
                </div>
                    <hr/>
                </> : null
                }

                {/* Devices Assigned */}
                <h5 className='title text-center'>Device(s) Details</h5>

                {patient?.assigned_devices?.length > 0 ?  <>
                    {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                    <div key={index}>
                        <div>
                            
                                <strong className='link'>IMEI: </strong> 
                                <p><b>{deviceass?.deviceObjectId?.imei || '---'}</b></p>
                            

                                <strong className='link'>Assign Date: </strong> 
                                <p><b>{deviceass?.deviceObjectId?.assignedTime || '---'}</b></p>

                                <div className='row-display'>
                                <div>  
                                <strong className='link'>RPM Consent : </strong> 
                                    {patient?.rpmconsent === true ? <p style={{color: 'green', fontWeight: 'bold'}}>
                                        Signed</p> : 
                                    <p style={{color: 'red', fontWeight: 'bold'}}>Not Signed</p>}
                                </div>

                                <div>    
                                    <strong className='link'>Initial Month:</strong> 
                                    <p><b>{patient?.initialsetup}</b></p>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                    ))}
                    </> : null
                }
     
                </div>        
            </div>
        
       </>  :

       activeTab === 'documents' ? <>
       <div className="row">
        <div className="col-md-6 mt-2">
            <PatientRPMCareplan patientId={patient?._id} isNurse={false}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientRPMConsent patientId={patient?._id} isNurse={false} />
        </div>

        <div className="col-md-6 mt-2">
            <PatientCareplans patientId={patient?._id} isNurse={false}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientCCMConsent patientId={patient?._id} isNurse={false}/>
        </div>
        
        </div>
       </> : null}
        </div>

        <div className='col-md-3'>
            <div className="p-2">    
                <h5 className="title mt-2">Targets Count</h5> 
                    <p>
                        <small>For the month of {new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}
                        </small>
                    </p>
                

                {patient?.patientType === 'Both' ? <>
                    <select className="form-control" value={minutesCategory} onChange={e => setMinutesCategory(e.target.value)}>
                        <option value="RPM">RPM Category</option>
                        <option value="CCM">CCM Category</option>
                    </select>
                    <br />
                </> : null}                                    
            
                        
                    {patient?.patientType === 'RPM' ? <RPMMinutesProgress patientId={patientid} />
                        : patient?.patientType === 'CCM' ?  <CCMMinutesProgress patientId={patient?._id} />
                        : patient?.patientType === 'Both' ? 
                            <>
                            {minutesCategory === 'RPM' ? <RPMMinutesProgress patientId={patientid} /> 
                            : minutesCategory === 'CCM' ?
                            <CCMMinutesProgress patientId={patient?._id} />
                             : null }
                            </>
                        : null }
                    </div>
            </div> 
        </div>   

         {/* Time spent Modal */}
         <Modal size="md" show={addTimeShow} onHide={handleClose}>
                <div className="row-display">    
                    <h5 className="title m-2 p-2">Add Time Manually</h5> 
                    <button className='edit-pill p-2 m-2' onClick={handleClose}>
                        <i className='bx bx-x'></i></button>
                </div>   

                <Modal.Body>
                    <AddTimeManuallyByDr 
                        totalTime={totalTime} 
                        drId={user?._id} 
                        patientId={patientid} 
                        patientType={patient?.patientType} 
                        />   
                </Modal.Body>
            </Modal>



        </div>
    </>
  )
}

export default StaffPatientInfo