import {useState} from 'react';
import MetaData from '../../layouts/MetaData';
import {useAlert} from 'react-alert';
import { TIME_SUMMARY_REPORT_RESET } from '../../constants/adminConstants';
import Loader from '../../layouts/Loader';
import { useDispatch, useSelector} from 'react-redux';
import { getTimeSummaryReportByDoctor } from '../../actions/adminActions';
import ExportSummaryReportToCSV from '../../components/ExportSummaryReportToCSV';
import Sidebar from '../../components/Staff/Sidebar';
import TopBar from '../../components/Staff/TopBar';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

const DoctorTimeSummaryReport = () => {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState('2024');

  const { user } = useSelector(state => state.auth);
  const {loading, timeSummaryReport} = useSelector(state => state.summaryReport);

  const generateTimeSummaryByHR = () => {
     if(!month){
      alert.error('Please select month');
      return;
    } else if(!year){
      alert.error('Please select month');
      return;
    } else {
      dispatch(getTimeSummaryReportByDoctor(user._id, month, year));
    }
  }

  const resetReport = () => {
    dispatch({
        type: TIME_SUMMARY_REPORT_RESET
    })
  }  


  return (
    <>
     <MetaData title="Financial Report" />
        <Sidebar />
            
            <section className="home-section">
                {/* TopBar */}  
                <TopBar />
                <br />

        <>
            <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">        
                <div className="home-content">
                    <div className='row-display'>
                            <h5 className='title'>RPM Financial Report</h5>
                            <div>  
                            <button className="btn btn-secondary btn-sm" 
                              onClick={() => history.goBack()}><i className='bx bx-arrow-back'></i>
                            </button>
                            </div>
                    </div>
                            <hr />

                            <div className="row cardWrapper">
                            <div className="col-md-3">
                              <label className="form-label">Month </label>
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={month} 
                                  onChange={e => setMonth(e.target.value)}
                                  >
                                  <option value="01">January</option>
                                  <option value="02">February</option>
                                  <option value="03">March</option>
                                  <option value="04">April</option>
                                  <option value="05">May</option>
                                  <option value="06">June</option>
                                  <option value="07">July</option>
                                  <option value="08">August</option>
                                  <option value="09">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                            </div>

                            <div className="col-md-3">
                            <label className="form-label">Year </label>
                                <select 
                                  name="month" 
                                  className="form-control"
                                  value={year} 
                                  onChange={e => setYear(e.target.value)}
                                  >
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                </select>              
                            </div>

                            <div className="col-md-1 mt-1">
                              <label className="form-label">Action </label>
                                <button className="edit-pill" onClick={generateTimeSummaryByHR}>Generate</button>
                            </div>
                            </div>
                        </div>
                  

                  <br />
                  {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                    <div className="row-display">
                    <div className="col-md-10">

                    </div>
                    <button className="edit-pill" style={{backgroundColor: 'red'}} onClick={resetReport}>
                      <i className='bx bxs-file-pdf'></i> Reset
                    </button> 
                    &nbsp;    
                    
                    <div>
                        <div style={{ display: "none" }}> 
                        </div>
                        <div className="col-md-2 col-lg-2">
                            <ExportSummaryReportToCSV csvData={timeSummaryReport} fileName="Summary Telemetary Report.csv" />
                        </div>
                    </div>
                </div>
              </> : null }

                {loading ? <Loader /> : <>
                {timeSummaryReport && timeSummaryReport?.length > 0 ? <>
                        <div className="col-md-12">
                          <br />
                          <table className="styled-table">
                              <thead align="center">
                              <tr>
                                <th>Sr.</th>
                                <th>Patient Name</th>
                                <th>Total Readings Received </th>
                                <th>Total Minutes</th>
                                <th>CPT Codes</th>
                                <th>Month & Year</th>
                                <th>Category</th>
                              </tr>
                              </thead>
                              <tbody>

                                {timeSummaryReport && timeSummaryReport.map((summaryReport, index) => (
                                  <tr key={index}> 
                                    <td><b>{index + 1}</b></td>
                                    
                                    <td style={{wordWrap: 'break-word'}}>
                                    <Link to={{ pathname: "/doctor/patient/profile", state: {patientid: summaryReport?.patientId }}} className="link">
                                      {summaryReport?.patientName}
                                    </Link>
                                    </td>

                                    {summaryReport.totalReadings > 16 ? 
                                      <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td> : 
                                      <td style={{color: 'red', fontWeight: 'bold'}}>{summaryReport?.totalReadings}</td>
                                    }

                                    {summaryReport?.totalMinutes < 20 ? 
                                      <td style={{color: 'red' , fontWeight: 'bold'}}>{summaryReport?.totalMinutes} mins</td> : 
                                      <td style={{color: 'green', fontWeight: 'bold'}}>{summaryReport?.totalMinutes} mins</td>
                                    }
                                    
                                    <td>
                                      {summaryReport.totalReadings >= 16 ? 
                                        <text style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}> 
                                        99454</text> : null }
                                      {summaryReport.totalMinutes >= 20 && summaryReport.totalMinutes < 40 ? 
                                        <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>99457</p> : 
                                        summaryReport.totalMinutes >= 40 ? <p style={{fontSize: '14px', color: 'green', fontWeight: 'bold'}}>
                                          99457 <br /> 99458</p> : null}
                                    </td>

                                    <td style={{wordWrap: 'break-word'}}>
                                      <b>{summaryReport?.Month} - {year && year}</b>
                                    </td>

                                    <td style={{fontWeight: 'bold'}}>RPM</td>
                                  </tr>
                                ))}                
                            </tbody>
                          </table>
                        </div>


                   </> : <>
                   <div className="text-center">
                       <b>No Result Found.</b>
                   </div>
                   </>}
                </>}

              </div>
        </>
      </section>
    
    </>
  )
}

export default DoctorTimeSummaryReport;