import { useState, useEffect } from 'react';
import Sidebar  from '../../components/AdminDashboard/Sidebar';
import TopBar  from '../../components/AdminDashboard/TopBar';
import MetaData from '../../layouts/MetaData';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { removeDoctorFromOrganization, removeNurseFromOrganization } from '../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_ALERT_MESSAGE } from '../../constants/Common';
import { useAlert } from 'react-alert';
import { Modal, Badge } from 'react-bootstrap';
import patientProfileImg from '../../assets/Images/crowd.png';
import doctorImg from '../../assets/Images/doctorIcon.png';
import assistantImg from '../../assets/Images/network.png';
import nurseImg from '../../assets/Images/nurse.png';


const OrganizationProfile = (props) => {
    
    const history = useHistory();
    const dispatch = useDispatch();
    const alert = useAlert();
    const [activeTab, setActiveTab] = useState("doctors");

    let orgInfo = props?.location?.state?.orgsDetails;

    const {_id, name, city, country } = orgInfo;
    const {error, message } = useSelector(state => state.common);
    const [noteModalShow, setNoteModalShow] = useState(false);

    const handleNoteModalShow = () => setNoteModalShow(true);
    const handleNoteModalClose = () => setNoteModalShow(false);
    
    useEffect(() => {
        if(error){
            alert.error(error);

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }

        if(message){
            alert.success(message);
            history.push('/organizations');

            dispatch({
                type: HIDE_ALERT_MESSAGE
              })
        }
        
    }, [dispatch, alert, error, message]);


    const removeDoctorHandler = (doctorId) => {
    
    var confirmation = window.confirm(`Are you sure you want remove doctor from organizaton ?`);
        if(confirmation){
        dispatch(removeDoctorFromOrganization(doctorId, _id));
      }
    }

    const setActiveTabInStorage = (linkname) => {
        setActiveTab(linkname);
    }

    const removeNurseHandler = (nurseId) => {
        var confirmation = window.confirm(`Are you sure you want to remove nurse from organization ?`);
        if(confirmation){
        dispatch(removeNurseFromOrganization(nurseId, _id));
        }
    }

    return (
    <>
    <MetaData title="Organization"/>
                <Sidebar />    

                <section className="home-section">
                {/* TopBar */}
                <TopBar />
                
                <br />
                <div className="shadow-lg p-3 mb-5 mr-4 ml-4 background-white">
                    <div className="home-content">
                    <div className="row-display">
                        <h5 className='title'>
                            <i className="bx bx-edit"></i> <strong>&nbsp;Organization Profile</strong>
                        </h5>

                        <div>
                            <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
                                <i className='bx bx-arrow-back' ></i> 
                            </button>
                            &nbsp;&nbsp;
                            <Link to="/adminDashboard" className="btn btn-secondary btn-sm">
                                <i className='bx bx-home' ></i></Link> &nbsp;

                            <Link to={{pathname: "/assign/organization", state: {orgInfo: orgInfo}}} className="edit-pill">
                                Assign Doctor
                            </Link>

                            &nbsp;&nbsp;
                            <Link to={{pathname: "/assign/organization/clinicalstaff", state: {orgInfo: orgInfo}}} className="edit-pill">
                                Assign Clinical Staff
                            </Link>

                            &nbsp;&nbsp;
                            <Link to={{pathname: "/assign/organization/nurses", state: {orgInfo: orgInfo}}} className="edit-pill">
                                Assign Nurse
                            </Link>

                            &nbsp;&nbsp;
                            {/* {_id === "65b91571420e3eaf1158f971" && <button className='edit-pill' onClick={handleNoteModalShow}>Blanchard B.P Criteria</button> } */}
                        </div>
                    </div>
                    <hr />



                    <div className='row-display'>
                        <div>
                            <p className='form-label'>Name :</p>  
                            <b>{name}</b>
                        </div>

                        <div>
                            <p className='form-label'>City</p>
                            <span className='profile-label'>{city}</span>
                        </div>

                        <div>
                            <p className='form-label'>Country</p>
                            <span className='profile-label'>{country}</span>
                        </div>

                        <div>
                            <p className='form-label'>Created Date</p>
                            <span className='profile-label'>{moment(orgInfo?.createdAt).tz("America/New_York").format('MM/DD/YYYY hh:mm z A')}</span>
                        </div>
                    </div>

                    <hr className='blue-hr'/>
                    <h5 className='title'>Entities Stats : </h5>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className="stats-container">
                                <img src={patientProfileImg} className="patient-profile-card-img" />
                                <div className="text-content">
                                    <h5 className="title mt-3 ml-3">Total Patients</h5>
                                    <div className="stats-number">
                                        {orgInfo?.totalPatientCount}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="stats-container">
                                <img src={doctorImg} className="patient-profile-card-img" />
                                <div className="text-content">
                                    <h5 className="title mt-3 ml-3">Total Doctors</h5>
                                    <div className="stats-number">
                                        {'0' + orgInfo?.doctors?.length}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="stats-container">
                                <img src={assistantImg} className="patient-profile-card-img" />
                                <div className="text-content">
                                    <h5 className="title mt-3 ml-3">Total Clinical Staff</h5>
                                    <div className="stats-number">
                                        {'0' + orgInfo?.assistants?.length}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="stats-container">
                                <img src={nurseImg} className="patient-profile-card-img" />
                                <div className="text-content">
                                    <h5 className="title mt-3 ml-3">Total Nurses</h5>
                                    <div className="stats-number">
                                        {'0' + orgInfo?.nurses?.length}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <br />

                    <div className='row-display' style={{width: '50%'}}>
                        <button className={`${activeTab === 'doctors' ? 'tabsBtnActive' : 'tabsBtn'}`}
                            onClick={() => setActiveTabInStorage('doctors')}>Assigned Doctors
                        </button>
                            
                        <button className={`${activeTab === 'clinicalStaff' ? 'tabsBtnActive' : 'tabsBtn'}`}
                            onClick={() => setActiveTabInStorage('clinicalStaff')}>Assigned Clinical Staff
                        </button>
                        
                        <button className={`${activeTab === 'nurseStaff' ? 'tabsBtnActive' : 'tabsBtn'}`}
                            onClick={() => setActiveTabInStorage('nurseStaff')}>Assigned Nurses
                        </button>
                    </div>
                    <br />

                    {activeTab === 'doctors' ? <>
                    {orgInfo?.doctors?.length > 0 && <>
                                <table className="styled-table">
                                <thead align="center">
                                    <tr>
                                        <th>Name & Email</th>
                                        <th>Verification Status</th>
                                        <th>Gender</th>
                                        <th>No. of Patients</th>
                                        <th>Account Status</th>
                                        <th>NPI number</th>
                                        <th>License Number</th>
                                        <th>Assigned Date</th>
                                        <th width="70px">Actions</th>
                                    </tr> 
                                </thead>
                            <tbody>      

                        {orgInfo?.doctors && orgInfo?.doctors.map((doctor, index) => (                      
                            
                            <tr align="center" key={index}>
                                
                                <td className="text-center">
                                    <Link className="link" to={{ pathname: "/doctorProfile", state: {id: doctor?.assigned_doctor_id?._id}}}> Dr. {doctor?.assigned_doctor_id?.lastname +" , "+ doctor?.assigned_doctor_id?.firstname} </Link> 
                                    <p>{doctor?.assigned_doctor_id?.email}</p>
                                </td>

                                <td>{doctor?.assigned_doctor_id?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}</td>    

                                <td><b>
                                    {doctor?.assigned_doctor_id?.gender === 'male' ? 'Male' : 'Female'}
                                </b></td>

                                <td className="text-center"><b style={{color: '#00A8A8', fontSize: '18px'}}>
                                    <i className='bx bx-user'></i> &nbsp; {doctor?.assigned_doctor_id?.patientCount || 0} </b>
                                </td>
                                
                                {doctor?.assigned_doctor_id?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>} 
                                
                                <td><b>{doctor?.assigned_doctor_id?.npinumber}</b></td>

                                <td><b>{doctor?.assigned_doctor_id?.licensenumber}</b></td>
                                
                                <td><b>{doctor?.addedDateTime && moment(doctor?.addedDateTime).format("MM-DD-YYYY hh:mm")}</b></td>
                                
                                {/* Actions */}
                                <td>
                                    <div className="row-display">
                                        <button 
                                        onClick={() => removeDoctorHandler(doctor?.assigned_doctor_id?._id)} 
                                        className="edit-pill"
                                        style={{backgroundColor:'red', color:'white'}}
                                        >
                                            <i className='bx bx-minus'></i>
                                        </button>
                                    </div>
                                </td>
                                {/* Actions ended*/}
                            </tr>                      
                        ))}
                            </tbody>
                        </table>
                        </>
                    } </>  //doctors details ended
                    : activeTab === 'clinicalStaff' ?
                    <>
                    {orgInfo?.assistants?.length > 0 && <>
                                <table className="styled-table">
                                <thead align="center">
                                    <tr>
                                        <th>Name & Email</th>
                                        <th>Verification Status</th>
                                        <th>Gender</th>
                                        <th>Account Status</th>
                                        <th>Assigned Date</th>
                                        <th width="70px">Actions</th>
                                    </tr> 
                                </thead>
                            <tbody>      

                        {orgInfo?.assistants && orgInfo?.assistants.map((assistant, index) => (                      
                            
                            <tr align="center" key={index}>
                                
                                <td className="text-center">
                                    <Link className="link" to={{ pathname: "/doctorProfile", state: {id: assistant?.assigned_assistant_id?._id}}}> Asst. {assistant?.assigned_assistant_id?.lastname +" , "+ assistant?.assigned_assistant_id?.firstname} </Link> 
                                    <p>{assistant?.assigned_doctor_id?.email}</p>
                                </td>

                                <td>{assistant?.assigned_assistant_id?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}</td>    

                                <td><b>
                                    {assistant?.assigned_assistant_id?.gender === 'male' ? 'Male' : 'Female'}
                                </b></td>

                                
                                {assistant?.assigned_assistant_id?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>} 
                                                                
                                <td><b>{assistant?.addedDateTime && moment(assistant?.addedDateTime).format("MM-DD-YYYY hh:mm a")}</b></td>
                                
                                <td>
                                    <div className="row-display">
                                        <button 
                                        // onClick={() => removeDoctorHandler(assistant?.assigned_assistant_id?._id)} 
                                        className="edit-pill"
                                        style={{backgroundColor:'red', color:'white'}}
                                        >
                                            <i className='bx bx-minus'></i>
                                        </button>
                                    </div>
                                </td>
                                {/* Actions ended*/}
                            </tr>                      
                        ))}
                            </tbody>
                        </table>
                        </>
                    }
                    </> //Clinical Staff details ended
                    : activeTab === 'nurseStaff' ? <>
                    
                    {orgInfo?.nurses?.length > 0 && <>
                                <table className="styled-table">
                                <thead align="center">
                                    <tr>
                                        <th>Name & Email</th>
                                        <th>Verification Status</th>
                                        <th>Gender</th>
                                        <th>Account Status</th>
                                        <th>Assigned Date</th>
                                        <th width="70px">Actions</th>
                                    </tr> 
                                </thead>
                            <tbody>      

                        {orgInfo?.nurses && orgInfo?.nurses.map((nurse, index) => (                      
                            
                            <tr align="center" key={index}>
                                
                                <td className="text-center">
                                    <Link className="link" to={{ pathname: "/doctorProfile", state: {id: nurse?.assigned_hr_id?._id}}}> 
                                        RN. {nurse?.assigned_hr_id?.lastname +" , "+ nurse?.assigned_hr_id?.firstname} </Link> 
                                    <p>{nurse?.assigned_hr_id?.email}</p>
                                </td>

                                <td>{nurse?.assigned_hr_id?.verified === true ? <b style={{color: 'green'}}>Verified</b> : <b style={{color: 'red'}}>Not Verified</b>}</td>    

                                <td><b>
                                    {nurse?.assigned_hr_id?.gender === 'male' ? 'Male' : 'Female'}
                                </b></td>

                                
                                {nurse?.assigned_hr_id?.block === false ? <td>
                                    <Badge pill bg="success text-white" style={{fontSize: '14px', fontWeight: 300}}>Active</Badge>
                                    </td> : <td><Badge pill bg="danger text-white" style={{fontSize: '12px'}}>Inactive</Badge>
                                </td>} 
                                                                
                                <td><b>{nurse?.addedDateTime && moment(nurse?.addedDateTime).format("MM-DD-YYYY hh:mm a")}</b></td>
                                
                                <td>
                                    <div className="row-display">
                                        <button 
                                        onClick={() => removeNurseHandler(nurse?.assigned_hr_id?._id)} 
                                        className="edit-pill"
                                        style={{backgroundColor:'red', color:'white'}}
                                        >
                                            <i className='bx bx-minus'></i>
                                        </button>
                                    </div>
                                </td>
                                {/* Actions ended*/}
                            </tr>                      
                        ))}
                            </tbody>
                        </table>
                        </>
                    }
                    
                    </> : null
                }    
                </div>
            </div>



        {/* Add Special Instruction Modal */}
        <Modal show={noteModalShow} onHide={handleNoteModalClose} size="lg">
            <Modal.Header >
                <h5 className="title">Blanchard B.P Criteria</h5> 
                <button className='edit-pill'onClick={handleNoteModalClose}>
                    <i className='bx bx-x'></i></button>
            </Modal.Header>

                <Modal.Body>
                <table className="styled-table" style={{backgroundColor: '#EDEADE'}}>
                    <thead align="center">
                        <tr>
                            <th width="80px">Blanchard</th>
                            <th width="150px">Systolic</th>
                            <th width="150px">Diastolic</th>
                            <th></th>
                        </tr> 
                    </thead>

                    <tbody>
                       <tr>
                            <td><b>Above</b></td>
                            <td>140</td>
                            <td>90</td>
                            <td>Create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>110</td>
                            <td>60</td>
                            <td>Create ECW Encounter</td>
                        </tr>   

                        <tr>
                            <td><b>Above</b></td>
                            <td>150</td>
                            <td>90</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call the office staff and create ECW Encounter</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>170</td>
                            <td>100</td>
                            <td>Call or text provider</td>
                        </tr>    

                        <tr>
                            <td><b>Below</b></td>
                            <td>100</td>
                            <td>55</td>
                            <td>Call or text provider</td>
                        </tr>

                        <tr>
                            <td><b>Above</b></td>
                            <td>180</td>
                            <td>110</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr>

                        <tr>
                            <td><b>Below</b></td>
                            <td>90</td>
                            <td>50</td>
                            <td>Ask patient/caregibver to call 911 after 2 confirmatory tests</td>
                        </tr> 
                    </tbody>
                </table>      
                </Modal.Body>
            </Modal>
            {/* Extra note modeal ends here*/}
        </section>
        
    </>
    )
}

export default OrganizationProfile