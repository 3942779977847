    import {useEffect, useState} from 'react';
    import { useDispatch, useSelector } from 'react-redux';
    import { Modal, Button } from 'react-bootstrap';
    import { Formik, Form } from 'formik';
    import TextField from '../../components/Form/TextField';
    import { carePlanOfPatient } from '../../actions/HRActions';
    import { calcTelemetryAvg } from '../../actions/adminActions';
    import moment from 'moment';
    import { ADDING_CARE_PLAN_RESET, ADDING_TIME_SPENT_RESET } from '../../constants/HRConstants';
    import { useAlert } from 'react-alert';
    import RPMMinutesProgress from '../../components/HR/RPMMinutesProgress';
    import CCMMinutesProgress from '../../components/HR/CCMMinutesProgress';
    import PatientCCMConsent from '../../components/Patient/PatientCCMConsent';
    import PatientCareplans from '../../components/Patient/PatientCareplans';
    import PatientRPMCareplan from '../../components/Patient/PatientRPMCareplan';
    import PatientRPMConsent from '../../components/Patient/PatientRPMConsent';
    import NotFound from '../../components/NotFound';
    import PatientEncounters from '../../components/Patient/PatientEncounters';
    import HRPatientOverview from './HRPatientOverview';
    

    const HRPatientInfo = ({patient, telemetaryReadings}) => {
    
    const dispatch = useDispatch();   
    const alert = useAlert();
    
    const [activeTab, setActiveTab] = useState('vitals');
    
    var check = moment(new Date(), 'YYYY/MM/DD');

    let month = check.format('M');
    month = Number(month)
    var year = check.format('YYYY');

    //  Careplan Fields
    const [carePlanShow ,setCarePlanShow] = useState(false);
    const [description, setDescription] = useState('');
    const [readingsInDay, setReadingsInDay] = useState('');
    const [readingsInNight, setReadingsInNight] = useState('');
    const [readingsPerMonth, setReadingsPerMonth] = useState('');
    const [fileName, setFileName] = useState({});
    const [minutesCategory, setMinutesCategory] = useState('RPM');
    const [addTimeShow, setAddTimeShow] = useState(false);

    // avgReadings 
    const [deviceId, setDeviceId] = useState(patient?.assigned_devices?.length > 0 ? patient?.assigned_devices[0]?.deviceObjectId?._id : null);
    const [records, setRecords] = useState(10);    

    const handleClose = () => setAddTimeShow(false);

    const handleCarePlanModalClose = () => setCarePlanShow(false);
    const handleCarePlanModalShow = () => setCarePlanShow(true);
    
    const { totalTime } = useSelector(state => state.totalTimeSpent);

    const {isSuccessful, carePlanAdded, error: careplanerror } = useSelector(state => state.timeSpent);
    const {user} = useSelector(state => state.auth); 
    const { average } = useSelector(state => state.avgCalc);
    

    let hrId = user?._id;
    let patientid = patient?._id;

    useEffect(() => {
    
    if(careplanerror){
        alert.error(careplanerror);
    }
        
    if(isSuccessful) {
        setAddTimeShow(false);
        alert.success('Minutes added successfully');       
        dispatch({ type: ADDING_TIME_SPENT_RESET})
    }

    if(carePlanAdded){
        setCarePlanShow(false);
        alert.success('Careplan Added');
        dispatch({ type: ADDING_CARE_PLAN_RESET });
        setDescription(''); 
    }

    if(deviceId !== null){
        handleAverageCalc();
    }

    },[dispatch, careplanerror, isSuccessful, carePlanAdded]);

    const handleFileChange = e => {
        setFileName(e.target.files[0]);
    }

    const submitCarePlan = () => {
        if(description === '') {
            setCarePlanShow(false);
            alert.error('Description is required');
            return
        }

    dispatch(carePlanOfPatient(patientid, hrId, 
            description, 
            readingsPerMonth, 
            readingsInDay,
            readingsInNight, 
            fileName))
        }

    const handleAverageCalc = () => {
        dispatch(calcTelemetryAvg(patientid, deviceId, records));
      };

    return (
        <>
        
        <div className="p-3">
        
        <HRPatientOverview 
            patient={patient}
            totalTime={totalTime} 
            hrId={hrId} 
        />
        
        <div> 
            <PatientEncounters 
                patientId={patientid} 
                patient={patient}
                extraNotes={patient?.extraNotes} 
                user={user}
                isEligible={true} 
            />
        </div>
        <br/>             


        <div className='mt-3 mb-3' style={{width: '40%'}}>
            <div className='row-display'>
                <button className={`${activeTab === 'vitals' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('vitals')}> Vitals</button>
                <button className={`${activeTab === 'documents' ? 'tabsBtnActive' : 'tabsBtn'}`} onClick={() => setActiveTab('documents')}>Documents</button>
            </div>
        </div>
       <br/>

       <div className="row">           
         <div className='col-md-9' style={{borderRightWidth: '3px', borderRightColor: 'gray', borderRightStyle: 'dotted'}}>
         {activeTab === 'vitals' ? <>
            <h5 className='title'>Recent Vitals</h5>
            
            <div className='row'>
                <div className='col-8'>
                {telemetaryReadings && telemetaryReadings.length > 0 ? <>
                    <table className="styled-table" >
                            <thead>
                                <tr>
                                    <th width="40px">#</th>
                                    <th>Vitals</th>
                                    <th>Pulse</th>
                                    <th>Device</th>
                                    <th>Date & Time</th>
                                    <th width="40px"></th>
                                </tr>
                            </thead>
                            <tbody>

                            {telemetaryReadings && telemetaryReadings.slice(0,10).map((devicedata, index) => (
                            
                            <tr align="center" key={index} className={devicedata?.notes && devicedata?.notes?.length > 0 ? 'coloredRow' : 'reddishRow'}>
                            
                            <td><b>{index + 1}</b></td>
                            
                              {/* vitals */}
                            {devicedata?.deviceId?.deviceType === "bp" ? <td> 
                                    <b>{devicedata?.telemetaryData?.sys} / {devicedata?.telemetaryData?.dia} mmHG </b><p>
                                        {devicedata?.telemetaryData?.sys >= 139 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Systolic is High</span> : 
                                        devicedata?.telemetaryData?.sys <= 110 ? <span style={{color: 'red', fontWeight: 'bold'}}> Systolic is Low</span> :
                                        devicedata?.telemetaryData?.dia >= 90 ? 
                                        <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is High</span> : 
                                        devicedata?.telemetaryData?.dia <= 70 ? <span style={{color: 'red', fontWeight: 'bold'}}>Diastolic is Low </span> : null }
                                        </p>
                                        </td> : devicedata?.deviceId?.deviceType === "weight" ? <td>
                                        <b>{(devicedata?.telemetaryData?.wt / 1000).toFixed(2)} &nbsp;Kg 
                                        <p>{(devicedata?.telemetaryData?.wt / 453.6).toFixed(2)} lbs</p>
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "sugar" ? <td>
                                    <b> {devicedata?.telemetaryData?.data} &nbsp;
                                        {devicedata?.telemetaryData?.unit === 1 ? 'mmol/L' : 'mg/dl'}
                                    </b>
                                </td> : devicedata?.deviceId?.deviceType === "spO2" ? <td>
                                    <b>{devicedata?.telemetaryData?.notification_payload?.spo2_pct}
                                    <p>Perfusion: {devicedata?.telemetaryData?.notification_payload?.perfusion_index_pct}</p></b>
                                </td> : <td>---</td> 
                            }

                            {/* Pulse rate */}
                            <td>
                                <b>{devicedata?.telemetaryData?.pul ? devicedata?.telemetaryData?.pul +'bpm' : '---'} </b>
                            </td>

                            {/* Device Type */}
                            <td><b>{devicedata?.deviceId?.deviceType === "bp" ? 
                                <span className='bpDeviceType'>B.P</span> : 
                                devicedata?.deviceId?.deviceType === "weight" ? 
                                <span className='weightDeviceType'>Weight</span> : 
                                devicedata?.deviceId?.deviceType === "sugar" ? 
                                <span className="glucoseDeviceType">Glucose</span> :
                                devicedata?.deviceId?.deviceType === "spO2" ? 
                                <span className="spo2DeviceType">Spo2</span> : null }</b>    
                            </td>

                            {/* date & time */}
                            <td>{devicedata?.telemetaryData?.notification_payload?.reading_type === "pulse_ox" ? <>
                                    <b>{moment(devicedata?.telemetaryData?.notification_payload?.ts_received * 1000).tz("America/New_York").format("MM/DD/YYYY hh:mm z A")}</b>
                                    </> : <>
                                        <b>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("MM/DD/YYYY")} </b>
                                        <p>{moment(devicedata?.telemetaryData?.ts * 1000).tz("America/New_York").format("hh:mm z A")}</p>
                                    </>}
                            </td>
                            
                            {/* responded or not */}
                            <td>{devicedata?.notes?.length > 0 ? <i className="fas fa-check" style={{color: 'green'}}></i> : 
                                <i className="fas fa-times" style={{color: 'red'}}></i>}
                            </td>

                            </tr>
                            ))}
                            </tbody>
                    </table>
                    </>
                    : <NotFound title="No Record Found."/> 
                }
                </div>


                <div className='col-4'> 

                    {patient?.assigned_devices?.length > 0 ? <>
                    <h5 className='title text-center'>Average Calculation</h5>

                <div className='row-display'>
                    <select className='form-control' value={deviceId} onChange={e => setDeviceId(e.target.value)}>
                        {patient?.assigned_devices && patient?.assigned_devices.map((deviceass, index) => (
                        <option value={deviceass?.deviceObjectId?._id} key={index}>
                            {deviceass?.deviceObjectId?.deviceType}
                        </option>
                    ))}
                    </select>
                    &nbsp;&nbsp;

                    <select className='form-control' name="records" 
                        value={records} 
                        onChange={e => setRecords(e.target.value)}
                    >
                        <option value="10">10 records</option>
                        <option value="20">20 records</option>
                        <option value="30">30 records</option>
                        <option value="40">40 records</option>
                    </select>
                    &nbsp;&nbsp;
                    <button className='edit-pill' onClick={handleAverageCalc}>
                        <i className="bx bx-search"></i>
                    </button>
                </div>

                <div className='text-center mt-4'>
                    {average?.deviceType === 'bp' ? <>
                    <div className='text-center mt-4'>
                    <i className='bx bx-heart' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Blood Pressure Readings</h5>    
                    <br/>
                    <h2><b>
                        {average?.averageSys} / {average?.averageDia}
                        </b> <small>mmHG</small></h2>
                        </div>
                        <br/>

                        <div className='text-center mt-4'>
                            <i className='bx bx-pulse' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                            <h5 className='text-muted'>Pulse</h5>    
                                <h2><b>
                                {average?.averagePulse}
                                </b> <small>BPM</small></h2>
                        </div>
                    </> 
                    : average?.deviceType === 'spO2' ? <>
                    <i className='bx bxs-droplet' style={{color: '#C20A4D', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Spo2 Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Pulse BPM</h5>
                        <h3>{average?.averagePulseBPM}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>Average Spo2 PCT</h5>
                        <h3>{average?.averageSpo2PCT}</h3>
                    </div> 

                    </> : average?.deviceType === 'weight' ? <>
                    <i className='bx bxs-hourglass-top' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Weight Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Weight</h5>
                        <h3>{average?.averageWeight}</h3>
                    </div>
                    <br />
                    <div className='row-display'>
                        <h5>In KGs</h5>
                        <h3>{(average?.averageWeight / 1000).toFixed(2)}</h3>
                    </div>

                    <br />
                    <div className='row-display'>
                        <h5>In Pounds</h5>
                        <h3>{(average?.averageWeight / 453.6).toFixed(2)}</h3>
                    </div>
                    </> : average?.deviceType === 'sugar' ? <>
                    <i className='bx bxs-droplet' style={{color: '#00A8A8', fontSize: '70px'}}></i>
                    <h5 className='text-muted'>Last <b>{average?.totalReadings}</b> Sugar Readings</h5>
                    <hr />

                    <div className='row-display'>
                        <h5>Average Sugar</h5>
                        <h3>{average?.averageSugar}</h3>
                    </div>
                    </> : null 
                    }        
                </div>
                <hr/>
                </> :  null }    

                </div>        
            </div>
        
       </>  :

       activeTab === 'documents' ? <>
       <div className="row">
        <div className="col-md-6 mt-2">
            <PatientRPMCareplan patientId={patient?._id} isNurse={false}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientRPMConsent patientId={patient?._id} isNurse={true} />
        </div>

        <div className="col-md-6 mt-2">
            <PatientCareplans patientId={patient?._id} isNurse={true}/>
        </div>

        <div className="col-md-6 mt-2">
            <PatientCCMConsent patientId={patient?._id} isNurse={true}/>
        </div>
        
        </div>
       </> : null}
        </div>

        <div className='col-md-3'>
            <div className="p-2">    
                <h5 className="title mt-2">Targets Count</h5> 
                    <p>
                        <small>For the month of {new Date().toLocaleString('en-us',{month:'long', year:'numeric'})}
                        </small>
                    </p>
                

                {patient?.patientType === 'Both' ? <>
                    <select className="form-control" value={minutesCategory} onChange={e => setMinutesCategory(e.target.value)}>
                        <option value="RPM">RPM Category</option>
                        <option value="CCM">CCM Category</option>
                    </select>
                    <br />
                </> : null}                                    
            
                        
                    {patient?.patientType === 'RPM' ? <RPMMinutesProgress patientId={patientid} />
                        : patient?.patientType === 'CCM' ?  <CCMMinutesProgress patientId={patient?._id} />
                        : patient?.patientType === 'Both' ? 
                            <>
                            {minutesCategory === 'RPM' ? <RPMMinutesProgress patientId={patientid} /> 
                            : minutesCategory === 'CCM' ?
                            <CCMMinutesProgress patientId={patient?._id} />
                             : null }
                            </>
                        : null }
                    </div>
            </div> 
        </div>   


            {/* Careplan Modal */}
            <Modal show={carePlanShow} onHide={handleCarePlanModalClose}>
            <Modal.Header >
                <Modal.Title>Add Careplan</Modal.Title> 
                <Button variant="danger" onClick={handleCarePlanModalClose}><i className='bx bx-x'></i></Button>
            </Modal.Header>

                <Modal.Body>
                    <Formik initialValues={{
                        description: '', 
                        readingsPerMonth:'',
                        readingsPerDay: '',
                        fileName: ''

                    }}
                    onSubmit={values => {
                        submitCarePlan(values)
                    }}
                    >
                        <div>
                            <Form>                            
                                <TextField 
                                    label="Readings / mo" 
                                    name="readingsPerMonth" 
                                    type="number" 
                                    placeholder="Readings / mo"
                                    value={readingsPerMonth} 
                                    onChange={(e) => setReadingsPerMonth(e.target.value)}
                                />

                                <TextField 
                                    label="Reading in Morning" 
                                    name="readingsInSlot1" 
                                    type="number" 
                                    placeholder="Readings / day"
                                    value={readingsInDay} 
                                    onChange={(e) => setReadingsInDay(e.target.value)}
                                />

                                <TextField 
                                    label="Reading in Night" 
                                    name="readingsInSlot2" 
                                    type="number" 
                                    placeholder="Readings / day"
                                    value={readingsInNight} 
                                    onChange={(e) => setReadingsInNight(e.target.value)}
                                />

                            <label htmlFor="description" className="form-label mt-3">Description</label>
                                <textarea 
                                    label="Description" 
                                    name="description"
                                    className="form-control"
                                    rows="4"	
                                    value={description} 
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Type description here .... "
                            />

                                <input 
                                    label="File" 
                                    name="fileName" 
                                    type="file"
                                    accept=".doc, .docx, .pdf"
                                    className="form-control"
                                    style={{border: 'none'}} 
                                    onChange={handleFileChange}
                                />
                            <br/>
                            <div className="row-class" style={{justifyContent: 'space-between'}}>
                                <button className="reset-btn ml-3" type="submit"> Submit</button>
                            </div>
                            </Form>
                        </div>
                    </Formik>   
                </Modal.Body>
            </Modal>
            {/* Careplan Modal ends here*/}
        </div>

        </>
    )
    }

    export default HRPatientInfo