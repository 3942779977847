import { useState } from 'react';
import adminImg from '../../assets/Images/defaultUser.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

let active = "Dashboard";

const Sidebar = () => {

    
    const [activeTab, setActiveTab] = useState(localStorage.getItem("items"));
    const { user } = useSelector(state => state.auth);

    const setActiveTabInStorage = (linkname) => {
        localStorage.setItem('items', linkname);
        setActiveTab(linkname);
    }

    const handleActive = (value) => {
        active = value;
    }

    return (
        <>            
        
        <div className="sidebar">
            <br/>
            <div>
                <div className="row-display-secondary" style={{paddingLeft: '10px'}}>
                    {user && user?.profileImg ? <>
                        <img src={`https://www.rpmatrix.com/v1/uploadFiles/${user?.profileImg}`} 
                        width="70" height="70" className="roundedBorderImg" alt="img" 
                    />  
                    </> : <img src={adminImg} width="50" height="50" className="roundedBorderImg" alt="img" />
                    }


                    <p className="text-white mt-2">    
                        {user && user?.name} <sup>{user?.verified === true ? <i className='bx bxs-badge-check' 
                            ></i> : null}</sup> 
                            
                            &nbsp;&nbsp;        
                            <Link to="/me" className="link text-white"><small>Edit profile</small></Link>

                            <p style={{fontSize: '18px', color: 'white'}}>Admin Dashboard</p>
                    </p>
                </div>
            </div>


            <div className='row'>
                <button className={`${activeTab === 'links' ? 'tabsBtnActive' : 'tabsBtn'}`}
                    onClick={() => setActiveTabInStorage('links')}>Quick Links
                </button>
                
                <button className={`${activeTab === 'reports' ? 'tabsBtnActive' : 'tabsBtn'}`}
                    onClick={() => setActiveTabInStorage('reports')}>Reports
                </button>
            </div>

            
            {activeTab === 'links' && <>

            <ul>
                <li className={`${active === 'Dashboard' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="Dashboard">
                    <Link to="/adminDashboard" 
                    onClick={() => handleActive("Dashboard")} className={`${active === 'Dashboard' ? 'activeLink' : 'link'}`}>
                        <i className='bx bxs-dashboard'></i> 
                        &nbsp;&nbsp;&nbsp;<span>Dashboard</span>
                    </Link>
                </li>
         
                <li className={`${active === 'RPMPatients' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="RPMPatients">
                    <Link to="/patients" 
                        onClick={() => handleActive('RPMPatients')}
                        name="RPMPatients" 
                        className={`${active === 'RPMPatients' ? 'activeLink' : 'sidebar-link'}`}
                    >
                    <i className='bx bx-user'></i>
                        &nbsp;&nbsp;&nbsp;<span>RPM Patients</span>
                    </Link>
                </li>

                 <li className={`${active === 'CCMPatients' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="CCMPatients">
                    <Link to="/CCM/Patients" 
                    onClick={() => handleActive('CCMPatients')}
                    className={`${active === 'CCMPatients' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-user'></i>
                        &nbsp;&nbsp;&nbsp;<span> CCM Patients</span>
                    </Link>
                </li>

                <li 
                    className={`${active === 'avgBPs' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} 
                    key="avgBPs"
                    >
                    <Link to="/averageBPs" 
                    onClick={() => handleActive('avgBPs')}
                    className={`${active === 'avgBPs' ? 'activeLink' : 'sidebar-link'}`}
                    >
                    <i className='bx bxs-droplet' style={{color: 'red'}}></i>
                        &nbsp;&nbsp;&nbsp;<span> Average B.Ps 
                        &nbsp;&nbsp;<span className='newSpan'>New</span>
                        </span>
                    </Link>
                </li>


                <li className={`${active === 'doctors' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="doctors">
                    <Link to="/doctors" 
                    onClick={() => handleActive('doctors')}
                    className={`${active === 'doctors' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-user'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Doctors</span>
                    </Link>
                </li>

                <li className={`${active === 'nurses' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="nurses">
                    <Link to="/hrlist"  
                    onClick={() => handleActive('nurses')}
                    className={`${active === 'nurses' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-user'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Nurses</span>
                    </Link>
                </li>

                <li className={`${active === 'assistants' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="assistants">
                    <Link to="/Assistants" 
                    onClick={() => handleActive('assistants')}
                    className={`${active === 'assistants' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-user'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Assistants</span>
                    </Link>
                </li>

                <li className={`${active === 'devices' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                    <Link to="/devices" 
                    onClick={() => handleActive('devices')}
                    className={`${active === 'devices' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-data'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Inventory</span>
                    </Link>
                </li>

                <li className={`${active === 'resetPswd' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                    <Link to="/credentials" 
                    onClick={() => handleActive('resetPswd')}
                    className={`${active === 'resetPswd' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-key'></i>
                        &nbsp;&nbsp;&nbsp;<span>Reset Password</span>
                    </Link>
                </li>

                <li className={`${active === 'chatMessages' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                    <Link to="/messages" 
                    onClick={() => handleActive('chatMessages')}
                    className={`${active === 'chatMessages' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-chat'></i>
                        &nbsp;&nbsp;&nbsp;<span>Chat & Messages </span>
                    </Link>
                </li>

                <li className={`${active === 'logs' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                    <Link to="/logs" 
                    onClick={() => handleActive('logs')}
                    className={`${active === 'logs' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-chat'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Logs </span>
                    </Link>
                </li>

                <li className={`${active === 'organizations' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                    <Link to="/organizations" 
                    onClick={() => handleActive('organizations')}
                    className={`${active === 'organizations' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-chat'></i>
                        &nbsp;&nbsp;&nbsp;<span>Manage Organizations </span>
                    </Link>
                </li>
                
                {user?._id === '640826226439e492a4e52f33' ? <>
                    <li className={`${active === 'admins' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`}>
                        <Link to="/admins" 
                        onClick={() => handleActive('admins')}
                        className={`${active === 'admins' ? 'activeLink' : 'sidebar-link'}`}  
                        key="admins">
                        <i className='bx bxs-user-account'></i>
                            &nbsp;&nbsp;&nbsp;<span>Manage Admins </span>
                        </Link>
                    </li>
                </> : null}
            </ul>
            </>}

            {activeTab === 'reports' && <>
            <ul>
                <li className={`${active === 'timeReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="TimeReport">
                    <Link to="/Admin/Report/RPM" 
                    onClick={() => handleActive('timeReport')}
                    className={`${active === 'timeReport' ? 'activeLink' : 'sidebar-link'}`}
                    >
                    <i className='bx bx-edit-alt'></i>
                        &nbsp;&nbsp;&nbsp;<span>Time Report</span>
                    </Link>
                </li>

                 {/* Active Patients */}
                 <li className={`${active === 'timereportbypatient' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="timereportbypatient">
                    <Link to="/report/timereportbydate" 
                    onClick={() => handleActive('timereportbypatient')}
                    className={`${active === 'timereportbypatient' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-edit'></i>
                        &nbsp;&nbsp;<span>Time Report By Date
                        &nbsp;&nbsp;<span className='newSpan'>New</span> 

                        </span>
                    </Link>
                </li>

                <li className={`${active === 'financialReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="financialReport">
                    <Link to="/report/financialReport"
                    onClick={() => handleActive('financialReport')}
                    className={`${active === 'financialReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-dollar'></i>
                        &nbsp;&nbsp;&nbsp;<span>Financial Report</span>
                    </Link>
                </li>

                <li className={`${active === 'telemetaryReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="TelemetryReport">
                    <Link to="/report/telemetary" 
                    onClick={() => handleActive('telemetaryReport')}
                    className={`${active === 'telemetaryReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-heart'></i>
                        &nbsp;&nbsp;&nbsp;<span>Telemetry Data Report</span>
                    </Link>
                </li>

                <li className={`${active === 'billingReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="BillingReport">
                    <Link to="/report/billing"
                    onClick={() => handleActive('billingReport')}
                    className={`${active === 'billingReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-copy-alt'></i>
                        &nbsp;&nbsp;&nbsp;<span>Billing Report</span>
                    </Link>
                </li>

                <li className={`${active === 'initialMonthReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="InitialMonthReport">
                    <Link to="/Admin/Report/InitialMonth" 
                    onClick={() => handleActive('initialMonthReport')}
                    className={`${active === 'initialMonthReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-devices'></i>
                        &nbsp;&nbsp;&nbsp;<span>Initial Month Report</span>
                    </Link>
                </li>

                <li className={`${active === 'callReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="callReport">
                    <Link to="/report/call" 
                    onClick={() => handleActive('callReport')}
                    className={`${active === 'callReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-phone'></i>
                        &nbsp;&nbsp;&nbsp;<span>Call Report 
                        </span>
                    </Link>
                </li>

                {/* Active Patients */}
                <li className={`${active === 'activePtsReport' ? 'sidebarLinkBtnActive' : 'sidebarLinkBtn'}`} key="activePtsReport">
                    <Link to="/report/activepts" 
                    onClick={() => handleActive('activePtsReport')}
                    className={`${active === 'activePtsReport' ? 'activeLink' : 'sidebar-link'}`}>
                    <i className='bx bx-phone'></i>
                        &nbsp;&nbsp;&nbsp;<span>Active Patients Report
                        </span>
                    </Link>
                </li>
            </ul>
            </>
            }
        </div>    
        </>
    )
}


export default Sidebar;
